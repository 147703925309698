import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MenuItemEntry from "../../../atoms/MenuItemEntry";
import { menuSubCategories } from "../constants";
import MenuEntryList from "../../../atoms/MenuEntryList";

export default function ItemsFolder({ selectedPath, setSelectedPath, data }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { itemId } = useParams();

  const initialSelectedCategory =
    data?.data?.find((item) => item.item_id === itemId)?.categories[0] ?? "";
  const [selectedCategory, setSelectedCategory] = useState(
    initialSelectedCategory
  );

  const handleRootItemClick = () => {
    setSelectedPath((prev) => (!prev.includes("items") ? "items/" : ""));
    if (!initialSelectedCategory) setSelectedCategory("");
  };

  const categories = menuSubCategories.reduce((categoriesAcc, subCategory) => {
    if (data.data) {
        let sorted_data = data.data.sort((a, b) => {
            const nameA = a?.display_name?.toLowerCase() || '';
            const nameB = b?.display_name?.toLowerCase() || '';
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
          });
      categoriesAcc[subCategory] = sorted_data.reduce((dataAcc, dataItem) => {
        if (dataItem.categories?.includes(subCategory))
          dataAcc[dataItem["item_id"]] = dataItem;
        // console.log("dataAcc: ", dataAcc)
        return dataAcc //.sort((a,b) => a.display_name < b.dispaly_name);
      }, {});
      return categoriesAcc;
    } else {
      categoriesAcc[subCategory] = {};
      return categoriesAcc;
    }
  }, {});

  const navigateToItem = (itemId) => {
    navigate("items/" + itemId);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory((prev) => (prev === category ? "" : category));
    setSelectedPath((prev) =>
      prev.includes(category) ? "items" : prev + category
    );
  };

  return (
    <div className="flex flex-col">
      <MenuItemEntry
        icon="logo_fieldagent_regular"
        label={t("items")}
        isSelected={selectedPath.includes("items")}
        handleClick={handleRootItemClick}
      />
      {selectedPath.includes("items") ? (
        <div className="pl-6 flex flex-col">
          <MenuItemEntry
            icon={"logo_fieldagent_regular"}
            label={t("all_items")}
            isSelected={selectedPath.endsWith("items")}
            handleClick={() => {
              navigate("items");
              handleCategoryClick("items");
            }}
          />
          {Object.entries(categories).map(([category, categoryData]) => {
            return (
              <div key={"items" + category}>
                <MenuItemEntry
                  key={"items" + category}
                  icon={
                    category === "imported"
                      ? "item_arbitrary_regular"
                      : "item_" + category + "_regular"
                  }
                  label={t(category)}
                  isSelected={selectedCategory === category}
                  handleClick={() => handleCategoryClick(category)}
                />
                {selectedCategory === category ? (
                  <div className="flex flex-col pl-6">
                    <MenuEntryList
                      selectedEntryId={itemId}
                      keys={{
                        labelKey: "display_name",
                        idKey: "item_id",
                        iconKey: "type"
                      }}
                      handleEntryClick={(item) => navigateToItem(item.item_id)}
                      data={Object.values(categoryData) ?? []}
                    />
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}
