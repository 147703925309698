import React from "react";
import { useTranslation } from "react-i18next";

import ResponsiveModal from "../../components/molecules/ResponsiveModal";
import Button from "../../components/molecules/Button";

function DeleteFolderModal({
  data,
  isOpen,
  onClose,
  handleDeleteFolder,
  setFolderData
}) {
  const { t } = useTranslation();

  const FolderDesign = () => {
    return (
      <div className="relative">
        <div className="bg-logo w-[126px] h-[93px] rounded-b-[12px] rounded-tr-[8px] shadow-md flex justify-center items-center p-2">
          <p className="text-primary text-base font-yantramanavLight truncate select-none">
            {data.name}
          </p>
        </div>
        <div class="bg-logo absolute -top-1.5 w-[60px] h-[8.5px] rounded-t-[20px]" />
        <div class="bg-primary absolute -top-1.5 left-4 w-[25.5px] h-[2px] rounded-b" />
        <div className="w-4 h-4 absolute top-0 right-0">
          <div className="bg-primary absolute top-2 right-2 w-1 h-1 rounded-full" />
          <div className="bg-primary absolute top-3.5 right-2 w-1 h-1 rounded-full" />
          <div className="bg-primary absolute top-5 right-2 w-1 h-1 rounded-full" />
        </div>
      </div>
    );
  };
  return (
    <ResponsiveModal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setFolderData({});
      }}
      modalTitle="delete_folder"
    >
      {data.name === "default" ? (
        <div className="bg-white w-[607px] h-[396px] p-8 rounded flex flex-col items-center overflow-auto no-scrollbar">
          <div className="w-[356px] flex flex-col items-center gap-[52px]">
            <div className="w-full flex flex-col items-center gap-1">
              <p className="text-[24px] text-center text-primary font-yantramanavRegular leading-[31.13px]">
                {data.name}
              </p>
              <p className="font-yantramanavLight text-center text-[16px] leading-[18.4px] tracking-[1.5%] text-black ">
                {t("default_folder_cannot_be_edited_or_deleted")}
              </p>
            </div>
            <FolderDesign />
            <p className="font-yantramanavLight text-center text-[14px] leading-[18.4px] tracking-[1.5%] text-black ">
              {data.description}
            </p>
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-[50px] p-8">
          <div className="w-full flex flex-col items-center gap-1">
            <p className="text-[24px] text-center text-primary font-yantramanavRegular leading-[31.13px]">
              {data.name}
            </p>
            <p className="font-yantramanavLight text-center text-[16px] leading-[18.4px] tracking-[1.5%] text-black ">
              {t("you_are_about_to_delete_this_folder")}
            </p>
            <p className="font-yantramanavLight text-center text-[16px] leading-[18.4px] tracking-[1.5%] text-black ">
              {t("all_files_in_folder_will_be_deleted")}
            </p>
          </div>
          <div className="flex flex-row justify-center gap-6">
            <Button fill text="cancel" color="lightGray" onClick={onClose} />
            <Button
              fill
              icon={"universal_delete_regular"}
              text="delete"
              color="red"
              onClick={() => handleDeleteFolder(data.vault_id)}
            />
          </div>
        </div>
      )}
    </ResponsiveModal>
  );
}

export default DeleteFolderModal;
