import { getToken } from "../utils/Authentication"
import config from './config.json';
import axios from 'axios'
import { getUser } from "../api/user";
const apiUrl = config.apiUrl;

export async function basicGet(endpoint, extraHeaders={}) {
    let token = await getToken();
    return fetch(apiUrl + endpoint, {
        headers: new Headers({...{
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }, ...extraHeaders}),
    });
}

export async function fetchData({ queryKey }) { // Used for react queryFn's
    const { url } = queryKey[queryKey.length-1]; // Assumes that the {url} object is last
    const response = await basicGet(url);
    if (!response.ok) {
        throw new Error('Network response was not ok')
    }
    return response.json()
}

export async function basicPatch(endpoint, data) {
    let token = await getToken();
    return fetch(apiUrl + endpoint, {
        method: "PATCH",
        headers: new Headers({
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }),
        body: JSON.stringify(data)
    });
}

export async function basicPost(endpoint, data) {
    let token = await getToken();
    return fetch(apiUrl + endpoint, {
        method: "POST",
        headers: new Headers({
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }),
        body: JSON.stringify(data)
    });
}

export async function basicDelete(endpoint) {
  let token = await getToken();
  return fetch(apiUrl + endpoint, {
    method: "DELETE",
    headers: new Headers({
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`
    })
  });
}

export const apiGet = async (endpoint) => {
  try {
    let token = await getToken();
    const response = await fetch(apiUrl + endpoint, {
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`
      })
    });
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.error("error in get");
    return undefined;
  }
};

export const apiGetIssues = async (endpoint) => {
  try {
    let token = await getToken();
    const response = await fetch(apiUrl + endpoint + "?active", {
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`
      })
    });
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.error("error in get", error);
    return undefined;
  }
};

export const sendPasswordResetEmail = async (email) => {
    await axios.delete(`${apiUrl}accounts/password?email=${email}`);
};

export const inviteNewUser = async (user) => {
  let token = await getToken();
  return await axios
    .post(
      apiUrl +
        "registrations?email=" +
        user.email +
        "&first_name=" +
        user.firstName +
        "&last_name=" +
        user.lastName +
        "&language=" +
        user.language +
        "&expiry_hours=24" +
        "&client=" +
        user.client,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      }
    )
    .catch((error) => {console.error("Error: ", error); return false});
};

export const updateUser = async (user, image, user_id) => {
  //TODO: we need to change how we upload Image, currently we are using market_id
  if (image) {
    let newImage = await uploadImage(image, user_id);
    user.profile_images = [newImage];
  }
  await apiPatch("users", user);
  await getUser();

  localStorage.setItem("updateInformation", "true");
  sessionStorage.setItem("refreshAfterUpdateInformation", "true");
  return true;
};

export const uploadImage = async (image, market_id) => {
  let token = await getToken();
  const formData = new FormData();
  formData.append("file", image);
  const options = {
    method: "POST",
    body: formData,
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  let res = await fetch(apiUrl + "images?market_id=" + market_id, options);
  const jsonData = await res.json();
  return jsonData;
};

export const apiPatch = async (endpoint, data) => {
  let token = await getToken();
  await axios
    .patch(apiUrl + endpoint, JSON.stringify(data), {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    })
    .catch((error) => console.error("Error: ", error));
  return;
};

export const uploadPDF = async (pdfFile, filename, market_id) => {
  let token = await getToken();
  const formData = new FormData();
  formData.append("file", { ...pdfFile, name: filename });
  const options = {
    method: "POST",
    body: formData,
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  // TODO: Fix endpoint
  let res = await fetch(apiUrl + "pdf?market_id=" + market_id, options);
  const jsonData = await res.json();
  return jsonData;
};
