import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import certificateImages from "../../../assets/certificates/index";
import Dropdown from "../../atoms/Dropdown";
import NumInput from "../../atoms/NumInput";
import Image from "../../atoms/Image";
import Icon from "../../atoms/Icon";
import Button from "../Button";

const OPTIONS = {
  types: ["BREEAM", "DGNB", "LEED", "passivehouse"],
  BREEAM: [
    "unclassified",
    "acceptable",
    "pass",
    "good",
    "very_good",
    "excellent",
    "outstanding"
  ],
  DGNB: ["bronze", "silver", "gold", "platinum"],
  LEED: ["certified", "silver", "gold", "platinum"],
  passivehouse: ["passivehouse"]
};

function BuildingCertificate({ initialValue, disabled, onChange }) {
  const [certificates, setCertificates] = useState(initialValue);
  const { t } = useTranslation();

  useEffect(() => {
    if (JSON.stringify(initialValue) !== JSON.stringify(certificates)) {
      setCertificates(initialValue);
    }
  }, [initialValue]);

  const handleInputChange = (index, key, value) => {
    setCertificates((prevCertificates) => {
      if (index >= 0 && index < prevCertificates.length) {
        const updatedCertificates = prevCertificates.map((certificate, idx) => {
          if (idx === index) {
            if (key === "type") {
              return {
                ...certificate,
                [key]: value,
                grade: ""
              };
            }
            return { ...certificate, [key]: value };
          }
          return certificate;
        });

        onChange(updatedCertificates);
        return updatedCertificates;
      } else {
        onChange(prevCertificates);
        return prevCertificates;
      }
    });
  };

  const removeCertificate = (index) => {
    if (index >= 0 && index < certificates.length) {
      const updatedCertificates = certificates.filter(
        (_, idx) => idx !== index
      );
      setCertificates(updatedCertificates);
      onChange(updatedCertificates);
    }
  };

  const addNewCertificate = () => {
    const newCertificate = {
      type: "",
      year: "",
      grade: ""
    };

    const newCertificates = [...certificates, newCertificate];
    setCertificates(newCertificates);
    onChange(newCertificates);
  };

  return (
    <div className="w-full h-full flex flex-col gap-3">
      {certificates?.map((item, index) => (
        <div
          key={index}
          className="flex flex-row gap-4 items-start justify-between w-full h-[97px] border rounded-sm shadow-md p-[6px] relative"
        >
          {!disabled ? (
            <button
              onClick={() => removeCertificate(index)}
              className="absolute -top-0.5 -left-1 h-3 w-3 bg-white rounded-full flex items-center justify-center hover:opacity-90"
            >
              <Icon size={14} color="danger">
                universal_close_filled
              </Icon>
            </button>
          ) : null}
          <div className="flex flex-row w-[74%] gap-4 p-2">
            <div className="flex flex-col w-1/2">
              <div className="mb-[9px] w-full">
                <Dropdown
                  index={item.type + item.grade + index}
                  options={OPTIONS.types}
                  initialValue={item.type}
                  disabled={disabled}
                  onChange={(e) => handleInputChange(index, "type", e)}
                />
              </div>
              <div className="w-full">
                <Dropdown
                  key={item.type + index}
                  options={OPTIONS[item.type]}
                  initialValue={item.grade}
                  disabled={disabled}
                  onChange={(e) => handleInputChange(index, "grade", e)}
                />
              </div>
            </div>

            <div className="flex flex-col w-1/2">
              <p className="text-black text-[14px] leading-[18.16px] font-yantramanavLight text-left mb-2 ">
                {t("year")}
              </p>
              <NumInput
                initialValue={item.year}
                disabled={disabled}
                onChange={(e) => handleInputChange(index, "year", e)}
              />
            </div>
          </div>

          <div className="w-[83px] h-[83px] shadow">
            <Image
              size="xLarge"
              source={certificateImages?.[item?.type]?.[item?.grade]}
            />
          </div>
        </div>
      ))}

      {!disabled && (
        <div className={`${certificates.length > 0 ? "mt-1" : " "}`}>
          <Button text="add_certificate" onClick={addNewCertificate} />
        </div>
      )}
    </div>
  );
}

export default BuildingCertificate;
