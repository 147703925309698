import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import MenuItemEntry from "../../../atoms/MenuItemEntry";

export default function ResponsibilityFolder({
  selectedPath,
  setSelectedPath
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isSelected = selectedPath.includes("responsibility");

  const handleClick = () => {
    setSelectedPath((prev) =>
      prev.includes("responsibility") ? "" : "responsibility"
    );
    navigate("responsibility");
  };

  return (
    <div className="flex flex-col">
      <MenuItemEntry
        icon="other_responsibilities_bold"
        label={t("responsibility")}
        isSelected={isSelected}
        handleClick={handleClick}
      />
    </div>
  );
}
