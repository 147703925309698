import React from "react";
import { useTranslation } from "react-i18next";

function Toast({ title, text }) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col">
      {title ? (
        <p className="text-primary font-yantramanavRegular text-[18px]">
          {t(title)}
        </p>
      ) : null}
      <p className="text-black font-yantramanavLight text-[16px]">{t(text)}</p>
    </div>
  );
}

export default Toast;
