import React, { useState } from "react";
import FlatList from "flatlist-react";
import ListCard from "../molecules/ListCard";
import Loadingsymbol from "../atoms/Loadingsymbol";
import Icon from "../atoms/Icon";
import { useTranslation } from "react-i18next";

function getKey(obj, keyArray) { //2023-02-05: Handy function used in FolderTreeV3 as well
  return keyArray.find(key => Object.hasOwn(obj, key)) ?? null;
}

function ListView({
  data,
  columnOrder,
  onClick,
  onClickExpandInfo,
  icon,
  searchString
}) {
  // TODO: Maybe need to send a prop with what to be able to search for instead of the columorder.
  const { t } = useTranslation();
  const [sortBy, setSortBy] = useState("display_name")
    // getKey(data ?? {}, ["display_name", "title", "name"])
//   ); // 2023-02-05: Currently, the different data sets have different field names, for the same thing
  const [descending, setDescending] = useState(false);

  const updateSort = (pressed) => {
    if (sortBy === pressed) {
      setDescending((prev) => !prev);
    } else {
      setSortBy(pressed);
    }
  };

  const renderItem = (item, index) => {
    return (
      <div key={index}>
        <ListCard
          isFirstImage={
            columnOrder?.length > 0 && columnOrder[0]?.key?.includes("image")
          }
          columnOrder={columnOrder}
          data={item}
          onClick={() => onClick(item)}
          onClickExpandInfo={
            onClickExpandInfo ? (e) => onClickExpandInfo(e, item) : null
          }
          icon={icon}
        />
      </div>
    );
  };

  const renderWhenEmpty = () => {
    // Loadingsymbol...
    return (
      <div className="w-full h-3/4 mt-8 flex flex-col justify-center items-center bg-white">
        <Loadingsymbol />
        <p className="text-body font-yantramanaRegular text-primary">
          {t("loading")}...
        </p>
      </div>
    );
  };

  return (
    <ul className="flex flex-col w-full h-full">
      <div className="flex flex-row h-[27px] w-full sticky top-0 z-10">
        {columnOrder?.map((order) => (
          <div
            onClick={() => updateSort(order.key)}
            key={order.key}
            className={`${
              order?.key?.includes("image") ? "w-[152px]" : "w-1/6 flex-grow"
            } px-2 h-full flex justify-between items-center border-mediumGray bg-darkWhite border-b border-r hover:cursor-pointer`}
          >
            <p className="text-columnTitle font-yantramanavLight truncate">
              {t(order?.label)}
            </p>
            {order?.key?.includes("image") ? null : (
              <Icon color="primary" size={15}>
                other_sort_regular
              </Icon>
            )}
          </div>
        ))}
      </div>
      <FlatList
        list={data}
        renderItem={renderItem}
        renderWhenEmpty={() => (
          <div className="w-full h-3/4 mt-8 flex flex-col justify-center items-center bg-white">
            <p className="text-body font-yantramanaRegular text-primary">
              {t("list_is_empty")}
            </p>
          </div>
        )}
        // sortBy={[
        //     sortBy,
        //     { key: "item_id", descending: descending }
        // ]}
        sortBy={sortBy}
        sortDescending={descending}
        renderOnScroll
        // limit={1000} // TODO! This might hi
        wrapperHtmlTag="div"
        searchTerm={
          searchString ? searchString.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") : 0
        }
        searchBy={
          columnOrder[0]?.key?.includes("image")
            ? columnOrder.slice(1)
            : columnOrder
        }
        searchCaseInsensitive
        className="w-full h-full overflow-scroll no-scrollbar mb-20"
      ></FlatList>
    </ul>
  );
}

export default ListView;
