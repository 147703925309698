import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import MenuItemEntry from "../../../atoms/MenuItemEntry";

export default function AnalyticsFolder({ selectedPath, setSelectedPath }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isSelected = selectedPath.includes("analytics");

  const handleClick = () => {
    setSelectedPath((prev) => (prev.includes("analytics") ? "" : "analytics"));
    navigate("analytics");
  };

  return (
    <div className="flex flex-col">
      <MenuItemEntry
        icon="other_barchart_bold"
        label={t("analytics")}
        isSelected={isSelected}
        handleClick={handleClick}
      />
    </div>
  );
}
