import { useEffect, useState, useRef } from 'react';
import { motion, useMotionValue } from 'framer-motion';
import { useTranslation } from "react-i18next";

import { realToElementPX, pointInPolygon, elementToRealPX } from './helper';
import PolygonArea from './PolygonArea';

import Dropdown from '../../atoms/Dropdown';
// import markerIcon from '../../../assets/rectangle.png';
import markerIcon from '../../../assets/marker_icon.png';

const SCALE_FACTOR = 0.2
const MARKER_HEIGHT = 269*SCALE_FACTOR;
const MARKER_WIDTH = 158*SCALE_FACTOR;

// const MARKER_HEIGHT = 50;
// // const MARKER_WIDTH = MARKER_HEIGHT * 0.57;
// const MARKER_WIDTH = MARKER_HEIGHT * 1;
const ONCLICK_SCALE = 1.5;

/**
 * This is addedfrom useMounts/SinglePage.js
 * It's the location data defined in the item document inside firestore
 * @typedef {Object} ItemLocationData
 * @property {{x: number, y: number}} pixel_location
 * @property {string} location_area
 * @property {string} floor_plan
 */

/**
 * @typedef {Object} LocationArea
 * @property {string} color
 * @property {Array.<{x: number, y: number}>} points
 */

/**
 * Added marketLocationData from useMounts/SinglePage.js
 * It's the location data inside a market collection inside firestore
 * @typedef {Object.<string, any>} MarketLocationData
 * @property {string} footprint_image - URL of the floor_plan image
 * @property {Array.<Object.<string, LocationArea>>} location_areas
 */

/**
 * @typedef {Object} LocationMapProps
 * @property {function(ItemLocationData): void} onChange
 * @property {{itemLocationData: ItemLocationData, marketLocationData: MarketLocationData}} initialValue
 */

/** @param {LocationMapProps} props @returns {ReactNode} */
function LocationMap(props) {
  const { initialValue } = props;
  const [mapImgSrc, setMapImgSrc] = useState("");
  const [floorPlan, setFloorPlan] = useState("");
  //const [markerLocation, setMarkerLocation] = useState(null);
  const [toggleMaximize, setToggleMaximize] = useState(false);
  const [selectedLocationArea, setSelectedLocationArea] = useState("");
  const [imgLoaded, setImgLoaded] = useState(false);
  const [partchData, setPatchData] = useState(/**@type {LocationItemData} */null)

  const mapImgSize = useRef({ width: 0, height: 0 });
  const markerLocationX = useMotionValue(0);
  const markerLocationY = useMotionValue(0);
  const markerScale = useMotionValue(1);

  const mapParentRef = useRef(null);
  const mapImg = useRef(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (initialValue) {
      if (Object.keys(initialValue["itemLocationData"]).length === 0 || Object.keys(initialValue["marketLocationData"]).length === 0) {
        // Set the imgSrc to "unavilable" or something
      } else {

        let selectedFloor = initialValue.itemLocationData.floor_plan;
        if (!(selectedFloor in initialValue.marketLocationData)) {
          selectedFloor = Object.keys(initialValue.marketLocationData)[0];
        }
        setMapImgSrc(initialValue["marketLocationData"][selectedFloor]["footprint_image"].url) // set selected location to first item
        setPatchData(initialValue.itemLocationData)

        let imgHeight = (70 * 5);
        let imgWidth = 0;
        let dimensionMultiplier = 0;
        const { height: imgOriginalHeight, width: imgOriginalWidth } = initialValue.marketLocationData[selectedFloor]["footprint_image"];
        dimensionMultiplier = (imgOriginalWidth / imgOriginalHeight);
        imgWidth = imgHeight * dimensionMultiplier;
        mapImgSize.current = { height: imgHeight, width: imgWidth }
      }
    }
  }, [])


  useEffect(() => {
    if (imgLoaded && mapParentRef.current && mapImg.current) {
      setFloorPlan(initialValue.itemLocationData.floor_plan)
      setSelectedLocationArea(initialValue.itemLocationData.location_area)

      const pixelLocation = initialValue.itemLocationData.pixel_location;
      const elementSize = mapImgSize.current;
      const imageSize = { width: mapImg.current.naturalWidth, height: mapImg.current.naturalHeight };
      const { x, y } = realToElementPX(pixelLocation, elementSize, imageSize)
      setMarkerPosition(x, y);
    }
  }, [imgLoaded])

  const handleFloorPlanChange = (selectedFloor) => {
    setFloorPlan(selectedFloor);
    setPatchData(
      /** @param {ItemLocationData} prev @returns {ItemLocationData} */
      (prev) => ({ ...prev, floor_plan: selectedFloor })
    )
    setMapImgSrc(initialValue["marketLocationData"][selectedFloor]["footprint_image"]);
  }

  const setMarkerPosition = (x, y) => {

    const w_1 = MARKER_WIDTH;
    const w_2 = toggleMaximize ? w_1 * (ONCLICK_SCALE) : w_1;
    const x_offset = ((w_1 - w_2) / 2);

    const h_1 = MARKER_HEIGHT;
    const h_2 = toggleMaximize ? (h_1 * ONCLICK_SCALE) : h_1;
    const y_offset = (h_1 - h_2) / 2;


    markerLocationX.set((((x - x_offset) - (w_2 / 2)))) // TODO NOT WORKING
    markerLocationY.set((((y - y_offset) - (h_2)))) // -8 makes it be right above pointer coordinates

  }

  const handleMapClick = (event) => {
    if (props.disabled || true) { // Don't let user edit until marketData is global // Anton, 20230119
      setToggleMaximize(true);
      return
    }

    // I will comment this out untill we want to use it // Tom, 20241203

    // if (!toggleMaximize) {
    //   setToggleMaximize(true);
    // } else {
    //   const rect = event.currentTarget.getBoundingClientRect();
    //   const clickX = event.clientX - rect.left;
    //   const clickY = event.clientY - rect.top;

    //   const markerCoordinates = {
    //     x: clickX / ONCLICK_SCALE,
    //     y: clickY / ONCLICK_SCALE
    //   }

    //   const availableOptions = initialValue.marketLocationData[floorPlan].location_areas;
    //   let newLocationArea = "indoor";
    //   for (const field in availableOptions) {
    //     const option = availableOptions[field];

    //     if (pointInPolygon(markerCoordinates, option.points)) {
    //       setSelectedLocationArea(field);
    //       newLocationArea = field
    //     }
    //   }
    //   setSelectedLocationArea(newLocationArea)
    //   setMarkerPosition(markerCoordinates.x, markerCoordinates.y)

    //   const elementSize = { width: mapParentRef.current.clientWidth, height: mapParentRef.current.clientHeight };
    //   const imageSize = { width: mapImg.current.clientWidth, height: mapImg.current.clientHeight };
    //   const { realX, realY } = elementToRealPX(markerCoordinates, elementSize, imageSize);
    //   setPatchData(
    //     /** @param {ItemLocationData} prev @returns {ItemLocationData} */
    //     (prev) => ({ ...prev, location_area: newLocationArea, pixel_location: { x: realX, y: realY } })
    //   )
    // }
  }

  const handleMapMinimize = (event) => {
    setToggleMaximize(false);
  }

  const hasLocation = initialValue && Object.keys(initialValue.itemLocationData).length && Object.keys(initialValue.marketLocationData).length;

  return (
    initialValue && Object.keys(initialValue).includes("marketLocationData", "itemLocationData") ?
      <div className=" w-full h-full flex flex-col relative gap-2">
        {hasLocation ? <div className=''>
          {/* Change true to props.disabled when marketData is global. //Anton, 20230119. */}
          <Dropdown disabled={true} options={Object.keys(initialValue["marketLocationData"]) || {}} initialValue={initialValue["itemLocationData"]["floor_plan"]} onChange={handleFloorPlanChange} placeholder={"select_floor_plan"} />
        </div> : null}
        {hasLocation ? <p className='font-yantramanavThin text-[14px] leading-[14px] text-darkDray'>{t('current_location_area')}: <span className=' font-yantramanavRegular'>{selectedLocationArea}</span></p> : null}
        <div className='flex bg-white pb-4 px-2'>
          {
            hasLocation ?
              <div className='h-[calc(70px*8)]'>
                <motion.div
                  animate={toggleMaximize ? {
                    scale: ONCLICK_SCALE,
                    translateX: "50%",
                    translateY: "-50%",
                  } : {}}
                  className={
                    [
                      'w-full rounded-sm z-10',
                      toggleMaximize ? 'bg-white drop-shadow-xl border border-gray-500 ' : ''
                    ].join(' ')
                  }
                >
                  {
                    toggleMaximize ?
                      <div className='absolute top-0 right-2 cursor-pointer text-gray-700 select-none z-10' onClick={handleMapMinimize}>x</div>
                      : undefined
                  }
                  <div ref={mapParentRef} className={
                    [
                      'relative w-full h-full border border-gray-300', // TODO THIS MAKES IT WRONG!! I removed p-4 for now!
                      toggleMaximize ? '' : ''
                    ].join(' ')
                  }>
                    <img
                      src={mapImgSrc}
                      onLoad={() => setImgLoaded(true)}
                      alt=""
                      style={{ width: `${mapImgSize.current.width}px`, height: `${mapImgSize.current.height}px` }}
                      className={
                        [
                          'select-none',
                          !toggleMaximize && true ? 'cursor-pointer' : '' // Change to true to "!props.disabled" when marketData is global. // Anton, 20230119
                        ].join(' ')
                      }
                      ref={mapImg}
                      onClick={handleMapClick}
                    />
                    {imgLoaded && initialValue.marketLocationData && initialValue.marketLocationData.hasOwnProperty(floorPlan) ?
                      Object.entries(initialValue.marketLocationData[floorPlan].location_areas).map(([key, value], index) =>
                        value.points.length ?
                          <PolygonArea key={key} locationArea={value} imageSize={{ height: mapImg.current.naturalHeight, width: mapImg.current.naturalWidth }} parentElementSize={{ width: mapImgSize.current.width, height: mapImgSize.current.height }} />
                          : undefined
                      )
                      : undefined}
                    {
                      markerLocationX && markerLocationY && (floorPlan === initialValue.itemLocationData.floor_plan) ?
                        <motion.img
                          className=''
                          style={{
                            height: `${MARKER_HEIGHT}px`,
                            width: `${MARKER_WIDTH}px`,
                            scale: markerScale,
                            position: 'absolute',
                            pointerEvents: 'none',
                            zIndex: 20,
                            top: markerLocationY,
                            left: markerLocationX,
                          }}
                          src={markerIcon}
                          alt=""
                        />
                        : undefined
                    }
                  </div>
                </motion.div>
              </div>
              :
              <div className='h-24'>
                <p className='font-yantramanavRegular text-mediumGray'>{t("no_location_data_available_for_item")}</p>
              </div>
          }

        </div>
      </div>
      :
      <div>
        <p className='font-yantramanavThin text-[14px] leading-[14px] text-darkDray'>{t('no_location_data_available_for_item')}</p>
      </div>
  )
}

export default LocationMap;
