import Icon from "./Icon";
import { useTranslation } from "react-i18next";

export default function TagPill({
  tag,
  small = false,
  disabled = false,
  selected,
  onClick
}) {
  const { t } = useTranslation();

  return (
    <div
      onClick={onClick ?? (() => undefined)}
      className={`${
        !disabled
          ? "hover:bg-logo/25 active:scale-95 transition-all hover:cursor-pointer "
          : ""
      } ${
        small ? "h-5 " : ""
      } bg-lightBlue rounded-2xl px-2.5 py-1 flex items-center gap-2 w-fit`}
    >
      <p
        className={`${
          small
            ? "text-[13px] font-yantramanavLight "
            : "text-sm font-yantramanavRegular"
        }  text-primary truncate`}
      >
        {t(tag)}
      </p>
      {selected ? (
        <Icon size={small ? 8 : 10} color="primary">
          universal_close_regular
        </Icon>
      ) : null}
    </div>
  );
}
