import reweEquipmentIdImage from "../../../assets/rewe_equipment_id.png";
import arbitraryIdImage from "../../../assets/placeholder-image.png";
import reweAssetIdImage from "../../../assets/rewe_asset_id.png";
import carrierIdImage from "../../../assets/carrier_id.png";
import mobilecIdImage from "../../../assets/mobilec_id.png";

export const imageMap = {
  rewe_equipment_id: reweEquipmentIdImage,
  rewe_asset_id: reweAssetIdImage,
  carrier_id: carrierIdImage,
  mobilec_id: mobilecIdImage,
  arbitrary_id: arbitraryIdImage
};

export const stickers = [
  "rewe_equipment_id",
  "rewe_asset_id",
  "carrier_id",
  "mobilec_id",
  "arbitrary_id"
];

export const stickerMap = new Map([
  ["rewe_equipment_id", [{ key: "image_input", type: "string" }]],
  ["rewe_asset_id", [{ key: "image_input", type: "string" }]],
  [
    "carrier_id",
    [
      { key: "image_input", type: "string" },
      { key: "carrier_id_2", type: "string" }
    ]
  ],
  [
    "mobilec_id",
    [
      { key: "image_input", type: "string" },
      { key: "next_test_date", type: "date" }
    ]
  ],
  [
    "arbitrary_id",
    [
      { key: "image_input", type: "string" },
      { key: "comment", type: "text" }
    ]
  ]
]);

