import { useEffect, useState } from "react";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { useShowIf } from "../../utils/ShowIf";
import KeyInput from "../molecules/KeyInput";
import Icon from "../atoms/Icon";

const Box = ({
  box,
  boxIndex,
  initialValue,
  edit,
  tags,
  onUpdate,
  market_id,
  modal = false
}) => {
  const [data, setData] = useState(initialValue);
  const [showBox, setShowBox] = useState(true);
  const { showIf } = useShowIf();
  const { t } = useTranslation();
  const headerStyles =
    "text-primary font-yantramanavRegular leading-5 text-[16px]";
  const headerBoxStyles =
    "text-left border-b mb-3 border-mediumGray flex flex-row justify-between items-end";
  const boxStyles = `${modal ? "w-full " : "min-w-[970px] w-full"} mb-5`;

  useEffect(() => {
    setData(initialValue); // Makes sure to reset the data if edit was discarded
  }, [edit]);

  const handleChange = (e) => {
    onUpdate(e);
    setData((prev) => ({ ...prev, ...e }));
  };

  // Visibility for the whole box. If show_if is not defined, the box will always be shown
  const shouldShowBox = box.show_if
    ? showIf(box.show_if, data, box.header)
    : true;

  const keyInputHelper = (inputInColumn, indexKey) => {
    const shouldShowInput = showIf(inputInColumn.show_if, data);
    return shouldShowInput ? (
      <KeyInput
        {...inputInColumn}
        onChange={handleChange}
        disabled={!edit}
        initialValue={get(data, inputInColumn.inputKey)}
        downloadTitle={get(initialValue, "display_name")}
        key={inputInColumn.inputKey}
        market_id={market_id}
        tags={tags}
      />
    ) : null;
  };

  const expandAndCollapseBox = () => (
    <div
      className="flex flex-row justify-center items-start gap-1 h-4 text-[10px] text-mediumGray hover:cursor-pointer"
      onClick={() => setShowBox(!showBox)}
    >
      {showBox ? t("collapse_box") : t("expand_box")}
      {showBox ? (
        <Icon color="mediumGray">direction_arrow_up_regular</Icon>
      ) : (
        <Icon color="mediumGray">direction_arrow_down_regular</Icon>
      )}
    </div>
  );

  const forBox = (box, boxIndex) => {
    return (
      <div className={`grid grid-cols-${box.items?.length} gap-4`}>
        {box.items?.map((colItems, colIndex) => {
          if (colItems.keys.length === 0) return null;
          return (
            <div
              key={box.header + box.items.length + colItems.keys[0].inputKey}
              className={`flex flex-col col-start-${box.merge[colIndex]?.columns?.[0]} col-end-${box.merge[colIndex]?.columns?.[1]}`}
            >
              {colItems.keys.map((item, itemIndex) => {
                return keyInputHelper(
                  item,
                  boxIndex + colIndex + itemIndex + item.inputKey
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };

  const forComponent = (box, boxIndex, compIndex) => {
    return (
      <div
        key={"forComponent" + box.header + compIndex}
        className={`grid grid-cols-${box.items.length} gap-4`}
      >
        {box.items.map((colItems, colIndex) => {
          if (colItems.keys.length === 0) return null;
          return (
            <div
              key={colIndex + box.header + compIndex}
              className={`flex flex-col col-start-${box.merge[colIndex]?.columns?.[0]} col-end-${box.merge[colIndex]?.columns?.[1]}`}
            >
              {colItems.keys.map((item, itemIndex) => {
                item = {
                  ...item,
                  inputKey: `components[${compIndex}].${item.inputKey}`
                };
                return keyInputHelper(
                  item,
                  boxIndex + colIndex + itemIndex + item.inputKey
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };

  const renderBoxContent = () => {
    if (!shouldShowBox) {
      return null; // Boxen should not be shown
    }

    if (box.isComponentStruct && initialValue.components) {
      return initialValue.components.map((comp, compIndex) => {
        if (comp.type === box.header) {
          return (
            <div key={compIndex} className={boxStyles}>
              <div className={headerBoxStyles}>
                <p className={headerStyles}>
                  {t(box.header)} - {t("component_header")}
                </p>
                {expandAndCollapseBox()}
              </div>
              <div className="mx-2">
                {showBox && <div>{forComponent(box, boxIndex, compIndex)}</div>}
              </div>
            </div>
          );
        }
        return null;
      });
    } else if (!box.isComponentStruct) {
      return (
        <div
          key={
            box.header +
            box.items?.length +
            Object.hasOwn(box, "isComponentStruct")
          }
          className={boxStyles}
        >
          <div className={headerBoxStyles}>
            <p className={headerStyles}>{t(box.header)}</p>
            {expandAndCollapseBox()}
          </div>
          {showBox && <div className="mx-2">{forBox(box, boxIndex)}</div>}
        </div>
      );
    }
  };

  return renderBoxContent();
};

export default Box;
