import { motion } from "framer-motion";
import GoogleMapReact from "google-map-react";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useDeepCompareEffect from "use-deep-compare-effect";
import googleMapsConfig from "../../../config/googleMapsConfig";
import ThumbnailCard from "../../molecules/ThumbnailCard";
import useSupercluster from "./useSuperCluster";
import Mark from "../../../assets/svg/Marker.svg";

const Map = ({ mapCoord }) => {
  const navigate = useNavigate();
  const mapDefault = {
    center: { lat: 52.37, lng: 9.69 },
    zoom: 5
  };

  const [mapDrag, setMapDrag] = useState(false);
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(mapDefault.zoom);
  const [points, setPoints] = useState([]);
  const mapRef = useRef(null);

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 }
  });

  useDeepCompareEffect(() => {
    setPoints(
      mapCoord.map((coordItem) => {
        return {
          type: "Feature",
          geometry:
            coordItem?.geolocation?.lng && coordItem?.geolocation?.lat
              ? {
                  type: "Point",
                  coordinates: [
                    coordItem.geolocation.lng,
                    coordItem.geolocation.lat
                  ]
                }
              : null,
          properties: {
            marketId: coordItem.market_id,
            displayName: coordItem.display_name,
            thumbnail: coordItem?.entrance_images?.[0].thumbnail ?? null
          }
        };
      })
    );
  }, [mapCoord]);

  return (
    <div className="bg-[#fff] rounded-sm overflow-hidden w-full h-full flex flex-col">
      <div
        className="map-container flex flex-grow max-h-full relative"
        style={{ height: "100%", width: "481px" }}
      >
        <GoogleMapReact
          bootstrapURLKeys={{
            key: googleMapsConfig.key,
            mapIds: googleMapsConfig.id
          }}
          defaultCenter={mapDefault.center}
          defaultZoom={mapDefault.zoom}
          options={{
            mapId: googleMapsConfig.id
          }}
          gestureHandling={"auto"}
          streetViewControl={false}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map }) => {
            mapRef.current = map;
          }}
          onDrag={() => {
            setMapDrag(true);
          }}
          onDragEnd={() => {
            setTimeout(() => {
              setMapDrag(false);
            }, 75);
          }}
          onChange={({ zoom: newZoom, bounds: newBounds }) => {
            setZoom(newZoom);
            setBounds([
              newBounds.nw.lng,
              newBounds.se.lat,
              newBounds.se.lng,
              newBounds.nw.lat
            ]);
            //handleZoomChange(zoom);
          }}
        >
          {!mapDrag
            ? clusters.map((cluster) => {
                const [lng, lat] = cluster.geometry.coordinates;
                const { cluster: isCluster, point_count: pointCount } =
                  cluster.properties;

                if (isCluster) {
                  return (
                    <motion.div
                      initial={{ opacity: 0 }}
                      transition={{ ease: "easeIn", duration: 0.1 }}
                      animate={{ opacity: 1 }}
                      key={`cluster-${cluster.id}`}
                      className="rounded-full flex justify-center items-center py-1 px-4 -translate-y-1/2 -translate-x-1/2"
                      lat={lat}
                      lng={lng}
                      style={{
                        minWidth: `${
                          30 + (pointCount / points.length) * 150
                        }px`,
                        minHeight: `${
                          30 + (pointCount / points.length) * 150
                        }px`,
                        backgroundImage:
                          "radial-gradient(circle, #4FC5EF 0%, transparent 98%"
                      }}
                      onClick={() => {
                        const expansionZoom = Math.min(
                          supercluster.getClusterExpansionZoom(cluster.id),
                          20
                        );
                        mapRef.current.setZoom(expansionZoom);
                        mapRef.current.panTo({ lat, lng });
                      }}
                    >
                      <p className=" font-yantramanavLight text-base ">
                        {pointCount}
                      </p>
                    </motion.div>
                  );
                } else {
                  return (
                    <div
                      lat={lat}
                      lng={lng}
                      key={`points-${cluster.properties.marketId}`}
                      className="h-5 w-5 relative group flex justify-center items-start hover:cusror-pointer"
                      onClick={() => {
                        navigate("/markets/" + cluster.properties.marketId);
                      }}
                    >
                      {/* <div className="relative group " onClick={() => markerClick(marketId)}> */}
                      <div className="z-10 absolute invisible group-hover:visible flex flex-col ">
                        {cluster.properties.thumbnail ? (
                          <div className=" -top-36 absolute -translate-x-1/2">
                            <ThumbnailCard
                              image={cluster.properties.thumbnail}
                              title={cluster.properties.displayName}
                            />
                          </div>
                        ) : (
                          <p className="px-2 py-0.5 truncate w-52 font-yantramanavLight text-base -top-5 -left-20 bg-darkWhite border border-mediumGray rounded z-10 select-none">
                            {cluster.properties.displayName}
                          </p>
                        )}
                      </div>
                      <img
                        className="absolute -translate-x-1/2 -translate-y-[90%]"
                        src={Mark}
                        alt=""
                      />
                    </div>
                  );
                }
              })
            : null}
        </GoogleMapReact>
        {mapDrag ? (
          <motion.div
            initial={{ opacity: 0 }}
            transition={{ ease: "easeIn", duration: 0.05 }}
            exit={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="absolute w-full h-full top-0 left-0 bg-slate-500/30"
          ></motion.div>
        ) : null}
      </div>
    </div>
  );
};

export default Map;
