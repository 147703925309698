import React from "react";

function Folder({ folder, onClick, onClickExpandInfo }) {
  return (
    <div
      className="flex flex-col gap-2  hover:cursor-pointer"
      onClick={onClick}
    >
      <div className="relative hover:scale-[1.02] transition-all">
        <div className="bg-logo w-[126px] h-[93px] rounded-b-[12px] rounded-tr-[8px] shadow-md flex justify-center items-center p-2">
          {/* <p className="text-primary text-base font-yantramanavLight truncate">
            {folder.name}
          </p> */}
        </div>
        <div class="bg-logo absolute -top-1.5 w-[60px] h-[8.5px] rounded-t-[20px]" />
        <div class="bg-primary absolute -top-1.5 left-4 w-[25.5px] h-[1.5px] rounded-b" />
        <div
          onClick={(e) => onClickExpandInfo(e)}
          className="hover:scale-[1.2] w-5 h-8 absolute top-0 right-0 hover:bg-lightBlue/10 rounded-full transition-all"
        >
          <div className="bg-primary absolute top-2 right-2 w-1 h-1 rounded-full" />
          <div className="bg-primary absolute top-3.5 right-2 w-1 h-1 rounded-full" />
          <div className="bg-primary absolute top-5 right-2 w-1 h-1 rounded-full" />
        </div>
      </div>
      <p className="text-black text-base text-center font-yantramanavLight truncate">
        {folder.name}
      </p>
    </div>
  );
}

export default Folder;
