import React, { useEffect, useState, useRef } from "react";
import SearchInput from "../atoms/SearchInput";
import TagPill from "../atoms/TagPill";
import Button from "./Button";
import { useTranslation } from "react-i18next";

function TagPillDropdown({ initialValue, allTags, onChange, disabled }) {
  const [filteredTags, setFilteredTags] = useState(Object.keys(allTags));
  const [showAddButton, setShowAddButton] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [open, setOpen] = useState(false);
  const tagmodalRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    const filtered = Object.keys(allTags).filter(
      (tag) => !initialValue?.includes(tag)
    );
    setFilteredTags(filtered);
  }, [initialValue]);

  useEffect(() => {
    setOpen(false);
  }, [disabled]);

  const handleInputChange = (e) => {
    const value = e;
    setInputValue(value);

    const filtered = Object.keys(allTags)
      .filter((tag) => tag.toLowerCase().includes(value.toLowerCase()))
      .filter((tag) => !initialValue.includes(tag.toLocaleLowerCase())); // Exclude tags already chosen
    setFilteredTags(filtered);
    if (filtered.length < 1) {
      setShowAddButton(true);
    } else {
      setShowAddButton(false);
    }
  };

  const handleAddTag = () => {
    if (inputValue && !initialValue.includes(inputValue)) {
      const updatedTags = [...initialValue, inputValue];
      onChange(updatedTags);
      setInputValue("");
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    const updatedTags = initialValue.filter((tag) => tag !== tagToRemove);
    onChange(updatedTags);
  };

  const handleTagClick = (tag) => {
    if (!initialValue.includes(tag)) {
      const updatedTags = [...initialValue, tag];
      onChange(updatedTags);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        tagmodalRef?.current &&
        !tagmodalRef?.current?.contains(event.target)
      ) {
        setOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [tagmodalRef]);

  if (disabled) {
    return (
      <div className="w-full flex flex-row flex-wrap gap-1">
        {initialValue?.map((tag, i) => (
          <TagPill key={i} tag={tag} small disabled />
        ))}
      </div>
    );
  } else {
    return (
      <div className="h-full mb-5" ref={tagmodalRef}>
        <div className="w-full flex flex-row flex-wrap gap-1.5">
          {initialValue?.map((tag, i) => (
            <TagPill
              key={i}
              tag={tag}
              small
              selected
              onClick={!disabled ? () => handleRemoveTag(tag) : undefined}
              disabled={disabled}
            />
          ))}
        </div>

        <div className="my-3 w-full relative">
          <Button
            text={open ? "close_tag_modal" : "add_more_tags"}
            onClick={() => setOpen(!open)}
          />
          {!open ? null : (
            <div className="p-2 border w-full border-mediumGray z-10 bg-white rounded drop-shadow-lg max-h-40 min-h-[134px] overflow-hidden mt-0.5 absolute z-20">
              <div className="flex flex-row w-full h-full gap-2">
                <div className="w-2/3 flex justify-start items-center bg-white mb-2">
                  <SearchInput
                    key={initialValue}
                    initialValue={inputValue}
                    placeholder={t("search_tags")}
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
                <div className="w-1/3">
                  {showAddButton ? (
                    <Button text="add_tag" onClick={handleAddTag} fill />
                  ) : null}
                </div>
              </div>
              <div className="w-full flex flex-row gap-2 flex-wrap">
                {filteredTags?.map((tag, i) => (
                  <TagPill
                    key={i}
                    tag={tag}
                    onClick={() => handleTagClick(tag)}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default TagPillDropdown;
