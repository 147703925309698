import { useNavigate } from "react-router-dom";
import Icon from "../components/atoms/Icon";
import { useTranslation } from "react-i18next";

const NoPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="w-full h-screen bg-transparent flex flex-col justify-center items-center">
      <div className="w-2/3 h-2/3 rounded border border-mediumGray bg-whiteTint flex flex-col justify-center items-center">
        <div className="w-2/5 flex flex-col justify-center items-center">
          <h1 className="font-yantramanavRegular text-mediumGray text-9xl">
            404
          </h1>
          <p className="font-yantramanavRegular text-mediumGray text-sm text-center">
            {t("information_text_on_404_page")}
          </p>
        </div>
        <button
          type="button"
          onClick={() => navigate(navigate(-1))}
          className="bg-lightGray border border-mediumGray w-36 h-12 p-1 rounded mt-6 flex flex-row gap-3 items-center justify-center active:scale-95"
        >
          <Icon size={24} color="mediumGray">
            direction_arrow_left_regular
          </Icon>
          <p className="text-buttonLM text-darkGray font-yantramanavRegular">
            {t("back")}
          </p>
        </button>
      </div>
    </div>
  );
};

export default NoPage;
