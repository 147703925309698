import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Checkbox from "../../components/atoms/Checkbox";
import ResponsibilityModal from "../../components/molecules/ResponsibilityModal";
import Icon from "../../components/atoms/Icon";

function ResponsibilityItem({
  data,
  struct,
  companies,
  edit,
  openHeader,
  setData
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const { t } = useTranslation();

  const getInitValue = (item, area, main, second) => {
    if (
      item?.responsibility?.[area] &&
      item?.responsibility?.[area]?.some((itc) => {
        return itc.main === main && (second === false || itc.second === second);
      })
    ) {
      return true;
    }
    return false;
  };

  const haveTimeOrCost = (it, area, main, is_main_checkbox, second) => {
    const checkArea = (predicate, second) => {
      return it?.responsibility?.[area]?.some(
        (cc) =>
          cc.predicates.some((pred) => pred.key === predicate) &&
          cc.main === main &&
          (second ? cc.second === second : true)
      );
    };

    if (is_main_checkbox) {
      if (it?.responsibility && it?.responsibility?.[area]) {
        if (checkArea("time") && checkArea("cost")) {
          return "other_advanced_regular";
        } else if (checkArea("time")) {
          return "other_advanced_regular";
        } else if (checkArea("cost")) {
          return "other_advanced_regular";
        } else {
          return "universal_check_regular";
        }
      }
    }

    if (it?.responsibility && it?.responsibility?.[area]) {
      if (checkArea("time", second) && checkArea("cost", second)) {
        return "other_advanced_regular";
      } else if (checkArea("time", second)) {
        return "other_advanced_regular";
      } else if (checkArea("cost", second)) {
        return "other_advanced_regular";
      }
    }
    return "universal_check_regular";
  };

  const getCompanyInfo = (second) => {
    return companies?.filter((c) => c.name === second)[0];
  };

  const pressCheckbox = (
    add,
    item,
    responsibility_area,
    main,
    second = false,
    predicates = []
  ) => {
    let reTemp = structuredClone(item);
    // console.log("item is: ", item);
    let item_responsibility = item.responsibility
      ? JSON.parse(JSON.stringify(item.responsibility))
      : {};

    if (!item_responsibility[responsibility_area]) {
      item_responsibility[responsibility_area] = [];
    }

    if (add) {
      if (second && item_responsibility[responsibility_area]) {
        // make sure to remove ANY concatCard that has a main but no second!
        // remove all e where e.main === main AND second dont exist
        item_responsibility[responsibility_area] = item_responsibility[
          responsibility_area
        ].filter((e) => e.second || e.main !== main);
      }
      // TODO if here If you press main and there is only one second. Then auto select that one
      if (item_responsibility[responsibility_area])
        item_responsibility[responsibility_area].push({
          ...getCompanyInfo(second),
          main: main,
          second: second,
          predicates: predicates
        });
      else
        item_responsibility[responsibility_area] = [
          {
            main: main,
            second: second,
            ...getCompanyInfo(second),
            predicates: predicates
          }
        ];
    } else {
      if (second) {
        // Remove a specific checkbox associated with both main and second
        item_responsibility[responsibility_area] = item_responsibility[
          responsibility_area
        ].filter((checkBox) => {
          return !(checkBox.main === main && checkBox.second === second);
        });
      } else {
        // If deselection doesn't involve a 'second', remove all checkboxes with the same 'main'
        item_responsibility[responsibility_area] = item_responsibility[
          responsibility_area
        ].filter((checkBox) => {
          return checkBox.main !== main;
        });
      }
    }
    reTemp.responsibility = item_responsibility;
    setData(reTemp);
    // setData((prev) => prev.map((i) => (i.item_id === item.item_id ? item : i)));
    // setUpdate((prev) => prev + 1);
  };

  const setModal = (data, struct, companies) => {
    setModalContent({
      item: data,
      struct: struct,
      companies: companies
    });
    setIsModalOpen(true);
  };


  return (
    <div>
      <div className="flex flex-row justify-start w-full hover:bg-lightBlue">
        <div
          onClick={() => (edit ? null : setModal(data, struct, companies))}
          className={`${
            edit ? "" : "cursor-pointer"
          } "flex flex-row gap-[12px] py-[11px] w-[253px] truncate"`}
        >
          {/* Item name here */}
          <div className="flex flex-col w-[200px]">
            <p className="font-yantramanavLight text-[14px] leading-[17.55px] w-[200px] h-[18px] truncate">
              {data?.display_name}
            </p>
            <p className="font-yantramanavLight text-[12px] leading-[15.63px] h-[16px] w-[102px] truncate text-black">
              {/* Tech. place: text here */}
              {data?.technical_place_id}
            </p>
          </div>
        </div>
        <div className="flex flex-row items-center">
          {/* rest: */}
          <div className="w-[50px]">
            {edit ? (
              <div
                className="w-[86px] h-[20px] rounded-sm py-[2px] px-1 border-mediumGray border flex justify-center items-center hover:cursor-pointer"
                onClick={() => {
                  setModal(data, struct, companies);
                }}
              >
                <p className="font-yantramanavRegular text-[12px] leading-4 truncate w-full">
                  {t("item_settings")}
                </p>
              </div>
            ) : null}
          </div>
          {/* space */}
          {edit ? (
            <div className="w-[64px] h-1"></div>
          ) : (
            <div className="-ml-7 h-1"></div>
          )}
          {/* list of 3 chekboxes */}
          <div className="flex flex-row justify-start w-full gap-12 hover:bg-lightBlue py-[11px]">
            {edit ? null : <div className="w-[32px] h-1px"></div>}
            {Object.keys(struct).map((area, area_index) => (
              <div
                key={area_index + area + "checkboxesforItem"}
                className="mr-2 flex flex-row items-center"
              >
                {Object.keys(struct[area]).map((main, main_index) => (
                  <div
                    key={area_index + "_" + main_index + "mainCheckboxes" + "_"}
                    className="flex flex-row items-center ml-2"
                  >
                    <div className="flex flex-row items-end">
                      <Checkbox
                        key={
                          area_index +
                          "_" +
                          main_index +
                          "mainCheckboxes checkbox"
                        }
                        initialValue={getInitValue(data, area, main, false)}
                        icon={haveTimeOrCost(data, area, main, true)}
                        onChange={(e) => pressCheckbox(e, data, area, main)}
                        disabled={!edit}
                      />
                      {openHeader.some(
                        (e) => e.area === area && e.main === main
                      )
                        ? companies
                            ?.filter((c, c_index) =>
                              struct[area][main].some((r) =>
                                c.company_type.includes(r)
                              )
                            )
                            .map((com, com_index) => (
                              <div
                                className="ml-2"
                                key={com + com_index + main + area + "_"}
                              >
                                <Checkbox
                                  initialValue={getInitValue(
                                    data,
                                    area,
                                    main,
                                    com.name
                                  )}
                                  disabled={!edit}
                                  size="small"
                                  icon={haveTimeOrCost(
                                    data,
                                    area,
                                    main,
                                    false,
                                    com.name
                                  )}
                                  onChange={(e) =>
                                    pressCheckbox(e, data, area, main, com.name)
                                  }
                                />
                              </div>
                            ))
                        : null}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
      <ResponsibilityModal
        key={data.item_id}
        modalContent={modalContent}
        isOpen={isModalOpen}
        onClose={(e) => {
          if (e && e.item_id) setData(e);

          setIsModalOpen(false);
          setModalContent({});
        }}
        market_id={data.market_id}
        editable={edit}
      />
    </div>
  );

  return (
    <div>
      <div className="flex flex-row justify-start w-full gap-4 hover:bg-lightBlue">
        <div
          onClick={() => (edit ? null : setModal(data, struct, companies))}
          className={`${
            edit ? "" : "cursor-pointer"
          } flex flex-row items-center my-1 gap-3`}
        >
          <div className="flex flex-col w-[200px]">
            <p className="font-yantramanavLight text-[14px] leading-[17.55px] w-[200px] h-[18px] truncate">
              {data?.display_name}
            </p>
            <div className="w-full flex flex-row gap-2">
              <p className="font-yantramanavLight text-[12px] leading-[15.63px] h-[16px] w-[102px] truncate text-black">
                {/* Tech. place: text here */}
                {data?.id_rewe}
              </p>
              {/* <p className="font-yantramanavLight text-[12px] leading-[15.63px] h-[16px] w-[80px] truncate text-black">
                                Rewe id: {it.id_rewe}
                              </p> */}
            </div>
          </div>
          <Icon size={16}>alert_info_circle</Icon>
          {edit ? (
            <div
              className="w-[100px] flex justify-end hover:cursor-pointer "
              onClick={() => {
                setModal(data, struct, companies);
              }}
            >
              <div className="w-[73px] h-[20px] rounded-sm py-[2px] px-1 border-mediumGray border flex justify-center items-center hover:cursor-pointe">
                <p className="font-yantramanavRegular text-[12px] leading-4">
                  {t("item_settings")}
                </p>
              </div>
            </div>
          ) : null}
        </div>
        {!edit ? <div className="w-[96px] h-1"></div> : null}

        {Object.keys(struct).map((area, area_index) => (
          <div
            key={area_index + area + "checkboxesforItem"}
            className="mr-2 flex flex-row items-center"
          >
            {Object.keys(struct[area]).map((main, main_index) => (
              <div
                key={area_index + "_" + main_index + "mainCheckboxes" + "_"}
                className="flex flex-row items-center ml-2"
              >
                <div className="flex flex-row items-end">
                  <Checkbox
                    key={
                      area_index + "_" + main_index + "mainCheckboxes checkbox"
                    }
                    initialValue={getInitValue(data, area, main, false)}
                    icon={haveTimeOrCost(data, area, main, true)}
                    onChange={(e) => pressCheckbox(e, data, area, main)}
                    disabled={!edit}
                  />
                  {openHeader.some((e) => e.area === area && e.main === main)
                    ? companies
                        ?.filter((c, c_index) =>
                          struct[area][main].some((r) =>
                            c.company_type.includes(r)
                          )
                        )
                        .map((com, com_index) => (
                          <div
                            className="ml-2"
                            key={com + com_index + main + area + "_"}
                          >
                            <Checkbox
                              initialValue={getInitValue(
                                data,
                                area,
                                main,
                                com.name
                              )}
                              disabled={!edit}
                              size="small"
                              icon={haveTimeOrCost(
                                data,
                                area,
                                main,
                                false,
                                com.name
                              )}
                              onChange={(e) =>
                                pressCheckbox(e, data, area, main, com.name)
                              }
                            />
                          </div>
                        ))
                    : null}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>

      <ResponsibilityModal
        key={data.item_id}
        modalContent={modalContent}
        isOpen={isModalOpen}
        onClose={(e) => {
          if (e && e.item_id) setData(e);
          // setData(e);
          // if (e && e.item_id) {
          //   setJsonObject((prev) =>
          //     prev.map((p) => (p.item_id === e.item_id ? e : p))
          //   );
          //   setOgJsonObject((prev) =>
          //     prev.map((p) => (p.item_id === e.item_id ? e : p))
          //   );
          //   setUpdate((e) => e + 1);
          // }
          setIsModalOpen(false);
          setModalContent({});
        }}
        market_id={data.market_id}
        editable={edit}
      />
    </div>
  );
}

export default ResponsibilityItem;
