import React from "react";
import { useTranslation } from "react-i18next";
import ResponsibilityModal from "../../components/molecules/ResponsibilityModal";
import Checkbox from "../../components/atoms/Checkbox";
import Icon from "../../components/atoms/Icon";

function ResponsibilityItemGroup({
  data,
  amounts,
  group,
  struct,
  companies,
  edit,
  openHeader,
  setData,
  openToggle,
  setOpenToggle
}) {
  const { t } = useTranslation();
  const [modalContent, setModalContent] = React.useState({});
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const setModal = (item, struct, companies, groupName) => {
    setModalContent({
      item: item,
      struct: struct,
      companies: companies,
      groupName: groupName
    });
    setIsModalOpen(true);
  };

  const getCompanyInfo = (second) => {
    return companies?.filter((c) => c.name === second)[0];
  };

  const haveTimeOrCost = (it, area, main, is_main_checkbox, second) => {
    const checkArea = (predicate, second) => {
      return it?.[area]?.some(
        (cc) =>
          cc.predicates.some((pred) => pred.key === predicate) &&
          cc.main === main &&
          (second ? cc.second === second : true)
      );
    };

    if (is_main_checkbox) {
      if (it?.[area] && checkArea("time") && checkArea("cost")) {
        return "other_advanced_regular";
      } else if (it?.[area] && checkArea("time")) {
        return "other_advanced_regular";
      } else if (it?.[area] && checkArea("cost")) {
        return "other_advanced_regular";
      } else {
        return "universal_check_regular";
      }
    }

    if (it?.[area]) {
      if (checkArea("time", second) && checkArea("cost", second)) {
        return "other_advanced_regular";
      } else if (checkArea("time", second)) {
        return "other_advanced_regular";
      } else if (checkArea("cost", second)) {
        return "other_advanced_regular";
      }
    }
    return "universal_check_regular";
  };

  const pressItemGroupCheckbox = (
    add,
    responsibility_area,
    main,
    second = false,
    predicates = []
  ) => {
    let temp2 = structuredClone(data) ?? {};
    // console.log(temp2)
    if (add) {
      if (second && temp2[responsibility_area]) {
        // make sure to remove ANY concatCard that has a main but no second!
        // remove all e where e.main === main AND second dont exist
        temp2[responsibility_area] = temp2[responsibility_area].filter(
          (e) => e.second || e.main !== main
        );
      }
      // TODO if here If you press main and there is only one second. Then auto select that one
      if (temp2[responsibility_area])
        temp2[responsibility_area].push({
          ...getCompanyInfo(second),
          main: main,
          second: second,
          predicates: predicates
        });
      else
        temp2[responsibility_area] = [
          {
            main: main,
            second: second,
            ...getCompanyInfo(second),
            predicates: predicates
          }
        ];
    } else {
      // REMOVED!
      if (temp2[responsibility_area]) {
        if (second) {
          temp2[responsibility_area] = temp2[responsibility_area].filter(
            (e) => !(e.second === second && e.main === main)
          );
        } else {
          temp2[responsibility_area] = temp2[responsibility_area].filter(
            (e) => !(e.main === main)
          );
        }
      }
    }
    setData(group, temp2, responsibility_area, main, second);
  };

  return (
    <div>
      <div className="flex flex-row hover:bg-lightBlue">
        <div
          className={`flex flex-row gap-[12px] py-[11px] w-[253px] truncate`}
        >
          <p
            className={`${
              edit ? "" : "cursor-pointer"
            } font-yantramanavRegular text-[16px] leading-[20.75px]`}
            onClick={() =>
              edit ? null : setModal(data ?? {}, struct, companies, group)
            }
          >
            {t(group)}{" "}
            <span className="font-yantramanavLight text-[14px]">
              ({amounts[1]}/{amounts[0]})
            </span>
          </p>
          {openToggle ? (
            <div
              onClick={() => setOpenToggle(group)}
              className="hover:cursor-pointer w-[18px] h-[18px] flex justify-center items-center "
            >
              <Icon size={12} color="black">
                direction_arrow_up_regular
              </Icon>
            </div>
          ) : (
            <div
              onClick={() => setOpenToggle(group)}
              className="hover:cursor-pointer w-[18px] h-[18px] flex justify-center items-center"
            >
              <Icon size={12} color="black">
                direction_arrow_down_regular
              </Icon>
            </div>
          )}
        </div>
        <div>
          {/* responsibility_group checkboxes now */}
          <div
            key={group + "_group_chewckbox_div"}
            className="flex flex-row justify-start w-full gap-12 hover:bg-lightBlue py-[11px]"
          >
            <div className="w-[50px]">
              {edit ? (
                <div
                  onClick={() => {
                    setModalContent({
                      item: data ?? {},
                      struct: struct,
                      companies: companies,
                      groupName: group
                    });
                    setIsModalOpen(true);
                  }}
                  className="w-[86px] h-[20px] rounded-sm py-[2px] px-1 border-mediumGray border flex justify-center items-center hover:cursor-pointer"
                >
                  <p className="font-yantramanavRegular text-[12px] leading-4 truncate w-full">
                    {t("group_settings")}
                  </p>
                </div>
              ) : (
                <div className="w-[50px] h-1px"></div>
              )}
            </div>

            {Object.keys(struct).map((area, area_index) => (
              <div
                key={area_index + area + "checkboxesforItem" + "_"}
                className="mr-2 flex"
              >
                {Object.keys(struct[area]).map((main, main_index) => (
                  <div
                    className="flex flex-row items-center ml-2"
                    key={"each_area_eachmain" + main + "_"}
                  >
                    <div className="flex flex-row items-end">
                      <Checkbox
                        icon={haveTimeOrCost(data, area, main, true)}
                        initialValue={
                          data?.[area] &&
                          data[area].some((c) => {
                            return c.main === main;
                          })
                        } // HERE if any has main this
                        onChange={(e) => {
                          pressItemGroupCheckbox(e, area, main);
                        }}
                        disabled={!edit}
                      />
                      {openHeader.some(
                        (e) => e.area === area && e.main === main
                      )
                        ? companies
                            ?.filter((c, c_index) =>
                              struct[area][main].some((r) =>
                                c.company_type.includes(r)
                              )
                            )
                            .map((com, com_index) => (
                              <div
                                className="ml-2"
                                key={com + com_index + main + area + "_group_"}
                              >
                                {/* the checkboxes of each second */}
                                {/* <p>
                                {JSON.stringify(data?.responsibility?.[group])}
                              </p> */}
                                <Checkbox
                                  icon={haveTimeOrCost(
                                    data,
                                    area,
                                    main,
                                    false,
                                    com.name
                                  )}
                                  initialValue={
                                    data?.[area] &&
                                    data[area].some((c) => {
                                      return (
                                        c.main === main && c.second === com.name
                                      );
                                    })
                                  } // here if any has main AND second
                                  size="small"
                                  onChange={(e) => {
                                    pressItemGroupCheckbox(
                                      e,
                                      area,
                                      main,
                                      com.name
                                    );
                                  }}
                                  disabled={!edit}
                                />
                              </div>
                            ))
                        : null}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>

      <ResponsibilityModal
        key={group + modalContent?.groupName ?? "0"}
        modalContent={modalContent}
        isOpen={isModalOpen}
        onClose={(e) => {
          // console.log("e: ", e)
          if (e && e[group]) setData(group, e[group]);
          setIsModalOpen(false);
          setModalContent({});
        }}
        //   market_id={market_id}
        editable={edit}
      />
    </div>
  );
}

export default ResponsibilityItemGroup;
