import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import Icon from "../atoms/Icon";
import EditTabs from "./EditTabs";

const ResponsiveModal = ({
  isOpen,
  modalTitle,
  onClose,
  children,
  editTabs = false
}) => {
  const { t } = useTranslation();
  const [isDragging, setIsDragging] = useState(false);
  const modalRef = useRef(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartPosition({
      x: e.clientX,
      y: e.clientY
    });
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const deltaX = e.clientX - startPosition.x;
      const deltaY = e.clientY - startPosition.y;
      setPosition({
        x: position.x + deltaX,
        y: position.y + deltaY
      });
      setStartPosition({
        x: e.clientX,
        y: e.clientY
      });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleClose = (e) => {
    setPosition({ x: 0, y: 0 }); // Reset position to initial value
    onClose(e);
  };

  const handleModalClick = (e) => {
    e.stopPropagation();
    if (e.currentTarget === e.target) {
      onClose(e);
    }
  };

  return (
    <div
      className={`${
        isOpen ? "block" : "hidden"
      } fixed top-0 left-0 h-screen w-screen bg-black bg-opacity-50 flex items-center justify-center z-20 overflow-hidden`}
      onClick={handleModalClick}
    >
      <div
        ref={modalRef}
        className={`${
          isOpen ? "modal" : ""
        } max-w-[1224px] min-w-[456px] max-h-[736px] min-h-fit bg-white rounded flex flex-col overflow-hidden`}
        style={{
          transform: `translate(${position.x}px, ${position.y}px)`
        }}
      >
        <div
          className="w-full h-[36px] flex justify-between items-center px-4 py-2 border-b border-mediumGray"
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          style={{ cursor: isDragging ? "grabbing" : "grab" }}
        >
          <p className="text-body leading-[18px] text-black font-yantramanavRegular">
            {t(modalTitle)}
          </p>
          <div
            className="flex items-center hover:cursor-pointer"
            onClick={handleClose}
          >
            <Icon size={20} color="mediumGray">
              universal_close_filled
            </Icon>
          </div>
        </div>
        {editTabs && (
          <div className="relative">
            <EditTabs
              edit={editTabs.edit}
              setEdit={editTabs.setEdit}
              handleSave={editTabs.onSave}
              handleDiscard={editTabs.discardChanges}
              modal
            />
          </div>
        )}
        <div className="flex justify-center items-center h-full w-full">
          {children}
        </div>
      </div>
    </div>
  );
};

export default ResponsiveModal;

