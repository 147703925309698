import { useEffect, useState } from "react";

const CommentInput = ({ initialValue, onChange, disabled = false }) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue])

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    onChange(newValue);
  };

  if (disabled)
    return (
      <p className="h-14 w-full font-yantramanavRegular text-textInput text-black border border-transparent">
        {value}
      </p>
    );

  return (
    <div>
      <textarea
        rows={3}
        value={value}
        disabled={disabled}
        onChange={handleChange}
        className="w-full font-yantramanavRegular text-textInput outline-none border border-mediumGray focus:border-black"
      ></textarea>
    </div>
  );
};

export default CommentInput;
