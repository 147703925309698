import { useTranslation } from "react-i18next";
import Button from "../../components/molecules/Button";
import Tab from "../../components/molecules/Tab";

export default function ResponsibilityTabs({ selectedTab, setSelectedTab, tabs }) {
  const { t } = useTranslation();
    return (
      <div className="flex h-[46px] border-r border-b pr-8 items-center">
        <div className="pl-4 justify-self-start grow flex flex-row gap-4 self-end">
          {tabs.map((tab, tabIndex) => (
            <Tab
              key={tab}
              onClick={() => setSelectedTab(tab)}
              text={t(tab.slice(4))}
              style="fixWhite"
              active={selectedTab === tab}
            />
          ))}
        </div>
        <Button
          text={
            selectedTab === "tab_general"
              ? t("export_item")
              : t(`export_${selectedTab.slice(4)}`)
          }
          icon="universal_export_regular"
          enabled={false}
          onClick={() => undefined}
        />
      </div>
    );

}
