import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { fetchData } from "../../../api/api";
import Loadingsymbol from "../../../components/atoms/Loadingsymbol";
import ListView from "../../../components/organisms/ListView";
import NoPage from "../../../pages/NoPage";
import NoDataFound from "../../../components/molecules/NoDataFound";

const INITIAL_SELECTED_COLUMNS = [
  { key: "main_images", label: "main_images" },
  { key: "title", label: "display_name" },
  { key: "target.display_name", label: "display_name" }
];

export default function IssuesTab() {
  // Get issues for specific item
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { itemId, marketId } = useParams();

  const issuesRes = useQuery({
    queryKey: [
      marketId,
      "data",
      "issues",
      { url: `issues?market_id=${marketId}&target_id=${itemId}&all=true` }
    ],
    queryFn: fetchData
  }); // Get the data

  if (issuesRes.isError) {
    return <NoPage />;
  }

  if (issuesRes.isPending) {
    return (
      <div className="h-full">
        {/* <div className="flex h-[46px] border-r border-b px-8 w-full"></div>{" "} */}
        {/** Empty header */}
        <div className="flex flex-col items-center mt-8">
          <Loadingsymbol />
          <p className="text-body font-yantramanaRegular text-primary">
            {t("loading")}...
          </p>
        </div>
      </div>
    );
  }

  if (issuesRes.data.length < 1) {
    return <NoDataFound data="issues" />;
  }

  return (
    <div className="h-full">
      <div>
        <ListView
          data={issuesRes.data}
          columnOrder={INITIAL_SELECTED_COLUMNS}
          searchString={""}
          onClick={(e) => {
            navigate(`/markets/${marketId}/issues/${e.issue_id}`);
          }}
        />
      </div>
    </div>
  );
}
