export const realToElementPX = (realCoords, elementSize, imageSize) => {
    const percentageX = imageSize.width / realCoords.x;
    const percentageY = imageSize.height / realCoords.y;

    const elementLocationX = elementSize.width / percentageX;
    const elementLocationY = elementSize.height / percentageY;

    return { x: elementLocationX, y: elementLocationY };
}

/**
 * Takes in coordinates based on the element size and returns image pixel coordinates
 * @param {{x: number, y: number}} elementCoords 
 * @param {{width: number, height: number}} elementSize 
 * @param {{width: number, height: number}} imageSize 
 * @returns {x: number, y: number}
 */

export const elementToRealPX = (elementCoords, elementSize, imageSize) => {
    const percentageX = elementCoords.x / elementSize.width;
    const percentageY = elementCoords.y / elementSize.height;

    const actualPixelX = percentageX * imageSize.width;
    const actualPixelY = percentageY * imageSize.height;

    return { x: actualPixelX, y: actualPixelY};
}

export function pointInPolygon(point, polygon) {
	const numVertices = polygon.length;
	const x = point.x;
	const y = point.y;
	let inside = false;

	let p1 = polygon[0];

	for (let i = 1; i <= numVertices; i++) {
		const p2 = polygon[i % numVertices];

		if (y > Math.min(p1.y, p2.y)) {
			if (y <= Math.max(p1.y, p2.y)) {
				if (x <= Math.max(p1.x, p2.x)) {
					const xIntersection = (y - p1.y) * (p2.x - p1.x) / (p2.y - p1.y) + p1.x;

					if (p1.x === p2.x || x <= xIntersection) {
						inside = !inside;
					}
				}
			}
		}

		p1 = p2;
	}

	return inside;
}

