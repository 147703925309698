import React, {useEffect, useRef} from 'react'
import { useTranslation } from "react-i18next";
import lottie from 'lottie-web'

function UnderConstruction() {
    const { t } = useTranslation()

    const container = useRef(null)

    useEffect(() => {
        lottie.loadAnimation({
            container: container.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: require('../../assets/lottie/under-construction.json')
        })
    }, [])

  return (
    <div className="w-full h-full bg-white flex flex-col justify-center items-center">
            <div className="w-2/5 flex flex-col justify-center items-center">
                <h1 className="font-yantramanavRegular text-darkGray text-xl">{t('under_cunstruction')}</h1>
                <p className="font-yantramanavLight text-mediumGray text-sm text-center">{t('coming_soon')}</p>
            </div>
            <div className='h-80' ref={container}></div>
        </div>
  )
}

export default UnderConstruction