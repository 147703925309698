function exists(key, data) {
  if (key.includes(".")) {
    const [firstKey, secondKey] = key.split(".");
    return data[firstKey]?.[secondKey] !== undefined;
  }
  return data[key] !== undefined;
}

const operators = new Map([
  ["equal", ({ key, value }, data) => data?.[key] === value],
  ["not_equal", ({ key, value }, data) => data?.[key] !== value],
  ["exists", ({ key }, data) => exists(key, data)],
  ["true", ({ key }, data) => data?.[key] === true],
  ["contains", ({ key, value }, data) => data?.[key]?.includes(value)],
  ["in-array", ({ key, value }, data) => value.includes(data?.[key])],
  ["not_in-array", ({ key, value }, data) => !value.includes(data?.[key])],
  [
    "any_contains",
    ({ key, value }, data) =>
      key
        ?.map((key) => data?.[key])
        .some((objValue) => value.includes(objValue))
  ],
  [
    "any_true",
    ({ key }, data) => key.some((eachKey) => data?.[eachKey] === true)
  ]
]);

export function useShowIf() {
  const showIf = (rule, data, sectionHeader = "") => {
    if (!rule || !rule?.operation) return true;

    if (sectionHeader && rule?.key) {
      const operator = operators.get(rule?.operation);

      if (operator({ key: rule.key, value: rule.value }, data) === false) {
        return false;
      }
    } else {
      return operators.has(rule.operation)
        ? operators.get(rule.operation)?.(rule, data)
        : false;
    }
  };
  return { showIf };
}
