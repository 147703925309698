import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ResponsiveModal from "../../components/molecules/ResponsiveModal";
import TagPillDropdown from "../../components/molecules/TagPillDropdown";
import Button from "../../components/molecules/Button";

function EditFolderModal({
  edit,
  setEdit,
  folderData,
  allTags,
  editModalOpen,
  onClose,
  handleSave,
  handleDiscard,
  onDeletion
}) {
  const { t } = useTranslation();
  const [initialFolderData, setInitialFolderData] = useState();
  const [folderName, setFolderName] = useState("");
  const [accessList, setAccessList] = useState("");
  const [accessMode, setAccessMode] = useState("");
  const [folderTags, setFolderTags] = useState([]);
  const [folderDescription, setFolderDescription] = useState("");

  const inputDivStyles = "w-1/2 mb-4 flex flex-col gap-[2px]";
  const inputStyles = `${
    !edit ? "border-b-transparent" : "border-b-darkGray focus:border-b-darkGray"
  } h-4 bg-transparent font-yantramanavRegular text-textInput outline-none w-full text-ellipsis truncate border-b text-black  `;
  const labelStyles =
    "text-black text-[14px] leading-[18.16px] tracking-[0.5px] font-yantramanavLight h-[19px]";

  useEffect(() => {
    if (!folderData) return;
    setInitialFolderData(folderData);
    setFolderName(folderData.name);
    setFolderDescription(folderData?.description);
    setAccessList(folderData.access ? Object.values(folderData?.access) : null);
    setFolderTags(folderData.tags ? folderData.tags : []);
    setAccessMode(
      folderData.access && Object.entries(folderData?.access).length > 1
        ? "list"
        : "private"
    );
  }, [editModalOpen, folderData]);

  const handleClose = () => {
    setEdit(false);
    setFolderName("");
    setAccessMode("");
    setAccessList("");
    setFolderTags([]);
    setFolderDescription("");
    setInitialFolderData();
    onClose();
  };

  const FolderDesign = () => {
    return (
      <div className="relative">
        <div className="bg-logo w-[126px] h-[93px] rounded-b-[12px] rounded-tr-[8px] shadow-md flex justify-center items-center p-2">
          <p className="text-primary text-base font-yantramanavLight truncate select-none">
            {t(folderName)}
          </p>
        </div>
        <div class="bg-logo absolute -top-1.5 w-[60px] h-[8.5px] rounded-t-[20px]" />
        <div class="bg-primary absolute -top-1.5 left-4 w-[25.5px] h-[2px] rounded-b" />
        <div className="w-4 h-4 absolute top-0 right-0">
          <div className="bg-primary absolute top-2 right-2 w-1 h-1 rounded-full" />
          <div className="bg-primary absolute top-3.5 right-2 w-1 h-1 rounded-full" />
          <div className="bg-primary absolute top-5 right-2 w-1 h-1 rounded-full" />
        </div>
      </div>
    );
  };

  const onSave = () => {
    let finalAccessList;

    if (typeof accessList === "string") {
      finalAccessList = accessList.replace(/\s/, "");
      finalAccessList = finalAccessList.length
        ? finalAccessList.split(",")
        : [];
    } else if (Array.isArray(accessList)) {
      finalAccessList = accessList;
    } else {
      finalAccessList = [];
    }

    handleSave({
      name: folderName,
      tags: folderTags,
      description: folderDescription,
      access: finalAccessList
    });
    handleClose();
  };

  const discardChanges = () => {
    setAccessList(initialFolderData.access);
    setFolderName(initialFolderData.name);
    setFolderTags(initialFolderData.tags);
    setFolderDescription(initialFolderData.description);
    setAccessList(
      initialFolderData.access ? Object.values(initialFolderData?.access) : null
    );
    setAccessMode(
      initialFolderData.access &&
        Object.entries(initialFolderData?.access).length > 1
        ? "list"
        : "private"
    );
    handleDiscard();
  };

  if (folderData.name === "default") {
    return (
      <ResponsiveModal
        modalTitle="edit_folder"
        isOpen={editModalOpen}
        onClose={handleClose}
      >
        <div className="bg-white w-[607px] h-[396px] p-8 rounded flex flex-col items-center overflow-auto no-scrollbar">
          <div className="w-[356px] flex flex-col items-center gap-[52px]">
            <div className="w-full flex flex-col items-center gap-1">
              <p className="text-[24px] text-center text-primary font-yantramanavRegular leading-[31.13px]">
                {t("edit_folder_title")}
              </p>
              <p className="font-yantramanavLight text-center text-[16px] leading-[18.4px] tracking-[1.5%] text-black ">
                {t("default_folder_cannot_be_edited_or_deleted")}
              </p>
            </div>
            <FolderDesign />
            <p className="font-yantramanavLight text-center text-[14px] leading-[18.4px] tracking-[1.5%] text-black ">
              {t(folderDescription)}
            </p>
          </div>
        </div>
      </ResponsiveModal>
    );
  } else {
    return (
      <ResponsiveModal
        modalTitle="edit_folder"
        isOpen={editModalOpen}
        onClose={handleClose}
        editTabs={{ edit, setEdit, onSave, discardChanges }}
      >
        <div
          className={`${
            edit ? "min-h-[465px]" : "min-h-[325px]"
          } relative w-[607px] max-h-[736px] p-8 flex flex-col items-center overflow-auto no-scrollbar`}
        >
          <div className="w-[416px] flex flex-col items-center gap-[52px]">
            <div className="w-full flex flex-col items-center gap-1">
              <p className="text-[24px] text-center text-primary font-yantramanavRegular leading-[31.13px]">
                {t("edit_folder_title")}
              </p>
              <p className="font-yantramanavLight text-center text-[16px] leading-[18.4px] tracking-[1.5%] text-black ">
                {t("here_you_can_edit_the_folder_name_and_access_rights")}
              </p>
              <p className="font-yantramanavLight text-center text-[16px] leading-[18.4px] tracking-[1.5%] text-black ">
                {t("or_delete_the_folder")}
              </p>
            </div>

            <div className="flex flex-col gap-3 w-full">
              <div className={`flex-row w-full flex gap-4`}>
                <div className={inputDivStyles}>
                  <label htmlFor="folderName" className={labelStyles}>
                    {t("folder_name")}
                  </label>
                  <input
                    id="folderName"
                    type="text"
                    value={folderName}
                    onChange={(e) => setFolderName(e.target.value)}
                    className={inputStyles}
                    placeholder={t("folder_name")}
                    disabled={!edit}
                  />
                </div>

                <div className={inputDivStyles}>
                  <label htmlFor="description" className={labelStyles}>
                    {t("description")}
                  </label>
                  <textarea
                    id="description"
                    type="text"
                    value={folderDescription}
                    onChange={(e) => setFolderDescription(e.target.value)}
                    placeholder={t("enter_description")}
                    disabled={!edit}
                    rows={4}
                    className="w-full p-1 font-yantramanavRegular text-textInput outline-none border border-mediumGray focus:border-black"
                  />
                </div>
              </div>

              {/** Tags and Access-rights */}
              <div className="w-full flex flex-row gap-4">
                <div className="w-1/2 flex flex-col -mb-12">
                  <label
                    htmlFor="folderName"
                    className={labelStyles + " mb-1.5"}
                  >
                    {t("tags")}
                  </label>
                  <TagPillDropdown
                    initialValue={folderTags}
                    allTags={allTags}
                    onChange={(e) => setFolderTags(e)}
                    disabled={!edit}
                  />
                </div>

                {edit ? (
                  <div className="flex flex-col gap-2">
                    <p className={labelStyles}>{t("access_rights")}</p>

                    <div
                      className="flex gap-2 hover:cursor-pointer items-center"
                      onClick={() => setAccessMode("private")}
                    >
                      <input
                        type="radio"
                        checked={accessMode === "private"}
                        onChange={(e) => setAccessMode("private")}
                      ></input>
                      <p className="h-[19px] w-full truncate text-black text-[14px] font-yantramanavLight text-left">
                        {t("set_folder_access_private")}
                      </p>
                    </div>
                    <div
                      className="flex gap-2 hover:cursor-pointer items-center"
                      onClick={() => setAccessMode("list")}
                    >
                      <input
                        type="radio"
                        checked={accessMode === "list"}
                        onChange={(e) => setAccessMode("list")}
                      ></input>
                      <p className="h-[19px] w-full truncate text-black text-[14px] font-yantramanavLight text-left">
                        {t("set_folder_access_list")}
                      </p>
                    </div>
                    {accessMode === "list" ? (
                      <div className="mt-2">
                        <p className={labelStyles}>{t("folder_access_list")}</p>
                        <p className={labelStyles}>
                          {t("(separate_email_with_commas)")}
                        </p>
                        <input
                          type="text"
                          value={accessList}
                          onChange={(e) => setAccessList(e.target.value)}
                          placeholder={t("enter_folder_access")}
                          className=" h-4 font-yantramanavRegular text-textInput outline-none w-full truncate border-b text-black  border-b-darkGray focus:border-b-darkGray mb-8"
                        />
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div className={inputDivStyles}>
                    <p className={labelStyles}>{t("access_rights")}</p>
                    {accessList &&
                      Object.entries(accessList).map(([key, value]) => (
                        <div key={key}>
                          <p className="text-black text-[14px] leading-[18.16px] tracking-[0.5px] font-yantramanavRegular">
                            {value}
                          </p>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>

            {/** Buttons */}
            {edit ? null : (
              <div className="flex flex-row w-full justify-start">
                <Button
                  text="delete_folder_button"
                  icon="universal_delete_regular"
                  color="red"
                  onClick={() => onDeletion(initialFolderData.vault_id)}
                />
              </div>
            )}
          </div>
        </div>
      </ResponsiveModal>
    );
  }
}

export default EditFolderModal;

