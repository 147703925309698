import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { EXAMPLEISSUESTRUCT } from "../../pages/Development/structures";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { basicPatch, fetchData } from "../../api/api";
import { set } from "lodash";
import UnderConstruction from "../../components/molecules/UnderConstruction";
import Loadingsymbol from "../../components/atoms/Loadingsymbol";
import SearchInput from "../../components/atoms/SearchInput";
import EditTabs from "../../components/molecules/EditTabs";
import Button from "../../components/molecules/Button";
import Box from "../../components/molecules/Box";
import NoPage from "../../pages/NoPage";

async function patchRequest(data, marketId, issueId) {
  const response = await basicPatch(
    `issues?market_id=${marketId}&issue_id=${issueId}`,
    data
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
}

export default function SingleIssue() {
  const { t } = useTranslation();
  const { marketId, issueId } = useParams();

  const {
    data: issueData,
    isPending: issueDataPending,
    isError: issueDataError
  } = useQuery({
    queryKey: [
      "issue",
      marketId,
      issueId,
      { url: `issues?market_id=${marketId}&issue_id=${issueId}` }
    ],
    queryFn: fetchData
  });

  const issueStructRes = {
    data: EXAMPLEISSUESTRUCT,
    isPending: false,
    isError: false
  };

  const [edit, setEdit] = useState(false);
  const [patchData, setPatchData] = useState({});

  const queryClient = useQueryClient();
  const issueMutation = useMutation({
    mutationFn: ({ patchData, marketId, issueId }) =>
      patchRequest(patchData, marketId, issueId),
    onError: (error) => {
      console.log("On error: ", error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["issue", marketId, issueId]
      });
      setPatchData({});
    }
  });

  useEffect(() => {
    if (!issueDataPending) {
      setEdit(false);
    }
  }, [issueData]);

  if (issueDataPending || issueStructRes.isPending || issueMutation.isPending) {
    return (
      <div className="h-full">
        <div className="flex h-[46px] border-r border-b px-8 w-full" />
        <div className="flex flex-col items-center mt-8">
          <Loadingsymbol />
          <p className="text-body font-yantramanaRegular text-primary">
            {t("loading")}...
          </p>
        </div>
      </div>
    );
  }

  if (issueDataError || issueStructRes.isPending) {
    return <NoPage />;
  }

  const handleUpdate = (e) => {
    if (Object.keys(e)[0].includes("components[")) {
      setPatchData((prev) => {
        set(prev, Object.keys(e)[0], Object.values(e)[0]);
        return prev;
      });
    } else {
      setPatchData((prev) => ({ ...prev, ...e }));
    }
  };

  const handleSave = () => {
    if (Object.keys(patchData).length > 0) {
      issueMutation.mutate({ patchData, marketId, issueId });
    } else {
      setEdit(false);
    }
  };

  const handleDiscard = () => {
    setEdit(false);
    setPatchData({});
  };

  return (
    <div className="h-full">
      <div className="flex h-[46px] border-r border-b pr-8 items-center justify-between px-8">
        {/* <SearchInput onChange={(e) => undefined} placeholder={t("search")} /> */}
        <div></div>
        <div className="justify-items-end flex gap-4">
          <Button
            text={t("export_pdf")}
            icon="universal_export_regular"
            enabled={false}
            onClick={() => undefined}
          />
        </div>
      </div>
      <div className="relative">
        <EditTabs
          edit={edit}
          setEdit={setEdit}
          handleSave={handleSave}
          handleDiscard={handleDiscard}
        />

        <div className={"w-full p-8 h-[calc(100vh - 46px)]"}>
          {Object.keys(issueData).length > 0 ? (
            issueStructRes.data.boxes.map((box, boxIndex) => (
              <Box
                key={
                  box.header +
                  Object.hasOwn(box, "isComponentStruct") +
                  box.items.length
                }
                box={box}
                boxIndex={boxIndex}
                initialValue={issueData}
                edit={edit}
                onUpdate={handleUpdate}
                market_id={marketId}
              />
            ))
          ) : issueStructRes.data && Object.keys(issueData).length <= 0 ? (
            <UnderConstruction />
          ) : null}

          {!issueStructRes.data ? <UnderConstruction /> : null}
        </div>
      </div>
    </div>
  );
}
