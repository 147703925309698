export const convertCLStructToSAStruct = (checkliststruct) => {
  if (!checkliststruct || checkliststruct === undefined) return;

  const filteredChecklistStruct = checkliststruct.filter(
    (apiBox) =>
      apiBox.box_title !== "extra_images" && apiBox.box_title !== "comment"
  );
  const result = {
    boxes: filteredChecklistStruct.map((apiBox) => {
      let keysArrays = [];
      let nonImageKeysArray = { keys: [] };
      let imageKeysArray = { keys: [] };

      apiBox.fields?.forEach((apiField) => {
        const keysObject = Object.keys(apiField).reduce(
          (keysResult, apiKey) => {
            const outputKey = apiKey === "key" ? "inputKey" : apiKey;
            keysResult[outputKey] = apiField[apiKey];
            return keysResult;
          },
          {}
        );

        if (apiField.type === "images") {
          imageKeysArray.keys.push(keysObject);
        } else if (apiField.type === "add_extra_details") {
          if (keysObject.options?.includes("comment")) {
            nonImageKeysArray.keys.push({
              type: "comments",
              label: "comments",
              inputKey: "comment"
            });
          }
          if (keysObject.options?.includes("extra_images")) {
            console.log("extra_images är vi här ❤️", keysObject);
            imageKeysArray.keys.push({
              type: "extra_images",
              label: "extra_images",
              inputKey: "extra_images"
            });
          }
        } else {
          nonImageKeysArray.keys.push(keysObject);
        }
      });

      // Fix the order of the arrays...:
      // 1. Add the nonimage-keys array at the beginning
      keysArrays.push({ keys: nonImageKeysArray.keys });
      for (let i = 1; i <= 4; i++) {
        keysArrays.push({ keys: [] });
      }
      // 2. Add the image-keys array to the 5th spot
      if (
        imageKeysArray.keys.some((keyObj) =>
          Object.values(keyObj).some((val) => val !== undefined && val !== "")
        )
      ) {
        keysArrays.push({ keys: imageKeysArray.keys });
      } else {
        keysArrays.push({ keys: [] });
      }
      while (keysArrays.length < 9) {
        keysArrays.push({ keys: [] });
      }

      return {
        items: keysArrays.map((keysObj) => ({ keys: keysObj.keys })),
        header: apiBox.box_title,
        merge: [{ columns: [1, 6] }, { columns: [6, 10] }]
      };
    })
  };

  const extraBox = {
    items: [
      {
        keys: [
          {
            type: "cost_positions",
            inputKey: "cost_positions"
          }
        ]
      },
      { keys: [] },
      { keys: [] },
      { keys: [] },
      { keys: [] },
      { keys: [] },
      { keys: [] },
      { keys: [] },
      { keys: [] }
    ],
    merge: [{ columns: [1, 10] }],
    header: "cost_positions"
  };

  result.boxes.push(extraBox);

  return result;
};
