import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ResponsiveModal from "../../components/molecules/ResponsiveModal";
import TagPillDropdown from "../../components/molecules/TagPillDropdown";
import Button from "../../components/molecules/Button";

function EditFileModal({
  edit,
  setEdit,
  fileData,
  allTags,
  editModalOpen,
  onClose,
  handleSave,
  handleDiscard,
  onDeletion
}) {
  const [fileExtension, setFileExtension] = useState("");
  const [fileName, setFileName] = useState();
  const [fileTags, setFileTags] = useState();
  const { t } = useTranslation();

  const inputDivStyles = "w-1/2 mb-4 flex flex-col gap-[2px]";
  const inputStyles = `${
    !edit ? "border-b-transparent" : "border-b-darkGray focus:border-b-darkGray"
  } h-4 bg-transparent font-yantramanavRegular text-textInput outline-none w-full text-ellipsis truncate border-b text-black  `;
  const labelStyles =
    "text-black text-[14px] leading-[18.16px] tracking-[0.5px] font-yantramanavLight h-[19px]";

  useEffect(() => {
    if (!fileData) return;
    setFileName(getFileNameWithoutExtension(fileData?.display_name));
    setFileExtension(fileData?.suffix);
    setFileTags(fileData.tags ? fileData.tags : []);
  }, [fileData]);

  const onSave = () => {
    handleSave({
      display_name: fileName + fileExtension,
      tags: fileTags
    });
    handleClose();
  };

  const handleClose = () => {
    setEdit(false);
    onClose();
  };

  const getFileNameWithoutExtension = (filename) => {
    if (!filename) return;
    const lastDotIndex = filename.lastIndexOf(".");
    if (lastDotIndex === -1) {
      return filename;
    }
    return filename.slice(0, lastDotIndex);
  };

  const discardChanges = () => {
    setFileName(getFileNameWithoutExtension(fileData?.display_name));
    setFileTags(fileData.tags ? fileData.tags : []);
    handleDiscard();
  };

  return (
    <ResponsiveModal
      modalTitle="edit_file"
      isOpen={editModalOpen}
      onClose={() => {
        handleClose();
      }}
      editTabs={{ edit, setEdit, onSave, discardChanges }}
    >
      <div
        className={`${
          edit ? "min-h-[469px]" : "min-h-[325px]"
        }  relative w-[607px] max-h-[736px] p-8 flex flex-col items-center overflow-y-scroll no-scrollbar`}
      >
        <div className="w-[446px] flex flex-col items-center gap-[52px]">
          <div className="w-full flex flex-col items-center gap-1">
            <p className="text-[24px] text-center text-primary font-yantramanavRegular leading-[31.13px]">
              {t("edit_file_title")}
            </p>
            <p className="font-yantramanavLight text-center text-[16px] leading-[18.4px] tracking-[1.5%] text-black ">
              {t("here_you_can_edit_the_files_name_and_tags")}
            </p>
            <p className="font-yantramanavLight text-center text-[16px] leading-[18.4px] tracking-[1.5%] text-black ">
              {t("or_delete_the_file")}
            </p>
          </div>
          <div className="w-full flex flex-col gap-3">
            <div className="flex-row w-full h-full flex gap-8">
              <div className={inputDivStyles}>
                <label htmlFor="fileName" className={labelStyles}>
                  {t("file_name")}
                </label>
                <div className="flex flex-row gap-1">
                  <input
                    id="fileName"
                    type="text"
                    value={fileName}
                    onChange={(e) => setFileName(e.target.value)}
                    className={inputStyles}
                    placeholder={t("file_name")}
                    disabled={!edit}
                  />
                  {edit ? (
                    <p className={labelStyles + " text-[16px]"}>
                      {fileExtension}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="w-1/2 flex flex-col">
                <label htmlFor="tags" className={labelStyles}>
                  {t("tags")}
                </label>
                <TagPillDropdown
                  initialValue={fileTags}
                  allTags={allTags}
                  onChange={(e) => setFileTags(e)}
                  disabled={!edit}
                />
              </div>
            </div>
          </div>
          {edit ? null : (
            <div className="flex flex-row w-full justify-start">
              <Button
                text="delete_file_button"
                icon="universal_delete_regular"
                color="red"
                onClick={() => onDeletion()}
              />
            </div>
          )}
        </div>
      </div>
    </ResponsiveModal>
  );
}

export default EditFileModal;
