import React from "react";
import { useState, useEffect } from "react";
import CommentInput from "../atoms/CommentInput";
import Button from "./Button";

function Comments({ initialValue, onChange, disabled = false }) {
  const [comments, setComments] = useState([]);

  useEffect(() => {
    const transformedComments =
      initialValue?.map((com) => ({
        comment: com.comment || ""
      })) ?? [];
    setComments(transformedComments);
  }, [initialValue]);

  const addCommentInput = () => {
    const newComment = { comment: "" };
    const newComments = [...comments, newComment];
    setComments(newComments);
    onChange(
      newComments.map((comment) => ({
        comment: comment.comment
      }))
    );
  };

  const handleUpdateComment = (index, newComment) => {
    setComments((prevComments) => {
      const updatedComments = prevComments.map((comment, idx) =>
        idx === index ? { ...comment, comment: newComment } : comment
      );
      onChange(
        updatedComments.map((comment) => ({ comment: comment.comment }))
      );
      return updatedComments;
    });
  };

  return (
    <div className="h-full mb-10">
      {comments?.map((comment, index) => (
        <div key={index}>
          <CommentInput
            key={index + comment}
            initialValue={comment.comment}
            onChange={(newComment) => handleUpdateComment(index, newComment)}
            disabled={disabled}
          />
        </div>
      ))}
      {!disabled && <Button text="add_comment" onClick={addCommentInput} />}
    </div>
  );
}

export default Comments;
