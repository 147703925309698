import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Icon from "../atoms/Icon";

const OptionCard = ({ option, selected, disabled, onClick }) => {
  const { t } = useTranslation();

  return (
    <div
      className={`h-14 w-14 flex flex-col items-center justify-center rounded-sm shadow-md ${
        selected ? "border border-primary hover:bg-white" : ""
      }  ${
        disabled ? "" : "cursor-pointer hover:bg-lightBlue border-mediumGray"
      }  `}
      onClick={onClick}
    >
      <Icon
        size={20}
        color={selected ? "primary" : disabled ? "mediumGray" : "darkGray"}
      >{`item_${option.value}_regular`}</Icon>
      <p
        className={`font-yantramanavRegular text-[10px] text-center truncate w-full px-1 select-none ${
          selected
            ? "text-black"
            : disabled
            ? "text-mediumGray"
            : "text-darkGray"
        }  `}
      >
        {t(option.value)}
      </p>
    </div>
  );
};

function SelectFromIcons({
  options,
  initialValue,
  onChange,
  disabled = false
}) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <div className="flex flex-row flex-wrap gap-2 min-h-14 mb-2 max-h-fit w-full">
      {options.map((option, index) => (
        <div className="w-14">
          <OptionCard
            key={index + option.value}
            option={option}
            selected={value === option.value}
            disabled={disabled}
            onClick={() => {
              if (disabled) return;
              setValue(option.value);
              onChange(option.value);
            }}
          />
        </div>
      ))}
    </div>
  );
}

export default SelectFromIcons;
