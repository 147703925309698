import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom"
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import { basicGet, fetchData } from "../../api/api";
import Loadingsymbol from "../../components/atoms/Loadingsymbol";
import NoPage from "../../pages/NoPage";
import ListView from "../../components/organisms/ListView";
import SearchInput from "../../components/atoms/SearchInput";
import Button from "../../components/molecules/Button";
import ArrangeColumns from "../../components/molecules/ArrangeColumns";
import { exportData } from "../viewHelpers";


const AVAILABLE_COLUMNS = [
  { key: "display_name", label: "display_name" },
  { key: "type", label: "type" },
  { key: "manufacturer", label: "manufacturer" },
  { key: "model", label: "model" },
  { key: "id_rewe", label: "id_rewe_item" }
];

const INITIAL_SELECTED_COLUMNS = [
    { key: "main_images", label: "main_images" },
    { key: "display_name", label: "display_name" },
    { key: "manufacturer", label: "manufacturer" }
]

export default function ItemsView() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchString, setSearchString] = useState("");
  const [isColumnDropdownOpen, setIsColumnDropdownOpen] = useState(false);
  const [availableColumns, setAvailableColumns] = useState(AVAILABLE_COLUMNS);
  const [selectedColumns, setSelectedColumns] = useState(
    INITIAL_SELECTED_COLUMNS
  );

  const { marketId } = useParams(); // Get market id
  const itemsData = useQuery({
    queryKey: [
      marketId,
      "data",
      "items",
      { url: `items?market_id=${marketId}` }
    ],
    queryFn: fetchData
  }); // Get the data
  const marketData = useQuery({
    queryKey: [
      marketId,
      "data",
      "market",
      { url: `markets?market_id=${marketId}` }
    ],
    queryFn: fetchData,
    staleTime: 10 * 1000
  });

  if (itemsData.isError) {
    return <NoPage />;
  }

  if (itemsData.isPending && !marketData.isPending) {
    return (
      <div className="h-full">
        <div className="flex h-[46px] border-r border-b px-8 w-full"></div>
        {/** Empty header */}
        <div className="flex flex-col items-center mt-8">
          <Loadingsymbol />
          <p className="text-body font-yantramanaRegular text-primary">
            {t("loading")}...
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="h-full">
      <div className="h-[46px] border-r border-b overflow-hidden flex justify-between items-center px-8 ">
        <SearchInput
          onChange={(e) => setSearchString(e)}
          placeholder={t("search")}
        />
        <div className="justify-items-end flex gap-4">
          <Button
            text={t("export_excel")}
            icon="universal_export_regular"
            enabled={true}
            onClick={() =>
              exportData(
                itemsData.data,
                selectedColumns,
                marketData.data,
                "items",
                t
              )
            }
          />
          <Button
            text={t("customize_columns")}
            icon="other_customize_regular"
            enabled={true}
            onClick={() => setIsColumnDropdownOpen((prev) => !prev)}
          />
        </div>
      </div>
      <div className="relative grow overflow-scroll no-scrollbar w-full h-full">
        <div className="fixed z-20 top right-[33px] h-48">
          {isColumnDropdownOpen ? (
            <div className="w-[196px] drop-shadow-md bg-white py-2 rounded">
              {availableColumns?.map((column, index) => {
                return (
                  <ArrangeColumns
                    key={index + column}
                    columnOrder={selectedColumns}
                    column={column}
                    setColumnOrder={setSelectedColumns}
                    availableColumns={availableColumns}
                    setAvailableColumns={setAvailableColumns}
                  />
                );
              })}
            </div>
          ) : undefined}
        </div>
        <div className="h-[400px] w-full">
          <div className={"flex"}>
            <ListView
              data={itemsData.data}
              columnOrder={selectedColumns}
              searchString={searchString}
              onClick={(e) => {
                navigate(`${e.item_id}`);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
