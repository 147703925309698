
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { basicGet, basicPatch, fetchData } from "../../api/api";
import Loadingsymbol from "../../components/atoms/Loadingsymbol";
import Box from "../../components/molecules/Box";
import Tab from "../../components/molecules/Tab";
import UnderConstruction from "../../components/molecules/UnderConstruction";
import NoPage from "../../pages/NoPage";
import EditTabs from "../../components/molecules/EditTabs";

async function fetchMarketStruct({ queryKey }) {
  const [_key1, _key2, marketId] = queryKey;
  const response = await basicGet(
    `structures?market_id=${marketId}&app=sa&structure_type=market`
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
}

async function patchItem(marketData, marketId) {
  const response = await basicPatch(
    `markets?market_id=${marketId}`,
    marketData
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response;
}

export default function SingleMarket() {
  const { t } = useTranslation();
  const [patchData, setPatchData] = useState({});
  const [edit, setEdit] = useState(false);

  const { marketId } = useParams(); // Get market id
  const queryClient = useQueryClient();

  const {
    data: marketData,
    isPending: marketDataPending,
    isError: marketDataError
  } = useQuery({
    queryKey: [
      marketId,
      "data",
      "market",
      { url: `/markets?market_id=${marketId}` }
    ],
    queryFn: fetchData,
    staleTime: 10 * 1000
  }); // Get the data

  const marketDataExists = marketData?.market_id;
  const {
    data: marketStruct,
    isPending: marketStructPending,
    isError: marketStructError
  } = useQuery({
    queryKey: ["struct", "market", marketId],
    queryFn: fetchMarketStruct,
    enabled: !!marketDataExists
  }); // "enabled" => Get the struct once data exists, we need the type

  const marketMutation = useMutation({
    mutationFn: ({ patchData: marketData, marketId }) =>
      patchItem(marketData, marketId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [marketId, "data", "market"]
      });
      setPatchData({});
    }
  });

  useEffect(() => {
    if (!marketDataPending) {
      setEdit(false);
    }
  }, [marketData]);

  if (marketDataError || marketStructError) {
    return <NoPage />;
  }

  if (marketDataPending || marketStructPending || marketMutation.isPending) {
    return (
      <div>
        <div className="flex h-[46px] border-r border-b px-8 w-full"></div>{" "}
        <div className="flex flex-col w-full items-center mt-8">
          <Loadingsymbol />
          <p className="text-body font-yantramanaRegular text-primary">
            {t("loading")}...
          </p>
        </div>
      </div>
    );
  }

  const handleUpdate = (e) => {
    if (Object.keys(e)[0].includes("components[")) {
      setPatchData((prev) => {
        setPatchData(prev, Object.keys(e)[0], Object.values(e)[0]);
        return prev;
      });
    } else {
      setPatchData((prev) => ({ ...prev, ...e }));
    }
  };

  const handleSave = () => {
    if (Object.keys(patchData).length > 0) {
      marketMutation.mutate({ patchData, marketId });
    } else {
      setEdit(false);
    }
  };

  const handleDiscard = () => {
    setEdit(false);
    setPatchData({});
  };
  return (
    <div className="h-full">
      <div className="flex h-[46px] border-r border-b px-8 w-full"></div>{" "}
      {/** Empty header */}
      <div
        key={marketId}
        className="relative grow overflow-scroll no-scrollbar w-full h-full shadow-[rgba(203,204,213,0.5)inset_1px_1px_4px_0px]"
      >
        <EditTabs
          edit={edit}
          setEdit={setEdit}
          handleSave={handleSave}
          handleDiscard={handleDiscard}
        />
        <div className={"relative w-full p-8"}>
          {Object.keys(marketData).length > 0 ? (
            marketStruct.boxes.map((box, boxIndex) => (
              <div
                key={box.header + boxIndex}
                className={
                  boxIndex === marketStruct.boxes.length - 1 ? "pb-8" : ""
                }
              >
                <Box
                  key={
                    box.header +
                    Object.hasOwn(box, "isComponentStruct") +
                    box.items.length
                  }
                  box={box}
                  boxIndex={boxIndex}
                  initialValue={marketData}
                  edit={edit}
                  onUpdate={handleUpdate}
                  market_id={marketId}
                />
              </div>
            ))
          ) : marketStruct && Object.keys(marketData).length <= 0 ? (
            <UnderConstruction />
          ) : null}

          {!marketStruct ? <UnderConstruction /> : null}
        </div>
      </div>
    </div>
  );
}
