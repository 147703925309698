import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Icon from "./Icon";
import HoverText from "../atoms/HoverText";
import {
  useComponentVisibleHover,
  useComponentVisibleClick
} from "../../utils/UtilsViewFunctions";

const MultiSelectDropdown = ({
  options,
  initialValue,
  onChange,
  disabled = false,
  placeholder
}) => {
  const { t } = useTranslation();
  const [values, setValues] = useState(initialValue ?? []);
  const { ref, isComponentVisible /*, setIsComponentVisible */ } =
    useComponentVisibleHover(false);
  const { refClick, isVisible, setIsVisible } = useComponentVisibleClick(false);

  useEffect(() => {
    setValues(initialValue ?? [])
  }, [initialValue])

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  const handleSelect = (item) => {
    let temp = JSON.parse(JSON.stringify(values));
    if (temp.includes(item)) {
      temp = temp.filter((a) => a !== item);
    } else {
      temp.push(item);
    }
    setValues(temp);
    onChange(temp);
  };

  const inputCssClasses = () => {
    const def =
      "absolute pr-4 top-0 bg-white left-0 font-yantramanavRegular text-textInput outline-none text-black w-full h-4 border-b focus:border-b-black appearance-none placeholder:text-mediumGray hover:cursor-pointer";
    if (disabled) return def + " border-b-transparent ";
    return def + " border-b-darkGray ";
  };

  const value = () => {
    const size = values ? values.length : 0;
    if (size <= 0) return "";
    if (size === 1) return t(values[0]);
    if (size === 2) return t(values[0]) + "," + t(values[1]);
    return t(values[0]) + "," + t(values[1]) + "  +" + (size - 2);
  };

  const valueHoverMap = values.map((info) => t(info));
  const valuesString = valueHoverMap.join(", ");

  return (
    <div ref={refClick} className="relative h-4">
      <input
        ref={ref}
        value={value()}
        disabled={disabled}
        onClick={handleClick}
        placeholder={t(placeholder)}
        className={inputCssClasses()}
      ></input>
      {isComponentVisible && disabled && valuesString.length >= 20 ? (
        <HoverText text={valuesString} />
      ) : null}
      {!disabled && (
        <div
          onClick={handleClick}
          className="absolute -top-1 right-0 hover:cursor-pointer "
        >
          {" "}
          <Icon size={16} color="black">
            direction_arrow_down_regular
          </Icon>
        </div>
      )}
      {isVisible && (
        <div className="absolute top-4 right-0 w-full bg-white z-10 shadow-md rounded overflow-hidden hover:cursor-pointer ">
          {options.map((item, index) => {
            const cssClassName = () => {
              const def =
                "text-black h-6 py-1 px-2 font-yantramanavRegular text-textInput hover:bg-lightBlue hover:text-primary flex justify-between items-center ";
              if (values.includes(item)) {
                return def + " bg-lightBlue text-primary ";
              }
              return def + " bg-white ";
            };
            return (
              <div
                key={item}
                className={cssClassName()}
                onClick={() => handleSelect(item)}
              >
                {t(item)}
                {values.includes(item) && (
                  <div className="py-1 hover:cursor-pointer">
                    <Icon color="primary">universal_check_regular</Icon>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
