import Checkbox from "../../components/atoms/Checkbox";
import CommentInput from "../../components/atoms/CommentInput";
import NumInput from "../../components/atoms/NumInput";
import TextInput from "../../components/atoms/TextInput";
import DateInput from "../../components/atoms/DateInput";
import Dropdown from "../../components/atoms/Dropdown";
import MultiSelectDropdown from "../../components/atoms/MultiSelectDropdown";
import KeyInput from "../../components/molecules/KeyInput";
import SingleData from "../../components/organisms/SingleData";
import SearchInput from "../../components/atoms/SearchInput";
import Image from "../../components/atoms/Image";
import Button from "../../components/molecules/Button";
import Tab from "../../components/molecules/Tab";
import Responsibility from "../../components/molecules/Responsibility";
import InteractiveIcon from "../../components/atoms/InteractiveIcon";


const Development = () => {
  const atoms = [
    [
      Checkbox,
      {
        initialValue: true,
        onChange: (e) => {
          console.log("pressed Value: ", e);
        }
      }
    ],
    [
      TextInput,
      {
        initialValue: null,
        placeholder: "Placeholder text",
        error_message: "Test error message in Input",
        onChange: (e) => {
          console.log("changed Value: ", e);
        }
      }
    ],
    [
      SearchInput,
      {
        initialValue: null,
        placeholder: "Placeholder text",
        onChange: (e) => {
          console.log("changed Value: ", e);
        }
      }
    ],
    [
      NumInput,
      {
        initialValue: 0,
        onChange: (e) => {
          console.log("pressed Value: ", e);
        }
      }
    ],
    [
      CommentInput,
      {
        initialValue: "true",
        onChange: (e) => {
          console.log("pressed Value: ", e);
        }
      }
    ],
    [
      DateInput,
      {
        initialValue: 724377600000, //1992-12-15
        onChange: (e) => {
          console.log("pressed Value: ", e);
        }
      }
    ],
    [
      Dropdown,
      {
        placeholder: "Placeholder text",
        onChange: (e) => {
          console.log("pressed Value: ", e);
        },
        options: ["test1", "test2"]
      }
    ],
    [
      Dropdown,
      {
        placeholder: "Placeholder text",
        addable: true,
        onChange: (e) => {
          console.log("pressed Value: ", e);
        },
        options: ["lt", "nk"]
      }
    ],
    [
      MultiSelectDropdown,
      {
        initialValue: ["test1"],
        placeholder: "Placeholder text",
        onChange: (e) => {
          console.log("pressed Value: ", e);
        },
        options: ["test1", "test2", "test3", "test4", "test5"]
      }
    ],
    [
      KeyInput,
      {
        inputKey: "name",
        label: "labelName",
        inputType: "string",
        initialValue: "hej",
        onChange: (e) => {
          console.log("save data: ", e);
        }
      }
    ],
/*     [
      SingleData,
      {
        initialValue: {},
        struct: EXAMPLESTRUCT
      }
    ], */
    [
      Button,
      {
        fill: true,
        icon: "check",
        text: "Button fill white",
        active: true,
        onClick: (e) => {
          console.log("save data: ", e);
        },
        enabled: true,
        color: "white"
      }
    ],
    [
      Button,
      {
        icon: "check",
        text: "Button - hug - default - white",
        active: false,
        onClick: (e) => {
          console.log("save data: ", e);
        },
        enabled: true,
        color: "white"
      }
    ],
    [
      Button,
      {
        icon: "check",
        text: "Button - hug - white - disabled",
        active: false,
        onClick: (e) => {
          console.log("save data: ", e);
        },
        enabled: false,
        color: "white"
      }
    ],
    [
      Button,
      {
        icon: "check",
        text: "Button - hug - default - green",
        active: false,
        onClick: (e) => {
          console.log("save data: ", e);
        },
        enabled: true,
        color: "green"
      }
    ],
    [
      Button,
      {
        icon: "check",
        text: "Button - hug - default - red",
        active: false,
        onClick: (e) => {
          console.log("save data: ", e);
        },
        enabled: true,
        color: "red"
      }
    ],
    [
      Tab,
      {
        style: "hugBlue",
        icon: "check",
        text: "Tab - hug - Blue - default",
        active: false,
        onClick: (e) => {
          console.log("save data: ", e);
        },
        enabled: true
      }
    ],
    [
      Tab,
      {
        style: "hugGreen",
        icon: "check",
        text: "Tab - hug - green - default",
        active: false,
        onClick: (e) => {
          console.log("save data: ", e);
        },
        enabled: true
      }
    ],
    [
      Tab,
      {
        style: "hugGreen",
        icon: "check",
        text: "Tab - disabled - hug - green/red",
        active: false,
        onClick: (e) => {
          console.log("save data: ", e);
        },
        enabled: false
      }
    ],
    [
      Tab,
      {
        style: "fixWhite",
        text: "Tab - white",
        active: false,
        onClick: (e) => {
          console.log("save data: ", e);
        },
        enabled: true
      }
    ],
    [
      Tab,
      {
        style: "fixWhite",
        text: "Tab white d",
        active: false,
        onClick: (e) => {
          console.log("save data: ", e);
        },
        enabled: false
      }
    ],
    [
      InteractiveIcon,
      {
        icon: "settings",
        onClick: (e) => {
          console.log("save data: ", e);
        }
      }
    ],
    [
      InteractiveIcon,
      {
        icon: "settings",
        size: "small",
        onClick: (e) => {
          console.log("save data: ", e);
        }
      }
    ],
    [
      Responsibility,
      {
        initialValue: {
          maintenance: [
            {
              email: "bread@email.com",
              main: "subtenant",
              name: "Bakary",
              phone: "0723205050",
              predicates: []
            }
          ],
          repairs: [
            {
              email: "bread@email.com",
              main: "subtenant",
              name: "Bakary",
              phone: "0723205050",
              predicates: []
            }
          ],
          owner: [
            {
              email: "bread@email.com",
              main: "subtenant",
              name: "Bakary",
              phone: "0723205050",
              predicates: []
            }
          ]
        },
        onChange: (e) => {
          console.log("save data: ", e);
        }
      }
    ]
  ];
  const renderAtoms = () => {
    return atoms.map((atom, index) => {
      let Atom = atom[0];
      let params = atom[1];
      return (
        <div key={index}>
          <Atom {...params} />
          <p>=============</p>
        </div>
      );
    });
  };

  return (
    <div>
      <div className="m-2">{renderAtoms()}</div>
    </div>
  );
};

export default Development;

export const EXAMPLEFILES = [
  {
    name: "Contract",
    url: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
  },
  {
    name: "File",
    url: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
  },
  {
    name: "Site Plan",
    url: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
  },
  {
    name: "Empty",
    url: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
  }
];