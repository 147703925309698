import { useState } from "react";
import Icon from "./Icon";

const SearchInput = ({ initialValue = "", size = 'medium', placeholder, onChange}) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (event) => {
        const newValue = event.target.value;
        setValue(newValue);
        onChange(newValue);
    };

    const inputCssClasses = () => {
		return 'font-yantramanavLight h-full py-1.5 outline-none w-full text-base text-ellipsis truncate text-black placeholder:text-darkGray ml-1.5';
	};

    return (
      <div
        className={`${
          size === "medium" ? "w-[316px] h-7" : "w-[224px] h-6"
        } text-black rounded border border-mediumGray px-2 flex justify-start items-center bg-white`}
      >
        <Icon size={size === "medium" ? 14 : 13} color="darkGray">
          universal_search_regular
        </Icon>
        <input
          className={inputCssClasses()}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
        />
      </div>
    );
};

export default SearchInput;

