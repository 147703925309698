import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { basicPost, fetchData, basicPatch, basicDelete } from "../../api/api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import MoreOptionsDropdown from "../../components/molecules/MoreOptionsDropdown";
import GalleryViewDropdown from "../Photobook/GalleryView/GalleryViewDropdown";
import Loadingsymbol from "../../components/atoms/Loadingsymbol";
import NoDataFound from "../../components/molecules/NoDataFound";
import SearchInput from "../../components/atoms/SearchInput";
import ListView from "../../components/organisms/ListView";
import Button from "../../components/molecules/Button";
import DeleteFolderModal from "./DeleteFolderModal";
import Tab from "../../components/molecules/Tab";
import EditFolderModal from "./EditFolderModal";
import AddFolderModal from "./AddFolderModal";
import NoPage from "../../pages/NoPage";
import Folder from "./Folder";

const INITIAL_COLUMNS = [
  { key: "main_images", label: "main_images" },
  { key: "name", label: "name" },
  { key: "created_at", label: "created_at" }
];

export default function Vault() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { marketId } = useParams();
  const queryClient = useQueryClient();
  const [tab, setTab] = useState({ gallery_view: false, list_view: true });
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [openFolderId, setOpenFolderId] = useState(null);
  const [searchString, setSearchString] = useState("");
  const [folderData, setFolderData] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [option, setOption] = useState(null);
  const [edit, setEdit] = useState(false);
  const [tags, setTags] = useState([]);

  const foldersRes = useQuery({
    queryKey: [
      marketId,
      "data",
      "folders",
      { url: `vaults?market_id=${marketId}` }
    ],
    queryFn: fetchData
  });

  const addFolderMutation = useMutation({
    mutationFn: ({ patchData: folderData, marketId }) =>
      basicPost(`vaults?market_id=${marketId}`, folderData),
    onError: (error) => {
      console.log("On error: ", error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [marketId, "data", "folders"]
      });
    }
  });

  const folderMutation = useMutation({
    mutationFn: ({ patchData: folderData, marketId, vaultId }) =>
      basicPatch(
        `vaults?market_id=${marketId}&vault_id=${vaultId}`,
        folderData
      ),
    onError: (error) => {
      console.log("On error: ", error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [marketId, "data", "folders"]
      });
    }
  });

  const deleteFolderMutation = useMutation({
    mutationFn: ({ folderId, marketId }) =>
      basicDelete(`vaults?market_id=${marketId}&vault_id=${folderId}`),
    onError: (error) => {
      console.log("On error: ", error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [marketId, "data", "folders"]
      });
    }
  });

  let availableTags = foldersRes.data?.reduce((acc, folder) => {
    if (!folder.tags) {
      return { ...acc };
    }
    let newObj = folder.tags.reduce((prev, current) => {
      return { ...prev, [current]: current };
    }, {});
    return { ...acc, ...newObj };
  }, {});

  const handleSave = (folderdata) => {
    folderMutation.mutate({
      patchData: folderdata,
      marketId,
      vaultId: folderData.vault_id
    });
  };

  const handleDiscard = () => {
    setEdit(false);
  };

  const handleAddFolder = (folderData) => {
    addFolderMutation.mutate({ patchData: folderData, marketId });
  };

  const expandInfo = (event, folder) => {
    event.stopPropagation();
    setFolderData(folder);
    setEditModalOpen(true);
  };

  const handleOptionPress = (e, option) => {
    if (option === "delete") {
      setDeleteModalOpen(true);
    } else if (option === "edit") {
      expandInfo(e, folderData);
    }
  };

  // ❌ Do not remove this function, as it is a part of the future
  const openOptionsDropdown = (event, folder) => {
    event.stopPropagation();
    setFolderData(folder);
    setOpenFolderId(folder.vault_id);
  };

  const handleDeleteFolder = (folderId) => {
    deleteFolderMutation.mutate({ folderId, marketId });
    setEditModalOpen(false);
    setFolderData({});
  };

  if (foldersRes.isPending) {
    return (
      <div className="h-full">
        <div className="flex h-[46px] border-r border-b px-8 w-full"></div>
        {/** Empty header */}
        <div className="flex flex-col items-center mt-8">
          <Loadingsymbol />
          <p className="text-body font-yantramanaRegular text-primary">
            {t("loading")}...
          </p>
        </div>
      </div>
    );
  }

  if (foldersRes.isError) {
    return <NoPage />;
  }

  let tagFilteredData = tags.length
    ? foldersRes?.data?.filter((folder) => {
        let foundTags = tags.filter(
          (tag) => (folder?.tags ?? []).indexOf(tag) !== -1
        );
        return foundTags.length;
      })
    : foldersRes?.data;
  let filteredData = tagFilteredData.filter((folder) => {
    return folder?.name.toLowerCase().includes(searchString.toLowerCase());
  });

  return (
    <div className="h-full" onClick={() => setOpenFolderId()}>
      <div className="h-[46px] border-r border-b overflow-hidden flex justify-between items-center px-8 ">
        <div className="flex self-end gap-4">
          <Tab
            text={t("vaults_list_view")}
            active={tab.list_view}
            style="fixWhite"
            onClick={() => setTab({ gallery_view: false, list_view: true })}
          />
          <Tab
            text={t("vaults_gallery_view")}
            active={tab.gallery_view}
            style="fixWhite"
            onClick={() => setTab({ gallery_view: true, list_view: false })}
          />
        </div>
      </div>

      <div className="h-[46px] border-r border-b flex justify-between items-center px-8">
        <div className="flex flex-row gap-2">
          <SearchInput
            onChange={(e) => setSearchString(e)}
            placeholder={t("search")}
          />
          <GalleryViewDropdown
            tags={availableTags}
            onChange={(tags) => setTags(tags)}
          />
        </div>
        <Button
          onClick={() => {
            setModalOpen(true);
          }}
          text="add_new_folder"
          icon="universal_zoom_in_circle"
        />
      </div>

      {tab.list_view ? (
        <ListView
          data={filteredData}
          columnOrder={INITIAL_COLUMNS}
          searchString={""}
          onClick={(e) => navigate(`${e.vault_id}`)}
          onClickExpandInfo={expandInfo}
          icon="universal_dots_regular"
        />
      ) : null}

      {tab.gallery_view ? (
        <div className="h-full overflow-scroll no-scrollbar shadow-[rgba(203,204,213,0.5)inset_1px_1px_4px_0px]">
          {filteredData.length < 1 ? (
            <div>
              <NoDataFound data="folders" />
            </div>
          ) : (
            <div className="flex flex-row gap-6 justify-start flex-wrap p-9 mb-36">
              {filteredData?.map((folder) => (
                <div key={folder.vault_id} className="relative">
                  <Folder
                    key={folder.vault_id}
                    folder={folder}
                    onClick={() => navigate(`${folder.vault_id}`)}
                    onClickExpandInfo={(e) => expandInfo(e, folder)}
                    // ❌ Prep for "more options-dropdown", not implemented, but
                    // please do not remove, as it is a part of the future
                    // onClickExpandInfo={(e) => openOptionsDropdown(e, folder)}
                  />
                  {openFolderId === folder.vault_id && (
                    <MoreOptionsDropdown
                      options={["delete", "edit"]}
                      setOption={setOption}
                      onPressOption={handleOptionPress}
                    />
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      ) : null}

      {/* ❌ Prep for "more options-dropdown", not implemented, but
        please do not remove, as it is a part of the future */}
      <DeleteFolderModal
        key={folderData.vault_id}
        data={folderData}
        isOpen={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        handleDeleteFolder={handleDeleteFolder}
        setFolderData={setFolderData}
      />

      <AddFolderModal
        modalOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        allTags={availableTags}
        onAddFolder={handleAddFolder}
      />

      <EditFolderModal
        key={folderData.vault_id}
        edit={edit}
        setEdit={setEdit}
        folderData={folderData}
        allTags={availableTags}
        editModalOpen={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        handleSave={handleSave}
        handleDiscard={handleDiscard}
        onDeletion={handleDeleteFolder}
      />
    </div>
  );
}
