import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ResponsiveModal from "./ResponsiveModal";
import CommentInput from "../atoms/CommentInput";
import TextInput from "../atoms/TextInput";
import Icon from "../atoms/Icon";

function ExtraCosts({ initialValue }) {
  const [isOpen, setIsOpen] = useState(false);
  const [extraCostData, setExtraCostData] = useState({});
  const { t } = useTranslation();

  const openModal = (e, index) => {
    setIsOpen(true);
    setExtraCostData(initialValue[index]);
  };

  if (!initialValue) {
    return (
      <div>
        <p className="ont-yantramanavRegular text-textInput text-black h-4">
          no_extra_costs_added
        </p>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-2">
      {initialValue?.map((item, index) => (
        <div
          key={index}
          onClick={(e) => openModal(e, index)}
          className="flex flex-row gap-2 justify-between items-center w-[300px] border-mediumGray border px-3 py-1 rounded shadow hover:cursor-pointer hover:bg-lightBlue hover:text-primary transition ease-in delay-75 font-yantramanavRegular text-inputTitle"
        >
          <div className="flex flex-row gap-2 items-center w-[85%]">
            <Icon size={17} color="primary">
              item_arbitrary_regular
            </Icon>
            <p className=" truncate">{item.name}</p>
          </div>
          <p>
            {item.quantity} {t("pcs")}
          </p>
        </div>
      ))}

      <ResponsiveModal
        modalTitle="extra_costs"
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
          setExtraCostData({});
        }}
      >
        <div className="min-w-[560px] h-[250px] p-8">
          <div className="flex flex-col gap-8">
            <div className="flex flex-row gap-2 items-start w-full">
              <div className="flex flex-col w-1/2">
                <p className="text-black text-[14px] leading-[18.16px] font-yantramanavLight text-left">
                  {t("name")}
                </p>
                <div className="w-[90%]">
                  <TextInput initialValue={extraCostData.name} disabled />
                </div>
              </div>
              <div className="flex flex-col w-1/2">
                <p className="text-black text-[14px] leading-[18.16px] font-yantramanavLight text-left ">
                  {t("quantity")}
                </p>
                <p className="font-yantramanavRegular text-inputTitle text-black h-4">
                  {extraCostData.quantity} {t("pcs")}
                </p>
              </div>
            </div>

            <div className="flex flex-row gap-2 items-start w-full">
              <div className="flex flex-col w-1/2">
                <p className="text-black text-[14px] leading-[18.16px] font-yantramanavLight text-left">
                  {t("cost_position_id")}
                </p>
                <p className="font-yantramanavRegular text-inputTitle text-black h-4">
                  {extraCostData.cost_position}
                </p>
              </div>
              <div className="flex flex-col w-1/2">
                <p className="text-black text-[14px] leading-[18.16px] font-yantramanavLight text-left">
                  {t("comment")}
                </p>
                <CommentInput
                  initialValue={extraCostData.cost_comment}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </ResponsiveModal>
    </div>
  );
}

export default ExtraCosts;
