
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MenuItemEntry from "../../../atoms/MenuItemEntry";
import { menuSubCategories } from "../constants";
import MenuEntryList from "../../../atoms/MenuEntryList";

export default function MissionsFolder({
  selectedPath,
  setSelectedPath,
  data
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { missionId } = useParams();

  const initialSelectedCategory =
    data?.data?.find((item) => item.mission_id === missionId)?.categories[0] ??
    "";
  const [selectedCategory, setSelectedCategory] = useState(
    initialSelectedCategory
  );

  const handleRootItemClick = () => {
    setSelectedPath((prev) => (!prev.includes("missions") ? "missions/" : ""));
    if (!initialSelectedCategory) setSelectedCategory("");
  };

  const categories = menuSubCategories.reduce((categoriesAcc, subCategory) => {
    if (data.data) {
      categoriesAcc[subCategory] = data.data.reduce((dataAcc, dataItem) => {
        if (dataItem.categories?.includes(subCategory))
          dataAcc[dataItem["mission_id"]] = dataItem;
        return dataAcc;
      }, {});
      return categoriesAcc;
    } else {
      categoriesAcc[subCategory] = {};
      return categoriesAcc;
    }
  }, {});

  const navigateToItem = (navMissionId) => {
    navigate("missions/" + navMissionId);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory((prev) => (prev === category ? "" : category));
    setSelectedPath((prev) =>
      prev.includes(category) ? "missions" : prev + category
    );
  };
  return (
    <div className="flex flex-col">
      <MenuItemEntry
        icon="logo_checklist_regular"
        label={t("missions")}
        isSelected={selectedPath.includes("missions")}
        handleClick={handleRootItemClick}
      />
      {selectedPath.includes("missions") ? (
        <div className="pl-6 flex flex-col">
          <MenuItemEntry
            icon={"logo_checklist_regular"}
            label={t("all_missions")}
            isSelected={selectedPath.endsWith("missions")}
            handleClick={() => {
              navigate("missions");
              handleCategoryClick("missions");
            }}
          />
          {Object.entries(categories).map(([category, categoryData]) => {
            return (
              <div key={"missions" + category}>
                <MenuItemEntry
                  icon={"item_" + category + "_regular"}
                  label={t(category)}
                  isSelected={selectedCategory === category}
                  handleClick={() => handleCategoryClick(category)}
                />
                {selectedCategory === category ? (
                  <div className="flex flex-col pl-6">
                    <MenuEntryList
                      selectedEntryId={missionId}
                      keys={{
                        labelKey: "name",
                        idKey: "mission_id",
                        iconKey: ""
                      }}
                      handleEntryClick={(item) =>
                        navigateToItem(item.mission_id)
                      }
                      data={Object.values(categoryData) ?? []}
                    />
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}
