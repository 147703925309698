import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import Icon from '../atoms/Icon';
import Image from '../atoms/Image';
import Button from "./Button";

function ImageViewer({ images, initialImageIndex, onClose, title, downloadTitle }) {
    const { t } = useTranslation();
    const [currentImageIndex, setCurrentImageIndex] = useState(initialImageIndex);
    const [startIndex, setStartIndex] = useState(0);
    const maxVisibleImages = 4;

    const endIndex = Math.min(images.length - 1, startIndex + maxVisibleImages - 1);
    const visibleSmallImages = images.slice(startIndex, endIndex + 1);

    // const download_photo = () => {
    //     FileSaver.saveAs(images[currentImageIndex], title + ".jpg");
    // }

    const download_photo = () => {
      fetch(images[currentImageIndex])
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = `${downloadTitle}_${title}.jpg`; // Set your desired filename here
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url); // Clean up
        })
        .catch((error) => {
          console.error("There was an error downloading the image!", error);
        });
    };

    const handleImageClick = (index) => {
      setCurrentImageIndex(index);
    };

    const handleNextImage = () => {
      setStartIndex(startIndex + 1);
    };

    const handlePrevImage = () => {
      setStartIndex(startIndex - 1);
    };

    return (
      <div className="fixed top-0 left-0 h-screen w-screen bg-black bg-opacity-50 flex items-center justify-center z-20">
        <div className="w-[856px] bg-white border border-mediumGray rounded-t flex flex-col items-center">
          {/* Header */}
          <div className="h-9 w-full flex justify-between items-center border-b border-mediumGray py-2  text-sm px-4">
            <p className="font-yantramanavRegular text-black">{title}</p>
            <button onClick={onClose}>
              <Icon size={20} color="mediumGray">
                universal_close_filled
              </Icon>
            </button>
          </div>

          {/* Big Image */}
          <div className="w-full px-4 h-[500px] mb-3">
            <div className="relative top-0 right-0 w-full h-full mt-4 mb-3">
              <Image
                className="absolute top-0 left-0"
                source={images[currentImageIndex]}
                size="xLarge"
                objectFit="object-contain"
              />
              <div className="absolute bottom-1 right-1 p-2">
                {downloadTitle && (
                  <Button
                    style="medium"
                    text={t("download_photo")}
                    icon="universal_download_regular"
                    onClick={() => download_photo()}
                  />
                )}
              </div>
            </div>
          </div>

          {/* Download-button & Comment-text */}
          <div className="h-[150px] w-full p-4 ">
            {/* Image Carousel */}
            <div className="relative">
              <div className="absolute top-0 right-0 flex [87px] w-full">
                <div className="flex">
                  {visibleSmallImages.map((image, index) => (
                    <div
                      key={index}
                      className={`overflow-hidden cursor-pointer [&:not(:first-child)]:ml-2`}
                      onClick={() => handleImageClick(startIndex + index)}
                    >
                      <Image source={image} customSize="w-[200px] h-[118px]" />
                    </div>
                  ))}
                </div>
              </div>
              {images.length > 4 && startIndex > 0 && (
                <button
                  className="absolute left-0 top-0 w-[24px] h-[118px] bg-darkWhite hover:bg-lightGray opacity-80"
                  onClick={handlePrevImage}
                >
                  <Icon size={16} color="black">
                    direction_arrow_left_regular
                  </Icon>
                </button>
              )}

              {images.length > 4 && endIndex < images.length - 1 && (
                <button
                  className="absolute right-0 top-0 w-[24px] h-[118px] bg-darkWhite hover:bg-lightGray opacity-80"
                  onClick={handleNextImage}
                >
                  <Icon color="black" size={16}>
                    direction_arrow_right_bold
                  </Icon>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
}

export default ImageViewer;
