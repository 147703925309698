import { useRef, useState } from "react";
import Supercluster from "supercluster";
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";
import { dequal } from "dequal";

/**
 * @typedef {Object} UseSuperclusterArgument
 * @property {Array<Supercluster.PointFeature<P>>} points - The points to be clustered.
 * @property {[number, number, number, number]} [bounds] - The bounding box within which to cluster points.
 * @property {number} zoom - The zoom level at which to cluster points.
 * @property {Supercluster.Options<P, C>} [options] - Supercluster library options.
 * @property {boolean} [disableRefresh] - If true, disables refreshing clusters on updates.
 * @template P, C
 */

/**
 * A React hook for using Supercluster to cluster points.
 * 
 * @param {UseSuperclusterArgument<P, C>} params - The arguments for the hook.
 * @returns {{ clusters: Array<Supercluster.ClusterFeature<C> | Supercluster.PointFeature<P>>, supercluster: Supercluster<P, C> }} The clusters and the Supercluster instance.
 * @template P extends GeoJsonProperties = Supercluster.AnyProps, C extends GeoJsonProperties = Supercluster.AnyProps
 */
const useSupercluster = ({
  points,
  bounds,
  zoom,
  options,
  disableRefresh
}) => {
  const superclusterRef = useRef();
  const pointsRef = useRef();
  const [clusters, setClusters] = useState([]);

  const zoomInt = Math.round(zoom);

  useDeepCompareEffectNoCheck(() => {
    if (disableRefresh === true) {
      return;
    }

    if (
      !superclusterRef.current ||
      !dequal(pointsRef.current, points) ||
      !dequal(
        (superclusterRef.current?.options),
        options
      )
    ) {
      superclusterRef.current = new Supercluster(options);
      superclusterRef.current.load(points);
    }

    if (bounds) {
      setClusters(superclusterRef.current.getClusters(bounds, zoomInt));
    }

    pointsRef.current = points;
  }, [points, bounds, zoomInt, options, disableRefresh]);

  return { clusters, supercluster: superclusterRef.current };
};

export default useSupercluster;
