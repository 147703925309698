import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Icon from "../../atoms/Icon";
import Loadingsymbol from "../../atoms/Loadingsymbol";
import { useInitMenu, useResourceInfoBuilder } from "./helpers";
import PhotobookFolder from "./RootItems/PhotobookFolder";
import ItemsFolder from "./RootItems/ItemsFolder";
import MissionsFolder from "./RootItems/MissionsFolder";
import IssuesFolder from "./RootItems/IssuesFolder";
import ResponsibilityFolder from "./RootItems/ResponsibilityFolder";
import General from "./RootItems/General";
import VaultFolder from "./RootItems/VaultFolder";
import AnalyticsFolder from "./RootItems/AnalyticsFolder";
import useNavigationStore from "../../../utils/NavigationStore";

const rootItems = [
    { key: "general", element: General },
    { key: "items", element: ItemsFolder },
    { key: "photobook", element: PhotobookFolder },
    { key: "missions", element: MissionsFolder },
    { key: "issues", element: IssuesFolder },
    { key: "analytics", element: AnalyticsFolder },
    { key: "vault", element: VaultFolder },
    { key: "responsibility", element: ResponsibilityFolder },
]

export default function FolderTree() {
  const { t } = useTranslation();
  const { marketId } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isPending: menuDataPending, menuData } = useInitMenu(marketId);
  const setNavigationState = useNavigationStore(
    (state) => state.setNavigationState
  );
  const currentPath = useNavigationStore((state) => state.navigationState.to);

  const [selectedFolder, setSelectedFolder] = useState(pathname);

  useEffect(() => {
    setSelectedFolder(pathname);
  }, [pathname]);

  const handleHomeClick = () => {
    setNavigationState(currentPath, "/");
    navigate("/");
  };

  if (menuDataPending) {
    return (
      <div className="w-full">
        <div className="flex justify-between border-b border-l py-2 px-4 h-[46px] overflow-hidden">
          <div
            className="flex gap-1 items-center hover:cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <Icon color="black" size={16}>
              direction_arrow_left_regular
            </Icon>
            <p className="text-base font-yantramanavLight hover:font-yantramanavRegular text-[16px]">
              {t("back")}
            </p>
          </div>
          <div
            className="flex gap-1 items-center text-[16px] hover:cursor-pointer"
            onClick={handleHomeClick}
          >
            <Icon color="primary" size={16}>
              other_home_regular
            </Icon>
            <p className=" font-yantramanavLight hover:font-yantramanavRegular">
              {t("home")}
            </p>
          </div>
        </div>
        <div className="flex flex-col w-full items-center mt-8">
          <Loadingsymbol />
          <p className="text-body font-yantramanaRegular text-primary">
            {t("loading")}...
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="h-full overflow-clip w-full">
      <div className="flex justify-between border-b border-l py-2 px-4 h-[46px] overflow-hidden">
        <div
          className="flex gap-1 items-center hover:cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <Icon color="black" size={16}>
            direction_arrow_left_regular
          </Icon>
          <p className="text-base font-yantramanavLight text-[16px] hover:font-yantramanavRegular">
            {t("back")}
          </p>
        </div>
        <div
          className="flex gap-1 items-center text-[16px] hover:cursor-pointer"
          onClick={handleHomeClick}
        >
          <Icon color="primary" size={16}>
            other_home_regular
          </Icon>
          <p className=" font-yantramanavLight hover:font-yantramanavRegular">
            {t("home")}
          </p>
        </div>
      </div>
      <div
        className="flex flex-col py-4 pl-4 border-l overflow-auto"
        style={{ height: "calc(100% - 50px)" }}
      >
        {rootItems.map((item) => (
          <item.element
            key={item.key}
            navigationPath={item.navigationPath}
            data={menuData?.[item.key] ?? {}}
            selectedPath={selectedFolder}
            setSelectedPath={setSelectedFolder}
          />
        ))}
      </div>
    </div>
  );
}
