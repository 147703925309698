import React from "react";
import placeholder from "../../assets/placeholder-image.png";

function Image({ source, size, objectFit = "object-cover", customSize = "" }) {
  const image = source ? source : placeholder;

  const imageCssClasses = () => {
    const def = `${objectFit} object-center rounded-sm overflow-hidden border border-mediumGray `;
    if (size === "small") return def + "w-[56px] h-[34px]";
    if (size === "thin") return def + "w-[132px] h-[71px]";
    if (size === "medium") return def + "w-[159px] h-[87px]";
    if (size === "large") return def + "w-[424px] h-[206px]";
    if (size === "xLarge") return def + "w-full h-full";
    return def + customSize;
  };

  return (
    <div className="relative h-full overflow-hidden rounded-sm">
      {size === "xLarge" ? (
        <div
          className="absolute inset-0 blur-sm rounded-sm"
          style={{
            backgroundImage: `url(${image})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center"
          }}
        ></div>
      ) : null}
      <img
        className={`relative ${imageCssClasses()}`}
        src={image}
        alt={size}
      />
    </div>
  );
}

export default Image;
