import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MenuItemEntry from "../../../atoms/MenuItemEntry";
import { menuSubCategories } from "../constants";
import MenuEntryList from "../../../atoms/MenuEntryList";

export default function IssuesFolder({ selectedPath, setSelectedPath, data }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { issueId } = useParams();

  const initialSelectedCategory =
    data?.data?.find((item) => item.issue_id === issueId)?.target
      ?.categories[0] ?? "";
  const [selectedCategory, setSelectedCategory] = useState(
    initialSelectedCategory
  );

  const handleRootItemClick = () => {
    setSelectedPath((prev) => (!prev.includes("issues") ? "issues/" : ""));
    if (!initialSelectedCategory) setSelectedCategory("");
  };

  const categories = menuSubCategories.reduce((categoriesAcc, subCategory) => {
    if (data.data) {
      categoriesAcc[subCategory] = data.data.reduce((dataAcc, dataItem) => {
        if (dataItem.target?.categories?.includes(subCategory))
          dataAcc[dataItem["issue_id"]] = {
            ...dataItem.target,
            issue_id: dataItem["issue_id"]
          };
        return dataAcc;
      }, {});
      return categoriesAcc;
    } else {
      categoriesAcc[subCategory] = {};
      return categoriesAcc;
    }
  }, {});

  const navigateToItem = (navIssueId) => {
    navigate("issues/" + navIssueId);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory((prev) => (prev === category ? "" : category));
    setSelectedPath((prev) =>
      prev.includes(category) ? "issues" : prev + category
    );
  };

  return (
    <div className="flex flex-col">
      <MenuItemEntry
        icon="logo_quicktell_regular"
        label={t("damage_report")}
        isSelected={selectedPath.includes("issues")}
        handleClick={handleRootItemClick}
      />
      {selectedPath.includes("issues") ? (
        <div className="pl-6 flex flex-col">
          <MenuItemEntry
            icon={"logo_quicktell_regular"}
            label={t("all_issues")}
            isSelected={selectedPath.endsWith("issues")}
            handleClick={() => {
              navigate("issues");
              handleCategoryClick("all_issues");
            }}
          />
          {Object.entries(categories).map(([category, categoryData]) => {
            return (
              <>
                <MenuItemEntry
                  icon={"item_" + category + "_regular"}
                  label={t(category)}
                  isSelected={selectedCategory === category}
                  handleClick={() => handleCategoryClick(category)}
                />
                {selectedCategory === category ? (
                  <div className="flex flex-col pl-6">
                    <MenuEntryList
                      selectedEntryId={issueId}
                      keys={{
                        labelKey: "display_name",
                        idKey: "issue_id",
                        iconKey: ""
                      }}
                      handleEntryClick={(item) => navigateToItem(item.issue_id)}
                      data={Object.values(categoryData) ?? []}
                    />
                  </div>
                ) : null}
              </>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}
