import { useTranslation } from "react-i18next";
import ResponsiveModal from "./ResponsiveModal";
import Box from "./Box";
import Tab from "./Tab";
import Icon from "../atoms/Icon";
import DateInput from "../atoms/DateInput";
import Dropdown from "../atoms/Dropdown";
import { useState,useEffect } from "react";
import NumInput from "../atoms/NumInput";

const ResponsibilityModal = ({
    modalContent,
    isOpen,
    onClose,
    market_id,
    editable
}) => {
  const { t } = useTranslation();
  const [update, setUpdate] = useState(1);
  const [singleItem, setSingleItem] = useState(modalContent?.item); // I think this is a good idea
  const [resp, setResp] = useState({})
  const [showBox, setShowBox] = useState(true);

  useEffect(() => {
    if(modalContent.item &&
      modalContent.groupName &&
      !modalContent.item.item_id){
      setResp({[modalContent.groupName] : structuredClone(modalContent.item)})

    }else{
      setResp(structuredClone(modalContent.item))
    }
  },[modalContent])

  const hardcodedBoxes = {
    boxes: [
      {
        items: [
          {
            keys: [
              {
                inputKey: "display_name",
                type: "string",
                label: "display_name",
                description: "",
                options: []
              },
              {
                inputKey: "manufacturer",
                type: "dropdown",
                label: "manufacturer",
                description: "",
                options: []
              },
              {
                inputKey: "production_year",
                type: "int",
                label: "production_year",
                description: "",
                options: []
              }
            ]
          },
          {
            keys: []
          },
          {
            keys: [
              {
                inputKey: "id_rewe",
                type: "string",
                label: "id_rewe_item",
                description: "",
                options: []
              },
              {
                inputKey: "model",
                type: "dropdown",
                label: "model",
                description: "",
                options: []
              },
              {
                inputKey: "installation_year",
                type: "int",
                label: "installation_year",
                description: "",
                options: []
              }
            ]
          },
          {
            keys: []
          },
          {
            keys: []
          },
          {
            keys: [
              {
                inputKey: "main_images",
                type: "images",
                label: "main_images",
                description: "",
                options: []
              }
            ]
          },
          {
            keys: []
          },
          {
            keys: []
          },
          {
            keys: []
          }
        ],
        merge: [
          {
            columns: [1, 3]
          },
          {
            columns: [2, 3]
          },
          {
            columns: [3, 5]
          },
          {
            columns: [4, 5]
          },
          {
            columns: [5, 6]
          },
          {
            columns: [6, 10]
          }
        ],
        header: "general",
        isComponentStruct: false
      }
    ]
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = date.getDate().toString().padStart(2, "0"); // Ensure two-digit day
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear().toString();
    const formattedDate = `${day}.${month}.${year}`;
    return formattedDate;
  };

  const titleStyle =
    "font-yantramanavLight text-black text-[14px] leading-[18.16px] tracking-[0.5px]";
  const valueStyle =
    "font-yantramanavRegular text-black text-[14px] leading-[16px]";

  const getCompaniesOptions = (area, main) => {
    if (!main) return [];
    if (!modalContent.struct?.[area]?.[main]) return [];
    return modalContent.companies
      .filter((c) =>
        modalContent.struct[area][main].some((arr) =>
          c.company_type.some((ct) => ct === arr)
        )
      )
      .map((c) => c.name);
  };

  const renderInfo = (item, area) => {
    // const groupedItems = item?.predicates?.reduce((grouped, predi) => {
    //     const key = predi.key;
    //     if (!grouped[key]) {
    //         grouped[key] = [];
    //     }
    //     grouped[key].push(predi);
    //     return grouped;
    // }, {});
    let copyOfSecond = JSON.parse(JSON.stringify(item)).second || "";
    return (
      <div
        key={item.main + +item.second + "_" + update + JSON.stringify(item)}
        className="flex flex-col justify-between gap-2"
      >
        <div>
          <p className={titleStyle}>{t("responsible_party")}</p>
          <Dropdown
            initialValue={item?.main || ""}
            disabled={!editable}
            options={Object.keys(modalContent.struct[area])}
            onChange={(e) => {
              item.main = e;
              item.second = "";
              item.phone = "";
              item.email = "";
              item.name = "";
              setUpdate((prev) => prev + 1);
            }}
          />
        </div>
        <>
          {!editable && item.second ? (
            <>
              <div key={item.main + +item.second + "_second_" + update}>
                <p className={titleStyle}>{t("service_partner")}</p>
                <Dropdown
                  initialValue={copyOfSecond}
                  disabled={!editable}
                  options={getCompaniesOptions(area, item.main)}
                  onChange={(e) => {
                    let cc = modalContent.companies.filter(
                      (c) => c.name === e
                    )[0];
                    item.second = cc.name;
                    item.phone = cc.phone;
                    item.email = cc.email;
                    item.name = cc.name;
                    setUpdate((prev) => prev + 1);
                  }}
                />
              </div>
              <div className="py-[2px] px-1">
                {item.phone && (
                  <p className="font-yantramanavRegular text-darkGray text-[12px] leading-[18.16px]">
                    {t("phone")}:{" "}
                    <span className="font-yantramanavLight">{item?.phone}</span>
                  </p>
                )}
                {item.email && (
                  <p className="font-yantramanavRegular text-darkGray text-[12px] leading-[18.16px]">
                    {t("email")}:{" "}
                    <span className="font-yantramanavLight">{item?.email}</span>
                  </p>
                )}
              </div>
            </>
          ) : editable ? (
            <>
              <div key={item.main + +item.second + "_second_" + update}>
                <p className={titleStyle}>{t("service_partner")}</p>
                <Dropdown
                  initialValue={copyOfSecond}
                  disabled={!editable}
                  options={getCompaniesOptions(area, item.main)}
                  onChange={(e) => {
                    let cc = modalContent.companies.filter(
                      (c) => c.name === e
                    )[0];
                    item.second = cc.name;
                    item.phone = cc.phone;
                    item.email = cc.email;
                    item.name = cc.name;
                    setUpdate((prev) => prev + 1);
                  }}
                />
              </div>
              <div className="py-[2px] px-1">
                {item.phone && (
                  <p className="font-yantramanavRegular text-darkGray text-[12px] leading-[18.16px]">
                    {t("phone")}:{" "}
                    <span className="font-yantramanavLight">{item?.phone}</span>
                  </p>
                )}
                {item.email && (
                  <p className="font-yantramanavRegular text-darkGray text-[12px] leading-[18.16px]">
                    {t("email")}:{" "}
                    <span className="font-yantramanavLight">{item?.email}</span>
                  </p>
                )}
              </div>
            </>
          ) : null}
          <div className="flex flex-col justify-evenly gap-2">
            {!item.predicates.some((r) => r.key === "time") && editable ? (
              <div
                onClick={() => {
                  item.predicates.push({
                    key: "time",
                    operation: "<",
                    value: Date.now()
                  });
                  item.predicates.push({
                    key: "time",
                    operation: ">",
                    value: Date.now()
                  });
                  setUpdate((p) => p + 1);
                }}
                className="flex flex-row items-center gap-2 hover:cursor-pointer"
              >
                <Icon size={16}>universal_zoom_in_circle</Icon>
                <p className={titleStyle}>{t("time_dependency")}</p>
              </div>
            ) : null}
            {!item.predicates.some((r) => r.key === "cost") && editable ? (
              <div
                onClick={() => {
                  item.predicates.push({
                    key: "cost",
                    operation: "<",
                    value: 1
                  });
                  item.predicates.push({
                    key: "cost",
                    operation: ">",
                    value: 1
                  });
                  setUpdate((p) => p + 1);
                }}
                className="flex flex-row items-center gap-2 hover:cursor-pointer"
              >
                <Icon size={16}>universal_zoom_in_circle</Icon>
                <p className={titleStyle}>{t("cost_dependency")}</p>
              </div>
            ) : null}
          </div>
        </>

        {/* Predicates */}
        {/* {item.predicates && item.predicates.map((pred, pred_index) =>  */}
        <div>
          {item.predicates.some((pred) => pred.key === "time") && (
            <div className="flex flex-col gap-2 mb-2 border-t border-dashed border-lightGray pt-3 ">
              <div className="flex flex-row items-center justify-between">
                <div className="flex flex-row items-center gap-2">
                  <Icon size={16}>universal_time_bold</Icon>
                  <p className={titleStyle}>{t("time_dependency")}</p>
                </div>
                {editable ? (
                  <div
                    onClick={() => {
                      item.predicates = item.predicates.filter(
                        (p) => p.key === "cost"
                      );
                      setUpdate((prev) => prev + 1);
                    }}
                    className="hover:cursor-pointer"
                  >
                    <Icon color={"danger"}>universal_delete_regular</Icon>
                  </div>
                ) : null}
              </div>
              <div className="flex flex-col gap-3">
                {/* <p className={titleStyle}>
                                    {pred.operation === "<" ? t('resposibile_before_time') : t('resposibile_after_time')}
                                </p> */}
                {/* {editable ?
                                    <div className={valueStyle}>
                                        <Dropdown initialValue={pred.operation} options={[">","<"]} onChange={(e) => {item.predicates[pred_index].operation = e; setUpdate(prev => prev+1)}}/>
                                    </div> : null} */}

                <p className={titleStyle}>{t("responsible_after_time")}</p>
                <div className={valueStyle}>
                  <DateInput
                    initialValue={
                      item.predicates.filter(
                        (pred) => pred.key === "time" && pred.operation === ">"
                      )[0].value
                    }
                    disabled={!editable}
                    onChange={(e) => {
                      let i = item.predicates.findIndex(
                        (p) => p.key === "time" && p.operation == ">"
                      );
                      item.predicates[i].value = e;
                    }}
                  />
                </div>

                <p className={titleStyle}>{t("responsible_before_time")}</p>

                <div className={valueStyle}>
                  <DateInput
                    initialValue={
                      item.predicates.filter(
                        (pred) => pred.key === "time" && pred.operation === "<"
                      )[0].value
                    }
                    disabled={!editable}
                    onChange={(e) => {
                      let i = item.predicates.findIndex(
                        (p) => p.key === "time" && p.operation == "<"
                      );
                      item.predicates[i].value = e;
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          {item.predicates.some((pred) => pred.key === "cost") && (
            <div className="my-6 border-t border-dashed border-lightGray pt-3 ">
              <div className="flex flex-row items-center justify-between mb-2">
                <div className="flex flex-row items-center gap-2">
                  <Icon size={14}>other_euro_bold</Icon>
                  <p className={titleStyle}>{t("cost_dependency")}</p>
                </div>
                {editable ? (
                  <div
                    className="hover:cursor-pointer"
                    onClick={() => {
                      item.predicates = item.predicates.filter(
                        (p) => p.key === "time"
                      );
                      setUpdate((prev) => prev + 1);
                    }}
                  >
                    <Icon color={"danger"}>universal_delete_regular</Icon>
                  </div>
                ) : null}
              </div>
              <div className="flex flex-col gap-7">
                <div className="flex flex-col gap-2">
                  <p className={titleStyle}>{t("responsible_greater_than")}</p>
                  <div className={valueStyle}>
                    <NumInput
                      initialValue={
                        item.predicates.filter(
                          (pred) =>
                            pred.key === "cost" && pred.operation === ">"
                        )[0].value
                      }
                      disabled={!editable}
                      onChange={(e) => {
                        let i = item.predicates.findIndex(
                          (p) => p.key === "cost" && p.operation == ">"
                        );
                        item.predicates[i].value = e;
                      }}
                    />

                    {/* <NumInput initialValue={pred.value} disabled={!editable} onChange={(e) => item.predicates[pred_index].value = e}/> */}
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <p className={titleStyle}>{t("responsible_lesser_than")}</p>
                  <div className={valueStyle}>
                    <NumInput
                      initialValue={
                        item.predicates.filter(
                          (pred) =>
                            pred.key === "cost" && pred.operation === "<"
                        )[0].value
                      }
                      disabled={!editable}
                      onChange={(e) => {
                        let i = item.predicates.findIndex(
                          (p) => p.key === "cost" && p.operation == "<"
                        );
                        item.predicates[i].value = e;
                      }}
                    />

                    {/* <NumInput initialValue={pred.value} disabled={!editable} onChange={(e) => item.predicates[pred_index].value = e}/> */}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* )} */}

        {/* {Object.keys(groupedItems).map((key) => (
                    <div
                        key={key}
                        className="border-t border-dashed border-lightGray pt-3"
                    >
                        {key === "time" && (
                            <div className="flex flex-row items-center gap-2">
                                <Icon size={16}>time_circle</Icon>
                                <p className="font-yantramanavLight text-black text-[14px] leading-[18.16px]">
                                    Time dependenct
                                </p>
                            </div>
                        )}

                        {key === "cost" && (
                            <div className="flex flex-row items-center gap-2">
                                <Icon size={16}>dollar</Icon>
                                <p className="font-yantramanavLight text-black text-[14px] leading-[18.16px]">
                                    Cost dependency
                                </p>
                            </div>
                        )}

                        {groupedItems[key]?.map((predi) => (
                            <div key={predi.id} className="py-2">
                                {predi.key === "time" &&
                                    predi.operation === "<" && (
                                        <>
                                            <p className={titleStyle}>
                                                Contract end:
                                            </p>
                                            <p className={valueStyle}>
                                                {formatDate(predi.value)}
                                            </p>
                                        </>
                                    )}
                                {predi.key === "time" &&
                                    predi.operation === ">" && (
                                        <>
                                            <p className={titleStyle}>
                                                Contract start:
                                            </p>
                                            <p className={valueStyle}>
                                                {formatDate(predi.value)}
                                            </p>
                                        </>
                                    )}
                                {predi.key === "cost" &&
                                    predi.operation === "<" && (
                                        <>
                                            <p className={titleStyle}>
                                                From value:
                                            </p>
                                            <p className={valueStyle}>
                                                {predi.value}€
                                            </p>
                                        </>
                                    )}
                                {predi.key === "cost" &&
                                    predi.operation === ">" && (
                                        <>
                                            <p className={titleStyle}>
                                                To value:
                                            </p>
                                            <p className={valueStyle}>
                                                {predi.value}€
                                            </p>
                                        </>
                                    )}
                            </div>
                        ))}

                    </div>
                ))} */}
      </div>
    );
  };

  if (
    modalContent.item &&
    modalContent.groupName &&
    !modalContent.item.item_id
  ) {
    // setResp({ [modalContent.groupName]: structuredClone(modalContent.item) });
    return (
      <ResponsiveModal isOpen={isOpen} onClose={onClose} modalTitle="more_info">
        <div className="flex flex-col max-w-[1200px] min-w-[980px] h-[736px] px-[34px] relative">
          {/* Content of modal */}
          {/* <div className="w-full h-full relative"> */}
          {editable && (
            <div className="absolute top-0 right-7">
              <Tab
                text="ok"
                style="hugGreen"
                icon="universal_check_regular"
                onClick={() => {
                  onClose(resp);
                }}
              />
            </div>
          )}
          <div className="w-full h-full mb-20 overflow-y-scroll no-scrollbar">
            {/* Heading Responsibilities */}
            <div className="w-full flex flex-row justify-between items-center border-b mt-9  border-mediumGray mb-[16px]">
              <p className="text-primary font-yantramanavRegular text-[16px] leading-[20.75px]">
                {t("responsibilities")} - {t(modalContent.groupName)}
              </p>
              <div
                className="flex flex-row justify-center items-start gap-1 h-6 text-[10px] text-mediumGray hover:cursor-pointer"
                onClick={() => setShowBox(!showBox)}
              >
                {showBox ? t("collapse_box") : t("expand_box")}
                {showBox ? (
                  <Icon color="mediumGray">direction_arrow_up_regular</Icon>
                ) : (
                  <Icon color="mediumGray">direction_arrow_down_regular</Icon>
                )}
              </div>
            </div>
            {/* Responsibility cards */}
            {showBox && (
              <div className="h-[624px] w-[910px] flex flex-row gap-16">
                {modalContent?.struct &&
                  Object.keys(modalContent?.struct).map((key) => (
                    <div className="px-2 w-[246px]">
                      <p className="font-yantramanavRegular text-black text-[14px] leading-[18.16px] mb-[16px]">
                        {t(key)}
                      </p>
                      <div>
                        {resp?.[modalContent.groupName] &&
                          resp?.[modalContent.groupName]?.[key] &&
                          resp?.[modalContent.groupName]?.[key]?.map((item) => (
                            <div className="w-[246px] mb-4 p-3 shadow-md rounded-sm flex flex-col gap-4 border border-lightGray">
                              {renderInfo(item, key)}
                            </div>
                          ))}
                      </div>
                      {editable && (
                        <div className="flex justify-center mt-5">
                          <div
                            className="hover:cursor-pointer "
                            onClick={() => {
                              if (resp[modalContent.groupName]) {
                                if (resp[modalContent.groupName]?.[key]) {
                                  setResp((prev) => {
                                    prev[modalContent.groupName][key].push({
                                      main: Object.keys(
                                        modalContent.struct[key]
                                      )[0],
                                      predicates: []
                                    });
                                    return prev;
                                  });
                                  // resp[modalContent.groupName][key].push({
                                  //   main: Object.keys(
                                  //     modalContent.struct[key]
                                  //   )[0],
                                  //   predicates: []
                                  // });
                                } else {
                                  setResp((prev) => {
                                    prev[modalContent.groupName][key] = [
                                      {
                                        main: Object.keys(
                                          modalContent.struct[key]
                                        )[0],
                                        predicates: []
                                      }
                                    ];
                                    return prev;
                                  });
                                  // resp[modalContent.groupName][key] = [
                                  //   {
                                  //     main: Object.keys(
                                  //       modalContent.struct[key]
                                  //     )[0],
                                  //     predicates: []
                                  //   }
                                  // ];
                                }
                              } else {
                                setResp((prev) => {
                                  prev[modalContent.groupName] = {};
                                  prev[modalContent.groupName][key] = [
                                    {
                                      main: Object.keys(
                                        modalContent.struct[key]
                                      )[0],
                                      predicates: []
                                    }
                                  ];
                                  return prev;
                                });
                                // resp[modalContent.groupName] = {};
                                // resp[modalContent.groupName][key] = [
                                //   {
                                //     main: Object.keys(
                                //       modalContent.struct[key]
                                //     )[0],
                                //     predicates: []
                                //   }
                                // ];
                              }
                              setUpdate((prev) => prev + 1);
                            }}
                          >
                            <div className="flex flex-col items-center">
                              <div className="transform active:scale-90">
                                <Icon size={30}>universal_zoom_in_circle</Icon>
                              </div>
                              <p className="font-yantramanavLight text-black text-[14px] leading-[16px] ">
                                {t("add_responibility")}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
      </ResponsiveModal>
    );
  }

  // setResp(structuredClone(modalContent.item));
  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose} modalTitle="more_info">
      {/* ModalSize: 980px x 736px */}
      <div className="flex flex-col min-w-[980px] h-[736px] px-[34px]">
        {/* Content of modal */}
        <div className="w-full h-full relative">
          {editable && (
            <div className="absolute top-0 right-3">
              <Tab
                text="ok"
                style="hugGreen"
                icon="universal_check_regular"
                onClick={() => {
                  onClose(resp);
                }}
              />
            </div>
          )}
          {/* Boxes */}
          <div className="flex flex-col h-[624px] w-[980px] mt-[36px] overflow-y-scroll no-scrollbar">
            <div>
              {resp && resp.item_id && Object.keys(resp).length > 0 ? (
                hardcodedBoxes?.boxes?.map((box, boxIndex) => (
                  <Box
                    key={boxIndex}
                    box={box}
                    initialValue={resp}
                    edit={false}
                    market_id={market_id}
                  />
                ))
              ) : (
                <p>work in progress</p>
              )}
            </div>

            <div>
              {/* Heading Responsibilities */}
              <div className="w-full flex flex-row justify-between items-center border-b border-mediumGray mb-[16px]">
                <p className="text-primary font-yantramanavRegular text-[16px]  leading-[20.75px] ">
                  {t("responsibilities")}
                </p>
                <div
                  className="flex flex-row justify-center items-start gap-1 h-6 text-[10px] text-mediumGray hover:cursor-pointer"
                  onClick={() => setShowBox(!showBox)}
                >
                  {showBox ? t("collapse_box") : t("expand_box")}
                  {showBox ? (
                    <Icon color="mediumGray">direction_arrow_up_regular</Icon>
                  ) : (
                    <Icon color="mediumGray">direction_arrow_down_regular</Icon>
                  )}
                </div>
              </div>
              {/* Responsibility cards */}
              {showBox && (
                <div className="flex flex-row gap-16">
                  {modalContent?.struct &&
                    Object.keys(modalContent?.struct).map((key) => (
                      <div className="px-2 w-[246px]">
                        <p className="font-yantramanavRegular text-black text-[14px] leading-[18.16px] mb-[16px]">
                          {t(key)}
                        </p>
                        <div>
                          {resp?.responsibility &&
                            resp?.responsibility[key] &&
                            resp?.responsibility[key]?.map((item) => (
                              <div className="w-[246px] mb-4 p-3 shadow-md rounded-sm flex flex-col gap-4 border border-lightGray">
                                {renderInfo(item, key)}
                              </div>
                            ))}
                        </div>
                        {editable && (
                          <div className="flex justify-center mt-5">
                            <div
                              className="hover:cursor-pointer"
                              onClick={() => {
                                if (resp?.responsibility) {
                                  if (resp.responsibility[key]) {
                                    setResp((prev) => {
                                      prev.responsibility[key].push({
                                        main: Object.keys(
                                          modalContent.struct[key]
                                        )[0],
                                        predicates: []
                                      });
                                      return prev;
                                    });
                                    // resp.responsibility[key].push({
                                    //   main: Object.keys(
                                    //     modalContent.struct[key]
                                    //   )[0],
                                    //   predicates: []
                                    // });
                                  } else {
                                    setResp((prev) => {
                                      prev.responsibility[key] = [
                                        {
                                          main: Object.keys(
                                            modalContent.struct[key]
                                          )[0],
                                          predicates: []
                                        }
                                      ];
                                      return prev;
                                    });
                                    // resp.responsibility[key] = [
                                    //   {
                                    //     main: Object.keys(
                                    //       modalContent.struct[key]
                                    //     )[0],
                                    //     predicates: []
                                    //   }
                                    // ];
                                  }
                                } else {
                                  setResp((prev) => {
                                    prev.responsibility = {};
                                    prev.responsibility[key] = [
                                      {
                                        main: Object.keys(
                                          modalContent.struct[key]
                                        )[0],
                                        predicates: []
                                      }
                                    ];
                                    return prev;
                                  });
                                  // resp.responsibility = {};
                                  // resp.responsibility[key] = [
                                  //   {
                                  //     main: Object.keys(
                                  //       modalContent.struct[key]
                                  //     )[0],
                                  //     predicates: []
                                  //   }
                                  // ];
                                }
                                setUpdate((prev) => prev + 1);
                              }}
                            >
                              <div className="flex flex-col items-center">
                                <div className="transform active:scale-90">
                                  <Icon size={30}>
                                    universal_zoom_in_circle
                                  </Icon>
                                </div>
                                <p className="font-yantramanavLight text-black text-[14px] leading-[16px] ">
                                  {t("add_responibility")}
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </ResponsiveModal>
  );
};

export default ResponsibilityModal;
