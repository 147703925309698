import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Dropzone from "react-dropzone";
import ImageViewer from "./ImageViewer";
import Image from "../atoms/Image";
import Icon from "../atoms/Icon";
import { uploadImage } from "../../api/api";
import Loadingsymbol from "../atoms/Loadingsymbol";

function Images({
  images,
  disabled,
  onClick,
  onChange,
  label,
  market_id,
  downloadTitle
}) {
  const { t } = useTranslation();
  const [currentImage, setCurrentImage] = useState(
    images ? images[0]?.hd : null
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [showPopup, setShowPopup] = useState(false);
  const [popupImageIndex, setPopupImageIndex] = useState(0);
  const [imagesObj, setImagesObj] = useState(images ?? []);
  const [loading, setLoading] = useState(false);
  const imagesPerPage = 5; // Number of images per page

  useEffect(() => {
    setImagesObj(images);
    setCurrentImage(images?.[0]?.hd ?? null);
  }, [images]);

  const handleImageDrop = async (acceptedFiles) => {
    setLoading(true);
    try {
      let newImage;
      if (acceptedFiles) {
        newImage = await uploadImage(acceptedFiles[0], market_id);
      }

      if (newImage.file) {
        // const updatedImages = [newImage.file, ...imagesObj];
        // const updatedImages = [
        //   newImage.file,
        //   ...(imagesObj || [newImage.file])
        // ];
        let updatedImages;
        if (imagesObj?.length === 0 || !imagesObj) {
          updatedImages = [newImage.file];
        } else {
          updatedImages = [newImage.file, ...(imagesObj || [newImage.file])];
        }
        setImagesObj(updatedImages);
        onChange(updatedImages);
        setCurrentImage(newImage.file.hd);
        setPopupImageIndex(0);
      }
    } catch (error) {
      console.error("Image upload failed:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteImage = (index, e) => {
    if (e && e.stopPropagation) e.stopPropagation();
    const updatedImages = imagesObj.filter((_, i) => i !== index);
    setImagesObj(updatedImages);
    onChange(updatedImages);

    if (popupImageIndex === index) {
      setCurrentImage(updatedImages[0]?.hd ?? null);
      setPopupImageIndex(0);
    }
  };

  const handleImageClick = (image, index) => {
    setCurrentImage(image);
    setPopupImageIndex(index);
  };

  const handlePage = (text) => {
    if (text === "plus") setCurrentPage((prevPage) => prevPage + 1);
    if (text === "minus") setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const totalPages = Math.ceil(imagesObj?.length / imagesPerPage);
  const startIndex = (currentPage - 1) * imagesPerPage;
  const endIndex = startIndex + imagesPerPage - (loading ? 1 : 0);
  const visibleImages = imagesObj?.slice(startIndex, endIndex);

  const RenderGallery = () => (
    <div className="flex w-full">
      {currentPage > 1 && (
        <button
          className="bg-white border border-mediumGray h-[34px] w-[27px] rounded-sm font-yantramanavRegular text-[12px] p-1 mr-1 hover:bg-darkWhite"
          onClick={() => handlePage("minus")}
        >
          +{startIndex}
        </button>
      )}
      {loading && (
        <div className="pl-4 w-[56px] h-[34px] bg-lightGray rounded-sm overflow-hidden border border-mediumGray">
          <Loadingsymbol />
        </div>
      )}
      {visibleImages.map((image, index) => (
        <div
          key={index}
          className={`${
            !disabled ? "mr-1 relative" : "mr-1 hover:cursor-pointer"
          }`}
          onClick={() => handleImageClick(image.hd, index)}
        >
          <Image source={image.hd} size="small" />
          {!disabled && (
            <button
              onClick={(e) => handleDeleteImage(index, e)}
              className="absolute top-0.5 right-0.5 h-3 w-3 bg-white rounded-full flex items-center justify-center hover:opacity-90"
            >
              <Icon size={14} color="danger">
                universal_close_filled
              </Icon>
            </button>
          )}
        </div>
      ))}
      {currentPage < totalPages && (
        <button
          className="bg-white border border-mediumGray h-[34px] w-[27px] rounded-sm font-yantramanavRegular text-[12px] p-1 hover:bg-darkWhite"
          onClick={() => handlePage("plus")}
        >
          +{imagesObj?.length - endIndex}
        </button>
      )}
    </div>
  );

  return (
    <>
      <div className="h-[246px] w-[424px] overflow-hidden">
        <div className="h-[206px] w-[424px] relative">
          <Image source={currentImage} size="large" />
          {disabled
            ? imagesObj?.length > 0 && (
                <div
                  onClick={() => setShowPopup(true)}
                  className="absolute top-0 right-0 w-8 h-8 bg-darkWhite flex justify-center items-center hover:cursor-pointer rounded-bl-2xl rounded-tr-sm border-t border-r border-mediumGray"
                >
                  <Icon size={16} color="primary">
                    other_enlarge_regular
                  </Icon>
                </div>
              )
            : !loading && (
                <Dropzone
                  acceptedFiles={["image/jpg"]}
                  onDrop={handleImageDrop}
                >
                  {({ getRootProps, getInputProps, isDragActive }) => (
                    <div
                      {...getRootProps()}
                      style={{
                        border: isDragActive ? "2px dashed gray" : null, // Dotted border when hovering with file
                        transition: "background-color 0.3s"
                      }}
                      className="absolute bottom-1 right-1 h-7 p-2 min-w-fit bg-white flex items-center justify-items-end hover:cursor-pointer rounded border border-mediumGray"
                    >
                      <input {...getInputProps()} />
                      <Icon size={16} color="primary">
                        universal_camera_regular
                      </Icon>
                      <p className="ml-2 font-yantramanavRegular text-textInput min-w-min">
                        {t("add_photo")}
                      </p>
                    </div>
                  )}
                </Dropzone>
              )}
          {imagesObj?.length > 0 && !disabled && (
            <button
              onClick={(e) => handleDeleteImage(popupImageIndex, e)}
              className="absolute top-1.5 right-1.5 h-3 w-3 bg-white rounded-full flex items-center justify-center hover:opacity-90"
            >
              <Icon size={20} color="danger">
                universal_close_filled
              </Icon>
            </button>
          )}
        </div>

        <div className="flex w-full mt-[6px]">
          {imagesObj?.length > 1 && <RenderGallery />}
        </div>
      </div>
      <div className="h-[10px] w-full" />
      {showPopup && (
        <ImageViewer
          images={imagesObj.map((imgObj) => imgObj.hd)}
          initialImageIndex={popupImageIndex}
          title={label}
          downloadTitle={downloadTitle}
          onClose={handleClosePopup}
        />
      )}
    </>
  );
}

export default Images;
