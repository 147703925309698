import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Icon from "./Icon";

const NumInput = ({
  initialValue,
  placeholder,
  onChange,
  disabled = false,
  errorMessage = ""
}) => {
  const { t } = useTranslation();

  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState(errorMessage);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = (event) => {
    let value = event.target.value.replace(/[^\d.-]/g, "");
    let lastChar = value.slice(-1);

    setError(event.target.value !== value ? "only_numbers" : errorMessage);

    if (
      (lastChar === "." && value.split(".").length <= 2) ||
      (lastChar !== "." && lastChar !== "-" && !isNaN(parseFloat(value))) ||
      value === "" ||
      (value.slice(-1) === "-" && value.length <= 1)
    ) {
      setValue(value);
      onChange(isNaN(parseFloat(value)) ? 0 : parseFloat(value));
    }
  };

  const inputCssClasses = () => {
    const def =
      "absolute h-4 font-yantramanavRegular text-textInput outline-none w-full text-ellipsis truncate border-b ";
    if (disabled)
      return def + "border-b-transparent mb-5 disabled:bg-transparent";
    if (error !== "")
      return def + " border-b-danger focus:border-b-danger text-danger";
    return def + "mb-5 text-black  border-b-darkGray focus:border-b-darkGray ";
  };

  return (
    <div className="relative">
      <div className="relative">
        <input
          disabled={disabled}
          className={inputCssClasses()}
          value={value}
          onChange={handleChange}
          placeholder={t(placeholder)}
        />
        {!disabled ? (
          <div className="absolute right-1 bottom">
            <Icon size={10} color="mediumGray">
              other_number_regular
            </Icon>
          </div>
        ) : null}
      </div>
      {error !== "" && !disabled ? (
        <p className="absolute top-4 left-0 text-textInput font-medium text-danger">
          {t(error)}
        </p>
      ) : null}
    </div>
  );
};

export default NumInput;
