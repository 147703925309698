

export const setAsyncStorage = async (key, value) => {
    const pr = async () => Promise.resolve().then(function () {
        localStorage.setItem(key, typeof value === "string" ? value : JSON.stringify(value))
    });
    return await pr()
}

export const getAsyncStorage = async (key) => {
    const pr = async () => Promise.resolve().then(function () {
        return localStorage.getItem(key);
    });
    let re = await pr()
    return JSON.parse(re)
}