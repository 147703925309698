import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import NoDataFound from "../../../components/molecules/NoDataFound";
import TagPill from "../../../components/atoms/TagPill";
import Icon from "../../../components/atoms/Icon";

export default function GalleryViewDropdown(props) {
  const { t } = useTranslation();
  const [tags] = useState(Object?.keys(props.tags));
  const [tagFilter, setTagFilter] = useState(new Set());
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRef?.current &&
        !dropdownRef?.current?.contains(event.target)
      ) {
        setOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleTagSelect = (tag) => {
    let newTagFilter = new Set(tagFilter);
    newTagFilter.add(tag);
    setTagFilter(newTagFilter);
    props.onChange(Array.from(newTagFilter));
  };

  const handleTagDeselect = (tag) => {
    let newTagFilter = new Set(tagFilter);
    newTagFilter.delete(tag);
    setTagFilter(newTagFilter);
    props.onChange(Array.from(newTagFilter));
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <div
        onClick={() => setOpen((prev) => !prev)}
        className="px-2 flex justify-between items-center h-7 w-[124px] rounded border border-mediumGray hover:cursor-pointer"
      >
        <p className="font-yantramanavLight h-full text-base truncate text-black">
          {t("filter_tags")}
        </p>
        {!open ? (
          <Icon size={13} color="darkGray">
            direction_arrow_right_regular
          </Icon>
        ) : (
          <Icon size={13} color="darkGray">
            direction_arrow_down_regular
          </Icon>
        )}
      </div>
      {open ? (
        <div className="p-2 border border-mediumGray absolute z-20 mt-1 bg-white rounded drop-shadow-lg">
          {tags.length < 1 ? (
            <div className="w-[324px] h-[98px]">
              <NoDataFound data="tags" />
            </div>
          ) : (
            <div className="flex flex-col w-[324px]">
              <div className="flex flex-col">
                <p className=" font-yantramanavRegular">
                  {t("selected_tags")}:
                </p>
                <div className="flex gap-2 flex-wrap">
                  {tagFilter.size ? (
                    Array.from(tagFilter).map((tag) => (
                      <TagPill
                        selected={true}
                        onClick={() => handleTagDeselect(tag)}
                        tag={tag}
                      />
                    ))
                  ) : (
                    <p className=" font-yantramanavRegular text-mediumGray">
                      {t("no_tags_selected")}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex flex-col mt-2">
                <p className=" font-yantramanavRegular">
                  {t("available_tags")}:
                </p>
                <div className="flex gap-2 flex-wrap">
                  {tagFilter.size
                    ? Object.keys(props.tags).map((tag) => {
                        return !tagFilter.has(tag) ? (
                          <TagPill
                            onClick={() => handleTagSelect(tag)}
                            tag={tag}
                          />
                        ) : null;
                      })
                    : Object.keys(props.tags).map((tag) => (
                        <TagPill
                          onClick={() => handleTagSelect(tag)}
                          tag={tag}
                          key={tag}
                        />
                      ))}
                  {tagFilter.size === Object.keys(props.tags).length ? (
                    <p className=" font-yantramanavRegular text-mediumGray">
                      {t("all_tags_selected")}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
}
