import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { fetchData } from "../../api/api";

import Loadingsymbol from "../../components/atoms/Loadingsymbol";
import SearchInput from "../../components/atoms/SearchInput";
import Button from "../../components/molecules/Button";
import Box from "../../components/molecules/Box";
import Image from "../../components/atoms/Image";
import NoPage from "../../pages/NoPage";
import { convertCLStructToSAStruct } from "./helpers";

export default function SingleChecklist() {
  const { t } = useTranslation();
  const { marketId, checklistId } = useParams();
  const [edit, setEdit] = useState(false);
  const fetchUrl = `checklists?market_id=${marketId}&checklist_id=${checklistId}`;

  const res = useQuery({
    queryKey: [
      marketId,
      "data",
      "mission",
      "checklist",
      checklistId,
      { url: fetchUrl }
    ],
    queryFn: fetchData
  });

  if (res.isPending) {
    return (
      <div className="w-full">
        <div className="h-[46px] border-b overflow-hidden flex justify-between items-center px-8"></div>
        <div className="flex flex-col w-full items-center mt-8">
          <Loadingsymbol />
          <p className="text-body font-yantramanaRegular text-primary">
            {t("loading")}...
          </p>
        </div>
      </div>
    );
  }

  if (res.isError || res.data.length === 0) {
    return (
      <div className="w-full">
        <div className="h-[46px] border-b overflow-hidden flex justify-between items-center px-8 "></div>
        <NoPage />
      </div>
    );
  }

  const struct = convertCLStructToSAStruct(res.data[0]?.structure);
  const handleUpdate = () => undefined;

  const targetItem = res.data[0]?.target;

  console.log("res.data[0]: ", res.data[0]);

  return (
    <div className="h-full pb-10">
      <div className="w-full">
        <div className="h-[46px] border-r border-b overflow-hidden flex justify-end items-center px-8 ">
          <Button
            text={t("export_pdf")}
            icon="universal_export_regular"
            enabled={false}
            onClick={() => undefined}
          />
        </div>
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ ease: "easeIn", duration: 0.38 }}
        key={checklistId}
        className={"w-full p-8 h-full overflow-auto no-scrollbar"}
      >
        <div className="flex flex-col pb-4">
          <div className="border-b border-mediumGray mb-3">
            <p className="font-yantramanavRegular text-primary leading-5 text-[16px]">
              {t("item_information")}
            </p>
          </div>
          <div className="flex gap-3 justify-between">
            <div className="flex flex-col gap-8">
              <div className="flex flex-col">
                <p className="text-black text-[14px] leading-[18.16px] font-yantramanavLight text-left">
                  {t("display_name")}
                </p>
                <p className="font-yantramanavRegular text-textInput truncate">
                  {targetItem?.display_name}
                </p>
              </div>

              <div className="flex flex-col">
                <p className="text-black text-[14px] leading-[18.16px] font-yantramanavLight text-left">
                  {t("category")}
                </p>
                <p className="font-yantramanavRegular text-textInput truncate">
                  {targetItem?.categories
                    ? Array.isArray(targetItem.categories)
                      ? targetItem.categories.map((cat) => t(cat)).join(", ")
                      : targetItem.categories
                    : t("no_categories")}
                </p>
              </div>

              {targetItem?.target_id && <Link
                to={
                  targetItem?.target_id
                    ? `../items/${targetItem.target_id}`
                    : ""
                }
                className="h-7 font-yantramanavRegular px-3 text-textInput rounded border flex flex-row items-center justify-center border-mediumGray bg-lightBlue text-primary w-fit hover:bg-mediumBlue hover:text-primary"
              >
                {t("go_to_item")}
              </Link>}
            </div>
            <Image source={targetItem?.main_images?.[0]?.sd} size="large" />
          </div>
        </div>
        {struct?.boxes?.map((box, boxIndex) => (
          <Box
            key={
              box.header +
              Object.hasOwn(box, "isComponentStruct") +
              box.items.length
            }
            box={box}
            boxIndex={boxIndex}
            initialValue={res.data[0]}
            edit={edit}
            onUpdate={handleUpdate}
            market_id={marketId}
          />
        ))}
      </motion.div>
    </div>
  );
}
