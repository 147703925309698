import React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function CostPosition({ initialValue }) {
  const [processedData, setProcessedData] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const transformData = () => {
      const groupedData = {};

      initialValue?.forEach((value) => {
        if (!groupedData[value]) {
          groupedData[value] = { position: value, amount: 1 };
        } else {
          groupedData[value].amount += 1;
        }
      });

      return Object.values(groupedData);
    };

    setProcessedData(transformData());
  }, [initialValue]);

  if (!initialValue || initialValue.length === 0) {
    return (
      <div className="text-textInput  font-yantramanavRegular">
        {t("no_extra_cost_added")}
      </div>
    );
  }

  return (
    <div className="w-full h-[206px] border rounded-sm border-mediumGray ">
      <div className="flex flex-col">
        <div className="flex justify-between p-2 border-b border-mediumGray text-black text-[15px] leading-[18.16px] font-yantramanavLight text-left">
          <p>{t("extra_costs_position_id")}</p>
          <p>{t("amount")}</p>
        </div>
        <div className="overflow-scroll w-full h-full no-scrollbar">
          {processedData.map((item, index) => (
            <div
              key={index}
              className="flex justify-between px-2 py-2 hover:bg-lightBlue transition ease-in delay-75 hover:text-primary font-yantramanavRegular text-textInput"
            >
              <p>{item.position}</p>
              <p>
                {item.amount} {t("pcs")}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CostPosition;
