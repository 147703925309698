import { useState, useEffect } from "react";
import Checkbox from "../atoms/Checkbox";
import { useTranslation } from "react-i18next";

const ArrangeColumns = ({
    columnOrder,
    column,
    setColumnOrder,
    availableColumns,
    setAvailableColumns
}) => {
    const { t } = useTranslation();
    const [checked, setChecked] = useState(columnOrder.some(item => item.key === column.key));

    useEffect(() => {
        if (!checked)
            setColumnOrder((prev) => prev.filter((item) => item.key !== column.key));
        if (checked && !columnOrder.some(item => item.key === column.key))
            setColumnOrder((prev) => [...prev, column]);
    }, [checked]);

    const handleDragStart = (e) => {
        e.dataTransfer.setData("text", column.key); // Set the data being dragged
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const draggedColumn = e.dataTransfer.getData("text"); // Get the data being dropped
        const newIndex = columnOrder.findIndex((item) => item.key === column.key);

        if (newIndex !== -1) {
            const newColumnOrder = [...columnOrder];
            const draggedIndex = newColumnOrder.findIndex((item) => item.key === draggedColumn);
    
            if (draggedIndex !== -1) {
                const [draggedItem] = newColumnOrder.splice(draggedIndex, 1);
                newColumnOrder.splice(newIndex, 0, draggedItem);
                setColumnOrder(newColumnOrder);
    
                const newAvailableColumns = newColumnOrder.slice(1).map((item) => item.key);
                setAvailableColumns(
                    availableColumns
                        .filter((item) => !item.key.includes("image") && !newAvailableColumns.includes(item.key))
                        .concat(availableColumns.filter((item) => newAvailableColumns.includes(item.key)))
                );
            }
        }
    };

    // Drag and Drop is not working as intended, 
    // from when we've changed from only a string to an object with both Key and Label. 
    // Therefore draggable is now set to false.

    return (
        <div
            className="w-full h-[34px] p-2 flex flex-row items-center justify-between hover:bg-lightBlue  text-black  hover:text-primary rounded-sm gap-2"
            draggable="false"
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
        >
            <div className="h-[18px] w-[156px] flex flex-row items-center gap-2">
                <Checkbox
                    initialValue={checked}
                    onChange={() => setChecked(!checked)}
                />
                <p id={column.key} className="text-inputTitle leading-[14.7px]font-yantramanavLight">
                    {t(column.label)}
                </p>
            </div>
            {/* <div className="hover:cursor-pointer">
                <Icon color="mediumGray" size={16}>
                    move
                </Icon>
            </div> */}
        </div>
    );
};

export default ArrangeColumns;
