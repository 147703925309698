import React, { useState } from "react";
import Icon from "../atoms/Icon";
import Image from "../atoms/Image";
import { useTranslation } from "react-i18next";

function ListCard({
  isFirstImage,
  columnOrder,
  data,
  onClick,
  onClickExpandInfo,
  icon
}) {
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);

  const renderColumns = (order, d) => {
    const columnData = order.map((column, index) => {
      let value = d[column.key] ?? "";
      let image = "";
      let isImageColumn = false;
      let targetImage =
        column.key === "target.main_images" ? d?.target?.main_images : "";

      if (typeof value === "object") {
        if (value.length > 0 && (value[0]?.thumbnail || value.thumbnail)) {
          image = value[0]?.thumbnail || value.thumbnail;
          isImageColumn = true;
        }
        value = ""; // in case of some bad value
      }

      if (column.key === "target.main_images") {
        if (
          targetImage?.length > 0 &&
          (targetImage[0]?.thumbnail || targetImage.thumbnail)
        ) {
          image = targetImage[0]?.thumbnail || targetImage.thumbnail;
          isImageColumn = true;
        }
        value = "";
      }

      const columnClass = isFirstImage
        ? index === 0
          ? "w-[152px]"
          : "w-1/6 max-w-[640px] flex-grow truncate"
        : "w-1/6 flex-grow";

      const isStatusColumn = column.key === "status";
      const isTargetData = column.key === "target.display_name";

      const formatDate = (timestamp) => {
        if (!timestamp) return "";
        const date = new Date(timestamp);
        return date.toLocaleDateString();
      };
      return (
        <div key={index} className={`${columnClass} h-full flex items-center `}>
          {isImageColumn ? (
            image && (
              <div className="p-2">
                <Image source={image} size="thin" />
              </div>
            )
          ) : isStatusColumn ? (
            <div className="py-[36px] px-[17px] w-full flex flex-row items-center ">
              <div
                className={`${
                  data.status === "has_error"
                    ? "bg-danger"
                    : data.status === "done"
                    ? "bg-completed"
                    : data.status === "finished"
                    ? "bg-completed"
                    : data.status === "pending"
                    ? "bg-progress"
                    : "bg-white border border-black  "
                } h-[10px] w-[10px] mr-[10px] rounded-full`}
              />
              <p className="text-body font-yantramanavRegular truncate">
                {t(value) ?? ""}
              </p>
            </div>
          ) : isTargetData ? (
            <p className="text-body font-yantramanavRegular truncate">
              {data?.target?.display_name ?? ""}
            </p>
          ) : column.key === "created_at" ? (
            <div className="py-[36px] px-[17px] w-full">
              <p className="text-body font-yantramanavRegular truncate">
                {formatDate(value)}
              </p>
            </div>
          ) : (
            <div className="py-[36px] px-[17px] w-full  ">
              {column.key.toLowerCase().includes("image") ? (
                <div className="flex justify-center">
                  <Icon size={48} color="primary">
                    {data.categories?.[0]
                      ? "item_" + data.categories?.[0] + "_regular"
                      : data.suffix
                      ? "other_file_" + data.suffix + "_regular"
                      : data.vault_id
                      ? "universal_map_regular"
                      : data.target?.type
                      ? "item_" + data.target?.type + "_regular"
                      : data.target?.categories?.[0]
                      ? "item_" + data.target?.categories?.[0] + "_regular"
                      : "other_market_regular"}
                  </Icon>
                </div>
              ) : (
                <p className="text-body font-yantramanavRegular truncate">
                  {t(value) ?? ""}
                </p>
              )}
            </div>
          )}
        </div>
      );
    });

    return columnData;
  };

  return (
    <div
      className="relative w-full h-[87px] flex flex-row items-center hover:cursor-pointer text-black listcardHoverTranslate hover:shadow-md border-t hover:border-t-gray-100 border-t-transparent"
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {renderColumns(columnOrder, data)}
      {onClickExpandInfo && isHovered && (
        <button
          className="absolute border-y border-l rounded-s-2xl
                    top-1/2 right-0 -translate-y-1/2 shadow-[rgba(0,0,0,0.15)_0px_1px_3px_0px]
                    w-6 h-6 hover:w-7 flex justify-center items-center bg-white hover:scale-[1.4] transition-all "
          onClick={onClickExpandInfo}
        >
          <Icon color={"darkGray"} size={15}>
            {icon}
          </Icon>
        </button>
      )}
    </div>
  );
}

export default ListCard;
