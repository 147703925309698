import React, { useState } from "react";
import Loadingsymbol from "../../components/atoms/Loadingsymbol";
import Icon from "../../components/atoms/Icon";

function GalleryViewItem({ data, fileLoading, onClick, onEditClick }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <a
      className={`${
        isHovered ? "h-fit z-10" : "h-28"
      } hover:text-primary text-black w-28 flex justify-center items-start ml-1 relative hover:bg-white hover:cursor-pointer hover:shadow-md rounded-sm break-words`}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="flex w-full p-2 ">
        <div className="flex flex-col w-full h-full justify-center gap-3 items-center">
          <div className="w-12 h-12 flex justify-center items-center">
            {fileLoading === data.display_name ? (
              <Loadingsymbol />
            ) : (
              <Icon color="primary" size={52}>
                {"other_file_" + data.suffix + "_regular"}
              </Icon>
            )}
          </div>
          <p
            className={`text-[16px] leading-[19px] w-full text-center font-yantramanavLight break-words ${
              isHovered
                ? "h-full break-words"
                : "h-10 overflow-hidden line-clamp-2"
            }`}
          >
            {data.display_name}
          </p>
        </div>
      </div>
      {onEditClick && isHovered && (
        <div
          onClick={(e) => onEditClick(e, data)}
          className="hover:scale-[1.2] hover:cursor-pointer w-5 h-8 absolute top-0 right-1 hover:bg-lightBlue/60 rounded-full transition-all"
        >
          <div className="bg-primary absolute top-2 right-2 w-1 h-1 rounded-full" />
          <div className="bg-primary absolute top-3.5 right-2 w-1 h-1 rounded-full" />
          <div className="bg-primary absolute top-5 right-2 w-1 h-1 rounded-full" />
        </div>
      )}
    </a>
  );
}

export default GalleryViewItem;
