import { useEffect, useState } from "react";
import Image from "../atoms/Image";
import Icon from "../atoms/Icon";

export default function ImageGallery(props) {
  const { images } = props;
  const [activeImage, setActiveImage] = useState(0);

  useEffect(() => {
    setActiveImage(0);
  }, [images]);

  const handleLeftClick = () => {
    setActiveImage((prev) => (prev - 1 < 0 ? images.length - 1 : prev - 1));
  };

  const handleRightClick = () => {
    setActiveImage((prev) => (prev + 1 >= images.length ? 0 : prev + 1));
  };

  return (
    <div className="relative flex items-center overflow-auto w-full">
      <div
        onClick={handleLeftClick}
        className="w-8 hover:w-12 h-16 hover:h-28 bg-white/60 hover:bg-darkGray/60 rounded-r-md hover:cursor-pointer group transition-all absolute top-1/2 left-0 -translate-y-1/2 z-10 flex justify-center items-center"
      >
        <Icon size={16} color={"white"}>
          direction_arrow_left_regular
        </Icon>
      </div>
      {images.map((image, idx) => (
        <div
          key={idx}
          className={[
            "select-none",
            idx !== activeImage
              ? "flex-shrink w-0"
              : " w-full flex items-center h-[420px]"
          ].join(" ")}
        >
          <div className="w-full h-[420px]">
            <div className="relative top-0 right-0 w-full h-full">
              <Image
                className="absolute top-0 left-0"
                source={image}
                size="xLarge"
                objectFit="object-contain"
              />
            </div>
          </div>
        </div>
      ))}
      <div
        onClick={handleRightClick}
        className="w-8 hover:w-12 h-16 hover:h-28 bg-white/60 hover:bg-darkGray/60 rounded-l-md hover:cursor-pointer group transition-all absolute top-1/2 right-0 -translate-y-1/2 z-10 flex justify-center items-center"
      >
        <Icon size={16} color={"white"}>
          direction_arrow_right_regular
        </Icon>
      </div>
    </div>
  );
}
