import outstanding from './outstanding.png';
import excellent from './excellent.png';
import acceptable from './acceptable.png';
import very_good from './very_good.png';
import good from './good.png';
import passImage from './pass.png';
import unclassified from './unclassified.png';
import platinum from './platinum.png';
import gold from './gold.png';
import silver from './silver.png';
import bronze from './bronze.png';
import leed_certified from './leed_certified.png';
import leed_platinum from './leed_platinum.png';
import leed_gold from './leed_gold.png';
import leed_silver from './leed_silver.png';
import passivehouse from './passivehouse.png';

export default {
  BREEAM: {
    outstanding,
    excellent,
    acceptable,
    very_good,
    good,
    pass: passImage,
    unclassified
  },
  DGNB: {
    platinum,
    gold,
    silver,
    bronze
  },
  LEED: {
    certified: leed_certified,
    platinum: leed_platinum,
    gold: leed_gold,
    silver: leed_silver
  },
  passivehouse: {
     passivehouse
  }
};
