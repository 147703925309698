import { useEffect, useState } from "react";
import Icon from "./Icon";

const Checkbox = ({
  initialValue,
  onChange,
  disabled = false,
  icon = "universal_check_regular",
  size = "medium"
}) => {
  const [checked, setChecked] = useState(initialValue);
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    setChecked(initialValue);
  }, [initialValue]);

  const handleChange = () => {
    if (!disabled) {
      setChecked(!checked);
      onChange(!checked);
    }
  };

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const inputCssClasses = () => {
    const def = `${
      size === "medium" ? "w-[18px] h-[18px]" : "w-[14px] h-[14px]"
    } absolute appearance-none border rounded-sm hover:cursor-pointer overflow-hidden `;
    if (disabled)
      return (
        def +
        ` ${
          icon === "universal_check_regular" ||
          icon === "other_advanced_regular"
            ? " checked:bg-darkGray border-darkGray checked:border-darkGray "
            : " checked:bg-lightGray border-mediumGray checked:border-mediumGray"
        } `
      );
    return (
      def +
      `${
        icon === "universal_check_regular" || icon === "other_advanced_regular"
          ? "checked:border-black checked:bg-primary"
          : "checked:border-primary checked:bg-white"
      }  border-primary`
    );
  };

  return (
    <div
      className={`${
        size === "medium" ? "w-[18px] h-[18px]" : "w-[14px] h-[14px]"
      }`}
    >
      <span className="relative">
        <input
          id="check"
          type="checkbox"
          disabled={disabled}
          // defaultChecked={checked}
          checked={checked}
          onChange={handleChange}
          className={inputCssClasses()}
        />

        <div
          onClick={handleChange}
          className={`${
            size === "medium" ? "w-[18px] h-[18px]" : "w-[14px] h-[14px]"
          } border border-darkGray rounded-sm absolute overflow-hidden flex justify-center items-center ease-in-out delay-150 ${
            disabled && checked
              ? "border-darkGray"
              : disabled
              ? "border-mediumGray bg-darkWhite "
              : checked
              ? "border-primary hover:cursor-pointer"
              : "border-primary hover:cursor-pointer hover:bg-lightBlue hover:border-darkGray"
          }`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {hovered && disabled ? (
            <Icon
              size={
                icon === "other_advanced_regular" && size !== "medium"
                  ? 11
                  : icon === "other_advanced_regular"
                  ? 15
                  : size === "medium"
                  ? 10
                  : 8
              }
              color={checked ? "mediumGray" : "white"}
            >
              {icon}
            </Icon>
          ) : checked ? (
            <Icon
              size={
                icon === "other_advanced_regular" && size !== "medium"
                  ? 11
                  : icon === "other_advanced_regular"
                  ? 15
                  : size === "medium"
                  ? 10
                  : 8
              }
              color={
                (disabled && icon === "universal_check_regular") ||
                icon === "other_advanced_regular"
                  ? "white"
                  : disabled
                  ? "mediumGray"
                  : icon === "universal_check_regular"
                  ? "white"
                  : "primary"
              }
            >
              {icon}
            </Icon>
          ) : hovered && !disabled ? (
            <Icon
              size={
                icon === "other_advanced_regular" && size !== "medium"
                  ? 11
                  : icon === "other_advanced_regular"
                  ? 15
                  : size === "medium"
                  ? 10
                  : 8
              }
              color={"deepBlue"}
            >
              {icon}
            </Icon>
          ) : null}
        </div>
      </span>
    </div>
  );
};

export default Checkbox;
