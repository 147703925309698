import React from 'react'
import { useTranslation } from 'react-i18next'

function Footer() {
  const { t } = useTranslation()
  return (
    <div className='w-4/5 mx-auto flex flex-col items-center'>
        <p className='font-yantramanavRegular text-[12px] text-center text-lightGray leading-4'>{t("storeAgent_is_a_brand_of_chillservices_for_further_information_please_visit_our_website")}</p>
        <p className='font-yantramanavRegular text-[12px] text-lightGray leading-4'>© {t("all_rights_reserved_2023")} Version: 2.1.0</p>
    </div>
  )
}

export default Footer