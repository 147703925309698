export const EXAMPLEISSUESTRUCT = {
  boxes: [
    {
      items: [
        {
          keys: [
            {
              inputKey: "title",
              label: "title",
              type: "string",
              description:
                "Title of the Issue, not of the name of the item itself."
            },
            {
              inputKey: "target.display_name",
              description:
                "The name of the item, that is affected by the issue",
              type: "string"
            }
          ]
        },
        {
          keys: []
        },
        {
          keys: [
            {
              inputKey: "comment",
              type: "comment",
              description: "Description of the Issue"
            }
          ]
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: [
            {
              type: "images",
              inputKey: "main_images"
            }
          ]
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        }
      ],
      merge: [
        { columns: [1, 3] },
        { columns: [2, 3] },
        { columns: [3, 5] },
        { columns: [4, 5] },
        { columns: [5, 6] },
        { columns: [6, 10] }
      ],
      header: "general"
    }
    // {
    //   items: [
    //     {
    //       keys: [
    //         {
    //           inputKey: "target.id_rewe",
    //           type: "string",
    //           description:
    //             "This is the id of some REWE-number. It will be displayed for everyone to see in the application, when hover an i-icon."
    //         }
    //       ]
    //     },
    //     {
    //       keys: []
    //     },
    //     {
    //       keys: [
    //         {
    //           inputKey: "target.id_wurm",
    //           type: "string"
    //         }
    //       ]
    //     },
    //     {
    //       keys: []
    //     },
    //     {
    //       keys: []
    //     },
    //     {
    //       keys: []
    //     },
    //     {
    //       keys: []
    //     },
    //     {
    //       keys: []
    //     },
    //     {
    //       keys: []
    //     }
    //   ],
    //   merge: [
    //     { columns: [1, 3] },
    //     { columns: [2, 3] },
    //     { columns: [3, 5] },
    //     { columns: [4, 5] },
    //     { columns: [5, 6] },
    //     { columns: [6, 10] }
    //   ],
    //   header: "identifiers"
    // }
  ]
};

export const EXAMPLEMISSIONSTRUCT = {
  boxes: [
    {
      items: [
        {
          keys: [
            {
              inputKey: "name",
              type: "string"
            },
            {
              inputKey: "is_locked",
              type: "string"
            }
          ]
        },
        {
          keys: []
        },
        {
          keys: [
            {
              inputKey: "created_at",
              type: "string"
            }
          ]
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        }
      ],
      merge: [
        { columns: [1, 3] },
        { columns: [2, 3] },
        { columns: [3, 5] },
        { columns: [4, 5] },
        { columns: [5, 6] },
        { columns: [6, 10] }
      ],
      header: "general"
    },
    {
      items: [
        {
          keys: [
            {
              type: "cost_positions",
              inputKey: "cost_positions"
            }
          ]
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        }
      ],
      merge: [{ columns: [1, 10] }],
      header: "cost_positions"
    }
  ]
};

export const EXAMPLEMPHOTOBOOKTRUCT = {
  boxes: [
    {
      items: [
        {
          keys: [
            {
              inputKey: "title",
              type: "string"
            },
            { inputKey: "tags", type: "string" }
          ]
        },
        {
          keys: []
        },
        {
          keys: [
            {
              inputKey: "created_at",
              type: "date"
            },
            { inputKey: "comment", type: "comment" }
          ]
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: [
            {
              type: "images",
              inputKey: "main_images"
            }
          ]
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        }
      ],
      merge: [
        { columns: [1, 3] },
        { columns: [2, 3] },
        { columns: [3, 5] },
        { columns: [4, 5] },
        { columns: [5, 6] },
        { columns: [6, 10] }
      ],
      header: "general"
    }
  ]
};

export const EXAMPLERDCSTRUCT = {
  boxes: [
    {
      merge: [
        {
          columns: [1, 3]
        },
        {
          columns: [2, 3]
        },
        {
          columns: [3, 5]
        },
        {
          columns: [4, 5]
        },
        {
          columns: [5, 6]
        },
        {
          columns: [6, 10]
        }
      ],
      header: "general",
      items: [
        {
          keys: [
            {
              description: "",
              type: "string",
              inputKey: "display_name",
              label: ""
            },
            {
              description: "",
              type: "dropdown",
              inputKey: "iso_classification",
              options: ["RVC", "RVA", "RHC", "RHF", "IVC", "IHC", "IHF"],
              label: ""
            },
            {
              description: "",
              type: "string",
              inputKey: "commet",
              label: ""
            }
          ]
        },
        {
          keys: []
        },
        {
          keys: [
            {
              description: "",
              type: "dropdown",
              inputKey: "temprature_classification",
              options: ["mt", "lt"],
              label: ""
            },
            {
              description: "",
              type: "dropdown_multiple",
              inputKey: "loops",
              options: [
                "mt_1",
                "mt_2",
                "mt_3",
                "mt_4",
                "lt_1",
                "lt_2",
                "lt_3",
                "lt_4",
                "integral"
              ],
              label: ""
            },
            {
              description: "",
              type: "dropdown",
              inputKey: "content",
              options: ["**"],
              label: ""
            }
          ]
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: [
            {
              description: "",
              type: "images",
              inputKey: "main_images",
              label: ""
            }
          ]
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        }
      ]
    },
    {
      merge: [
        {
          columns: [1, 3]
        },
        {
          columns: [2, 3]
        },
        {
          columns: [3, 5]
        },
        {
          columns: [4, 5]
        },
        {
          columns: [5, 6]
        },
        {
          columns: [6, 10]
        }
      ],
      header: "identifiers",
      items: [
        {
          keys: [
            {
              description: "",
              type: "string",
              inputKey: "id_rewe",
              label: ""
            }
          ]
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: [
            {
              description: "",
              type: "images",
              inputKey: "rewe_sticker_images",
              label: ""
            }
          ]
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        }
      ]
    },
    {
      merge: [
        {
          columns: [1, 3]
        },
        {
          columns: [2, 3]
        },
        {
          columns: [3, 5]
        },
        {
          columns: [4, 5]
        },
        {
          columns: [5, 6]
        },
        {
          columns: [6, 10]
        }
      ],
      header: "type_plate",
      items: [
        {
          keys: [
            {
              description: "",
              type: "string",
              inputKey: "manufacturer",
              label: ""
            },
            {
              description: "",
              type: "string",
              inputKey: "series",
              options: ["**"],
              label: ""
            },
            {
              description: "",
              type: "string",
              inputKey: "model",
              options: ["**"],
              label: ""
            },
            {
              description: "",
              type: "string",
              inputKey: "specified_light_power",
              label: ""
            },
            {
              description: "",
              type: "string",
              inputKey: "specified_fan_power",
              label: ""
            },
            {
              description: "",
              type: "string",
              inputKey: "integral_compressor_power",
              label: ""
            },
            {
              description: "",
              type: "string",
              inputKey: "integral_refrigerant",
              options: ["**"],
              label: ""
            },
            {
              description: "",
              type: "int",
              inputKey: "integral_refrigerant_charge",
              label: ""
            }
          ]
        },
        {
          keys: []
        },
        {
          keys: [
            {
              description: "",
              type: "string",
              inputKey: "year",
              label: ""
            },
            {
              description: "",
              type: "dropdown",
              inputKey: "orientation",
              options: ["** vertiacal horizontal"],
              label: ""
            },
            {
              description: "",
              type: "string",
              inputKey: "specified_antisweat_power",
              label: ""
            },
            {
              description: "",
              type: "string",
              inputKey: "specified_frame_heating_power",
              label: ""
            },
            {
              description: "",
              type: "string",
              inputKey: "specified_defrost_power",
              label: ""
            },
            {
              description: "",
              type: "string",
              inputKey: "defrost_type",
              options: ["**"],
              label: ""
            }
          ]
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: [
            {
              description: "",
              type: "images",
              inputKey: "type_plate_images",
              label: ""
            }
          ]
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        }
      ]
    },
    {
      merge: [
        {
          columns: [1, 3]
        },
        {
          columns: [2, 3]
        },
        {
          columns: [3, 5]
        },
        {
          columns: [4, 5]
        },
        {
          columns: [5, 6]
        },
        {
          columns: [6, 10]
        }
      ],
      header: "doors",
      items: [
        {
          keys: [
            {
              description: "",
              type: "dropdown",
              inputKey: "door_type",
              options: ["**"],
              label: ""
            },
            {
              description: "",
              type: "dropdown",
              inputKey: "glazing_type",
              options: ["**"],
              label: ""
            },
            {
              description: "",
              type: "int",
              inputKey: "door_quantity",
              label: ""
            },
            {
              description: "",
              type: "bool",
              inputKey: "night_blinds",
              options: ["**"],
              label: ""
            },
            {
              description: "",
              type: "bool",
              inputKey: "is_semivertical",
              options: ["**"],
              label: ""
            }
          ]
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        }
      ]
    },
    {
      merge: [
        {
          columns: [1, 3]
        },
        {
          columns: [2, 3]
        },
        {
          columns: [3, 5]
        },
        {
          columns: [4, 5]
        },
        {
          columns: [5, 6]
        },
        {
          columns: [6, 10]
        }
      ],
      header: "lid",
      items: [
        {
          keys: [
            {
              description: "",
              type: "dropdown",
              inputKey: "lid_type",
              options: ["**"],
              label: ""
            },
            {
              description: "",
              type: "dropdown",
              inputKey: "glazing_type",
              options: ["**"],
              label: ""
            },
            {
              description: "",
              type: "int",
              inputKey: "door_quantity",
              label: ""
            },
            {
              description: "",
              type: "bool",
              inputKey: "night_blinds",
              options: ["**"],
              label: ""
            }
          ]
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        }
      ]
    },
    {
      merge: [
        {
          columns: [1, 5]
        },
        {
          columns: [2, 3]
        },
        {
          columns: [3, 4]
        },
        {
          columns: [3, 8]
        },
        {
          columns: [5, 6]
        },
        {
          columns: [6, 10]
        }
      ],
      header: "location",
      items: [
        {
          keys: [
            {
              description: "",
              type: "location_map",
              inputKey: "location",
              label: ""
            }
          ]
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        }
      ]
    },
    {
      merge: [
        {
          columns: [1, 6]
        },
        {
          columns: [6, 8]
        },
        {
          columns: [5, 6]
        },
        {
          columns: [5, 6]
        },
        {
          columns: [7, 8]
        },
        {
          columns: [9, 10]
        }
      ],
      header: "lighting",
      isComponentStruct: true,
      items: [
        {
          keys: [
            {
              description: "",
              type: "int",
              inputKey: "quantity",
              label: ""
            },
            {
              description: "",
              type: "string",
              inputKey: "manufacturer",
              options: ["**"],
              label: ""
            },
            {
              description: "",
              type: "string",
              inputKey: "light_source",
              options: ["**"],
              label: ""
            },
            {
              description: "",
              type: "int",
              inputKey: "length",
              label: ""
            }
          ]
        },
        {
          keys: []
        },
        {
          keys: [
            {
              description: "",
              type: "string",
              inputKey: "model",
              options: ["**"],
              label: ""
            },
            {
              description: "",
              type: "int",
              inputKey: "power",
              label: ""
            },
            {
              description: "",
              type: "string",
              inputKey: "light_temperature",
              options: ["**"],
              label: ""
            }
          ]
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: [
            {
              description: "",
              type: "images",
              inputKey: "main_images",
              label: ""
            }
          ]
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        }
      ]
    },
    {
      merge: [
        {
          columns: [1, 3]
        },
        {
          columns: [2, 3]
        },
        {
          columns: [3, 5]
        },
        {
          columns: [4, 5]
        },
        {
          columns: [5, 6]
        },
        {
          columns: [6, 10]
        }
      ],
      header: "door",
      isComponentStruct: true,
      items: [
        {
          keys: [
            {
              description: "",
              type: "string",
              inputKey: "manufacturer",
              options: ["**"],
              label: ""
            },
            {
              description: "",
              type: "int",
              inputKey: "width",
              label: ""
            }
          ]
        },
        {
          keys: []
        },
        {
          keys: [
            {
              description: "",
              type: "string",
              inputKey: "model",
              options: ["**"],
              label: ""
            },
            {
              description: "",
              type: "int",
              inputKey: "height",
              label: ""
            }
          ]
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: [
            {
              description: "",
              type: "images",
              inputKey: "main_images",
              label: ""
            }
          ]
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        }
      ]
    },
    {
      merge: [
        {
          columns: [1, 3]
        },
        {
          columns: [2, 3]
        },
        {
          columns: [3, 5]
        },
        {
          columns: [4, 5]
        },
        {
          columns: [5, 6]
        },
        {
          columns: [6, 10]
        }
      ],
      header: "lid",
      isComponentStruct: true,
      items: [
        {
          keys: [
            {
              description: "",
              type: "string",
              inputKey: "manufacturer",
              options: ["**"],
              label: ""
            },
            {
              description: "",
              type: "int",
              inputKey: "width",
              label: ""
            }
          ]
        },
        {
          keys: []
        },
        {
          keys: [
            {
              description: "",
              type: "string",
              inputKey: "model",
              options: ["**"],
              label: ""
            },
            {
              description: "",
              type: "int",
              inputKey: "depth",
              label: ""
            }
          ]
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: [
            {
              description: "",
              type: "images",
              inputKey: "main_images",
              label: ""
            }
          ]
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        }
      ]
    },
    {
      merge: [
        {
          columns: [1, 3]
        },
        {
          columns: [2, 3]
        },
        {
          columns: [3, 5]
        },
        {
          columns: [4, 5]
        },
        {
          columns: [5, 6]
        },
        {
          columns: [6, 10]
        }
      ],
      header: "fan",
      isComponentStruct: true,
      items: [
        {
          keys: [
            {
              description: "",
              type: "string",
              inputKey: "manufacturer",
              options: ["**"],
              label: ""
            },
            {
              description: "",
              type: "int",
              inputKey: "quantity",
              label: ""
            }
          ]
        },
        {
          keys: []
        },
        {
          keys: [
            {
              description: "",
              type: "string",
              inputKey: "model",
              options: ["**"],
              label: ""
            },
            {
              description: "",
              type: "string",
              inputKey: "motor_type",
              options: ["**"],
              label: ""
            }
          ]
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: [
            {
              description: "",
              type: "images",
              inputKey: "main_images",
              label: ""
            }
          ]
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        }
      ]
    },
    {
      merge: [
        {
          columns: [1, 3]
        },
        {
          columns: [2, 3]
        },
        {
          columns: [3, 5]
        },
        {
          columns: [4, 5]
        },
        {
          columns: [5, 6]
        },
        {
          columns: [6, 10]
        }
      ],
      header: "valve",
      isComponentStruct: true,
      items: [
        {
          keys: [
            {
              description: "",
              type: "string",
              inputKey: "manufacturer",
              options: ["**"],
              label: ""
            },
            {
              description: "",
              type: "int",
              inputKey: "quantity",
              label: ""
            }
          ]
        },
        {
          keys: []
        },
        {
          keys: [
            {
              description: "",
              type: "string",
              inputKey: "model",
              options: ["**"],
              label: ""
            },
            {
              description: "",
              type: "string",
              inputKey: "valve_type",
              options: ["**"],
              label: ""
            }
          ]
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: [
            {
              description: "",
              type: "images",
              inputKey: "main_images",
              label: ""
            }
          ]
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        }
      ]
    },
    {
      merge: [
        {
          columns: [1, 3]
        },
        {
          columns: [2, 3]
        },
        {
          columns: [3, 5]
        },
        {
          columns: [4, 5]
        },
        {
          columns: [5, 6]
        },
        {
          columns: [6, 10]
        }
      ],
      header: "mist",
      isComponentStruct: true,
      items: [
        {
          keys: [
            {
              description: "",
              type: "string",
              inputKey: "manufacturer",
              options: ["**"],
              label: ""
            }
          ]
        },
        {
          keys: []
        },
        {
          keys: [
            {
              description: "",
              type: "string",
              inputKey: "model",
              options: ["**"],
              label: ""
            }
          ]
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: [
            {
              description: "",
              type: "images",
              inputKey: "main_images",
              label: ""
            }
          ]
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        }
      ]
    },
    {
      merge: [
        {
          columns: [1, 3]
        },
        {
          columns: [2, 3]
        },
        {
          columns: [3, 5]
        },
        {
          columns: [4, 5]
        },
        {
          columns: [5, 6]
        },
        {
          columns: [6, 10]
        }
      ],
      header: "sealing_strip",
      isComponentStruct: true,
      items: [
        {
          keys: [
            {
              description: "",
              type: "string",
              inputKey: "product_kit_used",
              options: ["**"],
              label: ""
            }
          ]
        },
        {
          keys: []
        },
        {
          keys: [
            {
              description: "",
              type: "date",
              inputKey: "installed_at",
              label: ""
            }
          ]
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: [
            {
              description: "",
              type: "images",
              inputKey: "main_images",
              label: ""
            }
          ]
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        }
      ]
    },
    {
      merge: [
        {
          columns: [1, 3]
        },
        {
          columns: [2, 3]
        },
        {
          columns: [3, 5]
        },
        {
          columns: [4, 5]
        },
        {
          columns: [5, 6]
        },
        {
          columns: [6, 10]
        }
      ],
      header: "sensor_hold",
      isComponentStruct: true,
      items: [
        {
          keys: [
            {
              description: "",
              type: "string",
              inputKey: "serial_key",
              label: ""
            }
          ]
        },
        {
          keys: []
        },
        {
          keys: [
            {
              description: "",
              type: "int",
              inputKey: "behind_door_number",
              label: ""
            }
          ]
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: [
            {
              description: "",
              type: "images",
              inputKey: "before_images",
              label: ""
            },
            {
              description: "",
              type: "images",
              inputKey: "after_images",
              label: ""
            }
          ]
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        }
      ]
    }
  ]
};

export const PREVIEWSTRUCTURE = {
  boxes: [
    {
      items: [
        {
          keys: [
            {
              inputKey: "id_rewe",
              type: "string"
            }
          ]
        },
        {
          keys: []
        },
        {
          keys: [
            {
              inputKey: "id_wurm",
              type: "string"
            }
          ]
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        }
      ],
      merge: [
        { columns: [1, 2] },
        { columns: [2, 3] },
        { columns: [3, 5] },
        { columns: [4, 5] },
        { columns: [5, 6] },
        { columns: [6, 10] }
      ],
      header: "identifiers"
    },
    {
      items: [
        {
          keys: [
            {
              inputKey: "sales_area",
              type: "string"
            },
            {
              inputKey: "market_area",
              type: "string"
            },
            {
              inputKey: "budiling_area",
              type: "string"
            }
          ]
        },
        { keys: [] },
        {
          keys: [
            {
              inputKey: "building_type",
              type: "string"
            },
            {
              inputKey: "construction_year",
              type: "string"
            },
            {
              inputKey: "agegroup",
              type: "string"
            }
          ]
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        },
        {
          keys: []
        }
      ],
      merge: [
        { columns: [1, 2] },
        { columns: [2, 3] },
        { columns: [3, 4] },
        { columns: [4, 5] },
        { columns: [5, 6] },
        { columns: [6, 10] }
      ],
      header: "building_information"
    }
  ]
};

export const EXAMPLEPHOTOBOOKMODALSTRUCT = {
  boxes: [
    {
      items: [
        {
          keys: [
            {
              inputKey: "title",
              type: "string"
            },
            {
              inputKey: "tags",
              type: "tags"
            }
          ]
        },
        {
          keys: [
            {
              inputKey: "created_at",
              type: "date"
            },
            {
              inputKey: "comment",
              type: "comment"
            }
          ]
        },
        {
          keys: [
            {
              inputKey: "location",
              type: "location_map"
            }
          ]
        }
      ],
      merge: [{ columns: [1, 3] }, { columns: [3, 10] }, { columns: [1, 5] }],
      header: "info"
    }
  ]
};


