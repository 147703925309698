import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Icon from "./Icon";
import HoverText from "../atoms/HoverText";
import { useComponentVisibleHover } from "../../utils/UtilsViewFunctions";

const Dropdown = ({
  options,
  initialValue,
  onChange,
  disabled = false,
  placeholder,
  addable = false
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(initialValue);
  const [showOptions, setShowOptions] = useState(false);
  const [optionToShow, setOptionToShow] = useState(options);

  const { ref, isComponentVisible } = useComponentVisibleHover(false);

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const handleChange = (event) => {
    const text = t(event.target.value);
    setOptionToShow(
      options.filter((value) =>
        t(value)
          .replace(/\s+/g, "")
          .toLowerCase()
          .includes(text.replace(/\s+/g, "").toLowerCase())
      )
    );
    setValue(text);
  };

  const handleClick = (event) => {
    setShowOptions(!showOptions);
  };

  const handleSelect = (item) => {
    setShowOptions(false);
    setValue(item);
    onChange(item);
  };

  const inputCssClasses = () => {
    const def =
      "absolute pr-4 top-0 bg-white left-0 font-yantramanavRegular text-textInput outline-none text-black w-full h-4 border-b focus:border-b-black appearance-none placeholder:text-mediumGray";
    if (disabled) return def + " border-b-transparent ";
    return def + " border-b-darkGray hover:cursor-pointer";
  };

  const handleOnBlur = () => {
    setTimeout(() => {
      setShowOptions(false);
    }, 200);
  };

  return (
    <div className="relative h-4" ref={ref}>
      <input
        value={t(value)}
        disabled={disabled}
        onClick={handleClick}
        onChange={handleChange}
        placeholder={t(placeholder)}
        className={inputCssClasses()}
        onBlur={handleOnBlur}
      ></input>
      {isComponentVisible && disabled && t(value).length >= 20 ? (
        <HoverText text={t(value)} />
      ) : null}
      {!disabled && (
        <div
          className="absolute -top-1.5 right-0 hover:cursor-pointer"
          onClick={() => handleClick()}
        >
          {" "}
          <Icon size={16} color="black">
            direction_arrow_down_regular
          </Icon>
        </div>
      )}
      {showOptions && (
        <div className="absolute top-4 right-0 w-full bg-white z-10 shadow-md rounded h-fit max-h-52 overflow-auto">
          {addable && value !== "" && (
            <div
              className="my-1 mx-2 flex justify-between items-center h-7 hover:cursor-pointer border-b border-disabled"
              onClick={() => handleSelect(value)}
            >
              <div className="text-black font-yantramanavRegular text-textInput grow">
                {t(value)}
              </div>
              <Icon size={14} color="primary">
                universal_zoom_in_circle
              </Icon>
            </div>
          )}
          {optionToShow?.length <= 0 && <div className="h-4">{}</div>}
          {optionToShow?.map((item, index) => {
            const cssClassName = () => {
              const def =
                "text-black py-1 px-2 font-yantramanavRegular text-textInput hover:cursor-pointer hover:bg-lightBlue hover:text-primary ";
              if (t(item) === t(value)) {
                return def + " bg-lightBlue text-primary ";
              }
              return def + " bg-white ";
            };
            return (
              <div
                key={item}
                className={cssClassName()}
                onClick={() => handleSelect(item)}
              >
                {t(item)}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
