import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { basicGet, basicPatch } from "../../api/api";
import Loadingsymbol from "../../components/atoms/Loadingsymbol";
import Box from "../../components/molecules/Box";
import UnderConstruction from "../../components/molecules/UnderConstruction";
import NoPage from "../../pages/NoPage";
import { EXAMPLEMISSIONSTRUCT } from "../../pages/Development/structures";
import SingleMissionHeader from "./SingleMissionHeader";
import ListView from "../../components/organisms/ListView";
import NoDataFound from "../../components/molecules/NoDataFound";

async function fetchMissionStruct({ queryKey }) {
  const [_key1, _key2, marketId, missionId] = queryKey;
  const response = await basicGet(
    `structures?market_id=${marketId}&app=sa&structure_type=inventory&inventory_type=${missionId}`
  );
  if (!response.ok) {
    console.log("Response 1: ", response, response.ok);
    throw new Error("Network response was not ok");
  }
  return response.json();
}

const getTabs = (singleItemId) => [
  { tab: "tab_general", page: "mission", singleItemId: singleItemId },
  { tab: "tab_checklists", page: "mission", singleItemId: singleItemId }
];

const LIST_COLUMNS = [
  { key: "target.main_images", label: "main_images" },
  { key: "display_name", label: "display_name" },
  { key: "status", label: "status" }
];

async function fetchMissionData({ queryKey }) {
  const [marketId, _key1, _key2, missionId] = queryKey;
  const response = await basicGet(
    `missions?market_id=${marketId}&mission_id=${missionId}`
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
}

async function patchMission(missionData, marketId, missionId) {
  const response = await basicPatch(
    `missions?market_id=${marketId}&mission_id=${missionId}`,
    missionData
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response;
}
export default function SingleMission() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [patchData, setPatchData] = useState({});
  const [selectedTab, setSelectedTab] = useState("tab_general");
  const { marketId, missionId } = useParams(); // Get resource id's
  const queryClient = useQueryClient();

  const missionMutation = useMutation({
    mutationFn: ({ patchData: missionData, marketId, missionId }) =>
      patchMission(missionData, marketId, missionId),
    onError: (error) => {
      console.log("On error: ", error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["data", "mission", marketId, missionId]
      });
    }
  });

  const {
    data: missionData,
    isPending: missionDataPending,
    isError: missionDataError
  } = useQuery({
    queryKey: [marketId, "data", "mission", missionId],
    queryFn: fetchMissionData
  }); // Get the data

    const missionStruct = EXAMPLEMISSIONSTRUCT;

    if (missionDataError) {
      return <NoPage />;
    }

    if (missionDataPending || missionMutation.isPending) {
      return (
        <div className="w-full">
          <div className="flex h-[46px] border-r border-b px-8 w-full"></div>{" "}
          {/** Empty header */}
          <div className="flex flex-col w-full items-center mt-8">
            <Loadingsymbol />
            <p className="text-body font-yantramanaRegular text-primary">
              {t("loading")}...
            </p>
          </div>
        </div>
      );
    }

  const handleUpdate = (e) => {
    if (Object.keys(e)[0].includes("components[")) {
      setPatchData((prev) => {
        setPatchData(prev, Object.keys(e)[0], Object.values(e)[0]);
        return prev;
      });
    } else {
      setPatchData((prev) => ({ ...prev, ...e }));
    }
  };

  // const handleSave = () => {
  //     missionMutation.mutate({ patchData, marketId, missionId });
  // }

  // const handleDiscard = () => {
  //     setEdit(false);
  //     setPatchData({});
  // }

  return (
    <div className="h-full">
      <SingleMissionHeader
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        tabs={getTabs(missionId)}
      />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ ease: "easeIn" }}
        key={missionId}
        className="relative overflow-scroll h-full no-scrollbar w-full shadow-[rgba(203,204,213,0.5)inset_1px_1px_4px_0px]"
      >
        {selectedTab === "tab_general" ? (
          <>
            <div className={"w-full p-8 h-[calc(100vh - 46px)]"}>
              {Object.keys(missionData).length > 0 &&
              Object.hasOwn(missionStruct, "boxes") ? (
                missionStruct.boxes.map((box, boxIndex) => (
                  <Box
                    key={
                      box.header +
                      Object.hasOwn(box, "isComponentStruct") +
                      box.items.length
                    }
                    box={box}
                    boxIndex={boxIndex}
                    initialValue={missionData}
                    edit={false}
                    onUpdate={handleUpdate}
                    market_id={marketId}
                  />
                ))
              ) : missionStruct && Object.keys(missionData).length <= 0 ? (
                <UnderConstruction />
              ) : null}

              {!Object.hasOwn(missionStruct, "boxes") ? (
                <UnderConstruction />
              ) : null}
            </div>
          </>
        ) : (
          <div>
            <ListView
              data={missionData.checklists}
              columnOrder={LIST_COLUMNS}
              onClick={(item) => {
                if (item?.checklist_id)
                  navigate(
                    `/markets/${marketId}/checklists/${item.checklist_id}`
                  );
              }}
            />
          </div>
        )}
      </motion.div>
    </div>
  );
}
