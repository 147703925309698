import React from "react";
import Tab from "./Tab";
import { useTranslation } from "react-i18next";

function EditTabs({ edit, setEdit, handleSave, handleDiscard, modal = false }) {
  const { t } = useTranslation();

  return (
    <div
      className={`${
        edit && modal
          ? "right-[35%]"
          : !edit && modal
          ? "right-[18%]"
          : edit
          ? "right-[21%]"
          : "right-[12%]"
      } absolute `}
    >
      <div className={`fixed z-20 h-11`}>
        {edit ? (
          <div className="flex flex-row gap-3">
            <Tab
              onClick={handleSave}
              text={t("save")}
              icon="universal_check_regular"
              style="hugGreen"
            />
            <Tab
              onClick={handleDiscard}
              text={t("discard")}
              icon="universal_close_regular"
              style="hugBlue"
            />
          </div>
        ) : (
          <Tab
            onClick={() => setEdit(true)}
            text={t("edit")}
            icon="universal_edit_regular"
            style="hugBlue"
          />
        )}
      </div>
    </div>
  );
}

export default EditTabs;

