import { getAsyncStorage, setAsyncStorage } from "./AsyncStorage";

// Login
export const login = async (email, password) => {
  const url = `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyBY_IAkOEWId4CuCvIT3OCH5vmSp8aLtw8`;
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        // eslint-disable-next-line no-dupe-keys
        email,
        email,
        password: password,
        returnSecureToken: true
      })
    });
    if (!response.ok) {
      const data = await response.json();
      throw new Error(data.error.message);
    }
    const data = await response.json();
    setAsyncStorage("token", {
      ...data,
      expires_at: Date.now() + 60 * 55 * 1000
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};

// checkAndRefresh()
const refresh = async (auth) => {
  const url = `https://securetoken.googleapis.com/v1/token?key=AIzaSyBY_IAkOEWId4CuCvIT3OCH5vmSp8aLtw8`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      grant_type: "refresh_token",
      refresh_token: auth.refreshToken
    })
  });
  if (!response.ok) {
    const data = await response.json();
    throw new Error(data.error.message);
  }
  const data = await response.json();
  console.log("this is a refresh");
  let newData = {
    ...auth,
    refreshToken: data.refresh_token,
    idToken: data.id_token,
    expires_at: Date.now() + 60 * 55 * 1000
  };
  await setAsyncStorage("token", newData);
  return newData.idToken;
};

//
export const getToken = async () => {
  let auth = await getAsyncStorage("token");
  if (auth.expires_at > Date.now()) return auth.idToken;
  try {
    let newAuth = await refresh(auth);
    return newAuth;
  } catch (error) {
    console.error("error fetching token");
    return false;
  }
};

// Logout
