import { useTranslation } from "react-i18next";
import React, { useState } from "react";

const RadioButtons = ({ initialValue = "", options, disabled, onChange }) => {
  const [selectedOption, setSelectedOption] = useState(initialValue);
  const { t } = useTranslation();

  const handleOptionChange = (event) => {
    const newOption = event.currentTarget.getAttribute("data-value");
    setSelectedOption(newOption);
    onChange(newOption);
  };

  const visibleOptions = disabled
    ? options.filter((option) => option === selectedOption)
    : options;

  return (
    <div className="flex flex-row mt-1">
      {visibleOptions?.map((option, index) => {
        const isFirst = index === 0;
        const isLast = index === visibleOptions.length - 1;

        const borderClasses = disabled
          ? "rounded"
          : isFirst
          ? "rounded-l"
          : isLast
          ? "rounded-r"
          : option === selectedOption
          ? "border-l border-r"
          : "border-l-transparent border-r-transparent";

        const backgroundClasses = disabled
          ? "bg-lightGray"
          : !disabled && option !== selectedOption
          ? "bg-white hover:bg-lightBlue hover:cursor-pointer"
          : disabled
          ? "bg-mediumGray"
          : "bg-primary border-primary hover:cursor-pointer";

        return (
          <button
            key={option}
            data-value={option}
            onClick={handleOptionChange}
            disabled={disabled}
            className={`${backgroundClasses} ${borderClasses} border border-mediumGray font-yantramanavRegular py-1 px-2 flex items-center gap-[6px] focus:outline-none`}
          >
            <p
              className={`${
                disabled && selectedOption !== option
                  ? "text-mediumGray"
                  : disabled
                  ? "text-darkGray"
                  : selectedOption === option
                  ? "text-white"
                  : "text-darkGray"
              } font-yantramanavRegular text-[14px]`}
            >
              {t(option)}
            </p>
          </button>
        );
      })}
    </div>
  );
};

export default RadioButtons;
