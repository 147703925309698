import { useRef, useState } from "react";
import Image from "../../../components/atoms/Image";
import TagPill from "../../../components/atoms/TagPill";

export default function GalleryViewItem({ photobook, onClick }) {
  const containerRef = useRef(null);
  const [scrollX, setScrollX] = useState(0);

  const handleScroll = (e) => {
    const delta = Math.max(-1, Math.min(1, e.deltaY || -e.detail));
    const newScrollX = scrollX + delta * 40;
    const maxScrollX =
      containerRef.current.scrollWidth - containerRef.current.clientWidth + 120;
    setScrollX(Math.max(0, Math.min(maxScrollX, newScrollX)));
  };

  return (
    <div
      key={photobook.entry_id}
      onClick={(e) => onClick(e)}
      className="flex flex-col rounded shadow-md hover:cursor-pointer hover:scale-[1.02] transition-all overflow-hidden  min-w-[220px]"
    >
      <Image
        source={photobook?.main_images?.[0]?.sd}
        customSize={"h-[150px] w-full"}
      />
      <div className="flex flex-col px-2 py-1">
        <p className=" font-yantramanavRegular text-[16px] h-[18px] leading-tight mt-1 truncate">
          {photobook?.title}
        </p>
        <div
          ref={containerRef}
          onWheel={(e) => handleScroll(e)}
          className="overflow-hidden relative py-2 h-[46px]"
          style={{ scrollBehavior: "smooth", scrollSnapType: "x mandatory" }}
        >
          <div
            className="flex flex-no-wrap gap-1.5"
            style={{
              transform: `${
                photobook?.tags?.length > 1 ? `translateX(-${scrollX}px)` : ""
              } `
            }}
          >
            {photobook?.tags?.map((tag) => (
              <TagPill key={tag} tag={tag} small disabled />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
