import React, { useState, useEffect } from "react";
import MultiSelectDropdown from "../../components/atoms/MultiSelectDropdown";
import TextInput from "../../components/atoms/TextInput";
import Button from "../../components/molecules/Button";
import Icon from "../../components/atoms/Icon";
import { useTranslation } from "react-i18next";

function ResponsibilityContacts({ edit, companies, setCompanies }) {
  const { t } = useTranslation();

  const [emailErrors, setEmailErrors] = useState(
    Array(companies.length).fill("")
  );
  const [phoneErrors, setPhoneErrors] = useState(
    Array(companies.length).fill("")
  );

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isPhoneValid = (phone) => {
    const phoneRegex = /^[\d+-]+$/;
    return phoneRegex.test(phone);
  };

  const handleEmailChange = (index, value) => {
    setCompanies((prev) => {
      const updatedCompanies = [...prev];
      updatedCompanies[index].email = value;
      return updatedCompanies;
    });
  };

  const handleBlurEmail = (index, value) => {
    if (value === "") {
      setEmailErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[index] = "";
        return newErrors;
      });
    } else if (!isEmailValid(value)) {
      setEmailErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[index] = "not_valid_email";
        return newErrors;
      });
    } else {
      setEmailErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[index] = "";
        return newErrors;
      });
    }
  };

  const handlePhoneChange = (index, value) => {
    setCompanies((prev) => {
      const updatedCompanies = [...prev];
      updatedCompanies[index].phone = value;
      return updatedCompanies;
    });
  };

  const handleBlurPhone = (index, value) => {
    if (value === "") {
      setPhoneErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[index] = "";
        return newErrors;
      });
    } else if (!isPhoneValid(value)) {
      setPhoneErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[index] = "not_valid_phone";
        return newErrors;
      });
    } else {
      setPhoneErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[index] = "";
        return newErrors;
      });
    }
  };

  return (
    <div className="flex flex-col w-full ">
      <div className="h-[46px] flex items-center border-b border-lightGray px-8">
        <Button
          enabled={edit}
          text={t("add_company")}
          onClick={() => {
            setCompanies((prev) => [...prev, {}]);
          }}
          icon="universal_zoom_in_circle"
        />
      </div>
      <div className="p-[34px]">
        <div className="grid grid-cols-5 gap-4 font-yantramanavMedium text-[14px] leading-[18.16px] text-black h-[19px] w-4/5">
          <p>{t("name")}</p>
          <p>{t("phone")}</p>
          <p>{t("email")}</p>
          <p>{t("company_type")}</p>
          <p></p>
        </div>
        {companies?.map((com, com_index) => (
          <div key={com_index} className="flex flex-col gap-4 w-4/5 py-1  ">
            {!edit ? (
              <div className="grid grid-cols-5 gap-4 ">
                <div className="h-[20px] border-transparent border-b">
                  <p className="font-yantramanavLight text-textInput text-black">
                    {com.name}
                  </p>
                </div>
                <div className="h-[20px] border-transparent border-b">
                  <p className="font-yantramanavLight text-textInput text-black">
                    {com.phone}
                  </p>
                </div>
                <div className="h-[20px] border-transparent border-b">
                  <p className="font-yantramanavLight text-textInput text-black">
                    {com.email}
                  </p>
                </div>
                <div className="h-[20px] border-transparent border-b">
                  <MultiSelectDropdown
                    options={[
                      "repair",
                      "maintenance",
                      "owner",
                      "cleaning",
                      "tenant",
                      "subtenant"
                    ]}
                    initialValue={com.company_type}
                    onChange={(e) => {
                      setCompanies((prev) => {
                        const updatedCompanies = [...prev];
                        updatedCompanies[com_index].company_type = e;
                        return updatedCompanies;
                      });
                    }}
                    disabled={!edit}
                    placeholder={"add_here"}
                  />
                </div>
              </div>
            ) : (
              <div className="grid grid-cols-5 gap-4 pb-2">
                <TextInput
                  key={com_index + "_TextInput_name"}
                  initialValue={com.name}
                  placeholder="name"
                  disabled={!edit}
                  onChange={(e) => {
                    setCompanies((prev) => {
                      const updatedCompanies = [...prev];
                      updatedCompanies[com_index].name = e;
                      return updatedCompanies;
                    });
                  }}
                />
                <TextInput
                  key={com_index + "_TextInput_phone"}
                  initialValue={com.phone}
                  placeholder="phone"
                  disabled={!edit}
                  onChange={(e) => handlePhoneChange(com_index, e)}
                  errorMessage={phoneErrors[com_index]}
                  onBlur={(e) => handleBlurPhone(com_index, e.target.value)}
                />
                <TextInput
                  key={com_index + "_TextInput_email"}
                  initialValue={com.email}
                  placeholder="email"
                  disabled={!edit}
                  onChange={(e) => handleEmailChange(com_index, e)}
                  errorMessage={emailErrors[com_index]}
                  onBlur={(e) => handleBlurEmail(com_index, e.target.value)}
                />
                <MultiSelectDropdown
                  key={com_index + "_dropdown_type"}
                  options={[
                    "repair",
                    "maintenance",
                    "owner",
                    "cleaning",
                    "tenant",
                    "subtenant"
                  ]}
                  initialValue={com.company_type}
                  onChange={(e) => {
                    setCompanies((prev) => {
                      const updatedCompanies = [...prev];
                      updatedCompanies[com_index].company_type = e;
                      return updatedCompanies;
                    });
                  }}
                  disabled={!edit}
                  placeholder={"company_type"}
                />
                {edit ? (
                  <div
                    className="cursor-pointer w-fit pr-1"
                    onClick={() => {
                      setCompanies((prev) => {
                        const updatedCompanies = [...prev];
                        updatedCompanies.splice(com_index, 1);
                        return updatedCompanies;
                      });
                    }}
                  >
                    <Icon color={"danger"}>universal_delete_regular</Icon>
                  </div>
                ) : null}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default ResponsibilityContacts;
