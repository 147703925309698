import { Routes, Route, useLocation } from 'react-router-dom'
import { motion } from 'framer-motion';
import {
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query'

import './App.css';
import Dashboard from './pages/Dashboard'
import Login from './pages/Login'
import NoPage from './pages/NoPage';
import PrivateRoutes from './utils/PrivateRoutes'
import SingleItem from './views/Items/SingleItem/SingleItem';
import Items from './views/Items/Items';
import SingleMarket from './views/Market/SingleMarket';
import Development from './pages/Development';
import SinglePage from './pages/SinglePage';
import Missions from './views/Missions/Missions';
import SingleMission from './views/Missions/SingleMission';
import Responsibility from './views/Responsibility/Responsibility';
import Photobook from './views/Photobook/Photobook';
import SinglePhotobook from './views/Photobook/SinglePhotobook';
import Issues from './views/Issues/Issues';
import SingleIssue from './views/Issues/SingleIssue';
import Vault from './views/Vault/Vault';
import Analytics from './views/Analytics/Analytics';
import Background from "./assets/Firefly_Background.png";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { AnimatePresence } from "framer-motion";
import useUpdateNavigationState from './utils/useUpdateNavigationState';
import SingleChecklist from './views/Checklist/SingleChecklist';
import VaultPage from "./views/Vault/VaultPage";

// Create a client
const queryClient = new QueryClient();

function App() {
  const location = useLocation();
  useUpdateNavigationState(); // Ensures navigation state is updated on route changes

  return (
    <div className="App bg-cover bg-center overflow-hidden relative">
      <motion.div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: -1,
          backgroundImage: `url(${Background})`,
          backgroundSize: "cover",
          backgroundPosition: "center"
        }}
        animate={{
          scale: !location.pathname.includes("login") ? 1.2 : 1,
          filter:
            (location.pathname.includes("markets") ? "blur(5px)" : "") ||
            (!location.pathname.includes("login") ? "blur(2px)" : "")
        }} // Apply zoom effect
        initial={{ scale: 1 }}
        transition={{ duration: 1, ease: "easeInOut" }} // Customize animation duration
      />
      <QueryClientProvider client={queryClient}>
        <AnimatePresence mode="wait" initial={false}>
          <Routes location={location} key={location.pathname}>
            <Route element={<PrivateRoutes />}>
              <Route
                element={<Dashboard />}
                path="/"
                exact
                key={location.pathname}
              />
              <Route
                element={<SinglePage />}
                path="/markets/:marketId"
                key={location.pathname}
              >
                <Route element={<SingleMarket />} index />
                <Route element={<Items />} path="items" />
                <Route element={<SingleItem />} path="items/:itemId" />
                <Route element={<Missions />} path="missions" />
                <Route element={<SingleMission />} path="missions/:missionId" />
                <Route
                  element={<SingleChecklist />}
                  path="checklists/:checklistId"
                />
                <Route element={<Issues />} path="issues" />
                <Route element={<SingleIssue />} path="issues/:issueId" />
                <Route element={<Responsibility />} path="responsibility" />
                <Route
                  element={<Photobook />}
                  path="photobook"
                  key={location.pathname}
                />
                <Route
                  element={<SinglePhotobook />}
                  path="photobook/:photobookId"
                />
                <Route element={<Vault />} path="vault" />
                <Route element={<VaultPage />} path="vault/:vaultId" />
                <Route element={<Analytics />} path="analytics" />
              </Route>
            </Route>
            <Route element={<Login />} path="/login" />,
            <Route element={<Development />} path="/dev" />,
            <Route element={<NoPage />} path="/*" />
          </Routes>
        </AnimatePresence>
      </QueryClientProvider>
      <ToastContainer
        position="bottom-right"
        autoClose={8000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        limit={3}
      />
    </div>
  );
}

export default App;
