import { useLocation } from "react-router-dom";
import config from "../api/config.json";
import { exportExcel } from "../utils/ExportHelper";
const apiUrl = config.apiUrl;

export function useGetQueryParams() {
  return new URLSearchParams(useLocation().search);
}

export const exportData = async (data, selectedColumns, marketData, fileLabel, t) => {
  const translatedColumns = selectedColumns.map((col) => ({
    key: col.key,
    label: t(col.label)
  }));

  let a = data.map((i) => {
    let aa = {};
    translatedColumns.forEach((element) => {
      if (element.key.includes("image")) return;
      aa[element.label] = t(i[element.key]) ?? "";
    });
    return aa;
  });
  await exportExcel(a, marketData, fileLabel + "/");
};
