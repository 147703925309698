import React from "react";
import Image from "../atoms/Image";
import Icon from "../atoms/Icon";
import TextInput from "../atoms/TextInput";
import { useTranslation } from "react-i18next";

function Header({ market }) {
  const { t } = useTranslation();
  const listOfIdKeys = ["id_rewe", "id_wurm"];

  return (
    <div className="w-[1037px] h-[103px] grow flex justify-between border-solid border overflow-hidden px-[34px] py-4">
      <div className="w-[796.9px]">
        <div className="h-[22px] w-fit bg-[#F5F8FF] flex items-center py-[2px] px-1">
          <div className="py-[2px] pl-1 pr-2">
            <Icon color="primary" size={16}>
              other_market_regular
            </Icon>
          </div>
          <p className="h-[16px] text-primary font-yantramanavRegular text-[14px] tracking-[0.5px] leading-4">
            {market?.display_name}
          </p>
        </div>

        <div className="mt-[14px] flex">
          {listOfIdKeys.map((idKey, index) => {
            if (market?.[idKey]) {
              return (
                <div key={index} className="w-[238px]">
                  <p className="font-yantramanavLight text-[14px] tracking-[0.5px] leading-4 text-black">
                    {t(idKey)}
                  </p>
                  <TextInput initialValue={market[idKey]} disabled />
                </div>
              );
            }
            return null;
          })}
        </div>
      </div>
      <Image source={market?.entrance_images?.[0]?.thumbnail} size="thin" />
    </div>
  );
}

export default Header;
