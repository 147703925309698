import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import MenuItemEntry from "../../../atoms/MenuItemEntry";

export default function General({ selectedPath, setSelectedPath }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { marketId } = useParams();

  const isSelected =
    selectedPath.endsWith(marketId) || selectedPath.includes("general");

  const handleClick = () => {
    setSelectedPath((prev) => (prev.includes("general") ? "" : "general"));
    navigate("");
  };

  return (
    <div className="flex flex-col">
      <MenuItemEntry
        icon="logo_storeagent_regular"
        label={t("general")}
        isSelected={isSelected}
        handleClick={handleClick}
      />
    </div>
  );
}
