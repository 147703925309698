import React from "react";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import DateInput from "../atoms/DateInput";
import Dropdown from "../atoms/Dropdown";
import Button from "../molecules/Button";
import Icon from "../atoms/Icon";

function Status({ initialValue, onChange, disabled = false }) {
  const [status, setStatus] = useState([]);
  const { t } = useTranslation();
  const functionalityLevels = [
    "status_working",
    "status_minor_issue",
    "status_major_issue",
    "status_under_repair",
    "status_under_construction",
    "status_not_working"
  ];
  const cleanlinessLevels = ["clean", "dirty", "light_dirt"];

  useEffect(() => {
    const transformedStatus =
      initialValue?.map((status) => ({
        level: status.functionality_level ?? status.cleanliness_level ?? "",
        status_timestamp: status.status_timestamp || "",
        type_of_status: status.type_of_status || "",
        options:
          status.type_of_status === "functionality"
            ? functionalityLevels
            : cleanlinessLevels
      })) ?? [];
    setStatus(transformedStatus);
  }, [initialValue]);

  const handleUpdateStatus = (index, newStatus) => {
    setStatus((prevStatus) => {
      const updatedStatus = prevStatus.map((stat, idx) =>
        idx === index ? { ...stat, level: newStatus } : stat
      );
      onChange(
        updatedStatus.map((stat) => {
          const levelKey =
            stat.type_of_status === "functionality"
              ? "functionality_level"
              : "cleanliness_level";

          return {
            [levelKey]: stat.level,
            type_of_status: stat.type_of_status,
            status_timestamp: stat.status_timestamp
          };
        })
      );
      return updatedStatus;
    });
  };

  const removeStatus = (index) => {
    if (index >= 0 && index < status.length) {
      const updatedStatusArray = status.filter((_, idx) => idx !== index);
      setStatus(updatedStatusArray);
      onChange(
        updatedStatusArray.map((st) => ({
          [`${st.type_of_status}_level`]: st.level,
          type_of_status: st.type_of_status,
          status_timestamp: st.status_timestamp
        }))
      );
    }
  };

  const addNewStatus = () => {
    setStatus((prevStatus) => [
      ...prevStatus,
      {
        type_of_status: "",
        level: "",
        status_timestamp: new Date().toISOString(),
        options: []
      }
    ]);
  };

  const handleUpdateNewStatusType = (index, type) => {
    setStatus((prevStatus) => {
      const updatedStatus = prevStatus.map((stat, idx) =>
        idx === index
          ? {
              ...stat,
              type_of_status: type,
              options:
                type === "functionality"
                  ? functionalityLevels
                  : cleanlinessLevels
            }
          : stat
      );
      return updatedStatus;
    });
  };

  const handleUpdateNewStatusLevel = (index, level) => {
    setStatus((prevStatus) => {
      const updatedStatus = prevStatus.map((stat, idx) =>
        idx === index ? { ...stat, level } : stat
      );
      onChange(
        updatedStatus.map((stat) => ({
          [`${stat.type_of_status}_level`]: stat.level,
          type_of_status: stat.type_of_status,
          status_timestamp: stat.status_timestamp
        }))
      );
      return updatedStatus;
    });
  };

  return (
    <div className="w-full f-full mb-8">
      {status.map((stat, index) => (
        <div
          key={index}
          className="flex w-full flex-col gap-3 mb-6 p-2 shadow rounded-sm relative"
        >
          {stat.type_of_status === "" ? (
            <Dropdown
              disabled={disabled}
              options={["functionality", "cleanliness"]}
              initialValue=""
              onChange={(value) => handleUpdateNewStatusType(index, value)}
            />
          ) : (
            <>
              <div className="flex flex-col w-full">
                <p className="text-black text-[14px] leading-[18.16px] font-yantramanavLight text-left h-[19px] truncate whitespace-nowrap">
                  {t(stat.type_of_status)}
                </p>
                <Dropdown
                  disabled={disabled}
                  options={stat.options}
                  initialValue={stat.level}
                  onChange={(value) => handleUpdateNewStatusLevel(index, value)}
                />
              </div>
              <div className="w-full">
                <p className="text-black text-[14px] leading-[18.16px] font-yantramanavLight text-left h-[19px] truncate whitespace-nowrap">
                  {t("status_timestamp")}
                </p>
                <DateInput
                  initialValue={stat.status_timestamp}
                  disabled={true}
                />
              </div>
              {!disabled ? (
                <button
                  onClick={() => removeStatus(index)}
                  className="absolute -top-0.5 -left-1 h-3 w-3 bg-white rounded-full flex items-center justify-center hover:opacity-90"
                >
                  <Icon size={14} color="danger">
                    universal_close_filled
                  </Icon>
                </button>
              ) : null}
            </>
          )}
        </div>
      ))}
      {!disabled && (
        <div className={`${status.length > 0 ? "-mt-2" : ""}`}>
          <Button text="add_status" onClick={addNewStatus} />
        </div>
      )}
    </div>
  );
}

export default Status;
