import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Dropzone, { ErrorCode as DropzoneErrors } from "react-dropzone";
import ResponsiveModal from "../../components/molecules/ResponsiveModal";
import TagPillDropdown from "../../components/molecules/TagPillDropdown";
import Button from "../../components/molecules/Button";
import Icon from "../../components/atoms/Icon";

const MAX_FILE_SIZE = 500000000; // Dropzone requires bytes
function AddFilesModal({
  modalOpen,
  onClose,
  fileTags,
  setFileTags,
  allTags,
  onSubmit
}) {
  const { t } = useTranslation();
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [fileUploadError, setFileUploadError] = useState("");
  const [filename, setFilename] = useState("");

  const labelStyles =
    "text-black text-[14px] leading-[18.16px] tracking-[0.5px] font-yantramanavLight h-[19px]";

  const handleClose = () => {
    setAcceptedFiles([]);
    setFilename("");
    setFileTags([]);
    setFileUploadError("");
    onClose();
  };

  const handleSubmition = (formData) => {
    onSubmit(formData, fileTags);
  };

  const handleFilesToSubmit = () => {
    if (acceptedFiles) {
      const formData = new FormData();

      acceptedFiles.forEach((file) => {
        const newFilename = filename
          ? `${filename}.${file.name.split(".").pop()}`
          : file.name;
        const newFile = new File([file], newFilename, {
          type: file.type
        });
        formData.append("files", newFile);
      });

      if (formData.has("files")) {
        handleSubmition(formData);
      }
      handleClose();
    }
  };

  const handleRejectedFile = (rejectedFiles) => {
    rejectedFiles.forEach((rf) => {
      if ("errors" in rf && rf.errors.length) {
        setFileUploadError(rf.errors[0].code);
      }
    });
  };

  return (
    <ResponsiveModal
      isOpen={modalOpen}
      modalTitle="add_file"
      onClose={handleClose}
    >
      <div className="w-[660px] min-h-[530px] max-h-[550px] p-9 overflow-y-scroll no-scrollbar">
        <div className="flex flex-col gap-[42px] items-center m-auto px-14 h-full flex-shrink-0 ">
          <Dropzone
            maxSize={MAX_FILE_SIZE}
            onDropRejected={handleRejectedFile}
            accept={{
              "image/png": [".png", ".jpg"],
              "application/octet-stream": [
                ".pdf",
                ".doc",
                ".docx",
                ".ppt",
                ".pptx",
                ".xlxs",
                ".xls"
              ],
              "text/plain": [".txt", ".csv", ".html"]
            }}
            onDrop={(acceptedFiles) => {
              setFileUploadError("");
              setAcceptedFiles(acceptedFiles);
            }}
          >
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div
                {...getRootProps()}
                style={{
                  border: isDragActive ? "2px dashed gray" : null,
                  transition: "background-color 0.3s"
                }}
                className="pt-8 w-full bg-white flex flex-col items-center gap-3 rounded border-2 border-lightGrey text-center"
              >
                <input {...getInputProps()} />
                <Icon size={26} color="primary">
                  universal_export_regular
                </Icon>
                <p className="h-7 yantramanavRegular px-2 text-textInput rounded border flex flex-row items-center justify-center hover:bg-lightBlue hover:text-primary hover:cursor-pointer">
                  {t("browse_file")}
                </p>
                {acceptedFiles.length > 0 ? (
                  <div className="h-16 flex w-full truncate text-center">
                    {acceptedFiles.map((file, idx) => (
                      <p
                        key={idx}
                        className="mt-2 text-[18px] font-yantramanavLight text-black text-center w-full truncate"
                      >
                        {`${idx > 0 ? "," : ""} ${
                          filename
                            ? `${filename}.${file.name.split(".").pop()}`
                            : file.name
                        }`}
                      </p>
                    ))}
                  </div>
                ) : fileUploadError !== "" ? (
                  <div className="h-16">
                    {fileUploadError === DropzoneErrors.FileTooLarge ? (
                      <p className="font-yantramanavRegular text-error text-[18px]">
                        * {t("file_too_large")}
                      </p>
                    ) : null}
                    {fileUploadError === DropzoneErrors.FileInvalidType ? (
                      <p className="font-yantramanavRegular text-error text-[18px]">
                        * {t("file_type_invalid")}
                      </p>
                    ) : null}
                  </div>
                ) : (
                  <div className="flex flex-col items-center h-16">
                    <p className="text-[16px] font-yantramanavLight text-mediumGray">
                      {t("drag_and_drop_file_here")}
                    </p>
                    <p className="text-[16px] font-yantramanavLight text-mediumGray">
                      *{t("files_supported__")}
                    </p>
                  </div>
                )}
              </div>
            )}
          </Dropzone>
          <div className="w-full flex flex-row gap-3">
            <div className="w-1/2 px-4">
              <p className="font-yantramanavLight text-[16px] leading-[20.75px] text-darkGray">
                {t("file_name")}
              </p>
              <div className="flex flex-row">
                <input
                  type="text"
                  className="w-full h-4 font-yantramanavRegular text-textInput outline-none truncate border-b mb-5 text-black border-b-darkGray focus:border-b-darkGray"
                  value={filename}
                  placeholder={t("name_your_file")}
                  onChange={(e) => setFilename(e.target.value)}
                />
              </div>
            </div>

            <div className="w-1/2 flex flex-col -mb-10 min-h-[150px]">
              <label htmlFor="folderName" className={labelStyles + " mb-1.5"}>
                {t("tags")}
              </label>
              <TagPillDropdown
                initialValue={fileTags}
                allTags={allTags}
                onChange={(e) => setFileTags(e)}
              />
            </div>
          </div>
          <div className="mt-3 w-[222px]">
            <Button
              fill
              text="upload_file"
              icon="universal_export_regular"
              enabled={acceptedFiles.length !== 0 && filename !== ""}
              onClick={() => handleFilesToSubmit(acceptedFiles)}
            />
          </div>
        </div>
      </div>
    </ResponsiveModal>
  );
}

export default AddFilesModal;
