import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { set } from "lodash";

import { basicGet, basicPatch } from "../../api/api";
import Loadingsymbol from "../../components/atoms/Loadingsymbol";
import Box from "../../components/molecules/Box";
import Tab from "../../components/molecules/Tab";
import UnderConstruction from "../../components/molecules/UnderConstruction";
import { EXAMPLEMPHOTOBOOKTRUCT } from "../../pages/Development/structures";
import Button from "../../components/molecules/Button";
import SearchInput from "../../components/atoms/SearchInput";

async function fetchData({ queryKey }) {
  const { url } = queryKey[queryKey.length - 1];
  const response = await basicGet(url);
  if (!response.ok) {
    console.log("Response: ", response);
    throw new Error('Network response was not ok')
  }
  return response.json()
}

async function patchRequest(url, patchData) {
  const response = await basicPatch(url, patchData);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response;
}

export default function SinglePhotobook() {
  const { t } = useTranslation();
  const { marketId, photobookId } = useParams(); // Get market id
  const photobookRes = useQuery({
    queryKey: [
      "photobook",
      "entry",
      marketId,
      photobookId,
      { url: `photobook?market_id=${marketId}&entry_id=${photobookId}` }
    ],
    queryFn: fetchData
  }); // Get the data
  const photobookStructRes = {
    data: EXAMPLEMPHOTOBOOKTRUCT,
    isPending: false,
    isError: false
  };

  const [edit, setEdit] = useState(false);
  const [patchData, setPatchData] = useState({});

  const queryClient = useQueryClient();
  const photobookMutation = useMutation({
    mutationFn: ({ requestUrl, patchData }) =>
      patchRequest(requestUrl, patchData),
    onError: (error) => {
      console.log("On error: ", error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["photobook", "entry", marketId, photobookId]
      });
    }
  });

  if (photobookRes.isPending || photobookStructRes.isPending) {
    return (
      <div className="h-full">
        <div className="flex h-[46px] border-r border-b px-8 w-full"></div>{" "}
        {/** Empty header */}
        <div className="flex flex-col items-center mt-8">
          <Loadingsymbol />
          <p className="text-body font-yantramanaRegular text-primary">
            {t("loading")}...
          </p>
        </div>
      </div>
    );
  }

  const handleUpdate = (e) => {
    if (Object.keys(e)[0].includes("components[")) {
      setPatchData((prev) => {
        set(prev, Object.keys(e)[0], Object.values(e)[0]);
        return prev;
      });
    } else {
      setPatchData((prev) => ({ ...prev, ...e }));
    }
  };

  const handleSave = () => {
    // TODO: Fix save - Currently no url to make patch request to.
  };
  const handleDiscard = () => setEdit(false);

  return (
    <div className="h-full">
      <div className="flex h-[46px] border-r border-b pr-8 items-center justify-end px-8">
        <div className="justify-items-end flex gap-4">
          <Button
            text={t("export_pdf")}
            icon="universal_export_regular"
            enabled={true}
            onClick={() => undefined}
          />
        </div>
      </div>
      <>
        <div className="absolute z-20 top right-[34px] h-11">
          {edit ? (
            <div className="flex flex-row gap-3">
              <Tab
                onClick={handleSave}
                text={t("save")}
                icon="universal_check_regular"
                style="hugGreen"
              />
              <Tab
                onClick={handleDiscard}
                text={t("discard")}
                icon="universal_close_regular"
                style="hugBlue"
              />
            </div>
          ) : (
            <Tab
              onClick={() => setEdit(true)}
              text={t("edit")}
              icon="universal_edit_regular"
              style="hugBlue"
            />
          )}
        </div>
        <div className={"w-full p-8 h-[calc(100vh - 46px)]"}>
          {Object.keys(photobookRes.data).length > 0 ? (
            photobookStructRes.data.boxes.map((box, boxIndex) => (
              <Box
                key={
                  box.header +
                  Object.hasOwn(box, "isComponentStruct") +
                  box.items.length
                }
                box={box}
                boxIndex={boxIndex}
                initialValue={photobookRes.data}
                edit={edit}
                onUpdate={handleUpdate}
                market_id={marketId}
              />
            ))
          ) : photobookStructRes.data &&
            Object.keys(photobookRes.data).length <= 0 ? (
            <UnderConstruction />
          ) : null}

          {!photobookStructRes.data ? <UnderConstruction /> : null}
        </div>
      </>
    </div>
  );
}
