import React from "react";
import { useTranslation } from "react-i18next";
import Icon from "../atoms/Icon";

const Modal = ({ isOpen, modalTitle, onClose, children }) => {
  const { t } = useTranslation();

  const handleModalClick = (e) => {
    e.stopPropagation();
    if (e.currentTarget === e.target) {
      onClose(e);
    }
  }

  return (
    <div
      className={`${
        isOpen ? "block" : "hidden"
      } fixed top-0 left-0 h-screen w-screen bg-black bg-opacity-50 flex items-center justify-center z-20 overflow-hidden `}
      onClick={handleModalClick}
    >
      <div
        className={`${
          isOpen ? "modal" : ""
        }  w-[687px] h-[506px] bg-white rounded flex flex-col`}
      >
        <div className="w-full h-[36px] flex justify-between items-center px-4 py-2 border-b border-mediumGray">
          <p className="text-body leading-[18px] text-black font-yantramanavRegular">
            {t(modalTitle)}
          </p>
          <div
            className="flex items-center hover:cursor-pointer"
            onClick={() => onClose()}
          >
            <Icon size={20} color="mediumGray">
              universal_close_filled
            </Icon>
          </div>
        </div>
        <div className="flex justify-center items-center h-[470px] w-full">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
