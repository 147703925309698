import { useQuery } from "@tanstack/react-query";
import { motion } from "framer-motion";
import { Outlet, useParams, useLocation, useNavigate } from 'react-router-dom';

import Header from "../components/molecules/Header";
import InviteUser from "../components/molecules/InviteUser";
import UpdateUser from "../components/molecules/UpdateUser";
import { useState } from "react";
import FolderTree from "../components/organisms/FolderTree/FolderTree";
import InteractiveIcon from "../components/atoms/InteractiveIcon";

import SALogo from "../assets/StoreAgentLogo.png";
import "./SinglePageCustom.css";
import { fetchData } from "../api/api";
import useNavigationStore from "../utils/NavigationStore";

export default function SinglePage() {
  const [isModalOpen, setIsModalOpen] = useState({
    invite: false,
    update: false
  });

  const navigate = useNavigate();
  const location = useLocation();

  const { from, to } = useNavigationStore((state) => state.navigationState);

  // Define animations based on the navigation state
  const animations = {
    initial: (from === "/" || (to === "/" || to === "/login")) ? { translateX: "70%", opacity: 0 } : {},
    animate: (from === "/" || (to === "/" || to === "/login")) ? { translateX: 0, opacity: 1 } : {},
    transition: {
      translateX: { duration: 0.37, ease: [.33, .55, .41, .95] },
      opacity: { duration: 0.4, ease: "easeOut" }
    },
    exit: (to === "/" || to === "/login") ? { translateX: "100%", opacity: 0, transition: { duration: 0.38, ease: "easeInOut" } } : {},
  };

  const { marketId } = useParams();

  const { data: marketData, isPending: marketPending, isError: marketError } = useQuery({
    queryKey: [marketId, 'data', 'market', { url: `markets?market_id=${marketId}` }],
    queryFn: fetchData,
    staleTime: 30 * 1000,
  });

  function refreshPage() {
    window.location.reload(false);
  }

  return (
    <motion.div
      initial={animations.initial}
      transition={animations.transition}
      animate={animations.animate}
      exit={animations.exit}
      className=" overflow-x-hidden "
      key={location.pathname}
    >
      <div className="h-screen w-full overflow-hidden flex justify-center bg-transparent ">
        <div className="h-screen max-w-[1300px] w-full overflow-hidden no-scrollbar bg-white flex flex-col shadow-[rgba(0,0,0,0.15)_0px_4px_22px_0px] z-[2]">
          <div className="flex h-[103px] w-full ">
            <div className="w-60 flex-shrink-0 border-t border-l border-b">
              <div
                onClick={() => navigate("/")}
                className="w-full h-full flex flex-row justify-center items-center hover:cursor-pointer"
              >
                <img className="h-14 -mt-2" src={SALogo} />
                <p className="ml-2 font-yantramanavRegular text-lg text-darkGray">
                  StoreAgent
                </p>
              </div>
            </div>
            <Header market={marketPending ? {} : marketData} />
          </div>
          <div className="flex" style={{ height: "calc(100% - 103px)" }}>
            <div className="w-60 flex-shrink-0 grow-0 flex flex-col border-b justify-between h-full">
              <div className="overflow-hidden h-full w-full">
                <FolderTree />
              </div>
              <div className="flex fles-row justify-start p-4 gap-2 w-full">
                <InteractiveIcon
                  icon="universal_settings_regular"
                  size="small"
                  onClick={() => setIsModalOpen({ update: true })}
                />
                <div className="grow ">
                  <InteractiveIcon
                    icon="other_add_user_regular"
                    size="small"
                    onClick={() => setIsModalOpen({ invite: true })}
                  />
                </div>
                <div className="justify-self-end ">
                  <InteractiveIcon
                    icon="universal_exit_regular"
                    size="small"
                    className="justify-self-end"
                    onClick={() => {
                      localStorage.clear();
                      navigate("/login");
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="relative border-l border-r w-full">
              <div className="h-full flex flex-col">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
        <InviteUser
          isOpen={isModalOpen.invite}
          onClose={() => setIsModalOpen({ ...isModalOpen, invite: false })}
        />
        <UpdateUser
          isOpen={isModalOpen.update}
          onClose={() => setIsModalOpen({ ...isModalOpen, update: false })}
          onUpdated={refreshPage}
        />
      </div>
    </motion.div>
  );
};

