import { useEffect, useState } from "react"
import { getAsyncStorage } from "../utils/AsyncStorage"

export default function useMount() {
    const [userId, setUserId] = useState("");
    const [language, setLanguage] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [profilePic, setProfilePic] = useState("");

    useEffect(() => {
        getAsyncStorage("user").then(result => {
            setFirstName(result.first_name);
            setLastName(result.last_name);
            setLanguage(result.language);
            setUserId(result.uid);
            let profileImage = result.profile_images ?? [];
            setProfilePic(profileImage.length > 0 ? profileImage[0].hd :  null);
        })
    }, [])

    return { userId, firstName, setFirstName, lastName, setLastName, language, setLanguage, profilePic, setProfilePic}
}
