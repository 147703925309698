import ListView from "./ListView";
import Box from "../molecules/Box";
import UnderConstruction from "../molecules/UnderConstruction";

const SingleData = ({
  initialValue,
  struct,
  onUpdate,
  goto,
  singleView = true,
  editInfo = false,
  search = "",
  setSearch,
  columnOrder,
  market_id,
  typeOfPage
}) => {
  const renderListView = () => {
    return (
      <ListView
        data={initialValue}
        columnOrder={columnOrder}
        searchString={search}
        onClick={(e) => {
          setSearch("");
          goto({
            menuType: typeOfPage,
            menuId:
              e.item_id ??
              e.issue_id ??
              e.mission_id ??
              e.entry_id ??
              e.checklist_id,
            menuCategory: e?.categories?.[0],
            menuLabel: e.display_name ?? e.title
          });
        }} // TODO ONLY WORKS FOR ITEM
      />
    );
  };

  return (
    <div className="relative grow overflow-scroll no-scrollbar w-full">
      <div className="absolute h-[400px] w-full">
        <div className={singleView ? "relative w-full p-8" : "relative w-full"}>
          {singleView && struct && Object.keys(initialValue).length > 0 ? (
            struct.map((box, boxIndex) => (
              <Box
                key={boxIndex}
                box={box}
                boxIndex={boxIndex}
                initialValue={initialValue}
                edit={editInfo}
                onUpdate={onUpdate}
                market_id={market_id}
              />
            ))
          ) : singleView && struct && Object.keys(initialValue).length <= 0 ? (
            <UnderConstruction />
          ) : null}

          {singleView && !struct ? <UnderConstruction /> : null}
          {!singleView && renderListView()}
        </div>
      </div>
    </div>
  );
};

export default SingleData;
