import { Navigate, Outlet, useLocation } from "react-router-dom"

const PrivateRoutes = () => {
  let location = useLocation()
  let token = JSON.parse(localStorage.getItem("token") ?? false)
  return (
    // the added 24hours is to forece the user to the loginscreen if the user has been
    // incative for more than 24hours
    // TODO: not tested
    localStorage.setItem('originalRoute', location.pathname),
      (token.expires_at + 1000 * 60 * 60 * 24 > Date.now()) ?
      <Outlet />
      : <Navigate to="/login" />
  )
}

export default PrivateRoutes
