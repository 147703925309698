import { useState, useEffect, useRef } from "react";

export const tabGeneral = "tab_general"
export const tabServiceWork = "tab_service_work";
export const tabAnalytics = "tab_analytics";

export function useComponentVisibleHover(initialIsVisible) {
    const [isComponentVisible, setIsComponentVisible] = useState(
      initialIsVisible
    );
    const ref = useRef(null);

    const handleClickOutside = event => {
      if (ref.current && ref.current.contains(event.target)) {
        setIsComponentVisible(true);
      } else {
        setIsComponentVisible(false);
      }
    };
  
    useEffect(() => {
      document.addEventListener("mouseover", handleClickOutside, true);
      return () => {
        document.removeEventListener("mouseover", handleClickOutside, true);
      };
    });
  
    return { ref, isComponentVisible, setIsComponentVisible };
}

export function useComponentVisibleClick(initialIsVisible) {
    const [isVisible, setIsVisible] = useState(
      initialIsVisible
    );
    const refClick = useRef(null);
  
    const handleClickOutside = event => {
      if (refClick.current && !refClick.current.contains(event.target)) {
        setIsVisible(false);
      }
    };
  
    useEffect(() => {
      document.addEventListener("click", handleClickOutside, true);
      return () => {
        document.removeEventListener("click", handleClickOutside, true);
      };
    });
  
    return { refClick, isVisible, setIsVisible };
}