export const MenuTypes = Object.freeze({
    ITEM: 0,  // Render that menuItem, i.e. render a single item
    CATEGORY: 1,  // Select what's inside
    INACTIVE: 2      // Do nothing
})
export const rootMenuItems = {
    "general": { data: [], res: null },
    "photobook": { data: [], res: null },
    "analytics": { data: [], res: null },
    "vault": { data: [], res: null },
    "responsibility": { data: [], res: null },
}

export const menuSubCategories = [
  "refrigeration",
  "heating",
  "ventilation",
  "cooling",
  "butchery",
  "energy_generation",
  "plumbing",
  "staff_facilities",
  "imported",
  "lighting",
  "other"
];

export const TABSIZE = 20;
