import React from "react";
import SearchInput from "../../components/atoms/SearchInput";
import { useTranslation } from "react-i18next";
import Icon from "../../components/atoms/Icon";
import Tab from "../../components/molecules/Tab";
import Button from "../../components/molecules/Button";

function ResponsibilityHeader({
  tabs,
  selectedTab,
  setSelectedTab,
  struct,
  setJsonObject,
  ogJsonObject,
  companies,
  openMains,
  setOpenMains
}) {
  const { t } = useTranslation();

  const searchFucnktion = (term) => {
    if (term === "") {
      setJsonObject(ogJsonObject);
    } else {
      const lowercaseTerm = term.toLowerCase();
      let temp = [];
      ogJsonObject.forEach((ob) => {
        const obIdRewe = ob.id_rewe ? ob.id_rewe.toLowerCase() : "";
        const obDisplayName = ob.display_name
          ? ob.display_name.toLowerCase()
          : "";
        if (
          obIdRewe.includes(lowercaseTerm) ||
          obDisplayName.includes(lowercaseTerm)
        ) {
          temp.push(ob);
        }
      });
      setJsonObject(temp);
    }
  };

  const pressingMain = (resp, main) => {
    let remove = openMains.some((e) => e.area === resp && e.main === main);
    if (!remove) {
      setOpenMains((prev) => [...prev, { area: resp, main: main }]);
    } else {
      setOpenMains((prev) =>
        prev.filter((e) => !(e.area === resp && e.main === main))
      );
    }
  };

  // Function to find the number of keys in any inner object
  function countInnerKeys(obj) {
    let totalKeys = 0;
    for (const key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        totalKeys += Object.keys(obj[key]).length;
      }
    }
    return totalKeys;
  }
  const countedInnerKeys = countInnerKeys(struct);

  return (
    <div className="w-full pt-8 bg-white sticky flex flex-row items-end gap-10 border-r border-b border-lightGray shadow-[rgba(0,0,0,0.10)_0px_2px_12px_-2px] h-[149px] px-[34px]">
      <div
        className={`${
          countedInnerKeys <= 9 ? "mr-[74px]" : "mr-[73px]"
        } pb-4 flex items-end `}
      >
        <SearchInput
          placeholder={t("search")}
          size="small"
          onChange={(e) => searchFucnktion(e)}
        />
      </div>
      {Object.keys(struct).map((responsibility_area, index) => {
        return (
          <div
            key={responsibility_area + index}
            className="flex flex-col justify-between ml-5"
          >
            <div className="">
            <p
              className={`${
                countedInnerKeys <= 9 ? "w-[76px]" : "w-[102px]"
              } font-yantramanavMedium text-[14px] leading-[18.24px] text-center`}
            >
              {t(responsibility_area)}
            </p>
            </div>
            <div className="flex flex-row mt-[80px] text-start gap-1 ">
              {Object.keys(struct[responsibility_area]).map((main, index) => {
                return (
                  <div
                    key={responsibility_area + "_" + main + "_" + index}
                    className="flex flex-row gap-0.5"
                  >
                    <div
                      className="flex rotate-[300deg] cursor-pointer w-5"
                      onClick={() => pressingMain(responsibility_area, main)}
                    >
                      <div>
                        <div className="flex flex-row justify-start gap-1.5">
                          <p className="ml-1 font-yantramanavRegular text-[14px] leading-[18.16px]">
                            {t(main)}
                          </p>
                          {openMains.some(
                            (e) =>
                              e.area === responsibility_area && e.main === main
                          ) ? (
                            <Icon size={12} color="black">
                                direction_arrow_up_regular
                            </Icon>
                          ) : (
                            <Icon size={12} color="black">
                              direction_arrow_down_regular
                            </Icon>
                          )}
                        </div>
                      </div>
                    </div>
                    {
                      // TODO IF MAIN AND RESPONSIBILITY AREA SHOW ALL SECONDS
                      openMains.some(
                        (e) => e.area === responsibility_area && e.main === main
                      ) && (
                        <div className={"flex flex-row gap-0"}>
                          {companies
                            ?.filter((c, c_index) =>
                              struct[responsibility_area][main].some((r) =>
                                c.company_type.includes(r)
                              )
                            )
                            .map((com, com_index) => (
                              <div
                                className="w-6 rotate-[300deg]"
                                key={com + com_index + index}
                              >
                                <div
                                  className="whitespace-nowrap w-[108px] "
                                  key={
                                    com + com_index + main + responsibility_area
                                  }
                                >
                                  <p className="ml-1 font-yantramanavLight text-[14px] leading-[18.16px] ">
                                    {t(com.name)}
                                  </p>
                                </div>
                              </div>
                            ))}
                        </div>
                      )
                    }
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default ResponsibilityHeader;
