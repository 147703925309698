import React from "react";
import { PREVIEWSTRUCTURE } from "../../pages/Development/structures";
import { useTranslation } from "react-i18next";
import ResponsiveModal from "./ResponsiveModal";
import Image from "../atoms/Image";
import Icon from "../atoms/Icon";
import Box from "./Box";

function PreviewModal({ data, isOpen, onClose, goToMarket }) {
  const { t } = useTranslation();

  return (
    <ResponsiveModal
      isOpen={isOpen}
      modalTitle={"market_preview"}
      onClose={onClose}
    >
      <div className="w-[456px] h-[527px] p-4">
        <div className="w-[424px] h-[71px] flex flex-row gap-3">
          <Image source={data?.entrance_images?.[0]?.thumbnail} size="thin" />
          <div className="flex flex-col justify-between w-[280px]">
            <p className="text-[14px] leading-4 font-yantramanavRegular ">
              {data?.display_name}
            </p>
            <div className="flex flex-row gap-2 items-center">
              <Icon size={15}>other_market_regular</Icon>
              <p className="text-[14px] leading-[18.16px] font-yantramanavLight truncate tracking-[0.5px]">
                {data?.street} {data?.zip} {data?.city}
              </p>
            </div>
          </div>
        </div>

        <div className="mt-4 h-[373px] w-full overflow-scroll no-scrollbar  ">
          {Object?.keys(data)?.length > 0
            ? PREVIEWSTRUCTURE?.boxes?.map((box, boxIndex) => (
                <Box
                  key={
                    box.header +
                    Object.hasOwn(box, "isComponentStruct") +
                    box.length +
                    3
                  }
                  box={box}
                  boxIndex={boxIndex}
                  initialValue={data}
                  edit={false}
                  market_id={data?.market_id}
                />
              ))
            : null}
        </div>

        <button
          onClick={() => {
            onClose();
            goToMarket(data);
          }}
          className="w-full bg-primary py-2 rounded text-white font-yantramanavLight text-[14px] leading-[16px]"
        >
          {t("go_to_market")}
        </button>
      </div>
    </ResponsiveModal>
  );
}

export default PreviewModal;
