import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import TextInput from "../atoms/TextInput";
import NumInput from "../atoms/NumInput";
import Dropdown from "../atoms/Dropdown";
import MultiSelectDropdown from "../atoms/MultiSelectDropdown";
import LocationMap from "./LocationMap";
import Checkbox from "../atoms/Checkbox";
import Responsibility from "./Responsibility";
import CommentInput from "../atoms/CommentInput";
import Images from "./Images";
import DateInput from "../atoms/DateInput";
import ArbitraryKeys from "./ArbitraryKeys";
import ExternalStickerIds from "./ExternalStickerIds/ExternalStickerIds";
import "./dynamicHeightStyle.css";
import HoverText from "../atoms/HoverText";
import { useComponentVisibleHover } from "../../utils/UtilsViewFunctions";
import Icon from "../atoms/Icon";
import RadioButtons from "../atoms/RadioButtons";
import TagPillDropdown from "./TagPillDropdown";
import Comments from "./Comments";
import ExtraImages from "./ExtraImages";
import Status from "./Status";
import LastCheckupSticker from "./LastCheckupSticker";
import CostPosition from "./CostPosition";
import ExtraCosts from "./ExtraCosts";
import SelectFromIcons from "./SelectFromIcons";
import BuildingCertificate from "./certificate/Certificate";

const getComponent = (inputType, handleChange, props, tags, label) => {
  switch (inputType) {
    case "string":
      return <TextInput onChange={handleChange} {...props} />;
    case "numberfield":
    case "int":
      return <NumInput onChange={handleChange} {...props} />;
    case "dropdown":
      return <Dropdown onChange={handleChange} {...props} />;
    case "dropdown_add":
      return <Dropdown onChange={handleChange} {...props} addable />;
    case "dropdown_multiple":
      return <MultiSelectDropdown onChange={handleChange} {...props} />;
    case "select_from_icons":
      return <SelectFromIcons onChange={handleChange} {...props} />;
    case "location":
    case "location_map" || "location":
      return <LocationMap onChange={() => undefined} {...props} />;
    case "bool":
      return <Checkbox onChange={handleChange} {...props} />;
    case "responsibility":
      return <Responsibility onChange={handleChange} {...props} />;
    case "text":
    case "comment":
      return <CommentInput onChange={handleChange} {...props} />;
    case "comments":
      return <Comments onChange={handleChange} {...props} />;
    case "images":
      return (
        <Images
          onChange={handleChange}
          images={props.initialValue}
          label={label}
          {...props}
        />
      );
    case "extra_images":
      return (
        <ExtraImages
          onChange={handleChange}
          images={props.initialValue}
          label={label}
          {...props}
        />
      );
    case "last_checkup_sticker":
      return (
        <LastCheckupSticker
          onChange={handleChange}
          images={props.initialValue}
          label={label}
          {...props}
        />
      );
    case "date":
      return <DateInput onChange={handleChange} {...props} />;
    case "radio":
      return <RadioButtons onChange={handleChange} {...props} />;
    case "tags":
      return (
        <TagPillDropdown onChange={handleChange} allTags={tags} {...props} />
      );
    case "arbitrary_keys":
      return <ArbitraryKeys onChange={handleChange} {...props} />;
    case "status":
      return <Status onChange={handleChange} {...props} />;
    case "rewe_sticker":
    case "external_ids":
      return <ExternalStickerIds onChange={handleChange} {...props} />;
    case "cost_positions":
      return <CostPosition {...props} />;
    case "extra_costs":
      return <ExtraCosts {...props} />;
    case "building_certificates":
      return <BuildingCertificate onChange={handleChange} {...props} />;
    default:
      return <p>{inputType} not included in getComponent</p>;
  }
};

const KeyInput = ({
  inputKey,
  label,
  tags,
  info_bubble,
  description,
  type,
  onChange,
  ...props
}) => {
  // Below is to make sure that the items are rezising as intended
  const { t } = useTranslation();
  const minimumHeight = 70;
  const extraSpace = 10;
  const contentRef = useRef(null);
  const [componentHeight, setComponentHeight] = useState(0);
  const [showInfoBubble, setShowInfoBubble] = useState(false);
  const { ref, isComponentVisible /*, setIsComponentVisible*/ } =
    useComponentVisibleHover(false);

  // useEffect(() => {
  //   setComponentHeight(
  //     Math.ceil((contentRef.current?.clientHeight || 0) / minimumHeight) *
  //       minimumHeight
  //   );
  // }, []);

  useEffect(() => {
    const contentHeight = contentRef.current?.clientHeight || 0;
    const spaceNeeded = description ? extraSpace : 0;

    // Calculate the total content height considering the required extra space
    const totalContentHeight = contentHeight + spaceNeeded;

    if (totalContentHeight > minimumHeight) {
      // Calculate the outer KeyInput height based on the total content height
      const heightDiff = totalContentHeight - minimumHeight;
      const newOuterHeight =
        minimumHeight + Math.ceil(heightDiff / minimumHeight) * minimumHeight;

      setComponentHeight(newOuterHeight);
    } else {
      setComponentHeight(minimumHeight);
    }
  }, []);

  const handleChange = (e) => {
    onChange({ [inputKey]: e });
  };

  let keySlug =
    label && label !== ""
      ? label
      : inputKey.includes(".")
      ? inputKey.split(".").slice(-1)[0]
      : inputKey;

  if (type === "empty") return null;
  if (
    type === "arbitrary_keys" ||
    type === "comments" ||
    type === "extra_images" ||
    type === "status" ||
    type === "extra_costs" ||
    type === "cost_positions" ||
    type === "building_certificates"
  ) {
    return (
      <div className="min-h-[70px] mb-6">
        <p
          ref={ref}
          className="text-black text-[14px] leading-[18.16px] font-yantramanavLight text-left h-[19px] truncate whitespace-nowrap"
        >
          {t(keySlug)}
        </p>
        {getComponent(type, handleChange, props, tags, t(keySlug))}
      </div>
    );
  }
  if (type === "external_ids") {
    return (
      <div>{getComponent(type, handleChange, props, tags, t(keySlug))}</div>
    );
  }
  // if (type === "comments" || type === "extra_images") {
  //   return (
  //     <div>
  //       <p
  //         ref={ref}
  //         className="text-black text-[14px] leading-[18.16px] font-yantramanavLight text-left h-[19px] truncate whitespace-nowrap"
  //       >
  //         {t(keySlug)}
  //       </p>
  //       {getComponent(type, handleChange, props, tags, t(keySlug))}
  //     </div>
  //   );
  // }

  return (
    <div
      key={inputKey + "keyInputDiv" + type}
      style={{ height: `${componentHeight}px` }}
    >
      <div
        ref={contentRef}
        className="flex flex-row w-full justify-between relative"
      >
        <div className="w-full">
          <div className="text-black text-[14px] leading-[18.16px] font-yantramanavLight text-left">
            <p
              ref={ref}
              className={`${
                type === "images" ? "" : "h-[19px]"
              } truncate whitespace-nowrap `}
            >
              {keySlug === "no_label" ? null : t(keySlug)}
            </p>
            {isComponentVisible && t(keySlug).length >= 30 ? (
              <HoverText text={t(keySlug)} />
            ) : null}
          </div>
          {/*  NOTE 230125: REMOVED DESCRIPTION FOR NOW, ON THE REQUEST OF CM */}
          {description ? (
            <p className="font-yantramanavThin text-[14px] leading-[14px] text-darkDray">
              {t(description)}
            </p>
          ) : null}
          {getComponent(type, handleChange, props, tags, t(keySlug))}
        </div>
        <div className="absolute w-full">
          {info_bubble ? (
            <div
              onMouseEnter={() => setShowInfoBubble(true)}
              onMouseLeave={() => setShowInfoBubble(false)}
              className="hover:cursor-pointer absolute top-0 right-0"
            >
              <Icon size={16} color="primary">
                alert_info_filled
              </Icon>
            </div>
          ) : null}

          {info_bubble && showInfoBubble ? (
            <div className="absolute top-[25px] right-0 z-40 w-[200px] rounded-lg bg-white py-2 px-3 shadow-[rgba(0,0,0,0.10)_0px_2px_15px_0px]">
              <p className="font-yantramanavLight text-[14px] text-darkGray">
                {t(keySlug)}
              </p>
              <p className="font-yantramanavLight text-[15px] leading-[18px] text-black">
                {t(info_bubble)}
              </p>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default KeyInput;
