import { useTranslation } from "react-i18next";
import Icon from "../atoms/Icon";
import React from "react";

function NoDataFound({ data }) {
  const { t } = useTranslation();
  return (
    <div className="w-full flex-1 flex flex-row justify-center mt-10 gap-2">
      <Icon size={16}>alert_error_circle</Icon>
      <p className="text-[16px] text-black font-yantramanavLight">
        {t(`no_${data}_found`)}
      </p>
    </div>
  );
}

export default NoDataFound;
