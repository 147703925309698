import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Images from "./Images";
import Button from "./Button";

function LastCheckupSticker({ initialValue, onChange, disabled = false }) {
  const [images, setImages] = useState([]);
  const { marketId } = useParams();

  useEffect(() => {
    const transformedImages =
      initialValue?.map((image) => ({
        last_checkup_sticker: image.last_checkup_sticker || []
      })) ?? [];
    setImages(transformedImages);
  }, [initialValue]);

  const addLastCheckupSticker = () => {
    const newSticker = { last_checkup_sticker: [] };
    const newStickers = [...images, newSticker];
    setImages(newStickers);
    onChange(
      newStickers.map((i) => ({
        last_checkup_sticker: i.last_checkup_sticker
      }))
    );
  };

  const handleImageChange = (newImages, index) => {
    const updatedImages = [...images];
    updatedImages[index] = { last_checkup_sticker: newImages };
    setImages(updatedImages);
    onChange(updatedImages);
  };

  return (
    <div className="mb-16">
      {images?.map(
        (image, index) =>
          image.last_checkup_sticker && (
            <Images
              key={index}
              market_id={marketId}
              images={image?.last_checkup_sticker}
              onChange={(newImages) => handleImageChange(newImages, index)}
              disabled={disabled}
            />
          )
      )}
      {!disabled && (
        <div className={`${images.length > 0 ? "-mt-5" : "mt-2"}`}>
          <Button
            text="add_lastcheckup_sticker"
            onClick={addLastCheckupSticker}
          />
        </div>
      )}
    </div>
  );
}

export default LastCheckupSticker;
