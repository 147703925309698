import * as FileSaver from 'file-saver'
import XLSX from "sheetjs-style"

export const exportExcel = async (data, marketData, path) => {
    const fileType = "application/vnd.ms-excel;charset=utf-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const saveData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(saveData, marketData.display_name + "_" + path.split("/")[path.split("/").length -2] + fileExtension);
}