import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Icon from "../atoms/Icon";

function MoreOptionsDropdown({ options, setOption, onPressOption }) {
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);

  const handleOptionClick = (e, option) => {
    setOption(option);
    if (onPressOption) {
      onPressOption(e, option);
    }
  };

  return (
    <div className="absolute top-2 right-3 z-20 ">
      <div className="h-fit w-36 bg-white shadow-md rounded-sm overflow-hidden">
        {options.map((option, idx) => (
          <div
            key={idx}
            className="py-3 px-2 w-full hover:bg-lightBlue hover:cursor-pointer font-yantramanavRegular text-[16px] text-mediumGray hover:text-primary flex flex-row gap-2"
            onClick={(e) => handleOptionClick(e, option)}
            onMouseEnter={() => setIsHovered(option)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <Icon color={isHovered === option ? "primary" : "mediumGray"}>
              {"universal_" + option + "_regular"}
            </Icon>
            {t(option)}
          </div>
        ))}
      </div>
    </div>
  );
}

export default MoreOptionsDropdown;
