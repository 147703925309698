import { useQuery } from "@tanstack/react-query";

import { fetchData } from "../../../api/api";
import { rootMenuItems } from "./constants";

export const useInitMenu = (marketId) => {
  // 2024-01-29: Only data for items, missions and issues exists. In the future, add useQuery's for each menu item where data exists.
  const itemsRes = useQuery({
    // Fetch data for items
    queryKey: [
      marketId,
      "data",
      "items",
      { url: `items?market_id=${marketId}` }
    ],
    queryFn: fetchData,
    staleTime: 5000
  });
  const missionsRes = useQuery({
    // Fetch data for missions
    queryKey: [
      marketId,
      "data",
      "missions",
      { url: `missions?market_id=${marketId}` }
    ],
    queryFn: fetchData,
    staleTime: 5000
  });
  const issuesRes = useQuery({
    // Fetch data for issues
    queryKey: [
      marketId,
      "data",
      "issues",
      { url: `issues?market_id=${marketId}&all=true` }
    ],
    queryFn: fetchData,
    staleTime: 5000
  });

  const menuItems = {
    // Mix the "mock" items and real items (items with data)
    items: { data: [], resData: itemsRes },
    missions: { data: [], resData: missionsRes },
    issues: { data: [], resData: issuesRes },
    ...rootMenuItems
  };

  const isPending =
    itemsRes.isPending || missionsRes.isPending || issuesRes.isPending;

  if (!isPending) {
    for (const [key, val] of Object.entries(menuItems)) {
      if (!Object.hasOwn(val, "resData")) {
        // Inactive/mock menu item
        continue;
      }
      menuItems[key]["data"] = val.resData.data?.sort((a, b) => {
        const nameA = a.display_name || a.name || "";
        const nameB = b.display_name || b.name || "";
        return nameA > nameB ? 1 : -1;
      });
    }
  }

  return { isPending, menuData: menuItems };
};

export function getKey(obj, keyArray) {
  return keyArray.find((key) => Object.hasOwn(obj, key)) ?? null;
}
