import { useState } from "react";

const DateInput = ({ initialValue, onChange, disabled = false}) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (event) => {
        const date = new Date(event.target.value);
        setValue(Math.floor(date.getTime()));
        onChange(date.getTime());
    };

    const date = new Date(value);
    const formattedDate = date.toLocaleDateString('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    }).split('/').reverse().join('-');

    return (
      <div
        className={
          "w-[100%] h-4 text-black border-b border-darkGray flex justify-start items-center"
        }
      >
        <input
          className={
            "font-yantramanavRegular h-full text-textInput outline-none w-full text-black placeholder:text-mediumGray"
          }
          type="date"
          value={formattedDate}
          disabled={disabled}
          onChange={handleChange}
        />
      </div>
    );
};

export default DateInput;
