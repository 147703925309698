import React from "react";
import Icon from "./Icon";
import Image from "./Image";

function InfoCard({ title, info, image, icon, onClick }) {
  return (
    <div
      onClick={onClick}
      className="w-[220px] h-[51px] rounded-sm py-3 px-2 bg-white shadow-md flex flex-row items-center justify-center gap-[8px] overflow-hidden hover:-translate-y-[1px] hover:shadow-lg hover:cursor-pointer transition-transform"
    >
      {image !== "icon" ? (
        <div className=" flex justify-center items-center">
          <Image source={image} alt={title} customSize="w-[32.8px] h-[28px]" />
        </div>
      ) : (
        <div className="w-[30.8px] h-[30.8px] flex justify-center items-center">
          <Icon size={22}>{icon}</Icon>
        </div>
      )}

      <div className="truncate w-full">
        <h2 className="text-[14px] h-[19px] font-yantramanavRegular leading-[18.16px] truncate">
          {title}
        </h2>
        <p className="text-[12px] h-[16px] font-yantramanavLight leading-[15.56px] truncate">
          {info}
        </p>
      </div>
    </div>
  );
}

export default InfoCard;
