import React from 'react'

function Icon({size, color, children}) {
  const colors = new Map([
    ["transparent", "transparent"],
    ["logo", "#4FC4F0"],
    ["primary", "#105480"],
    ["darkGray", "#565760"],
    ["disabled", "#ECEDF0"],
    ["mediumGray", "#CBCCD5"],
    ["lightGray", "#ECEDF0"],
    ["darkWhite", "#F8F8F8"],
    ["trueWhite", "#FCFCFF"],
    ["white", "#FFFFFF"],
    ["black", "#191A23"],
    ["danger", "#AF1212"],
    ["success", "#1B6108 "],
    ["lightBlue", "#EEF7FD"],
    ["mediumBlue", "#CCE7F9"],
    ["lightGreen", "#EFFDEB"],
    ["mediumGreen", "#CFFAC4"],
    ["deepGreen", "#B0F79D"],
    ["lightRed", "#FDF0F0"],
    ["mediumRed", "#FAD2D2"],
    ["deepRed", "#F7B3B3"],
    ["deepBlue", "#A9D7F4"],
    ["progress", "#9E8E00"],
    ["error", "#AF1212"],
    ["completed", "#1B6108"],
    ["brownTint", "rgba(97, 97, 97, 0.50)"]
  ]);

  return (
    <p style={{fontSize: size, fontFamily: 'ChillIcons', color: color ? colors.get(color) : colors.get('primary'), userSelect: "none"}}
      >{children}</p>
  )
}

export default Icon
