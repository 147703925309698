import React, { useEffect, useState } from "react";
import ResponsiveModal from "../../components/molecules/ResponsiveModal";
import TagPillDropdown from "../../components/molecules/TagPillDropdown";
import Button from "../../components/molecules/Button";
import { useTranslation } from "react-i18next";

function AddFolderModal({ modalOpen, onClose, allTags, onAddFolder }) {
  const [folderName, setFolderName] = useState("");
  const [accessMode, setAccessMode] = useState("");
  const [accessList, setAccessList] = useState("");
  const [description, setDescription] = useState("");
  const [folderTags, setFolderTags] = useState([]);
  const { t } = useTranslation();

  const inputDivStyles = "w-1/2 mb-4 flex flex-col gap-[2px]";
  const labelStyles =
    "text-black text-[14px] leading-[18.16px] tracking-[0.5px] font-yantramanavLight h-[19px]";
  const inputStyles =
    "h-4 font-yantramanavRegular text-textInput outline-none w-full truncate border-b text-black  border-b-darkGray focus:border-b-darkGray";

  const handleAddFolder = () => {
    if (folderName.trim() !== "") {
      let finalAccessList = accessList.replace(/\s/, "");
      finalAccessList = finalAccessList.length
        ? finalAccessList.split(",")
        : [];
      onAddFolder({
        name: folderName.trim(),
        access: finalAccessList,
        description: description,
        tags: folderTags
      });
      setFolderName("");
      onClose();
    }
  };

  const sanityCheck = () => {
    if (!folderName.trim().length) {
      return false;
    }
    if (accessMode === "list") {
      if (!accessList.trim()) return false;
    }
    return true;
  };

  return (
    <ResponsiveModal
      isOpen={modalOpen}
      modalTitle="add_folder"
      onClose={() => {
        setFolderName("");
        setAccessMode("");
        setAccessList("");
        setDescription("");
        setFolderTags([]);
        onClose();
      }}
    >
      <div className="w-[607px] max-h-[736px] min-h-fit p-8 flex flex-col items-center overflow-auto no-scrollbar">
        <div className="w-[356px] flex flex-col items-center gap-[50px]">
          <div className="w-full flex flex-col items-center gap-1">
            <p className="text-[24px] text-center text-primary font-yantramanavRegular leading-[31.13px]">
              {t("add_new_folder")}
            </p>
            <p className="font-yantramanavLight text-center text-[16px] leading-[18.4px] tracking-[1.5%] text-black ">
              {t("text_under_title_in_add_folder_modal")}
            </p>
          </div>
          <div>
            <div className="w-[446px] flex flex-row gap-6">
              <div className={inputDivStyles}>
                <label htmlFor="folderName" className={labelStyles}>
                  {t("folder_name")}
                </label>
                <input
                  id="folderName"
                  type="text"
                  value={folderName}
                  onChange={(e) => setFolderName(e.target.value)}
                  placeholder={t("enter_folder_name")}
                  className={inputStyles}
                />
              </div>
              <div className={inputDivStyles}>
                <label htmlFor="description" className={labelStyles}>
                  {t("description")}
                </label>
                <textarea
                  id="description"
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder={t("enter_description")}
                  rows={4}
                  className="w-full p-1 font-yantramanavRegular text-textInput outline-none border border-mediumGray focus:border-black"
                />
              </div>
            </div>
            <div className="w-[446px] flex flex-row gap-6">
              <div className="w-1/2 flex flex-col -mb-12">
                <label htmlFor="folderName" className={labelStyles}>
                  {t("tags")}
                </label>
                <TagPillDropdown
                  initialValue={folderTags}
                  allTags={allTags}
                  onChange={(e) => setFolderTags(e)}
                />
              </div>

              <div className="flex flex-col gap-2">
                <label htmlFor="access_rights" className={labelStyles}>
                  {t("access_rights")}
                </label>
                <div
                  className="flex gap-2 hover:cursor-pointer items-center"
                  onClick={() => setAccessMode("private")}
                >
                  <input
                    type="radio"
                    checked={accessMode === "private"}
                    onChange={(e) => setAccessMode("private")}
                  ></input>
                  <p className="h-[19px] w-full truncate text-black text-[14px] font-yantramanavLight text-left">
                    {t("set_folder_access_private")}
                  </p>
                </div>
                <div
                  className="flex gap-2 hover:cursor-pointer items-center"
                  onClick={() => setAccessMode("list")}
                >
                  <input
                    type="radio"
                    checked={accessMode === "list"}
                    onChange={(e) => setAccessMode("list")}
                  ></input>
                  <p className="h-[19px] w-full truncate text-black text-[14px] font-yantramanavLight text-left">
                    {t("set_folder_access_list")}
                  </p>
                </div>
                {accessMode === "list" ? (
                  <div className="mt-2">
                    <p className={labelStyles}>{t("folder_access_list")}</p>
                    <p className={labelStyles}>
                      {t("(separate_email_with_commas)")}
                    </p>
                    <input
                      type="text"
                      value={accessList}
                      onChange={(e) => setAccessList(e.target.value)}
                      placeholder={t("enter_folder_access")}
                      className="h-4 font-yantramanavRegular text-textInput outline-none w-full truncate border-b text-black border-b-darkGray focus:border-b-darkGray mb-2"
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="w-[222px]">
            <Button
              text="add_folder_button"
              icon="add_circle"
              enabled={sanityCheck()}
              fill
              onClick={handleAddFolder}
            />
          </div>
        </div>
      </div>
    </ResponsiveModal>
  );
}

export default AddFolderModal;
