import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { fetchData } from "../../api/api";
import Loadingsymbol from "../../components/atoms/Loadingsymbol";
import NoDataFound from "../../components/molecules/NoDataFound";
import ListView from "../../components/organisms/ListView";
import Button from "../../components/molecules/Button";
import GalleryView from "./GalleryView/GalleryView";
import Tab from "../../components/molecules/Tab";
import AddNewPhotobook from "./AddNewPhotobook";
import PhotobookModal from "./PhotobookModal";
import NoPage from "../../pages/NoPage";
import SearchInput from "../../components/atoms/SearchInput";
import GalleryViewDropdown from "./GalleryView/GalleryViewDropdown";
import { basicPost } from "../../api/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const INITIAL_COLUMNS = [
  { key: "main_images", label: "main_images" },
  { key: "title", label: "title" },
  { key: "comment", label: "comment" }
];
const INITIAL_AVAILABLE_COLUMNS = [
  {
    key: "display_name",
    label: "display_name"
  }
];

export default function Photobook() {
  const { t } = useTranslation();
  const [tab, setTab] = useState({ gallery_view: true, list_view: false });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNewPhotoModalOpen, setIsNewPhotoModalOpen] = useState(false);
  const [photobookData, setPhotobookData] = useState({});
  const [edit, setEdit] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [tags, setTags] = useState([]);
  const queryClient = useQueryClient();

  const { marketId } = useParams();
  const photobookRes = useQuery({
    queryKey: ["data", "photobook", { url: `photobook?market_id=${marketId}` }],
    queryFn: fetchData
  });

  const {
    data: marketData,
    isPending: marketDataPending,
    isError: marketDataError
  } = useQuery({
    queryKey: [
      marketId,
      "data",
      "market",
      { url: `markets?market_id=${marketId}` }
    ],
    queryFn: fetchData,
    staleTime: 10 * 1000
  }); // Get the data

  async function postPhoto(data, marketId) {
    const response = await basicPost(`photobook?market_id=${marketId}`, data);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
  }

  const photobookMutation = useMutation({
    mutationFn: ({ marketId, postData }) => postPhoto(postData, marketId),
    onError: (error) => {
      console.log("On error: ", error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["data", "photobook"]
      });
    },
    onSettled: () => {
      setIsNewPhotoModalOpen(false);
    }
  });

  useEffect(() => {
    if (!isModalOpen) {
      setEdit(false);
    }
  }, [isModalOpen, photobookRes]);

  if (photobookRes.isError) {
    return <NoPage />;
  }

  if (photobookRes.isPending || marketDataPending) {
    return (
      <div className="h-full">
        <div className="flex h-[46px] border-r border-b px-8 w-full"></div>
        {/** Empty header */}
        <div className="flex flex-col items-center mt-8">
          <Loadingsymbol />
          <p className="text-body font-yantramanaRegular text-primary">
            {t("loading")}...
          </p>
        </div>
      </div>
    );
  }

  const onSave = (data) => {
    photobookMutation.mutate({ marketId, postData: data });
  };

  const expandInfo = (item) => {
    setPhotobookData(item);
    setIsModalOpen(true);
  };

  let availableTags = photobookRes.data.reduce((acc, photobook) => {
    if (!photobook.tags) {
      return { ...acc };
    }
    let newObj = photobook.tags.reduce((prev, current) => {
      return { ...prev, [current]: current };
    }, {});
    return { ...acc, ...newObj };
  }, {});

  let tagFilteredData = tags.length
    ? photobookRes.data.filter((photobook) => {
        let foundTags = tags.filter(
          (tag) => (photobook?.tags ?? []).indexOf(tag) !== -1
        );
        return foundTags.length;
      })
    : photobookRes.data;

  let filteredData = tagFilteredData.filter((photobook) => {
    return photobook.title.toLowerCase().includes(searchValue.toLowerCase());
  });

  return (
    <div className="h-full w-full">
      <div className="h-[46px] border-r border-b overflow-hidden flex justify-between items-center px-8 ">
        <div className="flex self-end gap-4">
          <Tab
            text={t("photobook_gallery_view")}
            active={tab.gallery_view}
            style="fixWhite"
            onClick={() => setTab({ gallery_view: true, list_view: false })}
          />
          <Tab
            text={t("photobook_list_view")}
            active={tab.list_view}
            style="fixWhite"
            onClick={() => setTab({ gallery_view: false, list_view: true })}
          />
        </div>
      </div>
      <div className="flex gap-2 justify-between px-4 pt-4 border-b border-lightGray pb-2">
        <div className="flex flex-row gap-2">
          <SearchInput
            onChange={(val) => setSearchValue(val)}
            placeholder={t("search_photobook")}
          />
          <GalleryViewDropdown
            tags={availableTags}
            onChange={(tags) => setTags(tags)}
          />
        </div>
        <Button
          text={t("upload_new_photobook")}
          icon="universal_export_regular"
          onClick={() => setIsNewPhotoModalOpen(true)}
        />
      </div>
      {photobookRes.data.length === 0 ? (
        <NoDataFound data="photobooks" />
      ) : tab.list_view ? (
        <div className="relative overflow-scroll no-scrollbar w-full h-full">
          <div className="absolute h-[400px] w-full">
            <div className={"relative w-full"}>
              <ListView
                data={filteredData}
                columnOrder={INITIAL_COLUMNS}
                searchString={""}
                onClick={(e) => {
                  expandInfo(e);
                }}
              />
            </div>
          </div>
        </div>
      ) : tab.gallery_view ? (
        <div className="h-full w-full relative">
          <GalleryView
            data={filteredData}
            isPending={photobookRes.isPending}
            onClick={expandInfo}
            tags={availableTags}
          />
        </div>
      ) : null}

      <AddNewPhotobook
        marketId={marketId}
        isModalOpen={isNewPhotoModalOpen}
        setIsModalOpen={setIsNewPhotoModalOpen}
        allTags={availableTags}
        onSave={onSave}
      />

      <PhotobookModal
        photobook={photobookData}
        marketData={marketData}
        edit={edit}
        setEdit={setEdit}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        setPhotobookData={setPhotobookData}
        allTags={availableTags}
      />
    </div>
  );
}
