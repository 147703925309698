import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import MenuItemEntry from "../../../atoms/MenuItemEntry";

export default function VaultFolder({ selectedPath, setSelectedPath }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isSelected = selectedPath.includes("vault");

  const handleClick = () => {
    setSelectedPath((prev) => (prev.includes("vault") ? "" : "vault"));
    navigate("vault");
  };

  return (
    <div className="flex flex-col">
      <MenuItemEntry
        icon="universal_vault_bold"
        label={t("vault")}
        isSelected={isSelected}
        handleClick={handleClick}
      />
    </div>
  );
}
