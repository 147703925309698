import Image from "../atoms/Image";

const UserProfileImage = ({
    firstName,
    lastName,
    pathImage,
    size = "normal"
}) => {
    const profileLetters =
        (firstName !== "" ? firstName[0] : "") +
        (lastName !== "" ? lastName[0] : "");

    return (
      <div
        className={`${
          size === "small" ? "h-[38px] w-[38px]" : "h-[74px] w-[74px]"
        } rounded-full bg-darkGray flex justify-center items-center overflow-hidden`}
      >
        {pathImage ? (
          <div className="w-full h-full">
            <Image size="xLarge" source={pathImage} />
          </div>
        ) : (
          <p
            className={`${
              size === "small" ? "text-[18px]" : "text-[29px]"
            } text-white bg-transparent tracking-[.575px] font-normal font-yantramanavRegular select-none pt-1`}
          >
            {profileLetters}
          </p>
        )}
      </div>
    );
};

export default UserProfileImage;