import Icon from "../atoms/Icon";

const InteractiveIcon = ({
  icon,
  size = "large",
  color = "primary",
  active,
  onClick
}) => {
  const sizeDiv = {
    large: "h-10 w-10",
    small: "h-8 w-8"
  };

  const sizeIcon = {
    large: 24,
    small: 20
  };

  return (
    <div
      className={`${sizeDiv[size]} ${
        active ? "bg-lightBlue" : "bg-transparent"
      } ${
        color === "black" ? "hover:bg-mediumGray" : "hover:bg-lightBlue"
      } rounded-full flex justify-center items-center overflow-hidden  active:bg-mediumBlue cursor-pointer transition-all delay-75`}
      onClick={onClick}
    >
      <Icon color={color} size={sizeIcon[size]}>
        {icon}
      </Icon>
    </div>
  );
};

export default InteractiveIcon;