import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import MenuItemEntry from "../../../atoms/MenuItemEntry";

export default function PhotobookFolder({ selectedPath, setSelectedPath }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isSelected =
    pathname.includes("photobook") && selectedPath.includes("photobook");

  const handleClick = () => {
    setSelectedPath((prev) => (prev.includes("photobook") ? "" : "photobook"));
    navigate("photobook");
  };

  return (
    <div className="flex flex-col">
      <MenuItemEntry
        icon="other_photobook_bold"
        label={t("photobook")}
        isSelected={isSelected}
        handleClick={handleClick}
      />
    </div>
  );
}
