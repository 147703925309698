import Icon from "./Icon";

const MenuItemEntry = ({ icon, label, isSelected, handleClick }) => {
  const showIcon = icon.includes("arbitrary_")
    ? "item_arbitrary_regular"
    : icon;
  return (
    <div onClick={handleClick} className={"flex items-center"}>
      <Icon size={20}>{showIcon}</Icon>
      <p
        className={[
          "hover:font-yantramanavRegular hover:cursor-pointer mx-2 select-none truncate", // Default style
          isSelected ? "font-yantramanavRegular" : "font-yantramanavLight "
        ].join(" ")}
      >
        {label}
      </p>
    </div>
  );
};
export default MenuItemEntry;
