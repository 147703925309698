import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom"
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import { basicGet, fetchData } from "../../api/api";
import Loadingsymbol from "../../components/atoms/Loadingsymbol";
import NoPage from "../../pages/NoPage";
import ListView from "../../components/organisms/ListView";
import SearchInput from "../../components/atoms/SearchInput";
import Button from "../../components/molecules/Button";
import { exportExcel } from "../../utils/ExportHelper";
import ArrangeColumns from "../../components/molecules/ArrangeColumns";
import { exportData } from "../viewHelpers";
import NoDataFound from "../../components/molecules/NoDataFound";

const AVAILABLE_COLUMNS = [
  {
    key: "title",
    label: "display_name"
  }
];

const INITIAL_SELECTED_COLUMNS = [
  { key: "main_images", label: "main_images" },
  { key: "title", label: "display_name" },
  { key: "target.display_name", label: "target.display_name" }
];

export default function Issues() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchString, setSearchString] = useState("");
  const { marketId } = useParams();
  const issuesRes = useQuery({
    queryKey: [
      "issues",
      marketId,
      { url: `issues?market_id=${marketId}&all=true` }
    ],
    queryFn: fetchData
  });

  if (issuesRes.isPending) {
    return (
      <div className="h-full">
        <div className="flex h-[46px] border-r border-b px-8 w-full"></div>{" "}
        <div className="flex flex-col items-center mt-8">
          <Loadingsymbol />
          <p className="text-body font-yantramanaRegular text-primary">
            {t("loading")}...
          </p>
        </div>
      </div>
    );
  }

  if (issuesRes.isError) {
    return <NoPage />;
  }

  if (issuesRes.data.length < 1) {
    return (
      <>
        <div className="h-[46px] border-b overflow-hidden flex justify-between items-center px-8 "></div>
        <NoDataFound data="issues" />
      </>
    );
  }

  return (
    <div className="h-full">
      <div className="h-[46px] border-b overflow-hidden flex justify-between items-center px-8 ">
        <SearchInput
          onChange={(e) => setSearchString(e)}
          placeholder={t("search")}
        />
        <div className="justify-items-end flex gap-4">
          <Button
            text={t("export_pdf")}
            icon="universal_export_regular"
            enabled={false}
            onClick={() => undefined}
          />
        </div>
      </div>
      <div className="relative grow overflow-scroll no-scrollbar w-full h-full">
        <div className="absolute h-[400px] w-full">
          <div className={"relative w-full"}>
            <ListView
              data={issuesRes.data}
              columnOrder={INITIAL_SELECTED_COLUMNS}
              searchString={searchString}
              onClick={(e) => {
                navigate(`${e.issue_id}`);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
