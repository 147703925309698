import React from "react";
import Image from "../atoms/Image";

function ThumbnailCard({ image, title, subtitle, onClick }) {
  return (
    <div
      onClick={onClick}
      className="w-[132px] h-[113px] hover:cursor-pointer hover:scale-105 transition-transform overflow-hidden shadow-[rgba(0,0,0,0.15)_0px_2px_8px_0px] flex flex-col gap-[6px] bg-white rounded-sm"
    >
      <div className="w-[132px] h-[71px] rounded-t-sm overflow-hidden">
        <Image source={image} size={"xLarge"} />
      </div>
      <div className="px-2 pb-2 h-[36px]">
        <p className="text-[12px] h-[16px] font-yantramanavRegular leading-[15.56px] truncate">
          {title}
        </p>
        <p className="text-[12px] h-[16px] font-yantramanavLight leading-[15.56px] truncate">
          {subtitle}
        </p>
      </div>
    </div>
  );
}

export default ThumbnailCard;
