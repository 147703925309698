import Dropzone, { ErrorCode as DropzoneErrors } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { uploadImage } from "../../api/api";
import React, { useState } from "react";
import ResponsiveModal from "../../components/molecules/ResponsiveModal";
import TagPillDropdown from "../../components/molecules/TagPillDropdown";
import Button from "../../components/molecules/Button";
import Icon from "../../components/atoms/Icon";

function AddNewPhotobook({
  marketId,
  isModalOpen,
  setIsModalOpen,
  allTags,
  onSave
}) {
  const { t } = useTranslation();
  const [fileUploadError, setFileUploadError] = useState("");
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [description, setDescription] = useState("");
  const [postData, setPostData] = useState({});
  const [photo, setPhoto] = useState([]);
  const [title, setTitle] = useState("");
  const [tags, setTags] = useState([]);

  const labelStyles =
    "text-black text-[14px] leading-[18.16px] tracking-[0.5px] font-yantramanavLight h-[19px]";

  const handleClose = () => {
    setFileUploadError("");
    setAcceptedFiles([]);
    setDescription("");
    setPhoto([]);
    setTitle("");
    setTags([]);
    setIsModalOpen(false);
  };

  const handleImageDrop = async (acceptedFiles) => {
    setAcceptedFiles(acceptedFiles);
    let newImage;
    if (acceptedFiles) {
      newImage = await uploadImage(acceptedFiles[0], marketId);
    }

    if (newImage.file) {
      setPhoto([
        ...acceptedFiles.map((file) => {
          Object.assign(file, {
            hd: URL.createObjectURL(file),
            sd: URL.createObjectURL(file)
          });
          return file;
        })
      ]);
    } else {
      setFileUploadError(newImage.type_message);
    }

    const newPhotosList = [newImage.file];
    setPostData((prev) => ({ ...prev, main_images: newPhotosList }));
  };

  const handleSave = () => {
    if (title === "" || acceptedFiles.length === 0) {
      return;
    }
    onSave({
      ...postData,
      title: title,
      tags: tags,
      created_at: new Date(),
      comment: description
    });

    handleClose();
  };

  const handleRejectedFile = (rejectedFiles) => {
    rejectedFiles.forEach((rf) => {
      if ("errors" in rf && rf.errors.length) {
        setFileUploadError(rf.errors[0].code);
      }
    });
  };

  return (
    <ResponsiveModal
      modalTitle={t("add_new_photobook")}
      isOpen={isModalOpen}
      onClose={handleClose}
    >
      <div className="w-[660px] min-h-[520px] max-h-[590px] px-16 py-7 overflow-y-scroll no-scrollbar">
        <div className="flex flex-col gap-[42px] items-center m-auto h-full flex-shrink-0 ">
          {photo.length > 0 ? (
            <img
              src={photo[0]?.hd}
              alt="photobook"
              className="w-full h-[197px] bg-white object-contain rounded border border-lightGrey"
            />
          ) : (
            <Dropzone
              onDropRejected={handleRejectedFile}
              accept={{
                "image/*": [".png", ".jpeg", ".jpg"]
              }}
              onDrop={(acceptedFiles) => {
                setFileUploadError("");
                handleImageDrop(acceptedFiles);
              }}
            >
              {({ getRootProps, getInputProps, isDragActive }) => (
                <div
                  {...getRootProps()}
                  style={{
                    border: isDragActive ? "2px dashed gray" : null,
                    transition: "background-color 0.3s"
                  }}
                  className="pt-8 w-full bg-white flex flex-col items-center gap-3 rounded border-2 border-lightGrey text-center"
                >
                  <input {...getInputProps()} />
                  <Icon size={26} color="primary">
                    universal_export_regular
                  </Icon>
                  <p className="h-7 yantramanavRegular px-2 text-textInput rounded border flex flex-row items-center justify-center hover:bg-lightBlue hover:text-primary hover:cursor-pointer">
                    {t("browse_photo")}
                  </p>
                  {acceptedFiles.length > 0 && fileUploadError === "" ? (
                    <div className="h-16 flex w-full truncate text-center">
                      {acceptedFiles.map((file, idx) => (
                        <p
                          key={idx}
                          className="mt-2 text-[18px] font-yantramanavLight text-black text-center w-full truncate"
                        >
                          {`${idx > 0 ? "," : ""} ${
                            title
                              ? `${title}.${file.name.split(".").pop()}`
                              : file.name
                          }`}
                        </p>
                      ))}
                    </div>
                  ) : fileUploadError !== "" ? (
                    <div className="h-16">
                      {fileUploadError === DropzoneErrors.FileTooLarge ? (
                        <p className="font-yantramanavRegular text-error text-[18px]">
                          * {t("file_too_large")}
                        </p>
                      ) : null}
                      {fileUploadError === DropzoneErrors.FileInvalidType ? (
                        <p className="font-yantramanavRegular text-error text-[18px]">
                          * {t("file_type_invalid")}
                        </p>
                      ) : (
                        <p className="font-yantramanavRegular text-error text-[18px]">
                          * {t(fileUploadError)}
                        </p>
                      )}
                    </div>
                  ) : (
                    <div className="flex flex-col items-center h-16">
                      <p className="text-[16px] font-yantramanavLight text-mediumGray">
                        {t("drag_and_drop_file_here")}
                      </p>
                      <p className="text-[16px] font-yantramanavLight text-mediumGray">
                        *{t("files_supported__")}
                      </p>
                    </div>
                  )}
                </div>
              )}
            </Dropzone>
          )}
          <div className="w-full flex flex-row gap-3">
            <div className="w-1/2 px-4 flex flex-col gap-3">
              <div className="w-full">
                <label
                  htmlFor="title"
                  className="font-yantramanavLight text-[16px] leading-[20.75px] text-darkGray"
                >
                  {t("title")}
                </label>
                <input
                  id="title"
                  type="text"
                  className="w-full h-4 font-yantramanavRegular text-textInput outline-none truncate border-b mb-5 text-black border-b-mediumGray focus:border-b-darkGray"
                  value={title}
                  placeholder={t("name_your_photobook")}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>

              <div>
                <label
                  htmlFor="description"
                  className="font-yantramanavLight text-[16px] leading-[20.75px] text-darkGray"
                >
                  {t("description")}
                </label>
                <textarea
                  id="description"
                  type="text"
                  rows={4}
                  className="w-full p-1 font-yantramanavRegular text-textInput outline-none border border-mediumGray focus:border-black"
                  placeholder={t("describe_your_photobook")}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </div>

            <div className="w-1/2 flex flex-col -mb-24 min-h-[150px]">
              <label htmlFor="folderName" className={labelStyles + " mb-1.5"}>
                {t("tags")}
              </label>
              <TagPillDropdown
                initialValue={tags}
                allTags={allTags}
                onChange={(e) => setTags(e)}
              />
            </div>
          </div>
          <div className="mt-3 w-[222px]">
            <Button
              fill
              text="upload_photobook"
              icon="universal_export_regular"
              enabled={acceptedFiles.length !== 0 && title !== ""}
              onClick={() => handleSave()}
            />
          </div>
        </div>
      </div>
    </ResponsiveModal>
  );
}

export default AddNewPhotobook;
