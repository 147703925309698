import React from "react";
import Icon from "../atoms/Icon";
import { useTranslation } from "react-i18next";

function Button({ fill, icon, text, active, onClick, enabled = true, color }) {
    const { t } = useTranslation();

    const colorObject = {
      white: `border-mediumGray active:bg-mediumBlue active:text-primary ${
        active
          ? "bg-lightBlue text-primary "
          : "bg-white text-black hover:bg-lightBlue hover:text-primary"
      }`,
      red: "border-danger text-danger active:bg-deepRed bg-lightRed hover:bg-mediumRed",
      green:
        "border-success text-success active:bg-deepGreen bg-lightGreen hover:bg-mediumGreen",
      transparent: `text-body font-yantramanavRegular text-left  ${
        enabled
          ? " text-darkGray hover:cursor-pointer hover:text-black hover:font-yantramanavMedium"
          : "text-mediumGray"
      }`,
      login: ` border-primary shadow-[rgba(0,0,0,0.15)_0px_3px_4px_0px] active:scale-95 ${
        active
          ? "bg-lightBlue text-primary "
          : "bg-primary text-white hover:bg-darkPrimary"
      }`
    };

    const colorIcon = {
        white: "primary",
        red: "danger",
        green: "success"
    };

    const defStyle = `${
      fill ? "w-full " : ""
    } h-7 font-yantramanavRegular px-2 text-textInput rounded border flex flex-row items-center justify-center
    ${
      enabled
        ? color
          ? colorObject[color]
          : colorObject["white"]
        : " border-mediumGray text-mediumGray bg-lightGray "
    } ${enabled ? "cursor-pointer" : "cursor-default"} `;

    return (
        <button
            className={color === "transparent" ? colorObject[color] : defStyle}
            enabled={enabled.toString()}
            disabled={!enabled}
            onClick={onClick}
        >
            {icon && (
                <div className="flex items-center">
                    <Icon
                        color={
                            enabled
                                ? color
                                    ? colorIcon[color]
                                    : colorIcon["white"]
                                : "mediumGray"
                        }
                        size={16}
                    >
                        {icon}
                    </Icon>
                    <div className="mr-2" />
                </div>
            )}
            {t(text)}
        </button>
    );
}

export default Button;
