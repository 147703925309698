import { useState } from "react";
import { useTranslation } from "react-i18next";
import MenuItemEntry from "./MenuItemEntry";
import Icon from "./Icon";

const MenuEntryList = ({
  data,
  selectedEntryId,
  handleEntryClick,
  keys: { labelKey, idKey, iconKey }
}) => {
  const { t } = useTranslation();
  const [showAll, setShowAll] = useState(false);
  const [currentEntry, setCurrentEntry] = useState(
    data.findIndex((entry) => entry[idKey] === selectedEntryId)
  );

  if (!data) {
    return <div></div>;
  }

  let startIndex = currentEntry - 2;
  let endIndex = currentEntry + 2;

  if (startIndex < 0) {
    endIndex = Math.min(endIndex - startIndex, data.length - 1); // Adjust endIndex if we're at the start
    startIndex = 0;
  } else if (endIndex > data.length - 1) {
    startIndex = Math.max(0, startIndex - (endIndex - (data.length - 1))); // Adjust startIndex if we're at the end
    endIndex = data.length - 1;
  }

  let itemsToRender = data;
  if (data.length > 5) {
    itemsToRender = showAll ? data : data.slice(startIndex, endIndex + 1);
  }

  return (
    <div className="flex flex-col">
      {itemsToRender.map((entry) => {
        return (
          <div
            key={entry[idKey]}
            onClick={() =>
              setCurrentEntry(
                data.findIndex((indexItem) => indexItem[idKey] === entry[idKey])
              )
            }
          >
            <MenuItemEntry
              key={entry[idKey]}
              label={t(entry[labelKey])}
              icon={
                entry.type === "HexInv" ||
                entry.type === "P02Inv" ||
                entry.type === "P60Inv"
                  ? "item_arbitrary_regular"
                  : entry?.[iconKey]
                  ? "item_" + entry?.[iconKey] + "_regular"
                  : "item_arbitrary_regular"
              }
              isSelected={entry[idKey] === selectedEntryId}
              handleClick={() => handleEntryClick(entry)}
            />
          </div>
        );
      })}
      {data.length > 5 ? (
        <div
          className="font-yantramanavLight hover:font-yantramanavRegular flex flex-1 gap-1 hover:cursor-pointer"
          onClick={() => setShowAll((prev) => !prev)}
        >
          {t("menu_show_all")}{" "}
          <Icon color={"black"}>
            {showAll
              ? "direction_arrow_up_regular"
              : "direction_arrow_down_regular"}
          </Icon>{" "}
        </div>
      ) : null}
    </div>
  );
};

export default MenuEntryList;
