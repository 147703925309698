import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { basicGet, fetchData, basicDelete, basicPatch } from "../../api/api";
import { getToken } from "../../utils/Authentication";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import GalleryViewDropdown from "../Photobook/GalleryView/GalleryViewDropdown";
import Loadingsymbol from "../../components/atoms/Loadingsymbol";
import NoDataFound from "../../components/molecules/NoDataFound";
import SearchInput from "../../components/atoms/SearchInput";
import ListView from "../../components/organisms/ListView";
import Button from "../../components/molecules/Button";
import Tab from "../../components/molecules/Tab";
import GalleryViewItem from "./GalleryViewItem";
import AddFilesModal from "./AddFilesModal";
import EditFileModal from "./EditFileModal";
import NoPage from "../../pages/NoPage";
import config from "../../api/config.json";
const apiUrl = config.apiUrl;

const INITIAL_COLUMNS = [
  { key: "main_images", label: "main_images" },
  { key: "display_name", label: "display_name" },
  { key: "created_at", label: "created_at" },
  { key: "suffix", label: "file_type" }
];

const uploadFile = async (endpoint, data) => {
  let token = await getToken();
  const res = await fetch(apiUrl + endpoint, {
    method: "POST",
    headers: new Headers({
      Authorization: `Bearer ${token}`
    }),
    body: data
  });
  const result = await res.json();
  return result;
};

function VaultPage() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { marketId, vaultId } = useParams();
  const [tab, setTab] = useState({ gallery_view: false, list_view: true });
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [fileLoading, setFileLoading] = useState("");
  const [fileData, setFileData] = useState({});
  const [fileTags, setFileTags] = useState([]);
  const [edit, setEdit] = useState(false);
  const [tags, setTags] = useState([]);

  const folder = useQuery({
    queryKey: [
      marketId,
      "data",
      "folder",
      "vaultId",
      { url: `vaults?market_id=${marketId}&vault_id=${vaultId}` }
    ],
    queryFn: fetchData
  });

  const fileUploadMutation = useMutation({
    mutationFn: async ({ postData, marketId, tags }) => {
      const entryId = await uploadFile(
        `vault_entries?market_id=${marketId}&vault_id=${vaultId}`,
        postData
      );
      // For now this is a workaround to get the entry_id from
      // the response and then patch the file with tags.
      // This should be handled in the backend in the futute?!
      if (entryId) {
        const patchData = {
          tags: tags
        };
        await basicPatch(
          `vault_entries?market_id=${marketId}&vault_id=${vaultId}&entry_id=${entryId}`,
          patchData
        );
      }
    },

    onError: (error) => {
      console.log("On error: ", error);
    },
    onSettled: () => {
      refetchFolderData();
    }
  });

  const fileUpdateMutation = useMutation({
    mutationFn: ({ patchData, marketId }) =>
      basicPatch(
        `vault_entries?market_id=${marketId}&vault_id=${vaultId}&entry_id=${fileData.filename}`,
        patchData
      ),
    onError: (error) => {
      console.log("On error: ", error);
    },
    onSettled: () => {
      refetchFolderData();
    }
  });

  const refetchFolderData = async () => {
    await folder.refetch();
  };

  const deleteFileMutation = useMutation({
    mutationFn: ({ marketId }) =>
      basicDelete(
        `vault_entries?vault_id=${vaultId}&market_id=${marketId}&entry_id=${fileData.filename}`
      ),
    onError: (error) => {
      console.log("On error: ", error);
    },
    onSettled: () => {
      refetchFolderData();
    }
  });

  const marketData = useQuery({
    queryKey: [
      marketId,
      "data",
      "market",
      { url: `markets?market_id=${marketId}` }
    ],
    queryFn: fetchData,
    staleTime: 10 * 1000
  });

  const handleFileUpload = (fileFormData, tags) => {
    fileUploadMutation.mutate({
      postData: fileFormData,
      marketId,
      tags
    });
  };

  const downloadFile = async (fileName) => {
    try {
      setFileLoading(fileName.display_name);
      const response = await basicGet(
        `vault_entries?market_id=${marketId}&vault_id=${vaultId}&entry_id=${fileName.filename}`,
        { "Content-type": "application/octet-stream" }
      );
      if (!response.ok) {
        console.log("Error fetching file: ", response);
      }
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = fileName.display_name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.log("Error: ", error);
    }
    setFileLoading("");
  };

  const handleSave = (fileData) => {
    fileUpdateMutation.mutate({ patchData: fileData, marketId });
  };

  const handleDiscard = () => {
    setEdit(false);
  };

  const expandInfo = (event, file) => {
    event.stopPropagation();
    setFileData(file);
    setEditModalOpen(true);
  };

  const handleDeleteFolder = () => {
    deleteFileMutation.mutate({ marketId });
    setEditModalOpen(false);
  };

  const fileArray = Object.values(
    folder?.data?.entries ? folder.data.entries : {}
  );
  let tagFilterdData = tags.length
    ? fileArray.filter((file) => {
        let foundTags = tags.filter(
          (tag) => (file?.tags ?? []).indexOf(tag) !== -1
        );
        return foundTags.length;
      })
    : fileArray;

  const filteredFiles = tagFilterdData.filter((file) =>
    file.display_name.toLowerCase().includes(searchString.toLowerCase())
  );

  if (folder.isPending || marketData.isPending) {
    return (
      <div className="h-full">
        <div className="flex h-[46px] border-r border-b px-8 w-full"></div>
        <div className="flex flex-col items-center mt-8">
          <Loadingsymbol />
          <p className="text-body font-yantramanaRegular text-primary">
            {t("loading")}...
          </p>
        </div>
      </div>
    );
  }

  if (folder.isError) {
    return <NoPage />;
  }

  let availableTags;
  if (
    folder &&
    folder.data &&
    folder.data.entries &&
    typeof folder.data.entries === "object"
  ) {
    availableTags = Object.values(folder.data.entries).reduce((acc, files) => {
      if (!files.tags || !Array.isArray(files.tags)) {
        return { ...acc };
      }
      let newObj = files.tags.reduce((prev, current) => {
        return { ...prev, [current]: current };
      }, {});
      return { ...acc, ...newObj };
    }, {});
  } else {
    availableTags = {};
  }

  return (
    <div className="h-full">
      <div className="h-[46px] border-r border-b overflow-hidden flex justify-between items-center px-8 ">
        <div className="flex self-end gap-4">
          <Tab
            text={t("vault_list_view")}
            active={tab.list_view}
            style="fixWhite"
            onClick={() => setTab({ gallery_view: false, list_view: true })}
          />
          <Tab
            text={t("vault_gallery_view")}
            active={tab.gallery_view}
            style="fixWhite"
            onClick={() => setTab({ gallery_view: true, list_view: false })}
          />
        </div>
      </div>
      <div className="h-[46px] border-r border-b flex justify-between items-center px-8">
        <div className="flex flex-row gap-2">
          <SearchInput
            onChange={(e) => setSearchString(e)}
            placeholder={t("search")}
          />
          <GalleryViewDropdown
            tags={availableTags}
            onChange={(tags) => setTags(tags)}
          />
        </div>
        <Button
          text="add_new_file"
          icon="universal_zoom_in_circle"
          onClick={() => setIsModalOpen(true)}
        />
      </div>

      {folder.data && filteredFiles.length === 0 ? (
        <NoDataFound data="files" />
      ) : tab.list_view ? (
        <div className="overflow-scroll no-scrollbar w-full h-[calc(100vh-211px)]">
          <ListView
            data={filteredFiles}
            columnOrder={INITIAL_COLUMNS}
            searchString={""}
            onClick={(file) => downloadFile(file)}
            onClickExpandInfo={expandInfo}
            icon="universal_dots_regular"
          />
        </div>
      ) : tab.gallery_view ? (
        <div className="relative w-full h-[calc(100vh-211px)] p-4">
          <div
            style={{ gridAutoRows: "120px" }}
            className="grid grid-cols-2 tablet:grid-cols-4 xlTablet:grid-cols-7 gap-2 w-full h-full overflow-y-scroll no-scrollbar "
          >
            {filteredFiles.map((file) => (
              <GalleryViewItem
                key={file.filename}
                data={file}
                fileLoading={fileLoading}
                onClick={() => downloadFile(file)}
                onEditClick={expandInfo}
              />
            ))}
          </div>
        </div>
      ) : null}

      <AddFilesModal
        modalOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        fileTags={fileTags}
        setFileTags={setFileTags}
        allTags={availableTags}
        onSubmit={handleFileUpload}
      />

      <EditFileModal
        edit={edit}
        setEdit={setEdit}
        fileData={fileData}
        allTags={availableTags}
        editModalOpen={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        handleSave={handleSave}
        handleDiscard={handleDiscard}
        onDeletion={handleDeleteFolder}
      />
    </div>
  );
}

export default VaultPage;
