import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getAsyncStorage } from "../../utils/AsyncStorage";
import { useTranslation } from "react-i18next";
import { inviteNewUser } from "../../api/api";
import Dropdown from "../atoms/Dropdown";
import Button from "./Button";
import Modal from "./Modal";
import Toast from "../atoms/Toast";

function InviteUser({ isOpen, onClose }) {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [client, setClient] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [currentUser, setCurrentUser] = useState({});
  const emailRegx = /^.+(@{1}).+(\.)[A-z]+/g;
  const [language, setLanguage] = useState(currentUser.language || "de");

  useEffect(() => {
    getAsyncStorage("user").then((res) => {
      setCurrentUser(res);
    });
  }, []);

  const handleOnBlurEmail = (text) => {
    if (text === "") {
      setError("no_email");
    } else if (text.search(emailRegx)) {
      setError("invalid_email");
    } else setError("");
  };

  const handleInvite = async (e) => {
    e.preventDefault();
    let user = { email, firstName, lastName, language };
    if (!user) {
      setError("fill_all_fields");
    } else {
      setError("");
      user.client = client || currentUser.client;
      const invite = await inviteNewUser(user);
      if (invite) {
        toast.success(
          <Toast text={"an_invite_has_been_sent_to_the_given_email"} />
        );
        setMessage(t("invite_sent") + " " + email);
        setEmail("");
        setFirstName("");
        setLastName("");
        setLanguage(currentUser.language || "de");
        setClient("");
        setTimeout(() => {
          setMessage("");
        }, 4000);
      } else {
        toast.error(
            <Toast text={"error_sending_invite"} />
          );
          setEmail("");
          setFirstName("");
          setLastName("");
          setLanguage(currentUser.language || "de");
          setClient("");
      }
    }
  };

  const enableButton = () => {
    return !(
      email === "" ||
      email.search(emailRegx) ||
      lastName === "" ||
      firstName === ""
    );
  };

  const emailCssClasses = () => {
    const def =
      "h-4 bg-transparent font-yantramanavRegular text-textInput outline-none w-full text-ellipsis truncate border-b ";
    if (error === "invalid_email" || error === "no_email")
      return def + " border-b-danger focus:border-b-danger text-danger";
    return def + " text-black border-b-darkGray focus:border-b-darkGray ";
  };

  const paragraphCSS = () => {
    const def =
      " font-yantramanavLight text-center text-[16px] leading-[18.4px] tracking-[1.5%] ";
    if (message !== "") return def + " text-success ";
    if (error === "error_sending_invite") return def + " text-darkGray ";
    return def + " text-black ";
  };

  const inputDivStyles = "w-full mb-4 flex flex-col gap-[2px]";

  const inputStyles =
    "h-4 bg-transparent font-yantramanavRegular text-textInput outline-none w-full text-ellipsis truncate border-b text-black border-b-darkGray focus:border-b-darkGray";

  const labelStyles =
    "text-black text-[14px] leading-[18.16px] tracking-[0.5px] font-yantramanavLight h-[19px]";

  return (
    <Modal isOpen={isOpen} modalTitle="invite_user" onClose={onClose}>
      <div className="w-[356px] flex flex-col items-center gap-[52px]">
        <div className="w-full flex flex-col items-center gap-1">
          <p className="text-[24px] text-center text-primary font-yantramanavRegular leading-[31.13px]">
            {t("invite_user_title")}
          </p>
          {message !== "" ? (
            <p className={paragraphCSS()}>{t(message)}</p>
          ) : error === "error_sending_invite" ? (
            <p className={paragraphCSS()}>{t(error)}</p>
          ) : (
            <p className={paragraphCSS()}>{t("invite_user_text")}</p>
          )}
        </div>

        <form className="w-[432px] flex flex-col gap-6 items-center">
          <div className="w-full flex flex-row gap-6">
            <div className="w-full mb-6">
              <div className={inputDivStyles}>
                <label htmlFor="firstName" className={labelStyles}>
                  {t("first_name")}
                </label>
                <input
                  type="text"
                  id="firstName"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className={inputStyles}
                  placeholder={t("first_name")}
                />
              </div>
              <div className={inputDivStyles}>
                <label htmlFor="email" className={labelStyles}>
                  {t("email")}
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onBlur={(e) => handleOnBlurEmail(e.target.value)}
                  className={emailCssClasses()}
                  placeholder={t("email")}
                />
                <div>
                  {error !== "" ? (
                    <p className="text-danger text-[14px] leading-[16px] tracking-[0.3px] font-yantramanavMedium">
                      {error === "error_sending_invite" ? "" : t(error)}
                    </p>
                  ) : null}
                </div>
              </div>
              {currentUser.role === "admin" && (
                <div className={inputDivStyles}>
                  <label htmlFor="client" className={labelStyles}>
                    {t("client")}
                  </label>
                  <input
                    id="client"
                    type="text"
                    value={client}
                    onChange={(e) => setClient(e.target.value)}
                    className={inputStyles}
                    placeholder={t("client")}
                  />
                </div>
              )}
            </div>
            <div className="w-full flex flex-col">
              <div className={inputDivStyles}>
                <label htmlFor="lastName" className={labelStyles}>
                  {t("last_name")}
                </label>
                <input
                  id="lastName"
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className={inputStyles}
                  placeholder={t("last_name")}
                />
              </div>
              <div className={inputDivStyles}>
                <label htmlFor="language" className={labelStyles}>
                  {" "}
                  {t("language")}{" "}
                </label>
                <Dropdown
                  options={["en", "de", "sv"]}
                  initialValue={language}
                  placeholder={t("language")}
                  onChange={(e) => {
                    setLanguage(e);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="w-[222px]">
            <Button
              fill
              text="send_email"
              icon="universal_email_regular"
              enabled={enableButton()}
              onClick={(e) => handleInvite(e)}
            />
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default InviteUser;
