import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import Loadingsymbol from "../../../components/atoms/Loadingsymbol";
import NoPage from "../../../pages/NoPage";
import ListView from "../../../components/organisms/ListView";
import { fetchData } from "../../../api/api";
import NoDataFound from "../../../components/molecules/NoDataFound";

const LIST_COLUMNS = [
  { key: "display_name", label: "display_name" },
  { key: "status", label: "status" }
];
export default function MissionsTab() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { marketId, itemId } = useParams(); // Get market id
  const checklistData = useQuery({
    queryKey: [
      marketId,
      "data",
      "missions",
      { url: `checklists?market_id=${marketId}&target_id=${itemId}` }
    ],
    queryFn: fetchData
  }); // Get the data
  const marketData = useQuery({
    queryKey: [
      marketId,
      "data",
      "market",
      { url: `markets?market_id=${marketId}` }
    ],
    queryFn: fetchData
  });

  if (checklistData.isError || marketData.isError) {
    return <NoPage />;
  }

  if (checklistData.isPending || marketData.isPending) {
    return (
      <div className="h-full">
        {/* <div className="flex h-[46px] border-r border-b px-8 w-full"></div>{" "} */}
        {/** Empty header */}
        <div className="flex flex-col items-center mt-8">
          <Loadingsymbol />
          <p className="text-body font-yantramanaRegular text-primary">
            {t("loading")}...
          </p>
        </div>
      </div>
    );
  }

  if (checklistData.data.length < 1) {
    return <NoDataFound data="missions" />;
  }

  return (
    <div className="h-full">
      <div>
        <ListView
          data={checklistData.data}
          columnOrder={LIST_COLUMNS}
          onClick={(item) => navigate("../checklists/" + item.checklist_id)}
        />
      </div>
    </div>
  );
}
