import { useEffect, useState } from "react"
import { apiGet, apiGetIssues } from "../api/api";
import { getAsyncStorage } from "../utils/AsyncStorage";
import { all } from "axios";

export default function useMount() {
  // consts
  const [counts, setCounts] = useState(null);
  const [simpleMarkets, setSimpleMarkets] = useState([]);
  const [lastVisitedMarkets, setLastVisitedMarkets] = useState(false);
  const [allMissions, setAllMissions] = useState([]);
  const [allIssues, setAllIssues] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  // useEffects
  useEffect(() => {
    let mounted = true;
    // apiGet("counts").then(result => {
    //     if (mounted) {
    //         setCounts(result)
    //     }
    // })
    getAsyncStorage("user").then((result) => {
      setCurrentUser(result);
    });
    apiGet("simple_markets").then((result) => {
      if (mounted) {
        setSimpleMarkets(result);
      }
    });
    apiGet("last_visited_markets").then((result) => {
      if (mounted) {
        setLastVisitedMarkets(result);
      }
    });
    apiGet("missions").then((result) => {
      if (mounted) {
        setAllMissions(result);
      }
    });
    apiGetIssues("issues").then((result) => {
      if (mounted) {
        setAllIssues(result);
      }
    });
  }, []);

  return {
    counts,
    simpleMarkets,
    lastVisitedMarkets,
    currentUser,
    allMissions,
    allIssues
  };
}
