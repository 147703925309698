import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { uploadImage } from "../../../api/api";
import Dropzone from "react-dropzone";
import ResponsiveModal from "../ResponsiveModal";
import NewStickerForm from "./NewStickerIDForm";
import TextInput from "../../atoms/TextInput";
import DateInput from "../../atoms/DateInput";
import ImageViewer from "../ImageViewer";
import Image from "../../atoms/Image";
import Icon from "../../atoms/Icon";
import Button from "../Button";
import CommentInput from "../../atoms/CommentInput";

function ExternalStickerIds({ initialValue, onChange, disabled = false }) {
  const keyOrder = ["image_input", "carrier_id_2", "next_test_date", "comment"];
  const [stickerIDS, setStickerIDS] = useState(initialValue ?? []);
  const [showPopup, setShowPopup] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [viewImage, setViewImage] = useState([]);
  const [loading, setLoading] = useState(false);
  const { marketId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    setStickerIDS(initialValue ?? []);
  }, [initialValue]);

  const handleImageDrop = async (acceptedFiles, index) => {
    setLoading(true);
    let newImage;
    if (acceptedFiles) {
      newImage = await uploadImage(acceptedFiles[0], marketId);
    }
    if (newImage.file) {
      const updatedStickers = stickerIDS.map((sticker, idx) => {
        if (idx === index) {
          return { ...sticker, image: newImage.file };
        }
        return sticker;
      });

      setStickerIDS(updatedStickers);
      onChange(updatedStickers);
    }
    setLoading(false);
  };

  const handleInputChange = (index, key, value) => {
    const updatedStickers = stickerIDS.map((sticker, idx) => {
      if (idx === index) {
        return { ...sticker, [key]: value };
      }
      return sticker;
    });

    setStickerIDS(updatedStickers);
    onChange(updatedStickers);
  };

  const handleDeleteImage = (index, e) => {
    e.stopPropagation();
    const updatedStickers = stickerIDS.map((sticker, idx) => {
      if (idx === index) {
        return { ...sticker, image: null };
      }
      return sticker;
    });

    setStickerIDS(updatedStickers);
    onChange(updatedStickers);
  };

  const handleAddNewSticker = (newSticker) => {
    const updatedStickers = [...stickerIDS, newSticker];
    setStickerIDS(updatedStickers);
    onChange(updatedStickers);
    setShowModal(false);
  };

  const Buttons = ({ sticker, index }) => (
    <div>
      {!disabled ? (
        <div>
          {sticker.image ? (
            <button
              onClick={(e) => handleDeleteImage(index, e)}
              className="absolute top-1.5 right-1.5 h-3 w-3 bg-white rounded-full flex items-center justify-center hover:opacity-90"
            >
              <Icon size={20} color="danger">
                universal_close_filled
              </Icon>
            </button>
          ) : null}
          {!loading && (
            <Dropzone
              acceptedFiles={["image/jpg"]}
              onDrop={(accepted) => handleImageDrop(accepted, index)}
            >
              {({ getRootProps, getInputProps, isDragActive }) => (
                <div
                  {...getRootProps()}
                  style={{
                    border: isDragActive ? "2px dashed gray" : null,
                    transition: "background-color 0.3s"
                  }}
                  className="absolute bottom-1 right-1 h-7 p-2 min-w-fit bg-white flex items-center justify-items-end hover:cursor-pointer rounded border border-mediumGray"
                >
                  <input {...getInputProps()} />
                  <Icon size={16} color="primary">
                    universal_camera_regular
                  </Icon>
                  <p className=" ml-2 font-yantramanavRegular text-textInput min-w-min">
                    {sticker.image ? t("update_photo") : t("add_photo")}
                  </p>
                </div>
              )}
            </Dropzone>
          )}
        </div>
      ) : (
        <div
          onClick={() => {
            setViewImage(sticker);
            setShowPopup(true);
          }}
          className="absolute top-0 right-0 w-8 h-8 bg-darkWhite flex justify-center items-center hover:cursor-pointer rounded-bl-2xl rounded-tr-sm border-t border-r border-mediumGray"
        >
          <Icon size={16} color="primary">
            other_enlarge_regular
          </Icon>
        </div>
      )}
    </div>
  );

  return (
    <div className="flex flex-col gap-6 w-full">
      {stickerIDS.map((sticker, index) => (
        <div
          key={index}
          className="w-full flex justify-between items-start mb-3 bg-white "
        >
          <div className="flex flex-col w-[44%]">
            {Object.entries(sticker)
              .filter(([key]) => key !== "image" && key !== "id_type")
              .sort(
                ([keyA], [keyB]) =>
                  keyOrder.indexOf(keyA) - keyOrder.indexOf(keyB)
              )
              .map(([key, value]) => (
                <div key={key} className="flex flex-col mb-4 w-full">
                  <p className="text-inputTitle font-yantramanavLight">
                    {key === "image_input" && sticker.id_type
                      ? t(sticker.id_type)
                      : t(key)}
                  </p>
                  {key.includes("date") ? (
                    <DateInput
                      initialValue={value}
                      disabled={disabled}
                      onChange={(newVal) =>
                        handleInputChange(index, key, newVal)
                      }
                    />
                  ) : key.includes("comment") ? (
                    <CommentInput
                      initialValue={
                        Object.keys(value).length === 0 ? "" : value
                      }
                      disabled={disabled}
                      onChange={(newVal) =>
                        handleInputChange(index, key, newVal)
                      }
                    />
                  ) : (
                    <TextInput
                      initialValue={
                        Object.keys(value).length === 0 ? "" : value
                      }
                      disabled={disabled}
                      onChange={(newVal) =>
                        handleInputChange(index, key, newVal)
                      }
                    />
                  )}
                </div>
              ))}
          </div>
          <div className="flex flex-col">
            <p className="text-inputTitle font-yantramanavLight">
              {t(sticker.id_type + "_image")}
            </p>
            <div className="w-full relative">
              {sticker.image ? (
                <Image source={sticker.image?.hd} size="large" />
              ) : (
                <Image source={undefined} size="large" />
              )}
              <Buttons sticker={sticker} index={index} />
            </div>
          </div>
        </div>
      ))}
      {!disabled && (
        <div className="">
          <Button
            text={t("add_external_sticker_id")}
            onClick={() => setShowModal(true)}
          />
        </div>
      )}
      {showPopup && (
        <ImageViewer
          images={[viewImage.image.hd]}
          initialImageIndex={0}
          title={t(viewImage.id_type + "_image")}
          onClose={() => {
            setViewImage([]);
            setShowPopup(false);
          }}
        />
      )}

      <ResponsiveModal
        isOpen={showModal}
        modalTitle="external_sticker_id"
        onClose={() => setShowModal(false)}
      >
        <NewStickerForm
          isOpen={showModal}
          onAdd={handleAddNewSticker}
          closeModal={() => setShowModal(false)}
        />
      </ResponsiveModal>
    </div>
  );
}

export default ExternalStickerIds;
