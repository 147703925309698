import { useNavigate } from "react-router-dom";
import { login } from "../utils/Authentication";
import { useState } from "react";
import Icon from "../components/atoms/Icon";
import Footer from "../components/atoms/Footer";
import Toast from "../components/atoms/Toast";
import { getUser } from "../api/user";
import { useTranslation } from "react-i18next";
import Loadingsymbol from "../components/atoms/Loadingsymbol";
import Button from "../components/molecules/Button";
import Modal from "../components/molecules/Modal";
import { sendPasswordResetEmail } from "../api/api";
import { toast } from "react-toastify";
import { motion } from "framer-motion";

import SALogo from "../assets/StoreAgentLogo.png";

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const emailRegx = /^.+(@{1}).+(\.)[A-z]+/g;

  //error messages
  const wrongCredentials = "wrong_username_or_password";
  const noEmail = "no_email";
  const invalidEmail = "invalid_email";
  const noPassword = "no_password";

  const handleLogin = async (e, email, password) => {
    e.preventDefault();
    if (email === "") {
      setError(noEmail)
      return
    } else if (email.search(emailRegx)) {
      setError(invalidEmail)
      return
    }
    else if (password === "") {
      setError(noPassword)
      return
    }

    setLoading(true);
    const user = await login(email, password);
    if (user) {
      await getUser();
      setLoggedIn(true);
      const originalRoute = localStorage.getItem("originalRoute");
      if (originalRoute !== null && originalRoute !== "/login") {
        navigate(originalRoute);
        localStorage.removeItem("originalRoute");
      } else {
        toast(<Toast title="welcome" />);
        navigate("/");
      }
      setLoading(false);
      setLoggedIn(false);
    } else {
      setLoading(false);
      setError(wrongCredentials);
    }
  };

  const handleOnBlurEmail = (text) => {
    if (text === "") {
      setError(noEmail);
    } else if (text.search(emailRegx)) {
      setError(invalidEmail);
    } else setError("");
  };

  const handleOnBlurPass = (text) => {
    if (text === "") {
      setError(noPassword);
    } else setError("");
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const enableButton = () => {
    return !(email === "" || email.search(emailRegx) || password === "");
  };

  const emailCssClasses = () => {
    const def =
      "h-4 bg-transparent font-yantramanavRegular text-textInput outline-none w-full text-ellipsis truncate border-b ";
    if (error === invalidEmail || error === noEmail)
      return def + " border-b-danger focus:border-b-danger text-danger";
    return def + " text-black border-b-darkGray focus:border-b-darkGray ";
  };

  const passwordCssClasses = () => {
    const def =
      "h-4 bg-transparent font-yantramanavRegular text-textInput outline-none w-full text-ellipsis truncate border-b flex justify-between";
    if (error === noPassword)
      return def + " border-b-danger focus:border-b-danger text-danger";
    return def + " text-black  border-b-darkGray focus:border-b-darkGray ";
  };

  const handleSendResetEmail = async () => {
    setMessage(t("loading"))
    await sendPasswordResetEmail(email); //TODO Implement this function to send a reset email
    setMessage(t("a_link_has_been_sent_to_your_email_to_change_your_password"))
    // setEmail("");
    // setMessage(
    // t("a_link_has_been_sent_to_your_email_to_change_your_password")
    // );
  };

  return (
    <motion.div
      initial={{ translateX: "-50%", opacity: 0 }}
      animate={{ translateX: 0, opacity: 1 }}
      exit={{
        translateX: "-50%",
        opacity: 0,
        transition: { duration: 0.37, ease: "easeInOut" }
      }}
      transition={{ duration: 0.3, ease: "easeOut" }}
      className=" overflow-x-hidden"
    >
      <div className="h-screen w-screen bg-transparent relative">
        {/* Header */}
        <div
          className={`
        h-full absolute top-0 left-0 transition-all delay-[2000ms] overflow-hidden `}
        >
          <div className="h-screen w-[563px] bigScreen:w-[971px] flex flex-col justify-center items-center shadow-2xl bg-darkWhiteTint gap-8">
            <div className="flex justify-end items-center w-full flex-col gap-2">
              <img className="mb-2" src={SALogo} />
              <h1 className="text-[20px] bigScreen:text-[34.52px] leading-[25.94px] tracking-[0.5px] text-brownTint font-yantramanavRegular">
                StoreAgent
              </h1>
            </div>

            <div className="flex flex-col w-[238px] bigScreen:w-[410.74px] h-1/2">
              {error === wrongCredentials ? (
                <p className="text-textInput font-medium text-danger mb-2 h-5 text-center">
                  {t(error)}
                </p>
              ) : (
                <div className="h-5 mb-2" />
              )}
              <div className="text-left mb-3 ">
                <p className="text-primary font-normal font-yantramanavRegular leading-[25.94px] text-[20px] bigScreen:text-[34.52px] ">
                  {t("log_in")}
                </p>
              </div>

              {/* LoginForm */}
              <form className="flex flex-col">
                <label
                  htmlFor="email"
                  className="text-black text-sm font-yantramanavLight text-left"
                >
                  {t("email")}
                </label>
                <div className="mb-3 w-full">
                  <input
                    type="email"
                    id="email"
                    className={emailCssClasses()}
                    placeholder={t("email")}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onBlur={() => handleOnBlurEmail(email)}
                  />
                  <div className="h-2">
                    {error === invalidEmail || error === noEmail ? (
                      <p className="top-4 left-0 text-textInput font-medium text-danger whitespace-nowrap truncate">
                        {t(error)}
                      </p>
                    ) : null}
                  </div>
                </div>
                <label
                  htmlFor="password"
                  className="text-black text-sm font-yantramanavLight text-left"
                >
                  {t("password")}
                </label>
                <div className="mb-6">
                  <div className={passwordCssClasses()}>
                    <input
                      type={showPassword ? "text" : "password"}
                      id="password"
                      className="bg-transparent grow outline-none"
                      placeholder={t("password")}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      onBlur={() => handleOnBlurPass(password)}
                    />
                    {showPassword ? (
                      <i
                        className="flex items-center px-2 cursor-pointer"
                        onClick={togglePassword}
                      >
                        <Icon key="closed" color="darkGray" size={16}>
                          universal_eyeclosed_regular
                        </Icon>
                      </i>
                    ) : (
                      <i
                        className="flex items-center px-2 cursor-pointer"
                        onClick={togglePassword}
                      >
                        <Icon key="open" color="darkGray" size={16}>
                          universal_eyeopen_regular
                        </Icon>
                      </i>
                    )}
                  </div>
                  <div className="h-2">
                    {error === noPassword ? (
                      <p className="top-4 left-0 text-textInput font-medium text-danger whitespace-nowrap truncate">
                        {t(error)}
                      </p>
                    ) : null}
                  </div>
                </div>
                {loading ? (
                  <div className="w-full flex justify-center">
                    <Loadingsymbol />
                  </div>
                ) : (
                  <Button
                    fill={true}
                    color="login"
                    text={t("log_in")}
                    enabled={true}
                    onClick={(e) => handleLogin(e, email, password)}
                  />
                )}
                <div className="flex justify-end items-center mt-4">
                  <p
                    onClick={() => setIsModalOpen(true)}
                    className="text-darkGray text-body font-yantramanavRegular text-left hover:cursor-pointer hover:text-black hover:font-yantramanavMedium"
                  >
                    {t("forgot_password")}
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>

        <Modal
          isOpen={isModalOpen}
          modalTitle="forgot_password"
          onClose={() => setIsModalOpen(false)}
        >
          <div className="h-[235px] w-[356px] flex flex-col items-center gap-[52px]">
            <div className="w-full h-[356px] flex flex-col items-center gap-2">
              <p className="text-primary text-[24px] text-center font-yantramanavRegular leading-[31.13px] h-[32px] w-[228px]">
                {t("forgot_password_title")}
              </p>
              <p className="text-[16px] leading-[18.4px] text-darkGray font-yantramanavLight tracking-[1.5%] text-center w-[356px] h-[36px]">
                {t("reset_password_instructions")}
              </p>
            </div>

            <div className="w-[222px] h-[107px] flex flex-col gap-6">
              <div>
                <label
                  htmlFor="email"
                  className="text-black text-[14px]  font-yantramanavLight leading-[18.16px] tracking-[0.5px]"
                >
                  {t("email")}
                </label>
                <div className={emailCssClasses()}>
                  <input
                    type="email"
                    placeholder={t("email")}
                    className="bg-transparent outline-none w-full"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onBlur={() => handleOnBlurEmail(email)}
                  />
                </div>
                <div className="h-3">
                  {error !== "" ? (
                    <p className="text-danger text-body font-yantramanavRegular">
                      {t(error)}
                    </p>
                  ) : null}
                </div>
              </div>
              <Button
                fill
                text={t("send_email")}
                color="white"
                icon="email"
                onClick={async () => {
                    // setMessage("not_yet_implemented")
                    // TODO: Fixc this!
                    await handleSendResetEmail();
                }}
                // enabled={enableButton()}
              />
            </div>

            <p className="text-body text-center text-primary font-yantramanavRegular">
              {t(message)}
            </p>
          </div>
        </Modal>

        {/* <div className="flex justify-end items-end w-full pb-9">
        <Footer />
      </div> */}
      </div>
    </motion.div>
  );
};
export default Login;
