import Dropzone from "react-dropzone";
import React, { useState, useEffect } from "react";
import { EXAMPLEPHOTOBOOKMODALSTRUCT } from "../../pages/Development/structures";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AnimatePresence, motion } from "framer-motion";
import { uploadImage, basicPatch } from "../../api/api";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ResponsiveModal from "../../components/molecules/ResponsiveModal";
import ImageGallery from "../../components/molecules/ImageGallery";
import Box from "../../components/molecules/Box";
import Icon from "../../components/atoms/Icon";

function PhotobookModal({
  photobook,
  marketData,
  edit,
  allTags,
  isModalOpen,
  setEdit,
  setIsModalOpen,
  setPhotobookData
}) {
  const [photos, setPhotos] = useState(photobook.main_images ?? []);
  const [patchData, setPatchData] = useState({});
  const queryClient = useQueryClient();
  const { marketId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    if (photobook) setPhotos(photobook.main_images);

    return () => {
      if (photos?.length > 0)
        photos.forEach((file) => URL.revokeObjectURL(file?.sd));
    };
  }, [photobook]);

  // TODO 240424 - Add market data and create the "initialValue" variable like in SingleItem.jsx
  async function patchPhoto(data, marketId) {
    const response = await basicPatch(
      `photobook?market_id=${marketId}&entry_id=${photobook.entry_id}`,
      data
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
  }

  useEffect(() => {
    if (isModalOpen) {
      setPatchData({});
    }
  }, [isModalOpen]);

  const photobookMutation = useMutation({
    mutationFn: ({ requestUrl, marketId, patchData }) =>
      patchPhoto(patchData, marketId, requestUrl),
    onError: (error) => {
      console.log("On error: ", error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["data", "photobook"]
      });
    },
    onSettled: () => {
      setPhotobookData((prev) => ({ ...prev, ...patchData }));
    }
  });

  const handleImageDrop = async (acceptedFiles) => {
    let newImage;
    if (acceptedFiles) {
      newImage = await uploadImage(acceptedFiles[0], marketId);
    }

    const newPhotosList = [newImage.file, ...photos];
    setPatchData((prev) => ({ ...prev, main_images: newPhotosList }));

    setPhotos((prev) => [
      ...acceptedFiles.map((file) => {
        Object.assign(file, {
          hd: URL.createObjectURL(file),
          sd: URL.createObjectURL(file)
        });
        return file;
      }),
      ...prev
    ]);
  };

  const handleUpdate = (e) => {
    setPatchData((prev) => ({ ...prev, ...e }));
  };

  const onSave = async () => {
    photobookMutation.mutate({
      requestUrl: `photobook?market_id=${marketId}&entry_id=${photobook?.entry_id}`,
      marketId,
      patchData: { ...patchData }
    });

    setEdit(false);
  };

  const removePhoto = (idx) => {
    let newPhotos = [...photos];
    newPhotos.splice(idx, 1);
    setPhotos(newPhotos);
    setPatchData((prev) => ({ ...prev, main_images: newPhotos }));
  };

  const discardChanges = () => {
    setPhotos(photobook.main_images);
    setPhotobookData(photobook);
    setEdit(false);
  };

  const initialValue = {
    ...photobook,
    location: {
      itemLocationData: photobook?.location ?? {},
      marketLocationData: marketData?.["location_structure"] ?? {}
    }
  };
  return (
    <ResponsiveModal
      modalTitle={t("photo_of") + " " + photobook?.title}
      isOpen={isModalOpen}
      onClose={() => {
        setEdit(false);
        setPhotobookData({});
        setIsModalOpen(false);
      }}
      editTabs={{ edit, setEdit, onSave, discardChanges }}
    >
      <div className="relative max-h-[636px] w-[717px]">
        <div className=" max-h-[736px] min-h-fit overflow-scroll p-4 pb-6 w-full">
          <div className="flex flex-col items-center  ">
            <ImageGallery images={photos?.map((image) => image?.hd) ?? []} />

            <AnimatePresence mode="wait">
              {edit ? (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{
                    opacity: 0,
                    transition: { duration: 0.05, ease: "easeInOut" }
                  }}
                  className="w-full p-2 h-full border border-lightGrey rounded-sm mt-4"
                >
                  <div className="flex w-full items-start flex-wrap max-w-full justify-between">
                    <div className="flex flex-row gap-3 flex-wrap w-3/4 ">
                      {[...photos].map((image, idx) => {
                        return (
                          <div key={idx} className="rounded-md w-24 relative">
                            <div
                              onClick={() => removePhoto(idx)}
                              className="absolute -top-2 -right-2 z-10 hover:cursor-pointer"
                            >
                              <Icon color={"error"} size={18}>
                                universal_close_filled
                              </Icon>
                            </div>
                            <img src={image?.sd} />
                          </div>
                        );
                      })}
                    </div>
                    <Dropzone
                      acceptedFiles={["image/jpg"]}
                      onDrop={handleImageDrop}
                    >
                      {({ getRootProps, getInputProps, isDragActive }) => (
                        <div
                          {...getRootProps()}
                          style={{
                            border: isDragActive ? "2px dashed gray" : null, // Dotted border when hovering with file
                            transition: "background-color 0.3s"
                          }}
                          className="h-7 p-2 min-w-fit bg-white flex items-center justify-items-end hover:cursor-pointer rounded border border-mediumGray"
                        >
                          <input {...getInputProps()} />
                          <Icon size={16} color="primary">
                            universal_camera_regular
                          </Icon>
                          <p className=" ml-2 font-yantramanavRegular text-textInput min-w-min">
                            {t("add_photo")}
                          </p>
                        </div>
                      )}
                    </Dropzone>
                  </div>
                </motion.div>
              ) : null}
            </AnimatePresence>
            <div className="mt-4 w-[680px] h-[50px]">
              {photobook
                ? EXAMPLEPHOTOBOOKMODALSTRUCT?.boxes.map((box, boxIndex) => (
                    <Box
                      key={
                        box.header +
                        Object.hasOwn(box, "isComponentStruct") +
                        box.items.length +
                        photobook.entry_id
                      }
                      box={box}
                      boxIndex={boxIndex}
                      initialValue={initialValue}
                      edit={edit}
                      onUpdate={handleUpdate}
                      market_id={marketId}
                      modal
                      tags={allTags}
                    />
                  ))
                : null}
            </div>
          </div>
        </div>
      </div>
    </ResponsiveModal>
  );
}

export default PhotobookModal;
