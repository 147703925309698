import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import CommentInput from "../atoms/CommentInput";
import TagPillDropdown from "./TagPillDropdown";
import Button from "./Button";
import Images from "./Images";

function ExtraImages({ initialValue, onChange, disabled = false }) {
  const [images, setImages] = useState([]);
  const { marketId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    const transformedImages =
      initialValue?.map((image) => ({
        extra_images: image.extra_images || [],
        extra_images_tags: image.extra_images_tags || [],
        extra_images_comment: image.extra_images_comment || ""
      })) ?? [];
    setImages(transformedImages);
  }, [initialValue]);

  const allTags = images
    .flatMap((image) => image.extra_images_tags)
    .reduce((acc, tag) => {
      acc[tag] = true;
      return acc;
    }, {});

  const handleTagChange = (newTags, index) => {
    setImages((prevImages) => {
      const updatedImages = prevImages.map((img, idx) =>
        idx === index ? { ...img, extra_images_tags: newTags } : img
      );
      onChange(updatedImages);
      return updatedImages;
    });
  };

  const handleImagesChange = (newImages, index) => {
    setImages((prevImages) => {
      const updatedImages = prevImages.map((img, idx) =>
        idx === index ? { ...img, extra_images: newImages } : img
      );
      onChange(updatedImages);
      return updatedImages;
    });
  };

  const addExtraImages = () => {
    const newImage = { extra_images: [] };
    const newImages = [...images, newImage];
    setImages(newImages);
    onChange(
      newImages.map((i) => ({
        extra_images: i.extra_images,
        extra_images_tags: i.extra_images_tags,
        extra_images_comment: i.extra_images_comment
      }))
    );
  };

  return (
    <div className="flex flex-col">
      {images?.map((image, index) => (
        <div
          key={index}
          className={`${disabled ? " " : ""} flex flex-col mb-8`}
        >
          <Images
            images={image.extra_images}
            onChange={(newImages) => handleImagesChange(newImages, index)}
            disabled={disabled}
            market_id={marketId}
          />
          <div className="flex flex-col w-full -mt-4">
            <div className="gap-0.5 w-full flex flex-col">
              <div className="w-full flex flex-col gap-0.5">
                <p className="text-black text-[14px] leading-[18.16px] font-yantramanavLight text-left h-[19px] truncate whitespace-nowrap">
                  {t("image_comment")}
                </p>
                <CommentInput
                  initialValue={image.extra_images_comment}
                  onChange={(newComment) => {
                    setImages((prevImages) => {
                      const updatedImages = prevImages.map((img, idx) =>
                        idx === index
                          ? { ...img, extra_images_comment: newComment }
                          : img
                      );
                      onChange(updatedImages);
                      return updatedImages;
                    });
                  }}
                  disabled={disabled}
                />
              </div>
              <p className="text-black text-[14px] leading-[18.16px] font-yantramanavLight text-left h-[19px] truncate whitespace-nowrap">
                {t("image_tags")}
              </p>
              <div className="flex flex-row gap-x-1">
                <TagPillDropdown
                  initialValue={image.extra_images_tags}
                  allTags={allTags} // Pass allTags as an object
                  onChange={(newTags) => handleTagChange(newTags, index)}
                  disabled={disabled}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
      {!disabled && (
        <div className={`${images.length > 0 ? "-mt-5" : "mt-2"}`}>
          <Button text="add_extra_image" onClick={addExtraImages} />
        </div>
      )}
    </div>
  );
}

export default ExtraImages;
