import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CommentInput from "../atoms/CommentInput";
import HoverText from "../atoms/HoverText";
import TextInput from "../atoms/TextInput";
import Icon from "../atoms/Icon";
import Button from "./Button";
import { useComponentVisibleHover } from "../../utils/UtilsViewFunctions";

function ArbitraryKeys({ initialValue, onChange, disabled = false }) {
  const [keyValuePairs, setKeyValuePairs] = useState([]);
  const { ref, isComponentVisible } = useComponentVisibleHover(false);

  const { t } = useTranslation();

  useEffect(() => {
    const transformedPairs =
      initialValue?.map((item) => ({
        key_name: item.arbitrary_key?.key_name || "",
        key_value: item.arbitrary_key?.key_value || "",
        comment: item.arbitrary_key_value_comment || "",
        id: item.arbitrary_key?.id || Date.now()
      })) ?? [];
    setKeyValuePairs(transformedPairs);
  }, [initialValue]);

  const handleInputChange = (index, key, value) => {
    setKeyValuePairs((prevPairs) => {
      if (index >= 0 && index < prevPairs.length) {
        const updatedPairs = prevPairs.map((pair, idx) =>
          idx === index ? { ...pair, [key]: value } : pair
        );

        onChange(
          updatedPairs.map((pair) => ({
            arbitrary_key: {
              key_name: pair.key_name,
              key_value: pair.key_value,
              id: pair.id
            },
            arbitrary_key_value_comment: pair.comment
          }))
        );

        return updatedPairs;
      } else {
        return prevPairs;
      }
    });
  };

  const addNewKeyValuePair = () => {
    const newPair = {
      key_name: "",
      key_value: "",
      comment: "",
      id: Date.now()
    };
    const newPairs = [...keyValuePairs, newPair];
    setKeyValuePairs(newPairs);
    onChange(
      newPairs.map((pair) => ({
        arbitrary_key: {
          key_name: pair.key_name,
          key_value: pair.key_value,
          id: pair.id
        },
        arbitrary_key_value_comment: pair.comment
      }))
    );
  };

  const removeKeyValuePair = (index) => {
    if (index >= 0 && index < keyValuePairs.length) {
      const updatedPairs = keyValuePairs.filter((_, idx) => idx !== index);
      setKeyValuePairs(updatedPairs);
      onChange(
        updatedPairs.map((pair) => ({
          arbitrary_key: {
            key_name: pair.key_name,
            key_value: pair.key_value,
            id: pair.id
          },
          arbitrary_key_value_comment: pair.comment
        }))
      );
    }
  };
  return (
    <div className="w-full h-full flex flex-col gap-5 mb-8">
      {keyValuePairs.map((pair, index) => (
        <div key={index} className="flex flex-col gap-1.5 shadow">
          <div className="flex flex-row justify-center rounded-sm items-center h-12 px-2 pt-4 pb-2 relative">
            <div className="flex flex-col w-full gap-0.5 mb-3">
              <div className="flex-grow" ref={ref}>
                {isComponentVisible &&
                disabled &&
                pair?.key_name.length >= 20 ? (
                  <HoverText text={pair?.key_name} />
                ) : null}
                <input
                  className={`${
                    disabled
                      ? "font-yantramanavLight text-[14px] text-darkGray border-b-transparent"
                      : "font-yantramanavRegular text-black text-textInput border-b-darkGray "
                  }  bg-white border-b w-full h-5`}
                  value={pair?.key_name}
                  onChange={(e) =>
                    handleInputChange(index, "key_name", e.target.value)
                  }
                  placeholder={disabled ? "" : "enter_key_name"}
                  disabled={disabled}
                />
              </div>
              <div className="flex-grow">
                <TextInput
                  initialValue={pair?.key_value}
                  disabled={disabled}
                  placeholder={disabled ? "" : "enter_key_value"}
                  onChange={(newValue) =>
                    handleInputChange(index, "key_value", newValue)
                  }
                />
              </div>
            </div>
            {!disabled ? (
              <button
                onClick={() => removeKeyValuePair(index)}
                className="absolute -top-0.5 -left-1 h-3 w-3 bg-white rounded-full flex items-center justify-center hover:opacity-90"
              >
                <Icon size={14} color="danger">
                  universal_close_filled
                </Icon>
              </button>
            ) : null}
          </div>
          <div className="px-1.5">
            {disabled ? (
              <div className="pb-1">
                <p className="text-black text-[14px] leading-[18.16px] font-yantramanavLight text-left h-[19px] truncate whitespace-nowrap">
                  {t("arbitrary_key_comment")}
                </p>
                {/* <textarea
                  rows={3}
                  value={pair?.comment}
                  readOnly
                  className="w-full font-yantramanavRegular text-textInput outline-none border-0"
                ></textarea> */}
                <p className="text-black text-textInput font-yantramanavRegular text-left max-h-[50px] hover:overflow-y-scroll overflow-hidden no-x-scrollbar">
                  {pair?.comment}
                </p>
              </div>
            ) : (
              <div>
                <CommentInput
                  initialValue={pair?.comment}
                  disabled={disabled}
                  onChange={(newVal) =>
                    handleInputChange(index, "comment", newVal)
                  }
                />
              </div>
            )}
          </div>
        </div>
      ))}

      {!disabled && (
        <div className={`${keyValuePairs.length > 0 ? "-mt-2" : ""}`}>
          <Button text="add_arbitrary_key" onClick={addNewKeyValuePair} />
        </div>
      )}
    </div>
  );
}

export default ArbitraryKeys;
