import { useEffect, useState } from "react"
import { realToElementPX } from "./helper";

export default function PolygonArea(props) {
    const [polygonPoints, setPolygonPoints] = useState("");

    useEffect(() => {
        props.locationArea.points.forEach(point => {
            let elemPoint = realToElementPX(point, props.parentElementSize, props.imageSize);
            setPolygonPoints(prevPointString => prevPointString += ` ${elemPoint.x},${elemPoint.y}`);
        });
    }, [])

    return (
        <svg
            className="pointer-events-none ml-4 mt-4"
            height={props.parentElementSize.height}
            width={props.parentElementSize.width}
            viewBox={`0 0 ${props.parentElementSize.width} ${props.parentElementSize.height}`}
            style={{ position: 'absolute', top: 0, left: 0, zIndex: 20 }}
        >
            <polygon
                points={polygonPoints}
                fill={props.locationArea.color}
                className=" text-gray500"
                stroke="gray"
                strokeWidth={0.2}
                fillOpacity={0.2}
            />
        </svg>
    )
}