import React from 'react'
import Icon from '../atoms/Icon'
import { useTranslation } from "react-i18next";

function Tab({ style, icon, text, active, enabled = true, onClick }) {
  const { t } = useTranslation();

  const styleObject = {
    hugBlue: `rounded-b shadow-[rgba(203,204,213,0.5)inset_1px_1px_4px_0px] ${
      enabled
        ? "bg-lightBlue active:bg-deepBlue hover:bg-mediumBlue text-primary "
        : " text-mediumGray bg-lightGray "
    }`,
    hugGreen: `rounded-b shadow-[rgba(203,204,213,0.5)inset_1px_1px_4px_0px] ${
      enabled
        ? "bg-lightGreen hover:bg-mediumGreen text-success active:bg-deepGreen"
        : " text-mediumGray bg-lightGray "
    }`,
    fixWhite: `w-[100px] overflow-hidden truncate border-mediumGray rounded-t border-t border-l border-r ${
      enabled
        ? active
          ? " bg-lightBlue text-primary active:bg-mediumBlue "
          : " bg-white active:bg-mediumBlue text-black hover:bg-lightBlue hover:text-primary "
        : " text-mediumGray bg-lightGray "
    }`,
    dashboard: `transition-all duration-75 delay-0 h-7 w-fit truncate rounded-t border-t border-l border-r ${
      enabled
        ? active
          ? " h-[34px] pb-[5px] bg-whiteTint/75 text-primary border-whiteTint backdrop-blur-md "
          : " bg-darkWhiteTint/75 border-darkWhiteTint active:bg-whiteTint/75 text-black  hover:h-[34px] hover:pb-[5px] "
        : " text-mediumGray bg-lightGray border-lightGray "
    }`
  };

  const colorIcon = {
    fixWhite: "primary",
    hugBlue: "primary",
    hugGreen: "success"
  };

  const defStyle = `h-7 truncate font-yantramanavRegular px-2 text-textInput flex flex-row items-center justify-center transition ease-in delay-75 ${
    style ? styleObject[style] : styleObject["fixWhite"]
  }`;

  return (
    <div className="relative">
      <div
        className={
          style === "fixWhite" || style === "dashboard"
            ? ""
            : "rounded-b shadow"
        }
      >
        <button
          className={defStyle}
          enabled={enabled.toString()}
          onClick={onClick}
        >
          {icon && (
            <div className="flex items-center">
              <Icon
                color={
                  enabled
                    ? style
                      ? colorIcon[style]
                      : colorIcon["fixWhite"]
                    : "mediumGray"
                }
                size={16}
              >
                {icon}
              </Icon>
              <div className="mr-2" />
            </div>
          )}
          <p className="truncate">{t(text)}</p>
        </button>
      </div>
    </div>
  );
}

export default Tab;