// Approved and donde
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import HoverText from "../atoms/HoverText";
import { useComponentVisibleHover } from "../../utils/UtilsViewFunctions"

const TextInput = ({
  initialValue = "",
  placeholder,
  onChange,
  disabled = false,
  errorMessage = "",
  onBlur
}) => {
  const { t } = useTranslation();

  const [value, setValue] = useState(initialValue);

  const { ref, isComponentVisible /*, setIsComponentVisible */ } =
    useComponentVisibleHover(false);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    onChange(newValue);
  };

  const inputCssClasses = () => {
    const def =
      "absolute h-4 font-yantramanavRegular text-textInput outline-none w-full truncate border-b ";
    if (disabled)
      return def + "border-b-transparent mb-5 disabled:bg-transparent";
    if (errorMessage !== "")
      return def + " border-b-danger focus:border-b-danger text-danger";
    return def + " mb-5 text-black  border-b-darkGray focus:border-b-darkGray ";
  };

  return (
    <div className="relative h-4">
      <input
        ref={ref}
        className={inputCssClasses()}
        value={value}
        onChange={handleChange}
        placeholder={disabled ? "" : t(placeholder)}
        disabled={disabled}
        onBlur={onBlur}
      />
      {errorMessage !== "" && !disabled ? (
        <p className="absolute top-4 left-0 text-textInput font-medium text-danger">
          {t(errorMessage)}
        </p>
      ) : null}
      {isComponentVisible && disabled && t(value).length >= 20 ? (
        <HoverText text={t(value)} />
      ) : null}
    </div>
  );
};

export default TextInput;

