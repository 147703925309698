import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { set } from "lodash";
import { basicPatch, fetchData } from "../../../api/api";
import Loadingsymbol from "../../../components/atoms/Loadingsymbol";
import EditTabs from "../../../components/molecules/EditTabs";
import Box from "../../../components/molecules/Box";
import UnderConstruction from "../../../components/molecules/UnderConstruction";
import NoPage from "../../../pages/NoPage";
import Button from "../../../components/molecules/Button";
import ItemsHeader from "../ItemsHeader";
import SearchInput from "../../../components/atoms/SearchInput";
import MissionsTab from "./MissionsTab";
import IssuesTab from "./IssuesTab";

async function patchItem(itemData, marketId, itemId) {
  const response = await basicPatch(
    `items?market_id=${marketId}&item_id=${itemId}`,
    itemData
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response;
}

const tabs = [
  "tab_general",
  "tab_service_work",
  "tab_damage_report",
  "tab_analytics"
];

export default function SingleItem() {
  const { t } = useTranslation();
  const [patchData, setPatchData] = useState({});
  const [edit, setEdit] = useState(false);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const { marketId, itemId } = useParams();
  const queryClient = useQueryClient();

  const itemMutation = useMutation({
    mutationFn: ({ patchData: itemData, marketId, itemId }) =>
      patchItem(itemData, marketId, itemId),
    onError: (error) => {
      console.log("On error: ", error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [marketId, "data", "item", itemId]
      });
      setPatchData({});
    }
  });

  const {
    data: itemData,
    isPending: itemDataPending,
    isError: itemDataError
  } = useQuery({
    queryKey: [
      marketId,
      "data",
      "item",
      itemId,
      { url: `items?market_id=${marketId}&item_id=${itemId}` }
    ],
    queryFn: fetchData
  });

  const itemDataExists = itemData?.item_id;
  const {
    data: itemStruct,
    isPending: itemStructPending,
    isError: itemStructError
  } = useQuery({
    queryKey: [
      marketId,
      "struct",
      "item",
      itemId,
      {
        url: `structures?market_id=${marketId}&app=sa&structure_type=inventory&inventory_type=${itemData?.type}`
      }
    ],
    queryFn: fetchData,
    enabled: !!itemDataExists
  }); // "enabled" => Get the struct once data exists, we need the type

  const {
    data: marketData,
    isPending: marketDataPending,
    isError: marketDataError
  } = useQuery({
    queryKey: [
      marketId,
      "data",
      "market",
      { url: `markets?market_id=${marketId}` }
    ],
    queryFn: fetchData,
    staleTime: 10 * 1000
  });

  useEffect(() => {
    if (!itemDataPending) {
      setEdit(false);
    }
  }, [itemData]);

  if (
    itemDataError ||
    marketDataError ||
    itemStructError ||
    (itemData && Object.keys(itemData).length === 0)
  ) {
    return <NoPage />;
  }

  if (
    itemDataPending ||
    itemStructPending ||
    itemMutation.isPending ||
    marketDataPending
  ) {
    return (
      <div className="w-full">
        <div className="flex h-[46px] border-r border-b px-8 w-full"></div>{" "}
        {/** Empty header */}
        <div className="flex flex-col w-full items-center mt-8">
          <Loadingsymbol />
          <p className="text-body font-yantramanaRegular text-primary">
            {t("loading")}...
          </p>
        </div>
      </div>
    );
  }

  const handleUpdate = (e) => {
    if (Object.keys(e)[0].includes("components[")) {
      setPatchData((prev) => {
        if (!prev.components) {
          prev.components = JSON.parse(JSON.stringify(itemData.components));
        }
        const newState = JSON.parse(JSON.stringify(prev));
        set(newState, Object.keys(e)[0], Object.values(e)[0]);

        if (JSON.stringify(prev) !== JSON.stringify(newState)) {
          return newState;
        }
        return prev;
      });
    } else {
      setPatchData((prev) => {
        const newState = { ...prev, ...e };
        if (JSON.stringify(prev) !== JSON.stringify(newState)) {
          return newState;
        }
        return prev;
      });
    }
  };

  // Did not work with arbitrary keys
  // const handleUpdate = (e) => {
  //   if (Object.keys(e)[0].includes("components[")) {
  //     setPatchData((prev) => {
  //       if (!prev.components) {
  //         prev.components = JSON.parse(JSON.stringify(itemData.components));
  //       }
  //       set(prev, Object.keys(e)[0], Object.values(e)[0]);
  //       return prev;
  //     });
  //   } else {
  //     setPatchData((prev) => ({ ...prev, ...e }));
  //   }
  // };

  const handleSave = () => {
    if (Object.keys(patchData).length > 0) {
      itemMutation.mutate({ patchData, marketId, itemId });
    } else {
      setEdit(false);
    }
  };

  const handleDiscard = () => {
    setEdit(false);
    setPatchData({});
  };

  const initialValue = {
    ...itemData,
    location: {
      itemLocationData: itemData?.location ?? {},
      marketLocationData: marketData?.["location_structure"] ?? {}
    }
  };

  return (
    <div className="h-full">
      <ItemsHeader
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        tabs={tabs}
      />
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          transition={{ ease: "easeIn", duration: 0.1 }}
          animate={{ opacity: 1 }}
          exit={{
            opacity: 0,
            transition: { duration: 0.1, ease: "easeInOut" }
          }}
          key={itemId}
          className="relative overflow-scroll h-full no-scrollbar w-full shadow-[rgba(203,204,213,0.5)inset_1px_1px_4px_0px]"
        >
          {selectedTab === "tab_general" ? (
            <>
              <EditTabs
                edit={edit}
                setEdit={setEdit}
                handleSave={handleSave}
                handleDiscard={handleDiscard}
              />
              <div className={"w-full p-8 h-[calc(100vh - 149px)]"}>
                {Object.keys(itemData).length > 0 ? (
                  itemStruct.boxes?.map((box, boxIndex) => (
                    <Box
                      key={
                        box.header +
                        Object.hasOwn(box, "isComponentStruct") +
                        box.items.length
                      }
                      box={box}
                      boxIndex={boxIndex}
                      initialValue={initialValue}
                      edit={edit}
                      onUpdate={handleUpdate}
                      market_id={marketId}
                    />
                  ))
                ) : itemStruct && Object.keys(itemData).length <= 0 ? (
                  <UnderConstruction />
                ) : null}

                {!itemStruct ? <UnderConstruction /> : null}
              </div>
            </>
          ) : null}
          {selectedTab === "tab_service_work" ? <MissionsTab /> : null}
          {selectedTab === "tab_damage_report" ? <IssuesTab /> : null}
          {/* {selectedTab === "tab_analytics" ? <UnderConstruction /> : null} */}
          {!["tab_service_work", "tab_general", "tab_damage_report"].includes(
            selectedTab
          ) ? (
            <div>
              <div className="h-[46px] border-r border-b border-l overflow-hidden flex justify-between items-center px-8 mb-10 ">
                {/* <SearchInput
                  onChange={(e) => undefined}
                  placeholder={t("search")}
                /> */}
                <div></div>
                <div className="justify-items-end flex gap-4">
                  <Button
                    text={t("export_excel")}
                    icon="universal_export_regular"
                    enabled={false}
                    onClick={() => {
                      return undefined;
                    }}
                  />
                  <Button
                    text={t("customize_columns")}
                    icon="other_customize_regular"
                    enabled={false}
                    onClick={() => console.log("costumize_columns")}
                  />
                </div>
              </div>
              <UnderConstruction />
            </div>
          ) : null}
        </motion.div>
      </AnimatePresence>
    </div>
  );
}
