// useUpdateNavigationState.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useNavigationStore from './NavigationStore';

const useUpdateNavigationState = () => {
  const location = useLocation();
  const setNavigationState = useNavigationStore((state) => state.setNavigationState);
  const currentPath = useNavigationStore((state) => state.navigationState.to);

  useEffect(() => {
    // Update the state with the previous and current paths
    setNavigationState(currentPath, location.pathname);
  }, [location]); // Depend on location to trigger this effect
};

export default useUpdateNavigationState;
