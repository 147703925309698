import Loadingsymbol from "../../atoms/Loadingsymbol";
import CommentInput from "../../atoms/CommentInput";
import ThumbnailCard from "../ThumbnailCard";
import TextInput from "../../atoms/TextInput";
import DateInput from "../../atoms/DateInput";
import Icon from "../../atoms/Icon";
import Button from "../Button";
import { useState, useEffect } from "react";
import { stickers, stickerMap, imageMap } from "./stickerConfig";
import { useTranslation } from "react-i18next";
import { uploadImage } from "../../../api/api";
import { useParams } from "react-router-dom";
import Dropzone from "react-dropzone";

function NewStickerForm({ isOpen, onAdd, closeModal }) {
  const [idType, setIdType] = useState("");
  const [formFields, setFormFields] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [imageFile, setImageFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const { marketId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    if (!isOpen) {
      clearModal();
    }
  }, [isOpen]);

  const handleStickerTypeClick = (sticker) => {
    setIdType(sticker);
    const fields = stickerMap.get(sticker) || [];
    setFormFields(fields);
    const initialValues = fields.reduce((acc, field) => {
      acc[field.key] = "";
      return acc;
    }, {});
    setFormValues(initialValues);
  };

  const handleInputChange = (key, value) => {
    setFormValues((prev) => ({ ...prev, [key]: value }));
  };

  const handleImageDrop = async (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setImageFile(acceptedFiles[0]);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    let image = null;
    if (imageFile) {
      const uploadedImage = await uploadImage(imageFile, marketId);
      image = uploadedImage.file;
    }
    const newSticker = {
      "id_type": idType,
      ...formValues,
      image
    };
    setLoading(false);
    onAdd(newSticker);
    clearModal();
  };

  const clearModal = () => {
    setIdType("");
    setFormFields([]);
    setFormValues({});
    setImageFile(null);
  };

  return (
    <div className="flex flex-col gap-8 p-8 w-[650px] h-[310px]">
      <div className="flex flex-col flex-grow">
        {!idType ? (
          <div className="w-full flex-row flex flex-wrap gap-5 gap-x-4">
            {stickers.map((sticker, index) => (
              <div key={index} onClick={() => handleStickerTypeClick(sticker)}>
                <ThumbnailCard title={t(sticker)} image={imageMap[sticker]} />
              </div>
            ))}
          </div>
        ) : (
          <div className="w-full flex flex-row justify-between gap-8">
            <div className="w-1/2 flex flex-col">
              {formFields.map(({ key, type }) => (
                <div
                  key={key}
                  className="mb-4 flex flex-row w-full justify-between"
                >
                  <div className="flex flex-col w-full">
                    <p className="text-inputTitle font-yantramanavLight">
                      {key === "image_input" && idType ? t(idType) : t(key)}
                    </p>
                    {type === "string" && (
                      <TextInput
                        initialValue={formValues[key]}
                        onChange={(value) => handleInputChange(key, value)}
                        placeholder={key === "image_input" ? t(idType) : t(key)}
                      />
                    )}
                    {type === "date" && (
                      <DateInput
                        initialValue={formValues[key]}
                        onChange={(value) => handleInputChange(key, value)}
                      />
                    )}
                    {type === "text" && (
                      <CommentInput
                        initialValue={formValues[key]}
                        onChange={(value) => handleInputChange(key, value)}
                        placeholder={t(key)}
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div className="flex flex-col w-1/2 h-[140px] relative">
              <p className="text-inputTitle font-yantramanavLight">
                {t("image")}
              </p>
              <Dropzone acceptedFiles={["image/jpg"]} onDrop={handleImageDrop}>
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <div
                    {...getRootProps()}
                    style={{
                      border: isDragActive
                        ? "2px dashed gray"
                        : "2px dashed #ECEDF0",
                      padding: "20px",
                      textAlign: "center",
                      cursor: "pointer",
                      width: "100%",
                      height: "100%",
                      overflow: "hidden"
                    }}
                  >
                    {imageFile ? (
                      <p>{imageFile.name}</p>
                    ) : (
                      <div>
                        <p>{t("drag_and_drop_image")}</p>

                        <div
                          {...getRootProps()}
                          style={{
                            border: isDragActive ? "2px dashed gray" : null,
                            transition: "background-color 0.3s"
                          }}
                          className="absolute bottom-1 right-1 h-7 p-2 min-w-fit bg-white flex items-center justify-items-end hover:cursor-pointer rounded border border-mediumGray"
                        >
                          <input {...getInputProps()} />
                          <Icon size={16} color="primary">
                            universal_camera_regular
                          </Icon>
                          <p className=" ml-2 font-yantramanavRegular text-textInput min-w-min">
                            {t("add_photo")}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </Dropzone>
            </div>
          </div>
        )}
      </div>

      {loading ? (
        <div className="w-full flex justify-end">
          <Loadingsymbol />
        </div>
      ) : idType ? (
        <div className="flex justify-between">
          <Button
            text="go_back"
            icon="direction_arrow_left_regular"
            onClick={clearModal}
          />
          <div className="flex flex-row gap-4">
            <Button
              text={t("cancel")}
              onClick={() => {
                closeModal();
                clearModal();
              }}
            />
            <Button
              text={t("add_external_sticker_id")}
              disabled={!formValues.image_input || loading}
              onClick={handleSubmit}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default NewStickerForm;
