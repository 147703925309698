// #region Imports
import { useTranslation } from "react-i18next";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
// #endregion

const PieCharts = ({ graphData, rawData, type, languageCode }) => {
  // #region Variables
  const { t } = useTranslation();

  const dataKeyTranslations = {
    refrigeration: t("refrigeration"),
    lighting: t("lighting"),
    other: t("other"),
  };

  const colorMap = {
    refrigeration: "#76B1D9",
    lighting: "#ebbd32",
    other: "#787878",
  };
  // #endregion

  const totalValue = graphData.reduce((sum, entry) => sum + entry.data, 0);
  const renderLabel = ({ cx, cy, midAngle, outerRadius, value }) => {
    const radius = outerRadius - 70;
    const x = cx + radius * Math.cos((-midAngle * Math.PI) / 180);
    const y = cy + radius * Math.sin((-midAngle * Math.PI) / 180);

    const percentage = ((value / totalValue) * 100).toFixed(1);

    return (
      <text
        x={x}
        y={y}
        fill="#fff"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        fontSize={15}
      >
        {percentage}%
      </text>
    );
  };

  // region PieChart
  const graph = () => {
    return (
      <ResponsiveContainer width="100%" height={350}>
        <PieChart
          data={graphData}
          margin={{ top: 0, right: 15, left: 7, bottom: 40 }}
        >
          <Pie
            data={graphData}
            dataKey="data"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={150}
            fill="#8884d8"
            labelLine={false}
            label={renderLabel}
          >
            {graphData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colorMap[entry.name]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    );
  };

  // #region Return
  return (
    <section className="flex flex-col items-center p-4 space-y-4 bg-white shadow rounded-lg">
      <div className="ml-3 w-[96%]" data-html2canvas-ignore="true">
        <h5 className="text-categoryTitle font-yantramanavBold">
          <p>{t(type)}</p>
        </h5>
      </div>
      <div className="flex justify-end w-[96%] items-end my-1 mx-auto"></div>
      <div className="flex w-full justify-center relative">
        <div className="my-2 absolute top-0 right-8 p-2">
          {graphData.map((entry) => (
            <div key={entry.name} className="flex items-center pt-2">
              <div
                className="w-4 h-4 mr-2 rounded-full"
                style={{ backgroundColor: colorMap[entry.name] }}
              ></div>
              <span>{t(entry.name)}</span>
            </div>
          ))}
        </div>
        {graph()}
      </div>
      <div
        className="export-graph-row flex justify-end w-full"
        data-html2canvas-ignore="true"
      ></div>
    </section>
  );
};
// #endregion

export default PieCharts;
