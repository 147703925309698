import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { apiGet, basicPatch, fetchData } from "../../api/api";
import ResponsibilityItem from "./ResponsibilityItem";
import ResponsibilityItemGroup from "./ResponsibilityItemGroup";
import ResponsibilityContacts from "./ResponsibilityContacts";
import ResponsibilityHeader from "./ResponsibilityHeader";
import Loadingsymbol from "../../components/atoms/Loadingsymbol";
import ResponsibilityTabs from "./ResponsibilityTabs";
import EditTabs from "../../components/molecules/EditTabs";

const TABS = ["tab_responsibility", "tab_companies"];

async function patchRequest(url, data) {
  const response = await basicPatch(url, data);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response;
}

export default function Responsibility() {
  const { t } = useTranslation();
  const { marketId } = useParams(); // Get market id
  const queryClient = useQueryClient();

  const {
    data: marketData,
    isPending: marketDataPending,
    isError: marketDataError
  } = useQuery({
    queryKey: [marketId, "data", { url: `markets?market_id=${marketId}` }],
    queryFn: fetchData
  }); // Get the data

  const { data: itemsData, isPending: itemsDataPending } = useQuery({
    queryKey: [
      marketId,
      "data",
      "items",
      { url: `items?market_id=${marketId}` }
    ],
    queryFn: fetchData
  }); // Get the data

  const marketMutation = useMutation({
    mutationFn: ({ marketId, patchData: marketData }) =>
      patchRequest(`markets?market_id=${marketId}`, marketData),
    onError: (error) => console.log("On error: ", error),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [marketId, "data"] })
  });

  const itemMutation = useMutation({
    mutationFn: ({ marketId, itemId, patchData: itemData }) =>
      patchRequest(`items?market_id=${marketId}&item_id=${itemId}`, itemData),
    onError: (error) => console.log("On error: ", error),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [marketId, "data", "items"] })
  });

  const [jsonObject, setJsonObject] = useState([]);
  const [initialJsonObject, setInitialJsonObject] = useState([]);
  const [ogJsonObject, setOgJsonObject] = useState([]);
  const [items, setItems] = useState([]);
  const [selectedTab, setSelectedTab] = useState("tab_responsibility");
  const [localMarketData, setLocalMarketData] = useState(null);
  const [edit, setEdit] = useState(false);
  const [patchData, setPatchData] = useState({});
  const [struct, setStruct] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [openMains, setOpenMains] = useState([]);
  const [openGroup, setOpenGroup] = useState([]);
  const [companies, setCompanies] = useState([]);
  const groupOrder = [
    "item_group_food_preparation",
    "item_group_heating",
    "item_group_ventilation",
    "item_group_cooling",
    "item_group_refrigeration",
    "item_group_staff_facilities",
    "item_group_other",
    "item_group_energy_generation",
    "item_group_plumbing",
    "item_group_lighting",
    "item_group_no_match"
  ];

  useEffect(() => {
    if (marketData && !marketDataPending && !marketDataError) {
      setCompanies(marketData.companies ?? []);
      setLocalMarketData(marketData);
      setStruct(
        marketData.responsibility_template ?? {
          repairs: {
            subtenant: ["repair", "subtenant"],
            merchant: ["repair", "owner"],
            landlord: ["repair","landlord"]
          },
          maintenance: {
            subtenant: ["maintenance","subtenant"],
            merchant: ["maintenance", "owner"],
            landlord: ["maintenance","landlord"]
          },
          owner: {
            subtenant: ["subtenant", "tenant"],
            merchant: ["owner"],
            landlord: ["landlord"]
          }
        }
      );
    }
  }, [marketData, marketDataPending, marketDataError]);

  useEffect(() => {
    if (itemsData && !itemsDataPending) {
      setItems(itemsData);
    }
  }, [itemsData, itemsDataPending]);

  useEffect(() => {
    createJsonObject();
    if (localMarketData && !localMarketData.responsibility) {
      setLocalMarketData((prev) => {
        let temp = structuredClone(prev);
        temp.responsibility = {};
        return temp;
      });
    }
  }, [items]);

  useEffect(() => {
    if (jsonObject.length > 0) {
      onUpdate({ local_only_responsibility: jsonObject });
    }
  }, [jsonObject]);

  useEffect(() => {
    if (companies.length > 0) {
      onUpdate({ companies: companies });
    }
  }, [companies]);

  const createJsonObject = () => {
    let temp = [];
    items.forEach((item) => {
      if (!item.responsibility_group) {
        // temp.push({ ...item, responsibility_group: "item_group_no_match" });
      } else {
        temp.push(item);
      }
    });
    // todo sort?
    setOgJsonObject(temp);
    setJsonObject(temp);
    setInitialJsonObject(temp);
  };

  const filterEmptyCompanies = (companies) => {
    return companies.filter((val) => Object.keys(val).length !== 0);
  };

  const saveResponsibility = async (specialSave = false) => {
    setLoading(true);

    const filteredCompanies = filterEmptyCompanies(companies);
    setCompanies(filteredCompanies);

    setPatchData((prevPatchData) => ({
      ...prevPatchData,
      companies: filteredCompanies
    }));

    switch (specialSave) {
      case "tab_responsibility":
        if (patchData.local_only_responsibility) {
          let marketPush = { responsibility: patchData.responsibility };
          await marketMutation.mutateAsync({ marketId, patchData: marketPush });
          for (let i = 0; i < patchData.local_only_responsibility.length; i++) {
            setLoadingText(
              ` ${i + 1}/${patchData.local_only_responsibility.length}`
            );
            let item = patchData.local_only_responsibility[i];
            let itemPush = {
              categories: item.categories,
              responsibility_group: item.responsibility_group
            };

            if (item.responsibility) {
              itemPush.responsibility = JSON.parse(
                JSON.stringify(item.responsibility)
              );
            }

            await itemMutation.mutateAsync({
              marketId,
              itemId: item.item_id,
              patchData: itemPush
            });
          }
        }
        setLoadingText("");
        setEdit(false);
        break;
      case "tab_companies":
        if (patchData.companies) {
          marketMutation.mutate({
            marketId,
            patchData: {
              companies: JSON.parse(JSON.stringify(filteredCompanies))
            }
          });
        }
        setEdit(false);
        break;
      default:
        setEdit(false);
        break;
    }
    setLoading(false);
  };

  const handleDiscard = () => {
    setCompanies(marketData?.companies ?? []);
    setJsonObject(initialJsonObject);
    setLocalMarketData(marketData);
    setPatchData({});
    setEdit(false);
  };

  const onUpdate = (e) => {
    setPatchData((prev) => ({ ...prev, ...e }));
  };

  const onUpdateItem = (data) => {
    setJsonObject((prev) =>
      prev.map((i) => (i.item_id === data.item_id ? data : i))
    );
    setPatchData((prev) => {
      const newPatchData = { ...prev };
      if (!newPatchData.local_only_responsibility) {
        newPatchData.local_only_responsibility = [];
      }
      newPatchData.local_only_responsibility =
        newPatchData.local_only_responsibility.filter(
          (item) => item.item_id !== data.item_id
        );
      newPatchData.local_only_responsibility.push(data);
      return newPatchData;
    });
  };

  const isOldGroupSameAsItemsCheckbox = (oldMarketData, itemData) => {
    if (!itemData || typeof itemData !== "object") return true;
    if (!oldMarketData || typeof oldMarketData !== "object") return true;

    if (Object.keys(itemData).every((resp) => itemData[resp].length === 0))
      return true;

    if (
      Object.keys(oldMarketData).every(
        (resp_area) => !itemData[resp_area] || itemData[resp_area].length === 0
      )
    ) {
      return true;
    }

    return Object.keys(oldMarketData).every(
      (resp_area) =>
        oldMarketData[resp_area].every((oldData) =>
          itemData[resp_area].some(
            (itemData) => JSON.stringify(oldData) === JSON.stringify(itemData)
          )
        ) &&
        itemData[resp_area].every((itemD) =>
          oldMarketData[resp_area].some(
            (oD) => JSON.stringify(oD) === JSON.stringify(itemD)
          )
        )
    );
  };

  const onUpdateGroup = (group, data, area = false) => {
    setLocalMarketData((prev) => {
      let temp = structuredClone(prev || { responsibility: {} });
      if (!temp.responsibility) {
        temp.responsibility = {};
      }
      temp.responsibility[group] = data;
      onUpdate({ responsibility: temp.responsibility });
      return temp;
    });

    setJsonObject((prev) =>
      prev.map((p) => {
        let t = structuredClone(p);
        if (!t.responsibility) {
          t.responsibility = {};
        }
        if (!t.responsibility?.[area]) {
          t.responsibility[area] = [];
        }
        if (t.responsibility_group === group) {
          let willWrite = isOldGroupSameAsItemsCheckbox(
            localMarketData?.responsibility?.[group],
            t.responsibility
          );
          if (willWrite) {
            t.responsibility = data;
          }
        }
        return t;
      })
    );

    setPatchData((prev) => {
      const newPatchData = { ...prev };
      if (!newPatchData.local_only_responsibility) {
        newPatchData.local_only_responsibility = [];
      }
      jsonObject.forEach((item) => {
        if (item.responsibility_group === group) {
          newPatchData.local_only_responsibility =
            newPatchData.local_only_responsibility.filter(
              (i) => i.item_id !== item.item_id
            );
          newPatchData.local_only_responsibility.push({
            ...item,
            responsibility: data
          });
        }
      });
      return newPatchData;
    });
  };

  const setOpenToggle = (groupName) => {
    setOpenGroup((prev) =>
      prev.includes(groupName)
        ? prev.filter((i) => i !== groupName)
        : [...prev, groupName]
    );
  };

  if (marketDataPending || itemsDataPending || loading) {
    return (
      <div className="h-full">
        <div className="flex h-[46px] border-r border-b px-8 w-full"></div>
        <div className="flex flex-col items-center pt-8">
          <Loadingsymbol />
          <p className="text-body font-yantramanaRegular text-primary">
            {loading ? t("loading" + " " + loadingText) : t("loading") + "..."}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="h-full relative">
      <ResponsibilityTabs
        selectedTab={selectedTab}
        setSelectedTab={(tab) => setSelectedTab(tab)}
        tabs={TABS}
      />
      <EditTabs
        edit={edit}
        setEdit={setEdit}
        handleSave={() => saveResponsibility(selectedTab)}
        handleDiscard={handleDiscard}
      />
      {selectedTab === "tab_responsibility" ? (
        <div className="h-full">
          <div className="flex flex-col h-full">
            {/* Header */}
            <ResponsibilityHeader
              struct={struct}
              setJsonObject={setJsonObject}
              ogJsonObject={ogJsonObject}
              companies={companies}
              openMains={openMains}
              setOpenMains={setOpenMains}
            />
            {/* Body */}
            <div className="flex flex-col px-[34px] pt-2 h-full overflow-auto pb-20 ">
              {groupOrder.map((group) => (
                <div className="mb-3" key={group}>
                  <ResponsibilityItemGroup
                    data={localMarketData?.responsibility?.[group]}
                    amounts={[
                      ogJsonObject.filter((i) => i.responsibility_group === group)
                        .length,
                      jsonObject.filter((i) => i.responsibility_group === group).length
                    ]}
                    group={group}
                    struct={struct}
                    companies={companies}
                    edit={edit}
                    openHeader={openMains}
                    setData={onUpdateGroup}
                    openToggle={openGroup.includes(group)}
                    setOpenToggle={setOpenToggle}
                  />
                  {openGroup.includes(group) && (
                    <div className="mb-6">
                      {jsonObject
                        .filter((i) => i.responsibility_group === group)
                        .map((i) => (
                          <ResponsibilityItem
                            key={i.item_id}
                            data={i}
                            struct={struct}
                            companies={companies}
                            edit={edit}
                            openHeader={openMains}
                            setData={onUpdateItem}
                          />
                        ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <ResponsibilityContacts
          edit={edit}
          companies={companies}
          setCompanies={setCompanies}
        />
      )}
    </div>
  );
}
