import { useState } from "react";
import { useTranslation } from "react-i18next";
import Icon from "../atoms/Icon";

const HoverableIcon = ({ listOfPredicates, predKey }) => {
  const { t } = useTranslation();
//   const [showdescription, setShowDescription] = useState(false);

  return (
    <div className="flex flex-col mx-1 mt-2">
      <div className="flex flex-row items-center ml-1 h-[19px] mb-[2px]">
        {predKey === "time" ? (
          <Icon size={12}>universal_time_regular</Icon>
        ) : (
          <Icon size={12}>other_euro_regular</Icon>
        )}
        {predKey === "time" ? (
          <p className="mx-2 font-yantramanavLight text-[14px] leading-[18.16px] text-darkGray">
            {t("time_dependency")}
          </p>
        ) : (
          <p className="mx-2 font-yantramanavLight text-[14px] leading-[18.16px] text-darkGray">
            {t("cost_dependency")}
          </p>
        )}
      </div>
      <div className="flex flex-row justify-between">
        <div className="font-yantramanavLight text-[12px] text-darkGray ml-1">
          {predKey === "time" ? (
            <p>{t("responsible_after_time")}</p>
          ) : (
            <p>{t("responsible_greater_than")}</p>
          )}
          {predKey === "time" ? (
            <p>{t("responsible_before_time")}</p>
          ) : (
            <p>{t("responsible_lesser_than")}</p>
          )}
        </div>
        <div className="font-yantramanavLight text-[12px] text-black">
          {predKey === "time" ? (
            <div className="mx-2">
              <p>
                {new Date(
                  listOfPredicates.filter((p) => p.operation === ">")[0].value
                ).toLocaleDateString("de-DE", {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric"
                })}
              </p>
              <p>
                {new Date(
                  listOfPredicates.filter((p) => p.operation === "<")[0].value
                ).toLocaleDateString("de-DE", {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric"
                })}
              </p>
            </div>
          ) : (
            <div className="mr-2">
              <p>
                {listOfPredicates.filter((p) => p.operation === ">")[0].value}
              </p>
              <p>
                {listOfPredicates.filter((p) => p.operation === "<")[0].value}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );

//   return (
//     <div
//       onMouseEnter={() => setShowDescription(true)}
//       onMouseLeave={() => setShowDescription(false)}
//       className="hover:cursor-pointer "
//     >
//       {predKey === "time" ? (
//         <Icon className="mx-2">time_bold</Icon>
//       ) : (
//         <Icon className="mx-2">euro_bold</Icon>
//       )}
//       {showdescription ? (
//         <div className="relative">
//           <div className="absolute top-[5px] left-0 z-40 w-[300px] rounded-lg bg-white p-3 shadow-[rgba(0,0,0,0.10)_0px_2px_15px_0px] flex flex-row">
//             <p className="font-yantramanavLight text-[16px] leading-[20.75px] text-darkGray ">
//               {predKey === "time" ? (
//                 <p>{t("responsible_after_time")}</p>
//               ) : (
//                 <p>{t("responsible_greater_than")}</p>
//               )}
//               {predKey === "time" ? (
//                 <p>{t("responsible_before_time")}</p>
//               ) : (
//                 <p>{t("responsible_lesser_than")}</p>
//               )}
//             </p>
//             <p className="font-yantramanavLight text-[16px] text-black">
//               {predKey === "time" ? (
//                 <div className="">
//                   <p className="text-black text-sm font-yantramanavLight text-lef mx-2">
//                     {new Date(
//                       listOfPredicates.filter(
//                         (p) => p.operation === ">"
//                       )[0].value
//                     ).toLocaleDateString("de-DE", {
//                       year: "numeric",
//                       month: "numeric",
//                       day: "numeric"
//                     })}
//                   </p>
//                   <p className="text-black text-sm font-yantramanavLight text-lef mx-2">
//                     {new Date(
//                       listOfPredicates.filter(
//                         (p) => p.operation === "<"
//                       )[0].value
//                     ).toLocaleDateString("de-DE", {
//                       year: "numeric",
//                       month: "numeric",
//                       day: "numeric"
//                     })}
//                   </p>
//                 </div>
//               ) : (
//                 <div className="">
//                   <p className="text-black text-sm font-yantramanavLight text-lef mx-2">
//                     {
//                       listOfPredicates.filter((p) => p.operation === ">")[0]
//                         .value
//                     }
//                   </p>
//                   <p className="text-black text-sm font-yantramanavLight text-lef mx-2">
//                     {
//                       listOfPredicates.filter((p) => p.operation === "<")[0]
//                         .value
//                     }
//                   </p>
//                 </div>
//               )}
//             </p>
//           </div>
//         </div>
//       ) : null}
//     </div>
//   );
};

const Responsibility = ({ initialValue = {}, onChange, disabled = false }) => {
  // always dissabled?
  const { t } = useTranslation();
  const [value] = useState(initialValue);

  const getPredWith = (key, predList) => {
    return predList.filter((pr) => pr.key === key);
  };

  let areas = Object.keys(value).map((resp_area) => {
    return (
      <div key={resp_area} className="my-px py-1 w-full max-w-[200px]">
        <h5 className="text-primary font-yantramanavRegular leading-5 text-[16px] mb-1">
          {t(resp_area)}
        </h5>
        {value[resp_area].map((resp, index) => {
          let active_style = "";
          // if(resp.predicates && resp.predicates.filter(pr => pr.key === "time").length > 0){
          //     if(resp.predicates.filter(pr => pr.key === "time" && pr.operation === "<")[0].value >  Date.now() &&
          //     resp.predicates.filter(pr => pr.key === "time" && pr.operation === ">")[0].value <  Date.now()){
          //         // ther time is matching
          //     }else {
          //         // the time is not in effect
          //         active_style = "bg-slate-100"
          //     }
          // }
          return (
            <div
              key={index}
              className={
                "block max-w-sm bg-white border border-lightGray rounded-sm px-1 py-2 shadow mb-2" +
                active_style
              }
            >
              <div className="flex flex-col">
                <div className="flex flex-row justify-between items-start mx-1">
                  <div className="flex flex-col mx-1">
                    <p className="text-black text-body font-yantramanavRegular">
                      {t(resp.main)}
                    </p>
                    {resp.second ? (
                      <p className="text-darkGray text-body font-yantramanavRegular mt-1">
                        {t(resp.second)}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
              {/* {resp?.predicates.length > 0 ? <div className="flex flex-row justify-around"> */}
              <div className="divide-y divide-dashed">
                <div className="my-2 mx-1 flex flex-col">
                  {resp.email ? (
                    <p className="text-darkGray text-[12px] leading-4 font-yantramanavRegular text-lef mx-1">
                      {t("phone")}:{" "}
                      <span className="font-yantramanavLight">
                        {t(resp.email)}
                      </span>
                    </p>
                  ) : null}
                  {resp.phone ? (
                    <p className="text-darkGray text-[12px] leading-4 font-yantramanavRegular text-lef mx-1">
                      {t("email")}:{" "}
                      <span className="font-yantramanavLight">
                        {t(resp.phone)}
                      </span>
                    </p>
                  ) : null}
                </div>

                {resp?.predicates.length > 0 ? (
                  <div className="">
                    {getPredWith("time", resp.predicates).length > 0 ? (
                      <HoverableIcon
                        predKey="time"
                        listOfPredicates={getPredWith("time", resp.predicates)}
                      />
                    ) : null}
                    {getPredWith("cost", resp.predicates).length > 0 ? (
                      <HoverableIcon
                        predKey="cost"
                        listOfPredicates={getPredWith("cost", resp.predicates)}
                      />
                    ) : null}
                  </div>
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
    );
  });

  return (
    <>
      <div className="flex flex-row flex-wrap gap-x-4">{areas}</div>
      {disabled ? null : (
        <div className="font-yantramanavRegular text-[16px] text-left text-lightGray">
          {t("edits_are_made_from_the_responsibility_page")}
        </div>
      )}
    </>
  );
};

export default Responsibility;
