import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Icon from "../../../components/atoms/Icon";
import GalleryViewItem from "./GalleryViewItem";

export default function GalleryView({ data, isPending, onClick }) {
  const { t } = useTranslation();
  const [showScrollTop, setShowScrollTop] = useState(false);
  const scrollToTopRef = useRef(null);

  useEffect(() => {
    function handleScroll(event) {
      if (scrollToTopRef.current.scrollTop > 300) {
        setShowScrollTop(true);
      }
      if (scrollToTopRef.current.scrollTop <= 300) {
        setShowScrollTop(false);
      }
    }
    if (scrollToTopRef.current) {
      scrollToTopRef.current.addEventListener("scroll", handleScroll);
    }
  }, []);

  return (
    <div className="h-full">
      <div
        className="overflow-auto no-scrollbar h-[calc(100%-103px)] px-3 relative pt-4"
        ref={scrollToTopRef}
      >
        <AnimatePresence>
          {showScrollTop && (
            <motion.div
              onClick={() => {
                scrollToTopRef.current.scroll({
                  top: 0,
                  left: 0,
                  behavior: "smooth"
                });
              }}
              initial={{ opacity: 0, translateY: "-200%" }}
              animate={{ opacity: 1, translateY: "0%" }}
              transition={{ duration: 0.5, ease: "easeInOut" }}
              exit={{
                opacity: 0,
                transition: { duration: 0.3, ease: "easeInOut" }
              }}
              className={[
                "sticky top-0 ml-auto z-10 cursor-pointer ",
                "flex gap-2  border border-mediumGray bg-white rounded py-1 px-3 shadow-[rgba(0,0,0,0.40)_0px_2px_6px_1px] w-fit active:scale-95"
              ].join(" ")}
            >
              <Icon size={15}>direction_arrow_up_regular</Icon>
              <p className="font-yantramanavRegular">{t("scroll_to_top")}</p>
            </motion.div>
          )}
        </AnimatePresence>
        <div className="grid grid-col-1 tablet:grid-cols-2 xlTablet:grid-cols-3 laptop:grid-cols-4 gap-6 pb-2 px-1 absolute top-0 left-0 mx-3 mt-4">
          {data.map((photobook) => (
            <GalleryViewItem
              key={photobook.entry_id}
              photobook={photobook}
              isPending={isPending}
              onClick={() => onClick(photobook)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
